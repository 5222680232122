import { PageNotFoundResource } from "../locales";

export const pageNotFound: PageNotFoundResource = {
    title: "Ups!",
    subTitle: "Wir können die Seite nicht finden, die du suchst.",
    errorCode: "Fehlercode: 404",
    usefulLinks: "Hier sind stattdessen einige hilfreiche Links:",
    links: {
        home: "Startseite",
        order: "Bestellung",
        myAccount: "Kundendaten",
    },
};
