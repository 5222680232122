import { PDFResource } from "../locales";

export const pdf: PDFResource = {
    fileName: {
        deliveryCalendar: "calendrier-livraison.pdf",
        order: "commande-{{orderNo}}.pdf",
        products: {
            "deli": "deli-produits.pdf",
            "food-to-go": "food-to-go-produits.pdf",
            "salad-bar": "bar-a-salades-produits.pdf",
        },
        planogram: "planogramme.pdf",
    },
};
