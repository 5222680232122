import { createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { groupBy, notEmpty, unique } from "../../utils/array";
import { productSortComparer } from "../../utils/product";
import { RootState } from "../configureStore";
import { Category, FilterType, OrderStatus, Product, ProductType } from "../types";
import { cartEntityId, selectCartEntities, selectCartItems } from "./cartSlice";
import { selectAllCategories } from "./categoriesSlice";
import { selectClosedInfos } from "./closedProductsSlice";
import { selectTimeZone } from "./customerSlice";
import { selectAllDeliveryDates } from "./deliveryDatesSlice";
import { selectFavoriteEntities } from "./favoritesSlice";
import { selectDeliveryDate, selectIsZeroPriceOrder } from "./orderDetailsSlice";
import { selectHistoricOrders } from "./ordersSlice";
import { ProductState, selectAllProducts } from "./productsSlice";
import { selectLocale } from "./userSlice";

export const selectProductFilter = (state: RootState) => state.products.filter;

export const selectDeliFilter = (state: RootState) => state.products.filter[ProductType.Deli];

export const selectFoodToGoFilter = (state: RootState) =>
    state.products.filter[ProductType.FoodToGo];

export const selectSaladBarFilter = (state: RootState) =>
    state.products.filter[ProductType.SaladBar];

export const selectProductsLoading = (state: RootState) => state.products.loading;

export const selectProductsError = (state: RootState) => state.products.error;

export const selectProductTypes = createSelector(selectAllProducts, products =>
    unique(products.flatMap(p => p.types)),
);

export const selectProductSearch = (state: RootState) => state.products.search;

export const selectPlanogramProducts = createSelector(selectAllProducts, products =>
    products.filter(p => p.isInPlanogram),
);

const selectProducts = createSelector(
    selectAllProducts,
    selectIsZeroPriceOrder,
    (products, isZeroPriceOrder) => {
        if (isZeroPriceOrder) {
            return products.map(p => ({ ...p, price: 0, comparisonPrice: 0 }));
        }
        return products;
    },
);

const selectSearchProducts = createSelector(
    selectProducts,
    selectProductSearch,
    (products, search) => {
        if (search.length <= 2) {
            return products;
        }
        return products.filter((product: Product) => {
            const lowerCaseSearch = search.toLowerCase();
            return (
                product.name.toLowerCase().includes(lowerCaseSearch) ||
                product.productId.includes(lowerCaseSearch)
            );
        });
    },
);

export const selectOrderableProductAssortments = createSelector(
    selectAllDeliveryDates,
    selectDeliveryDate,
    (allDeliveryDates, deliveryDate) =>
        allDeliveryDates
            .filter(dd => dd.deliveryDate === deliveryDate)
            .flatMap(dd => dd.assortments.map(a => a.assortmentId)),
);

export const selectProductPastDeadline = (productId: string) =>
    createSelector(
        selectAllProducts,
        selectAllDeliveryDates,
        selectDeliveryDate,
        (products, deliveryDates, deliveryDate) => {
            const product = products.find(p => p.productId === productId);
            if (!product) return true;
            const deliveryAssortments = (
                deliveryDates.find(dd => dd.deliveryDate === deliveryDate)?.assortments ?? []
            ).map(da => ({
                assortmentId: da.assortmentId,
                deadline: DateTime.fromISO(da.deadline),
            }));
            const now = DateTime.now();

            return product.assortments.every(a => {
                const deadline = deliveryAssortments.find(da => da.assortmentId === a)?.deadline;
                if (!deadline) return true;
                return now > deadline;
            });
        },
    );

export const selectSaladBarProducts = createSelector(
    makeTabProductsSelector(ProductType.SaladBar),
    selectSaladBarFilter,
    selectAllCategories,
    selectLocale,
    (products, filter, categories, locale) =>
        toCategoryProducts(
            categories,
            filter === FilterType.planogram
                ? products.filter(p => p.isInPlanogram)
                : filter === FilterType.accessories
                ? products.filter(p => p.isNonFood && !p.isInPlanogram)
                : products,
            locale,
        ),
);

const DRESSINGS_CAT_ID = "p_195";
export const selectAllAvailableReplacementProducts = createSelector(
    makeTabProductsSelector(ProductType.SaladBar),
    selectAllCategories,
    selectLocale,
    selectClosedInfos,
    selectCartItems,
    selectSaladBarProducts,
    createSelector(
        (state: RootState) => state.products,
        (state: ProductState) => state.selectedRotationProducts,
    ),
    selectOrderableProductAssortments,
    (
        products,
        categories,
        locale,
        closedInfos,
        cartItems,
        saladBarProducts,
        selectedRotationProducts,
        orderableProductAssortments,
    ) =>
        toCategoryProducts(
            categories,
            products.filter(
                p =>
                    !p.isNonFood &&
                    !p.categoryIds.includes(DRESSINGS_CAT_ID) &&
                    !closedInfos.find(ci => ci.productId === p.productId) &&
                    !cartItems.find(ci => ci.productId === p.productId) &&
                    !productVisible(p.productId, saladBarProducts, selectedRotationProducts) &&
                    p.assortments.some(a => orderableProductAssortments.includes(a)),
            ),
            locale,
        ),
);

const DELI_MENU_CAT_ID = "p_345";
export const selectDeliProducts = createSelector(
    makeTabProductsSelector(ProductType.Deli),
    selectDeliFilter,
    selectAllCategories,
    selectFavoriteEntities,
    selectLocale,
    (products, filter, categories, favorites, locale) =>
        toCategoryProducts(
            categories.filter(c => c.categoryId !== DELI_MENU_CAT_ID),
            filter === FilterType.favorites
                ? products.filter(({ productId }) => favorites[productId] !== undefined)
                : products,
            locale,
        ),
);

export const selectFoodToGoProducts = createSelector(
    makeTabProductsSelector(ProductType.FoodToGo),
    selectFoodToGoFilter,
    selectAllCategories,
    selectFavoriteEntities,
    selectLocale,
    (products, filter, categories, favorites, locale) =>
        toCategoryProducts(
            categories,
            filter === FilterType.favorites
                ? products.filter(({ productId }) => favorites[productId] !== undefined)
                : products,
            locale,
        ),
);

export const selectUpcomingDeliveries = createSelector(
    selectHistoricOrders,
    selectTimeZone,
    (state: RootState) => state.products.selectedRotationProducts,
    (orders, tz, selectedRotationProduts) => {
        const today = DateTime.now().setZone(tz).toISODate();
        const upcomingDeliveries = orders.filter(o => o.deliveryDate >= today);
        const byDeliveryDate = groupBy(upcomingDeliveries, o => o.deliveryDate);
        const dates = Object.keys(byDeliveryDate).sort((a, b) => (a === b ? 0 : a < b ? -1 : 1));

        const countByProduct = upcomingDeliveries.reduce((acc, order) => {
            const deliveryDate = order.deliveryDate;
            for (const row of order.rows) {
                if (row.status === OrderStatus.Canceled) continue;
                if (!acc[row.productId]) {
                    acc[row.productId] = {};
                }
                if (!acc[row.productId][deliveryDate]) {
                    acc[row.productId][deliveryDate] = 0;
                }
                const count = (row.deliveredQuantity ?? row.orderedQuantity) / row.orderMultiple;
                acc[row.productId][deliveryDate] += count;
            }
            return acc;
        }, {} as { [productId: string]: { [deliveryDate: string]: number } });

        const getCount = (productId: string, deliveryDate: string) =>
            countByProduct[productId]?.[deliveryDate];

        const isUpcoming = (productId: string, deliveryDate: string) =>
            (getCount(productId, deliveryDate) ?? 0) > 0;

        return {
            dates,
            isUpcoming,
            getCount,
            getTableColumns: (products: Array<{ productId: string }>) => {
                return dates
                    .filter(date =>
                        products.some(({ productId }) => {
                            const rotation = selectedRotationProduts[productId];
                            return isUpcoming(rotation ?? productId, date);
                        }),
                    )
                    .slice(0, 3);
            },
        };
    },
);

export const selectSelectedRotationProducts = (state: RootState) =>
    state.products.selectedRotationProducts;

export const selectOriginalProduct = (productId: string) =>
    createSelector(
        selectAllProducts,
        selectSelectedRotationProducts,
        (products, selectedRotationProducts) => {
            const originalProductId = Object.entries(selectedRotationProducts).find(
                ([_, rot]) => rot === productId,
            )?.[0];
            return products.find(p => p.productId === originalProductId);
        },
    );

export const selectHasAccessories = createSelector(
    selectAllProducts,
    products => products.filter(p => p.isNonFood && !p.isInPlanogram).length !== 0,
);

// Returns a list of products to be displayed in the list of rotation products
// in the 'change product' dialog
export const selectChangeProductMenuProducts = createSelector(
    [selectAllProducts, selectSelectedRotationProducts, (_, product: Product) => product],
    (allProducts, selectedRotationProducts, product) => {
        const replacedProductId = Object.entries(selectedRotationProducts).find(
            ([_, rot]) => rot === product.productId,
        )?.[0];
        const replacedProduct = allProducts.find(p => p.productId === replacedProductId);

        const replacingProductId = selectedRotationProducts[product.productId];

        const rotationProducts = allProducts.filter(p =>
            product.rotationProducts.includes(p.productId),
        );

        return [
            ...(replacedProduct ? [replacedProduct] : []),
            ...(replacingProductId ? [product] : []),
            ...rotationProducts.filter(
                p =>
                    (!replacingProductId || p.productId !== replacingProductId) &&
                    (!replacedProduct || p.productId !== replacedProduct.productId),
            ),
        ];
    },
);

export const selectAvailableRotationProducts = createSelector(
    [selectAllProducts, selectClosedInfos, (_, product: Product) => product],
    (allProducts, closedInfos, product) =>
        allProducts.filter(
            p =>
                product.rotationProducts.includes(p.productId) &&
                !closedInfos.find(ci => ci.productId === p.productId),
        ),
);

function makeTabProductsSelector(productType: ProductType) {
    return createSelector(
        selectSearchProducts,
        selectCartEntities,
        createSelector(
            (state: RootState) => state.products,
            products => products.selectedRotationProducts,
        ),
        (products, cartItems, selectedRotationProducts) =>
            products
                .filter(({ types }) => types.includes(productType))
                .filter(({ productId }) => {
                    const rotationProduct = selectedRotationProducts[productId];
                    return (
                        cartItems[cartEntityId({ productType, productId })] === undefined &&
                        (!rotationProduct ||
                            cartItems[cartEntityId({ productType, productId: rotationProduct })] ===
                                undefined)
                    );
                }),
    );
}

function toCategoryProducts(categories: Category[], products: Product[], locale: string) {
    const productsByCategory = groupByCategory(products);
    return categories
        .map(cp => ({
            category: cp.name,
            products: (productsByCategory[cp.categoryId] ?? []).sort(productSortComparer(locale)),
        }))
        .filter(row => notEmpty(row.products));
}

function groupByCategory(products: Product[]) {
    const record: Record<string, Product[]> = {};
    for (const product of products) {
        for (const category of product.categoryIds) {
            if (!record[category]) {
                record[category] = [];
            }
            record[category].push(product);
        }
    }
    return record;
}

// Returns true if a product appears in the list, taking replaced products into consideration
function productVisible(
    productId: string,
    products: ReturnType<typeof toCategoryProducts>,
    selectedRotationProducts: Record<string, string | undefined>,
) {
    return products
        .flatMap(p => p.products)
        .map(p => p.productId)
        .includes(
            selectedRotationProducts[productId] ??
                Object.entries(selectedRotationProducts).find(
                    ([_, rot]) => rot === productId,
                )?.[0] ??
                productId,
        );
}
