import { TableCell } from "@material-ui/core";
import { DateTime } from "luxon";
import { FC } from "react";
import { Week } from "../../../state/slices/orderMatsSlice";
import { Product } from "../../../state/types";
import { OrderMatCell } from "./OrderMatCell";

type Props = {
    product: Product;
    selectedWeek: Week;
    startOfWeek: DateTime;
};

export const OrderMatCells: FC<Props> = ({ product, selectedWeek, startOfWeek }) => {
    return (
        <>
            {[1, 2, 3, 4, 5].map(dayOfWeek => (
                <TableCell key={dayOfWeek}>
                    <OrderMatCell
                        product={product}
                        dayOfWeek={dayOfWeek}
                        selectedWeek={selectedWeek}
                        startOfSelectedWeek={startOfWeek}
                    />
                </TableCell>
            ))}
        </>
    );
};
