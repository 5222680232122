import { useEffect, useState } from "react";

export const useDebounce = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (debouncedValue !== value) {
                setDebouncedValue(value);
            }
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [value, delay, debouncedValue]);

    return { debouncedValue, setDebouncedValue };
};

export const useDebounceState = <T>(
    initialValue: T,
    options: {
        delay: number;
        onDebounceChange: (value: T) => void;
    },
): [value: T, setValue: (v: T) => void, debouncedValue: T] => {
    const [value, setValue] = useState(initialValue);
    const [debouncedValue, setDebouncedValue] = useState(initialValue);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, options.delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, options.delay]);

    useEffect(() => {
        options.onDebounceChange(debouncedValue);
        // eslint-disable-next-line
    }, [debouncedValue]);

    return [value, setValue, debouncedValue];
};
