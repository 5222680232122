import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPage } from "./utils/googleAnalytics";

export const GAPageTracker = () => {
    const location = useLocation();

    useEffect(() => {
        trackPage(location.pathname);
    }, [location.pathname]);

    return null;
};
