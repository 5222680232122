import { CustomerSupportResource } from "../locales";

export const customerSupport: CustomerSupportResource = {
    title: "Bienvenue au service client de Picadeli",
    contactUs: "Contactez-nous",
    openingHours: "Horaires d'ouvertures",
    email: "E-mail: {{value}}",
    phone: "Phone: {{value}}",
    openingHoursStart: "Ouvre à : {{value}}",
    openingHoursEnd: "Ferme à : {{value}}",
};
