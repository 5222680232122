import { LandingResource } from "../locales";

export const landing: LandingResource = {
    news: "Nyheter",
    messages: "Meldinger",
    home: "Hjem",
    showAllNews: "Vis alle nyheter ({{count}})",
    showAllMessages: "Vis alle",
    myMessages: "Mine meldinger",
    noMessages: "Du har ingen meldinger",
    contactUs: "Kontakt oss",
};
