import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { ThemeType } from "../theme";

const useStyles = makeStyles<ThemeType, { spacing: number }>({
    container: {
        display: "flex",
        flexWrap: "wrap",
        marginLeft: props => -props.spacing,
        marginRight: props => -props.spacing,
    },
    wrapper: {
        padding: props => props.spacing,
    },
});

// eslint-disable-next-line
type Item = any;
export const FlexWrap: FC<{
    items?: Item[];
    itemsPerRow: number;
    spacing: number;
    rowKey: (item: Item) => string;
    renderRow: (item: Item) => JSX.Element;
}> = ({ spacing, items = [], rowKey, renderRow, itemsPerRow }) => {
    const classes = useStyles({ spacing });

    if (items.length === 0) {
        return null;
    }

    return (
        <div className={classes.container}>
            {items.map(item => (
                <div
                    className={classes.wrapper}
                    key={rowKey(item)}
                    style={{ width: `${100 / itemsPerRow}%` }}
                >
                    {renderRow(item)}
                </div>
            ))}
        </div>
    );
};
