import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FC } from "react";
import { ThemeType } from "../theme";

type Props = {
    fullHeight?: boolean;
    src: string;
};

const useStyles = makeStyles<ThemeType, Props>({
    image: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        width: "100%",
        height: props => (props.fullHeight ? "100%" : 300),
        backgroundImage: props => `url(${props.src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
});

export const BackgroundImage: FC<Props> = ({ src, fullHeight }) => {
    const classes = useStyles({ src, fullHeight });
    return <div className={classes.image} />;
};
