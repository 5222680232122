import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { InnerHTML } from "../../components/InnerHTML";
import { ModalHeader } from "../../components/modal/ModalHeader";
import { SidebarModal } from "../../components/modal/SidebarModal";
import { Message } from "../../state/types";
import { CoverImage } from "./CoverImage";

const useStyles = makeStyles({
    wrapper: {
        width: 360,
    },
    image: {
        marginTop: 14,
    },
});

type Props = { item?: Message; isOpen: boolean; close: () => void };
export const MessageModal: FC<Props> = ({ item, isOpen, close }) => {
    const classes = useStyles();
    const { t } = useTranslation("landing");
    return (
        <SidebarModal className={classes.wrapper} isOpen={isOpen} onBackdropClick={close}>
            <ModalHeader title={item?.title ?? ""} overline={t("messages")} close={close} />
            {item?.imageUrl && (
                <CoverImage className={classes.image} imageUrl={item.imageUrl} imageWidth="100%" />
            )}
            {item?.body && <InnerHTML html={item.body} />}
        </SidebarModal>
    );
};
