import { MyAccountResource } from "../locales";

export const myAccount: MyAccountResource = {
    accountSettings: "Tilin asetukset",
    addNewRecipient: "Lisää uusi vastaanottaja",
    alarmsAndNotifications: "Hälytykset ja ilmoitukset",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Ruokaa mukaan",
        saladBar: "Salaattibaari",
    },
    customerNo: "Asiakasnumero",
    customerUserIsMissing: "Asiakkaalle ei ole rekisteröity käyttäjää",
    email: "Sähköposti",
    emailInvoice: "Sähköpostilasku",
    foodAlarms: "Ruokahälytykset",
    language: "Kieli",
    missingContactPerson: "Yhteyshenkilö puuttuu",
    missingInformation: "Tietoja puuttuu",
    name: "Nimi",
    orderConfirmations: "Tilausvahvistukset",
    passwordReset: "Salasanan vaihto",
    passwordResetSentTo: "Salasanan vaihtopyyntö lähetetty vastaanottajalle",
    phone: "Puhelin",
    purchaseStats: "Ostotilastot",
    recipient: "Vastaanottaja",
    responsibleSingular: "Vastuuhenkilö",
    responsiblePlural: "Vastuuhenkilö",
    storeManager: "Kauppias / Myymäläpäällikkö",
    storeManagerDescription:
        "Käytetään tärkeisiin ilmoituksiin, jotka koskevat liikesuhteen hintamuutoksia tai vastaavia.",
    updateContactInfo: {
        success: "Tallennus onnistui.",
        error: "Tallentaminen ei onnistunut.",
    },
    yourStore: "Kauppasi",
};
