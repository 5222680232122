import { PDFResource } from "../locales";

export const pdf: PDFResource = {
    fileName: {
        deliveryCalendar: "leveranskalender.pdf",
        order: "order-{{orderNo}}.pdf",
        products: {
            "deli": "deli-produkter.pdf",
            "food-to-go": "food-to-go-produkter.pdf",
            "salad-bar": "salladsbar-produkter.pdf",
        },
    },
};
