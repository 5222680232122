import { Button, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../../../components/Container";
import { colors } from "../../../constants/colors";
import { CartHeader } from "./CartHeader";
import { CartHeaderDivider } from "./CartHeaderDivider";
import { DeliveryDateColumn } from "./delivery-date-column/DeliveryDateColumn";
import { HeaderColumn } from "./HeaderColumn";
import { PriceColumn } from "./PriceColumn";
import { WeightColumn } from "./weight-column/WeightColumn";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 0,
        backgroundColor: colors.white,
    },
    fixed: {
        backgroundColor: colors.white,
        padding: theme.spacing(1, 1),
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        zIndex: 1,
        borderBottom: `2px solid ${colors.heroGreen}`,
    },
    showCartBtn: {
        margin: "auto",
    },
    cartTitle: {
        marginLeft: theme.spacing(2),
        marginTop: "auto",
        marginBottom: "auto",
    },
}));

type Props = {
    onShowCartClick: () => void;
};

export const StickyCartHeader: FC<Props> = ({ onShowCartClick }) => {
    const classes = useStyles();
    const { t } = useTranslation("order");

    return (
        <Slide direction="down" in={true} mountOnEnter unmountOnExit data-testid="sticky-cart">
            <div className={classes.fixed}>
                <Container className={classes.container}>
                    <CartHeader classes={{ title: classes.cartTitle }}>
                        <DeliveryDateColumn />
                        <CartHeaderDivider />
                        <WeightColumn />
                        <CartHeaderDivider />
                        <PriceColumn />
                        <CartHeaderDivider />
                        <HeaderColumn>
                            <Button
                                className={classes.showCartBtn}
                                variant="contained"
                                color="primary"
                                onClick={onShowCartClick}
                            >
                                {t("cart.showCart")}
                            </Button>
                        </HeaderColumn>
                    </CartHeader>
                </Container>
            </div>
        </Slide>
    );
};
