import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isDefined } from "../../utils/array";
import { productSortComparer } from "../../utils/product";
import { RootState } from "../configureStore";
import { Product, ProductId, ProductMenuItem } from "../types";
import { ClosedInfo } from "./closedProductsSlice";
import { fetchProducts, selectProductEntities } from "./productsSlice";
import { selectLocale } from "./userSlice";

export type OrderSuggestion = { excessWeight: number; salesUnitQuantity: number; product: Product };

const adapter = createEntityAdapter<ProductMenuItem & { quantity?: number }>({
    selectId: product => product.menuProductId,
});

const productMenuSlice = createSlice({
    name: "productMenu",
    initialState: adapter.getInitialState<{
        menuQuantity: Record<ProductId, number>;
        orderSuggestion: Record<ProductId, OrderSuggestion>;
    }>({
        menuQuantity: {},
        orderSuggestion: {},
    }),
    reducers: {
        onCreateOrderSuggestion: (
            state,
            action: PayloadAction<{
                productMap: Record<ProductId, Product | undefined>;
                productMenuMap: Record<ProductId, ProductMenuItem | undefined>;
                closedInfos: Record<ProductId, ClosedInfo | undefined>;
            }>,
        ) => {
            const { productMap, productMenuMap, closedInfos } = action.payload;
            state.orderSuggestion = toOrderSuggestion(
                productMap,
                productMenuMap,
                closedInfos,
                state.menuQuantity,
            );
        },
        onOrderSuggestionQuantityChange: (
            state,
            action: PayloadAction<{ productId: string; quantity: number }>,
        ) => {
            const { productId, quantity } = action.payload;
            const currentEntry = (state.orderSuggestion ?? {})[productId];
            if (!currentEntry) {
                throw Error("Current entry is missing");
            }
            const product = currentEntry.product;
            const newQty = Math.max(0, quantity);
            const delta = newQty - currentEntry.salesUnitQuantity;
            state.orderSuggestion[productId].salesUnitQuantity = newQty;
            state.orderSuggestion[productId].excessWeight =
                currentEntry.excessWeight + delta * product.orderMultiple * product.drainedWeight;
        },
        onMenuQuantityChange: (
            state,
            action: PayloadAction<{ productId: string; quantity: number }>,
        ) => {
            const { productId, quantity } = action.payload;
            const newMap = { ...state.menuQuantity, [productId]: quantity };
            if (quantity <= 0) {
                delete newMap[productId];
            }
            state.menuQuantity = newMap;
        },
        clearOrderSuggestion: state => {
            state.orderSuggestion = {};
        },
        clearSelections: state => {
            state.menuQuantity = {};
            state.orderSuggestion = {};
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload.productMenu);
        });
    },
});

function toOrderSuggestion(
    productMap: Record<ProductId, Product | undefined>,
    menuItemMap: Record<ProductId, ProductMenuItem | undefined>,
    closedInfos: Record<ProductId, ClosedInfo | undefined>,
    menuPortionsMap: Record<ProductId, number>,
): Record<ProductId, OrderSuggestion> {
    const menuPortions = Object.entries(menuPortionsMap).map(([productId, quantity]) => ({
        portionQuantity: quantity,
        menuItem: menuItemMap[productId],
        product: productMap[productId],
    }));

    const accumulatedSalesUnitQty: Record<ProductId, number> = {};
    for (const { menuItem, portionQuantity } of menuPortions) {
        for (const productItem of menuItem?.products ?? []) {
            const product = productMap[productItem.productId];
            const currentQuantity = accumulatedSalesUnitQty[productItem.productId] ?? 0;
            const newQuantity = (productItem.quantity / product!.orderMultiple) * portionQuantity;
            accumulatedSalesUnitQty[productItem.productId] = currentQuantity + newQuantity;
        }
    }

    return Object.entries(accumulatedSalesUnitQty).reduce((map, [productId, salesUnitQty]) => {
        const ceiledSalesUnitQty = Math.ceil(salesUnitQty);
        const product = productMap[productId];
        const isClosed = closedInfos[productId] !== undefined;
        const weight = product!.orderMultiple * product!.drainedWeight;
        return {
            ...map,
            [productId]: {
                salesUnitQuantity: isClosed ? 0 : ceiledSalesUnitQty,
                excessWeight: isClosed
                    ? -(salesUnitQty * weight)
                    : (ceiledSalesUnitQty - salesUnitQty) * weight,
                product,
            },
        };
    }, {});
}

export const { selectAll: selectProductMenu, selectEntities: selectMenuEntities } =
    adapter.getSelectors<RootState>(state => state.productMenu);

export const {
    onMenuQuantityChange,
    clearSelections,
    clearOrderSuggestion,
    onOrderSuggestionQuantityChange,
    onCreateOrderSuggestion,
} = productMenuSlice.actions;

export const selectMenuQuantityByProductId = (productId: string) => (state: RootState) =>
    state.productMenu.menuQuantity[productId];

export const selectMenuQuantity = (state: RootState) => state.productMenu.menuQuantity;

export const selectOrderSuggestions = (state: RootState) =>
    Object.values(state.productMenu.orderSuggestion);

export const selectOrderSuggestionById = (productId: string) => (state: RootState) =>
    state.productMenu.orderSuggestion[productId];

export const selectOrderSuggestionProducts = createSelector(
    (state: RootState) => state.productMenu.orderSuggestion,
    selectLocale,
    (orderSuggestion, locale) =>
        Object.values(orderSuggestion)
            .map(p => p.product)
            .sort(productSortComparer(locale)),
);

export const selectMenuProducts = createSelector(
    selectProductMenu,
    selectProductEntities,
    selectLocale,
    (productMenu, productMap, locale) =>
        productMenu
            .map(p => productMap[p.menuProductId])
            .filter(isDefined)
            .sort(productSortComparer(locale)),
);

export const productMenuReducer = productMenuSlice.reducer;
