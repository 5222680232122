import { makeStyles } from "@material-ui/core/styles";
import UrgentIcon from "@material-ui/icons/Warning";
import cn from "classnames";
import React, { FC } from "react";
import { Body1, Body2 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { useAppSelector } from "../../state/configureStore";
import { selectCustomer } from "../../state/slices/customerSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { Message } from "../../state/types";
import { formatDateTime } from "../../utils/formatters";
import { InnerHtmlOverflow } from "./OverflowInnerHtml";

const useStyles = makeStyles({
    wrapper: {
        overflow: "hidden",
        cursor: "pointer",
    },
    urgentIcon: {
        marginRight: 10,
        backgroundColor: "#FFF1E6",
        borderRadius: 10,
        width: 36,
        height: 36,
        padding: 8,
        color: "#EE6E11",
    },
    header: {
        display: "flex",
        alignItems: "center",
    },
    content: {
        "overflow": "hidden",
        "maxHeight": 42,
        "minHeight": 42,
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": 2,
        "textOverflow": "ellipsis",
        "& p": {
            margin: 0,
        },
        "marginTop": 10,
    },
    title: {
        lineHeight: "21px",
    },
    timestamp: {
        marginLeft: "auto",
    },
    read: {
        color: colors.lightGrey,
    },
});

type Props = { message: Message; onClick: (message: Message) => void };
export const ShortMessage: FC<Props> = ({ message, onClick }) => {
    const locale = useAppSelector(selectLocale);
    const customer = useAppSelector(selectCustomer);
    const classes = useStyles();
    return (
        <div
            className={cn(classes.wrapper, { [classes.read]: message.read })}
            onClick={() => onClick(message)}
        >
            <div className={classes.header}>
                {message.urgent && <UrgentIcon className={classes.urgentIcon} />}
                <Body1 size={18} className={classes.title}>
                    {message.title}
                </Body1>
                <Body2 className={classes.timestamp} italic>
                    {formatDateTime(message.published, locale, customer?.timeZone)}
                </Body2>
            </div>
            <InnerHtmlOverflow html={message.body} />
        </div>
    );
};
