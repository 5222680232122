import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import { Category } from "../types";

export const fetchCategories = createAsyncThunk("fetch-categories", async () => {
    return get<Category[]>(Endpoint.categories);
});

const categoryAdapater = createEntityAdapter<Category>({
    selectId: category => category.categoryId,
});

const categoriesSlice = createSlice({
    name: "category",
    initialState: categoryAdapater.getInitialState<{ error?: string }>({}),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchCategories.fulfilled, (state, action) => {
            categoryAdapater.setAll(state, action.payload);
        });
        addCase(fetchCategories.rejected, (state, { error }) => {
            state.error = error.message;
        });
    },
});

export const { selectAll: selectAllCategories } = categoryAdapater.getSelectors<RootState>(
    state => state.categories,
);

export const selectCategoriesError = (state: RootState) => state.categories.error;

export const categoriesReducer = categoriesSlice.reducer;
