import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    dismissSnackbar,
    NotificationId,
    removeSnackbar,
    selectNotifications,
} from "../../state/slices/notificationSlice";

export const SnackbarSubscriber = () => {
    const [displayed, setDisplayed] = useState<NotificationId[]>([]);
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const display = (id: NotificationId) => setDisplayed(curr => [...curr, id]);

    const removeDisplayed = (id: NotificationId) =>
        setDisplayed(curr => curr.filter(key => id !== key));

    useEffect(() => {
        notifications.forEach(({ id, message, options, dismissed }) => {
            if (dismissed) {
                closeSnackbar(id);
                return;
            }

            if (displayed.includes(id)) return;

            enqueueSnackbar(message, {
                key: id,
                ...options,
                action: options.persist ? CloseSnackbarButton : undefined,
                onExited: (event, snackbarId) => {
                    dispatch(removeSnackbar(snackbarId));
                    removeDisplayed(snackbarId);
                },
            });

            display(id);
        });
    }, [displayed, notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

function CloseSnackbarButton(key: string) {
    const dispatch = useDispatch();
    const { t } = useTranslation("general");
    return (
        <Button
            variant="text"
            style={{
                color: "#FFF",
            }}
            onClick={() => dispatch(dismissSnackbar(key))}
        >
            {t("actions.ok")}
        </Button>
    );
}
