import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTimeLeftFormatter } from "../../hooks/useTimeLeftFormatter";
import { useAppSelector } from "../../state/configureStore";
import { selectAssortmentById } from "../../state/slices/assortmentsSlice";
import { DeliveryAssortment } from "../../state/types";
import { Body2 } from "../Typography";

type Props = {
    deliveryAssortment: DeliveryAssortment;
};

const useStyles = makeStyles(theme => ({
    container: {
        "padding": 10,
        "&:first-of-type": {
            paddingLeft: 0,
        },
    },
    timeLeft: {
        fontFamily: "Rubik-SemiBold",
        minWidth: 40,
    },
    description: {
        maxWidth: 110,
        opacity: 0.6,
    },
}));

export const AssortmentTimeLeft: FC<Props> = ({ deliveryAssortment }) => {
    const classes = useStyles();
    const { t } = useTranslation("order");
    const assortment = useAppSelector(state =>
        selectAssortmentById(state, deliveryAssortment.assortmentId),
    );
    const timeLeft = useTimeLeftFormatter(deliveryAssortment.deadline);

    return (
        <Box display="flex" flexDirection="column" className={classes.container}>
            <Body2 size={11} className={classes.description}>
                {t("deliveryInfo.timeLeftToOrder")}
            </Body2>
            <Body2 size={12} italic overflowEllipsis>
                {assortment?.name}
            </Body2>
            <Body2 className={classes.timeLeft} overflowEllipsis>
                {timeLeft}
            </Body2>
        </Box>
    );
};
