import { LandingResource } from "../locales";

export const landing: LandingResource = {
    news: "Nieuws",
    messages: "Berichten",
    home: "Beginpagina",
    showAllNews: "Al het nieuws tonen ({{count}})",
    showAllMessages: "Alles tonen",
    myMessages: "Mijn berichten",
    noMessages: "Er zijn geen berichten",
    contactUs: "Contact opnemen",
};
