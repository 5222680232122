import { makeStyles } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import loginBackground from "../../assets/images/avocado-bg.jpg";
import logo from "../../assets/images/logo.svg";
import { supportedLocales } from "../../assets/locales/locales";
import { Card } from "../../components/Card";
import { ErrorMessage } from "../../components/ErrorMessage";
import { LocaleSelect } from "../../components/header/LocaleSelect";
import { Page } from "../../components/Page";
import { Body1, H1 } from "../../components/Typography";
import { AuthContent } from "./AuthContent";

const useStyles = makeStyles(theme => ({
    page: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    card: {
        position: "relative",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "60px 40px 80px 40px",
        overflow: "hidden",
    },
    errorMessage: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "0px !important",
    },
    header: {
        position: "relative",
    },
    localeSelect: {
        "position": "absolute",
        "right": 0,
        "top": "50%",
        "transform": "translateY(-50%)",
        "& .MuiSelect-root": {
            maxWidth: 100,
            whiteSpace: "pre-wrap",
        },
    },
    text: {
        "marginTop": theme.spacing(2),
        "marginBottom": theme.spacing(2),
        "& > :nth-child(even)": {
            marginTop: theme.spacing(1),
        },
    },
    logo: {
        height: 60,
    },
    copyrightText: {
        marginTop: 10,
        marginBottom: 0,
    },
}));

type Props = {
    errorMessage?: string;
    title?: string;
    description?: string | ReactNode;
};
export const AuthPage: FC<Props> = ({ errorMessage, title, description, children }) => {
    const classes = useStyles();

    return (
        <Page
            background={{ src: loginBackground, fullHeight: true }}
            className={classes.page}
            ContentComponent={AuthContent}
            copyrightClassName={classes.copyrightText}
            transparent
        >
            <Card className={classes.card}>
                <div className={classes.header}>
                    <img alt="logo" className={classes.logo} src={logo} />
                    <LocaleSelect className={classes.localeSelect} locales={supportedLocales} />
                </div>

                <div className={classes.text}>
                    {description && <H1>{title}</H1>}
                    {description && <Body1>{description}</Body1>}
                </div>

                {children}

                {errorMessage && (
                    <ErrorMessage
                        className={classes.errorMessage}
                        error={{ body: errorMessage }}
                        showSomethingWentWrong={false}
                    />
                )}
            </Card>
        </Page>
    );
};
