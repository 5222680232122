import { createMuiTheme } from "@material-ui/core/styles";
import LunchBox from "./assets/fonts/LunchBox.woff";
import RubikItalic from "./assets/fonts/Rubik-Italic.ttf";
import RubikRegular from "./assets/fonts/Rubik-Regular.ttf";
import RubikSemiBold from "./assets/fonts/Rubik-SemiBold.ttf";
import { colors } from "./constants/colors";

const lunchBox = {
    fontFamily: "LunchBox",
    fontDisplay: "swap" as const,
    fontWeight: 400,
    src: `url(${LunchBox}) format('woff')`,
};

const rubikRegular = {
    fontFamily: "Rubik-Regular",
    fontDisplay: "swap" as const,
    fontWeight: 400,
    src: `url(${RubikRegular}) format('truetype')`,
};

const rubikSemiBold = {
    fontFamily: "Rubik-SemiBold",
    fontDisplay: "swap" as const,
    fontWeight: 500,
    src: `url(${RubikSemiBold}) format('truetype')`,
};

const rubikItalic = {
    fontFamily: "Rubik-Italic",
    fontDisplay: "swap" as const,
    fontWeight: 400,
    src: `url(${RubikItalic}) format('truetype')`,
};

export const rootBgColor = "#F9FAFA";

export const theme = createMuiTheme({
    typography: {
        fontFamily: "Rubik-Regular",
        htmlFontSize: 16,
    },
    spacing: factor => `${0.5 * factor}rem`,
    overrides: {
        MuiButton: {
            root: {
                fontSize: "0.875rem",
                borderRadius: "50px",
                height: "44px",
                lineHeight: "1.125rem",
                textTransform: "none",
                padding: "0.2em 1em",
                fontWeight: "bold",
                letterSpacing: "0.1em",
            },
            contained: {},
            containedPrimary: {
                backgroundColor: colors.heroGreen,
            },
            outlined: {
                "backgroundColor": "transparent",
                "border": "2px solid",
                "borderColor": colors.heroGreen,
                "color": colors.heroGreen,
                "&:hover": {
                    color: colors.white,
                    backgroundColor: colors.heroGreen,
                },
            },
            text: {
                padding: "1em",
            },
        },
        MuiCssBaseline: {
            "@global": {
                "@font-face": [rubikRegular, rubikSemiBold, rubikItalic, lunchBox],
                "body": {
                    backgroundColor: rootBgColor,
                    color: colors.darkGrey,
                    fontSize: "1rem",
                    minWidth: 1200,
                    overflowX: "auto",
                },
                "html": {
                    fontSize: 16,
                },
            },
        },
        MuiContainer: {
            root: {
                backgroundColor: rootBgColor,
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: "1.25rem",
            },
            scrollPaper: {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
        },
        MuiDialogActions: {
            root: {
                marginBottom: "0.5rem",
            },
        },
        MuiFormControl: {
            root: {
                marginTop: "5px",
            },
        },
        MuiLink: {
            root: {
                fontFamily: rubikRegular.fontFamily,
                textDecoration: "none",
            },
            underlineHover: {
                "&:hover": {
                    color: colors.heroGreen,
                    textDecoration: "none",
                },
            },
        },
        MuiTableCell: {
            root: {
                fontSize: "1em",
                padding: "4px 8px",
            },
            head: {
                textTransform: "uppercase",
                fontWeight: "bold",
                color: colors.darkGrey,
                fontSize: "1rem",
            },
            body: {
                color: colors.darkGrey,
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "0.8rem",
            },
        },
    },
    palette: {
        primary: {
            main: colors.heroGreen,
            contrastText: colors.white,
        },
        secondary: {
            main: colors.pink,
            light: colors.pinkExtraLight,
            contrastText: colors.white,
        },
        error: {
            main: colors.attentionRed,
            light: colors.pinkLight,
        },
    },
});

export type ThemeType = typeof theme;
