import { Container, ContainerProps, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        maxWidth: 600,
    },
}));

export const AuthContent: FC<ContainerProps> = ({ ...props }) => {
    const classes = useStyles();
    return <Container className={classes.container} maxWidth={false} {...props} />;
};
