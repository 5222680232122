import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../state/configureStore";
import { cartEntityId, selectCartItemById, updateCartItem } from "../../../state/slices/cartSlice";
import { selectProductById } from "../../../state/slices/productsSlice";
import { ProductType } from "../../../state/types";
import { Quantity } from "./Quantity";

type Props = {
    productId: string;
    productType: ProductType;
};

export const CartItemQuantity: FC<Props> = ({ productId, productType }) => {
    const dispatch = useAppDispatch();
    const cartItem = useAppSelector(state =>
        selectCartItemById(state, cartEntityId({ productId, productType })),
    );
    const product = useAppSelector(state => selectProductById(state, productId));

    return (
        <Quantity
            productId={productId}
            onQuantityChange={salesUnitQuantity => {
                void dispatch(updateCartItem({ productId, salesUnitQuantity, productType }));
            }}
            quantity={cartItem?.salesUnitQuantity}
            warningThreshold={
                product ? product.warningThreshold * product.orderMultiple : undefined
            }
            debounce
        />
    );
};
