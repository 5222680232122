import React, { FC, memo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../state/configureStore";
import {
    onOrderSuggestionQuantityChange,
    selectOrderSuggestionById,
} from "../../../../state/slices/productMenuSlice";
import { Quantity } from "../../quantity/Quantity";

type Props = {
    productId: string;
};
export const OrderSuggestionQty: FC<Props> = memo(({ productId }) => {
    const dispatch = useAppDispatch();
    const orderSuggestion = useSelector(selectOrderSuggestionById(productId));
    return (
        <Quantity
            productId={productId}
            quantity={orderSuggestion.salesUnitQuantity}
            onQuantityChange={quantity =>
                dispatch(onOrderSuggestionQuantityChange({ productId, quantity }))
            }
        />
    );
});
