import { Button, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body1 } from "../../../../components/Typography";
import { colors } from "../../../../constants/colors";
import { useAppDispatch } from "../../../../state/configureStore";
import {
    cartEntityId,
    removeItems,
    selectClosedInfoProducts,
} from "../../../../state/slices/cartSlice";
import { openDeliveryInfoDialog } from "../../../../state/slices/dialogsSlice";
import { Banner } from "./Banner";

const useStyles = makeStyles({
    link: {
        color: colors.darkGrey,
    },
    removeBtn: {
        display: "inline-block",
    },
});

export const DeliveryWarningBanner: FC = () => {
    const { t } = useTranslation("order");
    const classes = useStyles();
    const products = useSelector(selectClosedInfoProducts);
    const dispatch = useAppDispatch();

    if (!products.length) {
        return null;
    }

    return (
        <Banner severity="error">
            <Body1>{t("cart.couldNotDeliverChosenToDate")}</Body1>
            <Button onClick={() => dispatch(openDeliveryInfoDialog({ canClose: true }))}>
                {t("cart.changeDeliveryDate")}
            </Button>
            <Button
                className={classes.removeBtn}
                onClick={() =>
                    void dispatch(
                        removeItems(
                            products.map(p =>
                                cartEntityId({ productId: p.productId, productType: p.type }),
                            ),
                        ),
                    )
                }
            >
                {t("cart.removeProductsThatCannotBeDelivered")}
            </Button>
        </Banner>
    );
};
