import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { Asset } from "../state/types";
import { File } from "./File";
import { H3 } from "./Typography";

const useStyles = makeStyles({
    title: {
        marginBottom: 10,
    },
});

type FileAsset = Asset & { onClick?: () => void };
export const Files: FC<{
    assets?: FileAsset[];
    title?: string;
    className?: string;
}> = ({ assets = [], title, className }) => {
    const classes = useStyles();

    if (assets.length === 0) {
        return null;
    }

    return (
        <>
            <div className={cn(className, "files")}>
                {title && <H3 className={classes.title}>{title}</H3>}
                {assets.map(file => (
                    <File asset={file} key={file.assetId} onClick={file.onClick} />
                ))}
            </div>
        </>
    );
};
