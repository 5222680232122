import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Body1, H1 } from "../../../components/Typography";
import { selectCartItemsAboveThreshold } from "../../../state/slices/cartSlice";
import {
    closeThresholdWarningDialog,
    thresholdWarningIsOpen,
} from "../../../state/slices/dialogsSlice";

const useStyles = makeStyles({
    quantity: {
        fontWeight: "bold",
    },
});

export const ThresholdWarningDialog: FC = () => {
    const { t } = useTranslation(["general", "order"]);
    const products = useSelector(selectCartItemsAboveThreshold);
    const classes = useStyles();
    const open = useSelector(thresholdWarningIsOpen);
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeThresholdWarningDialog());
    };

    return (
        <Dialog maxWidth="md" onBackdropClick={close} open={open}>
            <DialogTitle disableTypography>
                <H1 primary>{t("order:thresholdWarningDialog.title")}</H1>
            </DialogTitle>
            <DialogContent>
                <Body1>{t("order:thresholdWarningDialog.weHaveNoticed")}</Body1>
                <Box mb={2}>
                    {products.map(p => (
                        <Body1 key={p.productId}>
                            <span className={classes.quantity}>{p.salesUnitQuantity}</span> x{" "}
                            {p.name}
                        </Body1>
                    ))}
                </Box>
                <Body1>{t("order:thresholdWarningDialog.pleaseCheck")}</Body1>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close} variant="contained">
                    {t("general:actions.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
