import React, { FC, memo } from "react";
import { useSelector } from "react-redux";
import { selectOrderSuggestionById } from "../../../../state/slices/productMenuSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatWeight } from "../../../../utils/formatters";

type Props = {
    productId: string;
};
export const ExcessWeightCell: FC<Props> = memo(({ productId }) => {
    const locale = useSelector(selectLocale);
    const orderSuggestion = useSelector(selectOrderSuggestionById(productId));
    return (
        <div>
            {orderSuggestion.excessWeight > 0 && "+"}
            {formatWeight(orderSuggestion.excessWeight, locale, { maximumFractionDigits: 2 })}
        </div>
    );
});
