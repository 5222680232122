import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2, H3 } from "../../../../components/Typography";
import { selectTotalWeight } from "../../../../state/slices/cartSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatWeight } from "../../../../utils/formatters";

export const TotalWeight: FC = () => {
    const { t } = useTranslation("order");
    const locale = useSelector(selectLocale);
    const totalWeight = useSelector(selectTotalWeight);

    return (
        <>
            <Body2>{t("cart.weight.no-split")}</Body2>
            <H3>{formatWeight(totalWeight, locale)}</H3>
        </>
    );
};
