import { RefObject, useEffect, useState } from "react";

export const useDetectOutsideViewportY = (ref: RefObject<HTMLDivElement>) => {
    const [isOutsideViewport, setIsOutsideViewport] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (ref && ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const isInViewport =
                    rect.top >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

                setIsOutsideViewport(!isInViewport);
            }
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [ref]);

    return isOutsideViewport;
};
