import { MyAccountResource } from "../locales";

export const myAccount: MyAccountResource = {
    accountSettings: "Kontoinställningar",
    addNewRecipient: "Lägg till ny mottagare",
    alarmsAndNotifications: "Alarm & notifikationer",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Food-to-go",
        saladBar: "Salladsbaren",
    },
    customerNo: "Kundnummer",
    customerUserIsMissing: "Det finns ingen användare registrerad för kunden.",
    email: "Epost",
    emailInvoice: "Epostfaktura",
    foodAlarms: "Matlarm",
    language: "Språk",
    missingContactPerson: "Kontaktperson saknas",
    missingInformation: "Saknar information",
    name: "Namn",
    orderConfirmations: "Orderbekräftelser",
    passwordReset: "Återställning av lösenord",
    passwordResetSentTo: "Återställning av lösenord skickas till",
    phone: "Telefon",
    purchaseStats: "Försäljningsstatistik",
    recipient: "Mottagare",
    responsibleSingular: "Ansvarig",
    responsiblePlural: "Ansvariga",
    storeManager: "Butikschef",
    storeManagerDescription:
        "Används för viktiga meddelande gällande affärsrelationen prisförändringar eller liknande.",
    updateContactInfo: {
        success: "Har sparats.",
        error: "Kunde inte spara.",
    },
    yourStore: "Din butik",
};
