import { styled } from "@material-ui/core/styles";
import React, { FC } from "react";
import { headingFonts } from "./Typography";

export const StyledInnerHTML = styled("div")(({ theme }) => {
    const { h1, h2, h3, h4, h5, h6 } = headingFonts(theme);
    return {
        "& img": {
            maxWidth: "100%",
        },
        "& p": {
            margin: "0.5rem 0",
        },
        "& h1": h1,
        "& h2": h2,
        "& h3": h3,
        "& h4": h4,
        "& h5": h5,
        "& h6": h6,
    };
});

type Props = {
    html: string;
};
export const InnerHTML: FC<Props> = ({ html }) => {
    const openLinkInNewTab = (ev: React.SyntheticEvent) => {
        const target = ev.target as HTMLLinkElement;
        if (target.tagName.toLowerCase() === "a") {
            ev.preventDefault();
            window.open(target.href, "_blank");
        }
    };
    return (
        <StyledInnerHTML dangerouslySetInnerHTML={{ __html: html }} onClick={openLinkInNewTab} />
    );
};
