import { GuidanceResource } from "../locales";

export const guidance: GuidanceResource = {
    campaignMaterial: "Kampanjemateriell",
    guidance: "Veiledning",
    planogram: "Planogram",
    currentPlanogram: "Nåværende planogram",
    futurePlanogram: "Fremtidig planogram {{start}}",
    labels: "Etiketter",
    deviationAndClaims: "Avvik og krav",
    risks: "Risikoer",
    certificates: "Sertifikater",
};
