import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useIsLoggedInAsCustomer } from "../../hooks/useIsLoggedInAsCustomer.ts";
import { selectCustomer } from "../../state/slices/customerSlice";
import { HistoricOrder } from "../../state/types";
import { OrderTableRow } from "./OrderTableRow";

type Props = {
    orders: HistoricOrder[];
};

export const OrdersTable: FC<Props> = ({ orders }) => {
    const { t } = useTranslation(["orderHistory", "general"]);
    const customer = useSelector(selectCustomer);
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();

    return (
        <Table data-testid="order-table">
            <TableHead>
                <TableRow>
                    {isLoggedInAsCustomer && (
                        <TableCell size="small" padding="none">
                            {/* Column for created by */}
                        </TableCell>
                    )}
                    {customer?.groupOrdersOnDeliveryNoteId && (
                        <TableCell>{t("orderHistory:table.header.orderRef")}</TableCell>
                    )}
                    <TableCell>{t("orderHistory:table.header.deliveryDate")}</TableCell>
                    <TableCell>{t("orderHistory:table.header.timeCreated")}</TableCell>
                    <TableCell>{t("orderHistory:table.header.deliversTo")}</TableCell>
                    <TableCell>{t("orderHistory:table.header.totalWeight")}</TableCell>
                    <TableCell>{t("orderHistory:table.header.status")}</TableCell>
                    {customer?.showPrices && (
                        <TableCell align="right">
                            {customer?.hasVat
                                ? t("general:price.totalExclVat")
                                : t("general:price.total")}
                        </TableCell>
                    )}
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {orders.map((order, index) => (
                    <OrderTableRow
                        key={`${order.deliveryDate}:${index}`}
                        order={order}
                        isOddRow={index % 2 === 1}
                    />
                ))}
            </TableBody>
        </Table>
    );
};
