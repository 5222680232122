const environment = parseEnvironment();

export const config = {
    environment,
    isProd: environment === "production",
} as const;

console.log(`Running environment: "${config.environment}"`);

function parseEnvironment() {
    if (
        window.location.hostname === "arcorder.picadeli.com" ||
        window.location.hostname.startsWith("shop.picadeli.")
    ) {
        return "production";
    } else if (window.location.hostname === "staging-shop.picadeli.com") {
        return "staging";
    } else if (window.location.hostname === "localhost") {
        return "development";
    } else {
        throw new Error(`Unsupported hostname ${window.location.hostname}`);
    }
}
