import { IconButton } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { Body1, H2 } from "./Typography";

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        alignItems: "flex-start",
        padding: theme.spacing(1, 2),
    },
    closeButton: {
        marginLeft: "auto",
    },
    title: {
        margin: theme.spacing(2, 0, 1, 0),
    },
}));

type Props = {
    title: string;
    subTitle?: string;
    onClose: () => void;
};

export const DialogHeader: FC<Props> = ({ title, subTitle, onClose }) => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            <div>
                <H2 className={classes.title} primary>
                    {title.toUpperCase()}
                </H2>
                {subTitle && <Body1>{subTitle}</Body1>}
            </div>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </div>
    );
};
