import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isLoggedInAsCustomer } from "../network/authService";
import { selectIsLoggedIn } from "../state/slices/userSlice";

export const useIsLoggedInAsCustomer = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    return useMemo(() => {
        if (isLoggedIn) {
            return isLoggedInAsCustomer();
        }
        return false;
    }, [isLoggedIn]);
};
