export const colors = {
    attentionRed: "#bd2222",
    black: "#1F1F1F",
    blue: "#4CAFC5",
    blueExtraLight: "#D8E9EF",
    blueLight: "#AAD3DE",
    grey: "#a4a4a4",
    lightGrey: "#8C9D97",
    darkGrey: "#444444",
    heroGreenExtraLight: "#D7EAD9",
    heroGreenLight: "#8DC9A0",
    heroGreen: "#6FBC85",
    heroGreenDark: "#00A75D",
    darkWhite: "#f5f5f5",
    pinkExtraLight: "#F8E2E7",
    pinkLight: "#F6C7CE",
    pink: "#EE8B96",
    white: "#FFF",
    yellowExtraLight: "#FFF1D3",
    yellowLight: "#FDE3B6",
    yellow: "#FECF66",
} as const;
