import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2 } from "../../../../components/Typography";
import { useAppSelector } from "../../../../state/configureStore";
import { selectCustomer } from "../../../../state/slices/customerSlice";
import { selectSupportDetails } from "../../../../state/slices/supportSlice";
import { Banner } from "./Banner";

export const CreditFreezeBanner = () => {
    const { t } = useTranslation();
    const customer = useSelector(selectCustomer);
    const supportDetails = useAppSelector(selectSupportDetails);

    if (!customer?.creditFreeze) return null;

    return (
        <Banner severity="error">
            <Body2 bold>{t("order:creditFreeze.title")}</Body2>
            <Body2 preWrap>
                {t("order:creditFreeze.body", {
                    phone: supportDetails?.phone,
                })}
            </Body2>
        </Banner>
    );
};
