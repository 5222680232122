import { OrderHistoryResource } from "../locales";

export const orderHistory: OrderHistoryResource = {
    claim: {
        orderRowTitle: "Claim voor {{productName}}",
        orderTitle: "Claim order",
        info: "In geval van een ontbrekende levering of andere grote klachten, kunt u bellen met de klantenservice op {{phoneNumber}}",
        placeholderReason: "Selecteer een reden voor de claim.",
        bestBefore: "Ten minste houdbaar tot:",
        batchCode: "Partijcode:",
        outerBoxBroken: "Buitenste verpakking beschadigd:",
        additionalInfo: "Aanvullende informatie:",
        contactForTheClaim: "Naam van de persoon die de claim indient:",
        contactEmail: "E-mailadres:",
        fullOrderText: "Je staat op het punt om alle producten in deze bestelling te claimen.",
        picture: "Foto:",
        success: {
            title: "Bedankt voor uw claim",
            content:
                "We verwerken uw claim binnen 48 uur en komen bij u terug als we vragen hebben.",
            bestRegards: "Met vriendelijke groet",
            picadeliCustomerService: "Klantenservice Picadeli",
        },
    },
    claimStatus: {
        "not-available": "Niet beschikbaar",
        "call-customer-services": "Klantenservice bellen",
        "claimed": "Claim ingediend",
    },
    table: {
        details: {
            copyToCart: "Kopieer bestelling naar winkelwagen",
            deliveryAddress: "Bezorgingsadres",
            invoiceAddress: "Factuuradres",
            orderedProducts: "Bestelde producten",
            table: {
                claimOrder: "Volledige bestelling claimen",
                cost: "Prijs",
                deliveredQuantity: "Geleverd",
                invoicedAt: "Gefactureerd",
                weight: "Netto gewicht",
                orderNumber: "Bestelnr.",
                orderedQuantity: "Besteld",
                picked: "Samengesteld",
                productId: "Artikelnr.",
                productName: "Artikelnaam",
                reclamation: "Claim indienen",
                subtotal: "Subtotaal",
            },
        },
        header: {
            orderRef: "Ref",
            createdBy: "Gemaakt door",
            deliversTo: "Levering voor",
            deliveryDate: "Leveringsdatum",
            status: "Status",
            timeCreated: "Gemaakt",
            totalCostExclVat: "Totale prijs exc. btw",
            totalWeight: "Totaal gewicht",
        },
        review: "Controleren",
    },
    thereIsNoOrderHistory: "Er is geen bestelgeschiedenis.",
};
