import { Box, Button, styled } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "../../../hooks/useDialog";
import { selectMenuProducts } from "../../../state/slices/productMenuSlice";
import { selectDeliFilter, selectDeliProducts } from "../../../state/slices/productSelectors";
import { filterByType } from "../../../state/slices/productsSlice";
import { FilterType, ProductType } from "../../../state/types";
import { ProductMenuDialog } from "../product-menu/ProductMenuDialog";
import { ProductTables } from "../product-table/ProductTables";
import { FilterButton } from "./FilterButton";
import { TabContent } from "./TabContent";
import { TabHeader } from "./TabHeader";

const ProductMenuBtn = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

export const DeliTab: FC<{ tabIndex: number }> = ({ tabIndex }) => {
    const { t } = useTranslation("order");
    const dispatch = useDispatch();
    const filter = useSelector(selectDeliFilter);
    const products = useSelector(selectDeliProducts);
    const productMenuDialog = useDialog();
    const menuProducts = useSelector(selectMenuProducts);

    return (
        <TabContent tabIndex={tabIndex}>
            <TabHeader>
                <Box display="flex">
                    <FilterButton
                        onClick={() =>
                            dispatch(
                                filterByType({
                                    productType: ProductType.Deli,
                                    filterType: FilterType.all,
                                }),
                            )
                        }
                        highlight={filter === FilterType.all}
                    >
                        {t("filter.showAllProducts")}
                    </FilterButton>
                    <FilterButton
                        onClick={() =>
                            dispatch(
                                filterByType({
                                    productType: ProductType.Deli,
                                    filterType: FilterType.favorites,
                                }),
                            )
                        }
                        highlight={filter === FilterType.favorites}
                    >
                        {t("filter.showFavorites")}
                    </FilterButton>
                </Box>

                {menuProducts.length > 0 && (
                    <ProductMenuBtn
                        color="primary"
                        variant="contained"
                        onClick={productMenuDialog.open}
                    >
                        {t("productMenu.orderHelpMeals")}
                    </ProductMenuBtn>
                )}
            </TabHeader>

            <ProductTables
                showFavorite
                productType={ProductType.Deli}
                products={products}
                filter={filter}
            />
            <ProductMenuDialog
                isOpen={productMenuDialog.isOpen}
                onClose={productMenuDialog.close}
                productType={ProductType.Deli}
            />
        </TabContent>
    );
};
