import { Button, makeStyles } from "@material-ui/core";
import cn from "classnames";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../state/configureStore";
import { clearLoginError, login, selectUserState } from "../../state/slices/userSlice";
import { AuthInput } from "./AuthInput";
import { AuthPage } from "./AuthPage";
import { AuthSubmitButton } from "./AuthSubmitButton";

const useStyles = makeStyles(theme => ({
    btn: {
        minWidth: 260,
        alignSelf: "center",
    },
    loginBtn: {
        marginTop: theme.spacing(3),
    },
    forgotPasswordBtn: {
        marginTop: theme.spacing(2),
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
}));

export const LoginPage: FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t } = useTranslation("auth");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { loginLoading, loginError } = useSelector(selectUserState, shallowEqual);
    const handleLogin = (ev: FormEvent) => {
        ev.preventDefault();
        void dispatch(login({ username, password }));
    };
    const handleForgotPassword = () => history.push("/forgot-password");

    useEffect(() => {
        return () => {
            dispatch(clearLoginError());
        };
    }, [dispatch]);

    return (
        <AuthPage
            errorMessage={loginError}
            title={t("login.title")}
            description={t("login.description")}
        >
            <form className={classes.form} onSubmit={handleLogin}>
                <AuthInput
                    label={t("login.usernameLbl")}
                    value={username}
                    onChange={setUsername}
                    autoFocus
                    autoComplete="username"
                    type="text"
                    name="username"
                />
                <AuthInput
                    label={t("login.passwordLbl")}
                    value={password}
                    onChange={setPassword}
                    autoComplete="current-password"
                    type="password"
                    name="password"
                />
                <AuthSubmitButton
                    className={cn(classes.btn, classes.loginBtn)}
                    loading={loginLoading}
                >
                    {t("login.loginBtn")}
                </AuthSubmitButton>
            </form>
            <Button
                className={cn(classes.btn, classes.forgotPasswordBtn)}
                onClick={handleForgotPassword}
            >
                {t("login.forgotPasswordBtn")}
            </Button>
        </AuthPage>
    );
};
