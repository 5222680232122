import { Box, Button, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC, RefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Container } from "../../../components/Container";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { RotateButton } from "../../../components/RotateButton";
import { colors } from "../../../constants/colors";
import { useDetectOutsideViewportY } from "../../../hooks/useDetectOutsideViewport";
import { useToggle } from "../../../hooks/useToggle";
import { useAppDispatch } from "../../../state/configureStore";
import {
    cartEntityId,
    fetchCart,
    removeItems,
    selectAddToCartStatus,
    selectCartProductsByType,
    selectScrollToCart,
} from "../../../state/slices/cartSlice";
import { clearPlaceOrderError, selectPlaceOrderError } from "../../../state/slices/ordersSlice";
import { selectUpcomingDeliveries } from "../../../state/slices/productSelectors";
import { RequestStatus } from "../../../state/types";
import { ProductTable } from "../product-table/ProductTable";
import { CartItemQuantity } from "../quantity/CartItemQuantity";
import { ThresholdWarningDialog } from "../quantity/ThresholdWarningDialog";
import { CreditFreezeBanner } from "./banners/CreditFreezeBanner";
import { DeliveryWarningBanner } from "./banners/DeliveryWarningBanner";
import { QuantityWarningBanner } from "./banners/QuantityWarningBanner";
import { ShippingBanners } from "./banners/ShippingBanners";
import { CartHeader } from "./CartHeader";
import { CartHeaderDivider } from "./CartHeaderDivider";
import { CartItemWarning } from "./CartItemWarning";
import { DeliveryDateColumn } from "./delivery-date-column/DeliveryDateColumn";
import { OrderColumn } from "./order-column/OrderColumn";
import { PriceColumn } from "./PriceColumn";
import { StickyCartHeader } from "./StickyCartHeader";
import { WeightColumn } from "./weight-column/WeightColumn";

const useStyles = makeStyles(theme => ({
    container: {
        border: `2px solid ${colors.heroGreen}`,
        padding: theme.spacing(4, 2.5),
        borderRadius: 30,
        backgroundColor: colors.white,
    },
    errorValidationBtn: {
        marginTop: theme.spacing(1),
    },
}));

type Props = {
    orderMatCart?: boolean;
};

export const Cart: FC<Props> = ({ orderMatCart }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["general", "order"]);
    const cartProducts = useSelector(selectCartProductsByType);
    const addToCartStatus = useSelector(selectAddToCartStatus);
    const upcomingDeliveries = useSelector(selectUpcomingDeliveries);
    const scrollToCart = useSelector(selectScrollToCart);
    const cartHeaderRef = useRef<HTMLDivElement>(null);
    const isOutsideViewport = useDetectOutsideViewportY(cartHeaderRef);
    const showCartToggle = useToggle(true);
    const placeOrderError = useSelector(selectPlaceOrderError);
    const invalidProductIds = placeOrderError.validationError?.invalidProductIds ?? [];

    useEffect(() => {
        void dispatch(fetchCart());
    }, [dispatch]);

    useEffect(() => {
        if (addToCartStatus === RequestStatus.Loading && scrollToCart) {
            scrollToRef(cartHeaderRef);
        }
    }, [addToCartStatus, cartHeaderRef, scrollToCart]);

    const handleOnShowCartClick = () => scrollToRef(cartHeaderRef);

    const handleRemoveInvalidProductsIds = () => {
        const entityIds = cartProducts
            .flatMap(p => p.products)
            .filter(p => invalidProductIds.includes(p.productId))
            .map(p => cartEntityId({ productId: p.productId, productType: p.type }));
        void dispatch(removeItems(entityIds));
        dispatch(clearPlaceOrderError());
    };

    return (
        <>
            {isOutsideViewport && <StickyCartHeader onShowCartClick={handleOnShowCartClick} />}
            <Container className={cn(classes.container)}>
                <div ref={cartHeaderRef}>
                    <CartHeader>
                        <DeliveryDateColumn />
                        <CartHeaderDivider />
                        <WeightColumn />
                        <CartHeaderDivider />
                        <PriceColumn />
                        <CartHeaderDivider />
                        <OrderColumn showPlaceOrderBtn={!orderMatCart} />
                    </CartHeader>
                </div>
                {(placeOrderError.unknownError || placeOrderError.validationError) && (
                    <ErrorMessage
                        error={{
                            body:
                                placeOrderError.validationError?.message?.body ??
                                placeOrderError.unknownError,
                            title: placeOrderError.validationError?.message?.title,
                            lead: placeOrderError.validationError?.message?.lead,
                        }}
                        clearError={() => dispatch(clearPlaceOrderError())}
                    >
                        {invalidProductIds.length > 0 && (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.errorValidationBtn}
                                onClick={() => handleRemoveInvalidProductsIds()}
                            >
                                {t("order:placeOrderError.removeInvalidProductsBtn")}
                            </Button>
                        )}
                    </ErrorMessage>
                )}
                <CreditFreezeBanner />
                <ShippingBanners />
                <QuantityWarningBanner />
                <DeliveryWarningBanner />
                {cartProducts.length > 0 && (
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <RotateButton
                            isOpen={showCartToggle.value}
                            onClick={showCartToggle.toggle}
                            openText={t("order:cart.showCartProducts")}
                            closeText={t("order:cart.hideCartProducts")}
                        />
                    </Box>
                )}
                <Collapse in={showCartToggle.value} timeout="auto">
                    {cartProducts.map(({ productType, products }) => (
                        <ProductTable
                            key={`cart-product-table-${productType}`}
                            title={t(`general:productTypes.${productType}` as const)}
                            productType={productType}
                            products={products}
                            QuantityComponent={CartItemQuantity}
                            WarningComponent={CartItemWarning}
                            onDelete={productId =>
                                void dispatch(
                                    removeItems([cartEntityId({ productId, productType })]),
                                )
                            }
                            showProductPdfDownload={false}
                            showChangeProductButton
                            allowReplacingCartItem
                            upcomingDeliveryDates={upcomingDeliveries.getTableColumns(
                                cartProducts.map(p => p.products).flat(),
                            )}
                        />
                    ))}
                </Collapse>
            </Container>
            <ThresholdWarningDialog />
        </>
    );
};

function scrollToRef(ref: RefObject<HTMLDivElement>) {
    if (ref?.current?.scrollIntoView) {
        try {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } catch (e) {
            console.warn(`scrolling into view does not work ${e}`);
        }
    }
}
