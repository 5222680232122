import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../hooks/useDialog";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import {
    replaceWithOrderSuggestion,
    selectCartProductsCount,
    selectReplaceCartStatus,
} from "../../state/slices/cartSlice";
import { selectDeliveryDateById } from "../../state/slices/deliveryDatesSlice";
import { RequestStatus } from "../../state/types";
import { LoadingButton } from "../LoadingButton";
import { ClearCartConfirmationDialog } from "./ClearCartConfirmationDialog";

type Props = {
    deliveryDate?: string;
};

export const OrderSuggestionButton: FC<Props> = ({ deliveryDate }) => {
    const dispatch = useAppDispatch();
    const cartItemsCount = useAppSelector(selectCartProductsCount);
    const isOrderSuggestionAvailable =
        useAppSelector(state => selectDeliveryDateById(state, deliveryDate ?? ""))
            ?.isOrderSuggestionAvailable ?? false;
    const dialog = useDialog();
    const replaceCartStatus = useAppSelector(selectReplaceCartStatus);
    const { t } = useTranslation("order");

    const handleDialogCancel = () => {
        dialog.close();
    };

    const handleDialogConfirm = () => {
        if (deliveryDate) {
            dialog.close();
            void dispatch(replaceWithOrderSuggestion(deliveryDate));
        }
    };

    const handleReplaceCart = async () => {
        if (deliveryDate && cartItemsCount) {
            dialog.open();
        } else if (deliveryDate) {
            await dispatch(replaceWithOrderSuggestion(deliveryDate));
        }
    };

    if (!isOrderSuggestionAvailable) {
        return null;
    }

    return (
        <>
            <ClearCartConfirmationDialog
                onCancel={handleDialogCancel}
                onConfirm={handleDialogConfirm}
                open={dialog.isOpen}
            />
            <LoadingButton
                loading={replaceCartStatus === RequestStatus.Loading}
                onClick={handleReplaceCart}
            >
                {t("orderSuggestion.fetchSuggestionButton")}
            </LoadingButton>
        </>
    );
};
