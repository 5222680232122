export const pageNotFound = {
    title: "Ooops!",
    subTitle: "We can't seem to find the page you're looking for",
    errorCode: "Error code: 404",
    usefulLinks: "Here are some useful links instead:",
    links: {
        home: "Home",
        order: "Order",
        myAccount: "My account",
    },
};
