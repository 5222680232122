import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
    FormGroup,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Checkbox } from "../../../components/Checkbox";
import { RotateButton } from "../../../components/RotateButton";
import { Body1, H1 } from "../../../components/Typography";
import { colors } from "../../../constants/colors";
import { useIsLoggedInAsCustomer } from "../../../hooks/useIsLoggedInAsCustomer.ts";
import { useToggle } from "../../../hooks/useToggle";
import { useAppDispatch } from "../../../state/configureStore";
import { setLastSelectedDeliveryDate } from "../../../state/slices/customerSlice";
import {
    fetchDeliveryDates,
    selectAllDeliveryDates,
} from "../../../state/slices/deliveryDatesSlice";
import {
    closeDeliveryInfoDialog,
    selectDeliveryInfoDialog,
} from "../../../state/slices/dialogsSlice";
import { setOrderDetails } from "../../../state/slices/orderDetailsSlice";
import { DeliveryDatesTable } from "./DeliveryDatesTable";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        paddingBottom: 20,
    },
    orderRefLabel: {
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    orderRefTextField: {
        marginTop: theme.spacing(1),
    },
    orderRefInput: {
        padding: theme.spacing(1),
    },
    title: {
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    line: {
        flex: 1,
        height: 1,
        border: `1px solid ${colors.darkGrey}`,
    },
    deliveryCalendarText: {
        margin: theme.spacing(0, 2),
    },
    deliveryTextContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    zeroPrice: {
        margin: theme.spacing(1, 0),
    },
    checkboxLabel: {
        fontWeight: "bold",
    },
    details: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    advancedOptionBtn: {
        marginTop: theme.spacing(1),
        fontSize: theme.typography.pxToRem(14),
    },
    centered: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    adminWarningAlert: {
        marginBottom: theme.spacing(1),
    },
}));

export const DeliveryInfoDialog: FC = () => {
    const { t } = useTranslation("order");
    const [orderRef, setOrderRef] = useState("");
    const isZeroPriceOrderToggle = useToggle(false);
    const showAdvancedOptionsToggle = useToggle(false);
    const isAdminCalenderToggle = useToggle(false);
    const bypassProductAvailabilityDateToggle = useToggle(false);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const dialog = useSelector(selectDeliveryInfoDialog);
    const deliveryDates = useSelector(selectAllDeliveryDates);
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const [bypassDeliveryDateStr, setBypassDeliveryDateStr] = useState("");
    const isExistingDeliveryDate = deliveryDates.some(
        v => v.deliveryDate === bypassDeliveryDateStr,
    );

    const save = (deliveryDate: string) => {
        dispatch(
            setOrderDetails({
                deliveryDate,
                orderSuggestion: false,
                orderRef,
                zeroPriceOrder: isZeroPriceOrderToggle.value,
                bypassProductAvailability: bypassProductAvailabilityDateToggle.value,
                bypassDeadline:
                    isAdminCalenderToggle.value &&
                    (deliveryDates
                        .find(d => d.deliveryDate === deliveryDate)
                        ?.assortments.some(({ deadline }) => new Date(deadline) < new Date()) ??
                        false),
            }),
        );
        if (!isLoggedInAsCustomer) {
            void dispatch(setLastSelectedDeliveryDate(deliveryDate));
        }
        dispatch(closeDeliveryInfoDialog());
    };

    const handleClose = () => {
        if (dialog.canClose || dialog.missingDeliveryCalendar) {
            dispatch(closeDeliveryInfoDialog());
        }
    };

    const handleOnEntering = () => {
        void dispatch(fetchDeliveryDates());
        setOrderRef("");
        showAdvancedOptionsToggle.reset();
        isZeroPriceOrderToggle.reset();
        isAdminCalenderToggle.reset();
        bypassProductAvailabilityDateToggle.reset();
        setBypassDeliveryDateStr("");
    };

    useEffect(() => {
        bypassProductAvailabilityDateToggle.reset();
        setBypassDeliveryDateStr("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdminCalenderToggle.value]);

    useEffect(() => {
        if (!bypassProductAvailabilityDateToggle.value) {
            setBypassDeliveryDateStr("");
        }
    }, [bypassProductAvailabilityDateToggle.value]);

    if (!dialog.isOpen) return null;

    return (
        <Dialog
            data-testid="deliveryInfoDialog"
            open
            maxWidth="md"
            onBackdropClick={handleClose}
            onEntering={handleOnEntering}
        >
            <DialogContent className={classes.content}>
                <H1 className={classes.title} primary>
                    {t("deliveryInfo.title")}
                </H1>
                <Body1 className={classes.orderRefLabel}>{t("deliveryInfo.orderRefLabel")}</Body1>
                <div className={classes.centered}>
                    <TextField
                        variant="outlined"
                        value={orderRef}
                        autoFocus
                        className={classes.orderRefTextField}
                        InputProps={{
                            classes: {
                                input: classes.orderRefInput,
                            },
                        }}
                        inputProps={{ maxLength: 60 }}
                        onChange={event => setOrderRef(event.target.value)}
                    />

                    {isLoggedInAsCustomer && (
                        <RotateButton
                            size="small"
                            className={classes.advancedOptionBtn}
                            isOpen={showAdvancedOptionsToggle.value}
                            onClick={showAdvancedOptionsToggle.toggle}
                            openText={t("deliveryInfo.showAdvancedOptions")}
                            closeText={t("deliveryInfo.hideAdvancedOptions")}
                        />
                    )}

                    {isLoggedInAsCustomer && showAdvancedOptionsToggle.value && (
                        <>
                            <FormGroup className={classes.zeroPrice}>
                                <FormControlLabel
                                    classes={{ label: classes.checkboxLabel }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={isZeroPriceOrderToggle.value}
                                            onClick={isZeroPriceOrderToggle.toggle}
                                        />
                                    }
                                    label={t("deliveryInfo.zeroPriceOrder")}
                                />
                                <FormControlLabel
                                    classes={{ label: classes.checkboxLabel }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={isAdminCalenderToggle.value}
                                            onClick={isAdminCalenderToggle.toggle}
                                        />
                                    }
                                    label={t("deliveryInfo.adminCalendar")}
                                />

                                {isAdminCalenderToggle.value && (
                                    <FormControlLabel
                                        classes={{ label: classes.checkboxLabel }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={bypassProductAvailabilityDateToggle.value}
                                                onClick={bypassProductAvailabilityDateToggle.toggle}
                                            />
                                        }
                                        label={t("deliveryInfo.adminCalendarAnyDeliveryDate")}
                                    />
                                )}
                            </FormGroup>
                        </>
                    )}
                    <Box className={classes.deliveryTextContainer}>
                        <div className={classes.line} />
                        <Body1 className={classes.deliveryCalendarText} bold>
                            {isAdminCalenderToggle.value
                                ? t("deliveryInfo.adminCalendar")
                                : t("deliveryInfo.myDeliveryCalendar")}
                        </Body1>
                        <div className={classes.line} />
                    </Box>
                </div>

                {isAdminCalenderToggle.value && (
                    <Alert severity="warning" className={classes.adminWarningAlert}>
                        {t("deliveryInfo.adminCalendarWarning")}
                    </Alert>
                )}
                {!isExistingDeliveryDate && bypassDeliveryDateStr && (
                    <Alert severity="warning" className={classes.adminWarningAlert}>
                        {t("deliveryInfo.adminCalendarDeliveryDateWarning")}
                    </Alert>
                )}

                {!bypassProductAvailabilityDateToggle.value ? (
                    <DeliveryDatesTable
                        onSelectDate={save}
                        deliveryDates={
                            isLoggedInAsCustomer && !isAdminCalenderToggle.value
                                ? deliveryDates.filter(d =>
                                      d.assortments.some(a => new Date(a.deadline) > new Date()),
                                  )
                                : deliveryDates
                        }
                    />
                ) : (
                    <div className={classes.centered}>
                        <TextField
                            id="any-delivery-date"
                            type="date"
                            onChange={e => setBypassDeliveryDateStr(e.target.value)}
                            value={bypassDeliveryDateStr}
                        />

                        <Button
                            disabled={!bypassDeliveryDateStr}
                            onClick={() => save(bypassDeliveryDateStr)}
                            style={{ marginTop: "1rem" }}
                        >
                            {t("deliveryInfo.table.btn.select")}
                        </Button>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
