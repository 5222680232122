import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import React from "react";
import { colors } from "../../../constants/colors";

export const OrderTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "textTransform": "capitalize",
            "fontWeight": theme.typography.fontWeightBold,
            "fontSize": theme.typography.pxToRem(15),
            "marginRight": theme.spacing(0),
            "border": "2px solid",
            "borderColor": theme.palette.primary.main,
            "color": theme.palette.primary.main,
            "&:hover": {
                color: colors.white,
                backgroundColor: theme.palette.primary.main,
            },
            "borderRadius": 50,
            "&:not(:last-of-type):first-of-type": {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: `1px solid ${theme.palette.primary.main}`,
            },
            "&:not(:first-of-type):last-of-type": {
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
            "&:not(:last-of-type):not(:first-of-type)": {
                borderRadius: 0,
                borderRight: 0,
                borderLeft: 0,
            },
        },
        selected: {
            color: colors.white,
            backgroundColor: theme.palette.primary.main,
        },
    }),
)((props: { label: string }) => <Tab {...props} />);
