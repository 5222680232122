export const landing = {
    news: "News",
    messages: "Messages",
    home: "Home",
    showAllNews: "Show all news ({{count}})",
    showAllMessages: "Show all",
    myMessages: "My messages",
    noMessages: "You have no messages",
    contactUs: "Contact us",
};
