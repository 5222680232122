import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLocale } from "../state/slices/userSlice";

export const useTimeLeftFormatter = (dateStr: string) => {
    const { t } = useTranslation("general");
    const [timeLeft, setTimeleft] = useState("");
    const userLocale = useSelector(selectLocale);

    useEffect(() => {
        const formatTimeLeft = () => {
            const now = DateTime.now();
            const end = DateTime.fromISO(dateStr);
            if (now > end) {
                setTimeleft(t("time.noTimeLeft"));
                return;
            }

            const duration = Interval.fromDateTimes(now, end)
                .toDuration(["days", "hours", "minutes", "seconds"])
                .toObject();
            const days = duration?.days ?? 0;
            const hours = duration?.hours ?? 0;
            const minutes = duration?.minutes ?? 0;
            const seconds = duration?.seconds ?? 0;

            // Show only days if deadline is more than one weak away.
            if (days >= 7) {
                setTimeleft(t("time.short.days", { days }));
                return;
            }

            const text = [
                {
                    value: days,
                    text: t("time.short.days", { days }),
                    hidden: days === 0,
                },
                {
                    value: hours,
                    text: t("time.short.hours", { hours }),
                    hidden: hours === 0,
                },
                {
                    value: minutes,
                    text: t("time.short.minutes", { minutes }),
                    hidden: days > 0,
                },
                {
                    value: seconds,
                    text: t("time.short.seconds", { seconds: Math.floor(seconds) }),
                    hidden: hours > 0,
                },
            ]
                .filter(item => item.value !== undefined && !item.hidden)
                .map(item => item.text)
                .join(" ");

            setTimeleft(text);
        };

        formatTimeLeft();

        const intervalId = setInterval(formatTimeLeft, 1000);
        return () => clearInterval(intervalId);
    }, [dateStr, userLocale, t]);

    return timeLeft;
};
