import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { useDialog } from "../../hooks/useDialog";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { fetchNewsAndMessages } from "../../state/sharedActions";
import { readMessage, selectMessages, selectRequestStatus } from "../../state/slices/messageSlice";
import { selectNews } from "../../state/slices/newsSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { Message, News, RequestStatus } from "../../state/types";
import { GtmEvents, pushDataLayer } from "../../utils/pushDataLayer";
import { HomeView } from "./HomeView";
import { MessageModal } from "./MessageModal";
import { MessagesView } from "./MessagesView";
import { NewsModal } from "./NewsModal";
import { NewsView } from "./NewsView";

const useStyles = makeStyles({
    page: {
        fontSize: 14,
    },
});

type View = "home" | "news" | "messages";

export const LandingPage = () => {
    const [view, setView] = useState<View>("home");
    const newsDialog = useDialog<News>();
    const messageDialog = useDialog<Message>();
    const messages = useAppSelector(selectMessages);
    const news = useAppSelector(selectNews);
    const locale = useAppSelector(selectLocale);
    const request = useAppSelector(selectRequestStatus);
    const dispatch = useAppDispatch();
    const classes = useStyles();

    useEffect(() => {
        void dispatch(fetchNewsAndMessages());
    }, [dispatch, locale]);

    const handleOnMessageClick = (message: Message) => {
        messageDialog.setData(message);
        if (!message.read) {
            void dispatch(readMessage(message));
        }
    };

    const handleOnNewsClick = (news: News) => {
        pushDataLayer(GtmEvents.News, { cmsAssetId: news.newsId, newsTitle: news.title });
        newsDialog.setData(news);
    };

    return (
        <Page
            className={classes.page}
            loading={
                request === RequestStatus.Loading && news.length === 0 && messages.length === 0
            }
        >
            <NewsModal item={newsDialog.data} isOpen={newsDialog.isOpen} close={newsDialog.close} />
            <MessageModal
                item={messageDialog.data}
                isOpen={messageDialog.isOpen}
                close={messageDialog.close}
            />
            {view === "home" && (
                <HomeView
                    news={news}
                    messages={messages}
                    onNewsClick={handleOnNewsClick}
                    onMessageClick={handleOnMessageClick}
                    onShowAllNews={() => setView("news")}
                    onShowAllMessages={() => setView("messages")}
                    hasNoMessages={request === RequestStatus.Fulfilled && messages.length === 0}
                />
            )}
            {view === "news" && (
                <NewsView
                    news={news}
                    onBack={() => setView("home")}
                    onNewsClick={handleOnNewsClick}
                />
            )}
            {view === "messages" && (
                <MessagesView
                    messages={messages}
                    onBack={() => setView("home")}
                    onMessageClick={handleOnMessageClick}
                />
            )}
        </Page>
    );
};
