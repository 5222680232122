import { Link as MuiLink, LinkProps } from "@material-ui/core";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

type Props = {
    className?: string;
    to?: string;
    onClick?: () => void;
} & LinkProps;

export const Link: FC<Props> = ({ to, onClick, className, ...props }) => (
    <MuiLink
        component={RouterLink}
        to={to ?? ""}
        classes={{ root: className }}
        onClick={(ev: React.MouseEvent<HTMLAnchorElement>) => {
            if (onClick !== undefined) {
                ev.preventDefault();
                onClick();
            }
        }}
        {...props}
    />
);
