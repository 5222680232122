import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

type Params = {
    values: Array<string | null>;
    isParsed: boolean;
};
export const useQueryParams = (...strings: string[]) => {
    const [params, setParams] = useState<Params>({
        values: strings.map(() => null),
        isParsed: false,
    });
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (params.isParsed) {
            return;
        }
        const searchParams = new URLSearchParams(location.search);
        const parsedValues = strings.map(s => {
            const str = searchParams.get(s);
            if (str) {
                searchParams.delete(s);
            }
            return str;
        });
        history.replace({
            search: searchParams.toString(),
        });
        setParams({
            values: parsedValues,
            isParsed: true,
        });
    }, [history, location, strings, params]);

    return params;
};
