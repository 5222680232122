import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionsObject } from "notistack";
import { RootState } from "../configureStore";

export type NotificationId = string | number;

type Notification = {
    id: NotificationId;
    message: string;
    options: OptionsObject;
    dismissed: boolean;
};

const entityAdapter = createEntityAdapter<Notification>({
    selectId: model => model.id,
});

const notificationSlice = createSlice({
    name: "notifications",
    initialState: entityAdapter.getInitialState(),
    reducers: {
        showSnackbar: (state, action: PayloadAction<OptionsObject & { message: string }>) => {
            const { message, ...options } = action.payload;
            entityAdapter.addOne(state, {
                id: new Date().getTime() + Math.random(),
                options: {
                    variant: "default",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    ...options,
                },
                dismissed: false,
                message,
            });
        },
        removeSnackbar: (state, action: PayloadAction<NotificationId>) => {
            entityAdapter.removeOne(state, action.payload);
        },
        dismissSnackbar: (state, action: PayloadAction<NotificationId>) => {
            entityAdapter.updateOne(state, {
                id: action.payload,
                changes: { dismissed: true },
            });
        },
        dismissAllSnackbars: state => {
            entityAdapter.updateMany(
                state,
                state.ids.map(id => ({ id, changes: { dismissed: true } })),
            );
        },
    },
});

export const { showSnackbar, dismissSnackbar, dismissAllSnackbars, removeSnackbar } =
    notificationSlice.actions;

export const { selectAll: selectNotifications } = entityAdapter.getSelectors<RootState>(
    state => state.notifications,
);

export const notificationsReducer = notificationSlice.reducer;
