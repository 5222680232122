import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/InputForm";
import { LoadingButton } from "../../components/LoadingButton";
import { ModalHeader } from "../../components/modal/ModalHeader";
import { TextField } from "../../components/TextField";
import { useConfirmPasswordField } from "../../hooks/form/useConfirmPasswordField";
import { useNewPasswordField } from "../../hooks/form/useNewPasswordField";
import { useOldPasswordField } from "../../hooks/form/useOldPasswordField";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { changePassword, selectCreateUserRequest } from "../../state/slices/userSlice";
import { RequestStatus } from "../../state/types";
import { isSuccess } from "../../utils/redux";

const useStyles = makeStyles(theme => ({
    container: {
        width: 360,
    },
    alert: {
        margin: theme.spacing(1, 0),
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        marginTop: theme.spacing(2),
        textAlign: "center",
    },
    button: {
        margin: "20px auto",
    },
    title: {
        marginBottom: theme.spacing(1),
    },
}));

export const ChangePasswordModal: FC<{ close: () => void }> = ({ close }) => {
    const { t } = useTranslation(["myAccount", "auth", "general"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const request = useAppSelector(selectCreateUserRequest);

    const oldPasswordField = useOldPasswordField();
    const newPasswordField = useNewPasswordField();
    const confirmPasswordField = useConfirmPasswordField(newPasswordField.value);

    const handleSubmit = async () => {
        const thunkResponse = await dispatch(
            changePassword({
                oldPassword: oldPasswordField.value,
                newPassword: newPasswordField.value,
            }),
        );
        if (isSuccess(thunkResponse, changePassword)) {
            close();
        }
    };

    return (
        <div className={classes.container}>
            <ModalHeader title={t("auth:password.managePassword")} close={close} />
            <Form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    autoFocus
                    type="password"
                    autoComplete="new-password"
                    value={oldPasswordField.value}
                    required
                    onChange={oldPasswordField.onChange}
                    label={t("auth:password.oldPassword")}
                    errorMessage={oldPasswordField.error}
                />
                <TextField
                    type="password"
                    value={newPasswordField.value}
                    autoComplete="new-password"
                    required
                    onChange={newPasswordField.onChange}
                    label={t("auth:password.newPassword")}
                    errorMessage={newPasswordField.error}
                />
                <TextField
                    type="password"
                    value={confirmPasswordField.value}
                    required
                    onChange={confirmPasswordField.onChange}
                    label={t("auth:password.confirmNewPassword")}
                    errorMessage={confirmPasswordField.error}
                />
                <LoadingButton
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disableElevation
                    loading={request === RequestStatus.Loading}
                    disabled={
                        Boolean(newPasswordField.error) ||
                        Boolean(confirmPasswordField.error) ||
                        newPasswordField.value.length === 0 ||
                        oldPasswordField.value.length === 0 ||
                        newPasswordField.value.length === 0
                    }
                >
                    {t("auth:password.changePassword")}
                </LoadingButton>
            </Form>
        </div>
    );
};
