export const landing = {
    news: "Picadeli news",
    messages: "Meddelanden",
    home: "Hem",
    showAllNews: "Visa alla nyheter ({{count}})",
    showAllMessages: "Visa alla",
    myMessages: "Mina meddelanden",
    noMessages: "Du har inga meddelanden",
    contactUs: "Kontakta oss",
};
