import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Endpoint } from "../../network/endpoints";
import { get, post } from "../../network/restClient";
import { translate } from "../../utils/translate";
import { RootState, ThunkApiConfig } from "../configureStore";
import { HistoricOrder } from "../types";

type ValidationError = {
    message: ValdationMessage;
    invalidProductIds: string[];
};

type ValdationMessage = {
    title: string;
    lead: string;
    body?: string;
};

/**
 * Fetch order history from page
 */
export const fetchOrderHistory = createAsyncThunk<
    HistoricOrder[],
    { page: number },
    ThunkApiConfig
>("fetch/order-history", async (args = { page: 0 }, { dispatch, getState }) => {
    return get<HistoricOrder[]>(Endpoint.orderHistory, {
        params: { page: args.page },
    });
});

export const placeOrder = createAsyncThunk<
    { orderNo: number },
    {
        order: {
            deliveryDate: string;
            reference?: string;
            zeroPriceOrder: boolean;
            bypassProductAvailability: boolean;
            items: Array<{ productId: string; quantity: number }>;
        };
    },
    ThunkApiConfig & { rejectValue: ValidationError }
>("orders/place", async ({ order }, thunkAPI) => {
    try {
        return await post<{ orderNo: number }>(Endpoint.order, order, {
            throwRaw: true,
            snackbar: {
                errorMessage: translate("order.placeOrderError.couldNotPlaceOrder"),
                successMessage: translate("order.placeOrderSuccess.snackbarMessage"),
            },
        });
    } catch (rawError) {
        const err = rawError as AxiosError<ValidationError>;
        if (err.response?.status === 409) {
            const responseBody = err.response?.data;
            return thunkAPI.rejectWithValue(responseBody);
        }
        throw new Error(err.message);
    }
});

type State = {
    orderHistoryError?: string;
    orderHistoryLoading: boolean;
    placeOrderLoading: boolean;
    placeOrderSuccess?: { orderNo: number };
    placeOrderError: {
        unknownError?: string;
        validationError?: ValidationError;
    };
    historicOrders: HistoricOrder[];
};

const initialState: State = {
    orderHistoryLoading: false,
    placeOrderLoading: false,
    placeOrderError: {},
    historicOrders: [],
};

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        clearPlaceOrderSuccess: state => {
            state.placeOrderSuccess = undefined;
        },
        clearPlaceOrderError: state => {
            state.placeOrderError = {};
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchOrderHistory.fulfilled, (state, { payload: orders, meta }) => {
            state.historicOrders =
                meta.arg.page > 0 ? [...state.historicOrders, ...orders] : orders;
            state.orderHistoryLoading = false;
        });
        addCase(fetchOrderHistory.pending, state => {
            state.orderHistoryError = undefined;
            state.orderHistoryLoading = true;
        });
        addCase(fetchOrderHistory.rejected, (state, { error }) => {
            state.orderHistoryError = error.message;
            state.orderHistoryLoading = false;
        });
        addCase(placeOrder.pending, state => {
            state.placeOrderError = {};
            state.placeOrderLoading = true;
        });
        addCase(placeOrder.fulfilled, (state, action) => {
            state.placeOrderLoading = false;
            state.placeOrderSuccess = action.payload;
        });
        addCase(placeOrder.rejected, (state, action) => {
            state.placeOrderLoading = false;

            if (action.payload) {
                state.placeOrderError.validationError = action.payload;
            } else {
                state.placeOrderError.unknownError = action.error.message;
            }
        });
    },
});

export const { clearPlaceOrderSuccess, clearPlaceOrderError } = ordersSlice.actions;

export const selectHistoricOrders = (state: RootState) => state.orders.historicOrders;

export const selectIsLoadingOrderHistory = (state: RootState) => state.orders.orderHistoryLoading;
export const selectOrderHistoryError = (state: RootState) => state.orders.orderHistoryError;

export const selectPlaceOrderLoading = (state: RootState) => state.orders.placeOrderLoading;
export const selectPlaceOrderError = (state: RootState) => state.orders.placeOrderError;
export const selectPlaceOrderSuccess = (state: RootState) => state.orders.placeOrderSuccess;

export const ordersReducer = ordersSlice.reducer;
