import { Button, ButtonProps, createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { colors } from "../../../constants/colors";

type Props = { highlight: boolean };

export const FilterButton = withStyles(() =>
    createStyles({
        root: {
            "borderRadius": 0,
            "borderLeftWidth": 0,
            "backgroundColor": (props: Props) =>
                props.highlight ? colors.heroGreen : colors.white,
            "color": (props: Props) => (props.highlight ? colors.white : colors.heroGreen),
            "&:first-of-type": {
                borderLeftWidth: "2px",
                borderTopLeftRadius: "50px",
                borderBottomLeftRadius: "50px",
            },
            "&:last-of-type": {
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
            },
        },
        outlined: {
            "&:hover": {
                opacity: 0.8,
            },
        },
    }),
)(({ highlight, ...props }: ButtonProps & Props) => <Button {...props} variant="outlined" />);
