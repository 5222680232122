import {
    Box,
    Button,
    Divider,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import cn from "classnames";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingButton } from "../../components/LoadingButton";
import { Body1, H2, H3 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { useAppDispatch } from "../../state/configureStore";
import { addProductsToCart, selectReplaceCartStatus } from "../../state/slices/cartSlice";
import { selectHasOrderClaimCodes } from "../../state/slices/claimCodesSlice";
import { selectCurrency, selectCustomer } from "../../state/slices/customerSlice";
import { orderPDF } from "../../state/slices/pdfSlice";
import { selectLocale } from "../../state/slices/userSlice";
import {
    ClaimStatus,
    HistoricOrder,
    OrderRow,
    OrderStatus,
    RequestStatus,
} from "../../state/types";
import { groupBy, sum } from "../../utils/array";
import { formatPrice, formatWeight } from "../../utils/formatters";
import { ClaimOrder } from "./ClaimOrder";
import { ClaimOrderRow } from "./ClaimOrderRow";

const useStyles = makeStyles(theme => ({
    address: {
        "marginRight": "2rem",
        "& > *": {
            margin: 0,
        },
    },
    costDetails: {
        display: "flex",
        justifyContent: "flex-end",
    },
    costItem: {
        "margin": theme.spacing(0.5, 2),
        "&:last-of-type": {
            marginRight: 0,
        },
    },
    divider: {
        margin: theme.spacing(3, 0),
    },
    actionBtn: {
        "margin": theme.spacing(4),
        "& + $actionBtn": {
            marginTop: 0,
        },
    },
    productTableBody: {
        "& tr:nth-child(even)": {
            backgroundColor: colors.darkWhite,
        },
    },
    productTableRow: {
        "& > td": {
            borderBottom: "none",
        },
    },
    title: {
        color: "#000",
        margin: theme.spacing(0.5, 0),
    },
    bigger: {
        fontSize: theme.typography.pxToRem(18),
        fontFamily: "Rubik-SemiBold",
    },
    canceled: {
        textDecoration: "line-through",
        color: colors.attentionRed,
    },
    claimBtn: {
        height: 33,
    },
    lowercase: {
        textTransform: "lowercase",
    },
    highlightRow: {
        "& .MuiTableCell-body": {
            color: colors.attentionRed,
        },
    },
}));

type Props = {
    order: HistoricOrder;
};

const Title: FC = ({ children }) => {
    const classes = useStyles();
    return <H3 className={classes.title}>{children}</H3>;
};

export const OrderTableDetails: FC<Props> = ({ order }) => {
    const classes = useStyles();
    const { t } = useTranslation(["orderHistory", "general"]);
    const dispatch = useAppDispatch();
    const locale = useSelector(selectLocale);
    const currency = useSelector(selectCurrency);
    const customer = useSelector(selectCustomer);
    const replaceCartStatus = useSelector(selectReplaceCartStatus);
    const hasOrderClaimCodes = useSelector(selectHasOrderClaimCodes);

    const hasPickedAllDeliveries = useMemo(() => {
        const nonCanceledRows = order.rows.filter(r => r.status !== OrderStatus.Canceled);
        const groupedByDeliveryNote = groupBy(nonCanceledRows, row => row.deliveryNoteId ?? -1);
        return Object.values(groupedByDeliveryNote).every(rows =>
            rows.some(r => (r?.deliveredQuantity ?? 0) > 0),
        );
    }, [order.rows]);

    const handleCopyToCart = async () => {
        await dispatch(
            addProductsToCart(
                order.rows.map(row => ({
                    quantity: row.orderedQuantity,
                    productId: row.productId,
                })),
            ),
        );
    };
    const printOrder = (opt: { sortByArticleNo: boolean }) => async () => {
        const filter = customer?.groupOrdersOnDeliveryNoteId
            ? {
                  deliveryNoteId: order.rows[0].deliveryNoteId,
                  sortByArticleNo: opt.sortByArticleNo,
              }
            : {
                  orderNo: order.orderNo,
                  erpOrderNo: order.rows[0].erpOrderNo,
                  sortByArticleNo: opt.sortByArticleNo,
              };

        if (!filter.deliveryNoteId) {
            filter.orderNo = order.orderNo;
        }

        await dispatch(orderPDF(filter));
    };

    return (
        <>
            <Box display="flex">
                <div className={classes.address}>
                    <Title>{t("orderHistory:table.details.deliveryAddress")}</Title>
                    <Body1>{customer?.deliveryAddress.name}</Body1>
                    <Body1>{customer?.deliveryAddress.coAddress}</Body1>
                    <Body1>{customer?.deliveryAddress.street}</Body1>
                    <Body1>
                        {customer?.deliveryAddress.city}, {customer?.deliveryAddress.postalNo}
                    </Body1>
                    <Body1>{customer?.deliveryAddress.countryCode}</Body1>
                </div>
                <div className={classes.address}>
                    <Title>{t("orderHistory:table.details.invoiceAddress")}</Title>
                    <Body1>{customer?.invoiceAddress.name}</Body1>
                    <Body1>{customer?.invoiceAddress.coAddress}</Body1>
                    <Body1>{customer?.invoiceAddress.street}</Body1>
                    <Body1>
                        {customer?.invoiceAddress.city}, {customer?.invoiceAddress.postalNo}
                    </Body1>
                    <Body1>{customer?.invoiceAddress.countryCode}</Body1>
                </div>
            </Box>

            <Divider className={classes.divider} />
            {customer?.canClaimFullOrder &&
                customer?.onlineClaimAllowed &&
                hasOrderClaimCodes &&
                hasAllClaimable(order.rows) && (
                    <>
                        <ClaimOrder rows={order.rows} />
                        <Divider className={classes.divider} />
                    </>
                )}

            <Box>
                <Title>{t("orderHistory:table.details.orderedProducts")}</Title>
                <Table>
                    <TableHead>
                        <TableRow>
                            {!customer?.groupOrdersOnDeliveryNoteId && (
                                <TableCell>
                                    {t("orderHistory:table.details.table.orderNumber")}
                                </TableCell>
                            )}
                            <TableCell>{t("orderHistory:table.details.table.productId")}</TableCell>
                            <TableCell>
                                {t("orderHistory:table.details.table.productName")}
                            </TableCell>
                            {customer?.showPrices && (
                                <TableCell align="center">
                                    {t("orderHistory:table.details.table.cost")}
                                </TableCell>
                            )}
                            <TableCell align="center">
                                {t("orderHistory:table.details.table.orderedQuantity")}
                            </TableCell>
                            <TableCell align="center">
                                {order.status === OrderStatus.Invoiced
                                    ? t("orderHistory:table.details.table.deliveredQuantity")
                                    : t("orderHistory:table.details.table.picked")}
                            </TableCell>
                            {customer?.showOrderRowWeight && (
                                <TableCell align="center">
                                    {t("orderHistory:table.details.table.weight")}
                                </TableCell>
                            )}
                            {customer?.showPrices && (
                                <TableCell align="right">
                                    {t("orderHistory:table.details.table.subtotal")}
                                </TableCell>
                            )}
                            {customer?.onlineClaimAllowed && (
                                <TableCell align="center">
                                    {t("orderHistory:table.details.table.reclamation")}
                                </TableCell>
                            )}
                            {order.status === OrderStatus.Invoiced && (
                                <TableCell align="center">
                                    {t("orderHistory:table.details.table.invoicedAt")}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.productTableBody}>
                        {order.rows.map(row => (
                            <TableRow
                                key={row.productId}
                                className={cn(classes.productTableRow, {
                                    [classes.highlightRow]:
                                        hasPickedAllDeliveries &&
                                        row.deliveredQuantity !== row.orderedQuantity,
                                })}
                            >
                                {!customer?.groupOrdersOnDeliveryNoteId && (
                                    <TableCell>{row.erpOrderNo}</TableCell>
                                )}
                                <TableCell className={classes.bigger}>{row.productId}</TableCell>
                                <TableCell>
                                    <span
                                        className={cn({
                                            [classes.canceled]: row.status === OrderStatus.Canceled,
                                        })}
                                    >
                                        {row.productName}
                                    </span>
                                    {row.status === OrderStatus.Canceled && (
                                        <span className={classes.lowercase}>
                                            {" "}
                                            ({t("general:orderStatus.canceled")})
                                        </span>
                                    )}
                                </TableCell>
                                {customer?.showPrices && row.unitPrice !== undefined && (
                                    <TableCell align="center">
                                        {formatPrice(
                                            row.unitPrice * row.orderMultiple,
                                            currency,
                                            locale,
                                        )}
                                    </TableCell>
                                )}
                                <TableCell align="center">
                                    {(row.orderedQuantity / row.orderMultiple).toLocaleString(
                                        locale,
                                        {
                                            maximumFractionDigits: 0,
                                        },
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {(
                                        (row?.deliveredQuantity ?? 0) / row.orderMultiple
                                    ).toLocaleString(locale, {
                                        maximumFractionDigits: 0,
                                    })}
                                </TableCell>
                                {customer?.showOrderRowWeight && (
                                    <TableCell align="center">
                                        {formatWeight(
                                            row.drainedWeight *
                                                (row.deliveredQuantity ?? row.orderedQuantity),
                                            locale,
                                        )}
                                    </TableCell>
                                )}
                                {row.subtotal !== undefined && (
                                    <TableCell align="right" className={classes.bigger}>
                                        {formatPrice(row.subtotal, currency, locale)}
                                    </TableCell>
                                )}
                                {customer?.onlineClaimAllowed && (
                                    <TableCell align="center">
                                        {row.claimStatus === ClaimStatus.Claimable
                                            ? row.erpOrderNo && (
                                                  <ClaimOrderRow
                                                      erpOrderNo={row.erpOrderNo}
                                                      orderRow={row}
                                                  />
                                              )
                                            : t(
                                                  `orderHistory:claimStatus.${row.claimStatus}` as const,
                                              )}
                                    </TableCell>
                                )}

                                {order.status === OrderStatus.Invoiced && (
                                    <TableCell align="center">{row.invoiceNo}</TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>

            <Divider className={classes.divider} />

            {customer?.showPrices && (
                <div className={classes.costDetails}>
                    <div className={classes.costItem}>
                        {customer?.hasVat ? (
                            <Body1>{t("general:price.totalExclVat")}</Body1>
                        ) : (
                            <Body1>{t("general:price.total")}</Body1>
                        )}
                        <H2>
                            {order.costs &&
                                formatPrice(
                                    sum(order.costs, item => item.costExVat),
                                    currency,
                                    locale,
                                )}
                        </H2>
                    </div>
                </div>
            )}

            <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                    className={classes.actionBtn}
                    color="primary"
                    variant="contained"
                    onClick={printOrder({ sortByArticleNo: false })}
                >
                    {t("general:actions.print")}
                </Button>
                <Button
                    className={classes.actionBtn}
                    color="primary"
                    variant="contained"
                    onClick={printOrder({ sortByArticleNo: true })}
                >
                    {t("general:actions.printArticleNumberScheme")}
                </Button>
                <LoadingButton
                    loading={replaceCartStatus === RequestStatus.Loading}
                    className={classes.actionBtn}
                    color="primary"
                    variant="contained"
                    onClick={handleCopyToCart}
                >
                    {t("orderHistory:table.details.copyToCart")}
                </LoadingButton>
            </Box>
        </>
    );
};

function hasAllClaimable(rows: OrderRow[]): rows is Array<OrderRow & { erpOrderNo: number }> {
    return rows.every(
        row => row.claimStatus === ClaimStatus.Claimable && row.erpOrderNo !== undefined,
    );
}
