import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { InnerHTML } from "../../components/InnerHTML";

const useStyles = makeStyles(() => ({
    content: {
        "overflow": "hidden",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "textOverflow": "ellipsis",
        "& *": {
            margin: "0 !important",
            fontWeight: "400 !important",
            fontFamily: "Rubik-Regular !important",
            fontStyle: "normal",
        },
        "marginTop": 10,
    },
    small: {
        "marginTop": 12,
        "maxHeight": 42,
        "minHeight": 42,
        "WebkitLineClamp": 2,
        "lineHeight": "20px",
        "& *": {
            fontSize: "14px !important",
        },
    },
    big: {
        "marginTop": 20,
        "maxHeight": 98,
        "minHeight": 98,
        "WebkitLineClamp": 4,
        "lineHeight": "24px",
        "& *": {
            fontSize: "16px !important",
        },
    },
}));

type Props = { html: string; className?: string; size?: "big" | "small" };
export const InnerHtmlOverflow: FC<Props> = ({ html, className, size = "small" }) => {
    const classes = useStyles();
    return (
        <div
            className={cn(classes.content, className, {
                [classes.big]: size === "big",
                [classes.small]: size === "small",
            })}
        >
            <InnerHTML html={html} />
        </div>
    );
};
