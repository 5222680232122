import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { removeEmptyStrings } from "../../utils/array";
import { downloadFile } from "../../utils/blob";
import { translate } from "../../utils/translate";
import { RootState } from "../configureStore";
import { ProductTemperature } from "../types";

type Filter = {
    fromDate?: string;
    toDate?: string;
    productName?: string;
};

export const fetchProductTemperatures = createAsyncThunk(
    "fetch/product-temperatures",
    async (filter: Filter) => {
        return get<ProductTemperature[]>(Endpoint.productsTemperature, {
            params: removeEmptyStrings(filter),
        });
    },
);

export const fetchProductTemperaturesSpreadsheet = createAsyncThunk(
    "fetch/product-temperatures/spreadsheet",
    async (filter: Filter) => {
        const blob = await get<Blob>(Endpoint.productsTemperatureSpreadsheet, {
            responseType: "blob",
            params: removeEmptyStrings(filter),
            snackbar: { showServerError: true },
        });
        downloadFile(blob, translate("reports.temperatureMeasurements.spreadsheet"));
    },
);

const adapter = createEntityAdapter<ProductTemperature>({
    selectId: entity => `${entity.timeMeasured}${entity.module}${entity.productId}`,
});

const slice = createSlice({
    name: "product-temperatures-slice",
    initialState: adapter.getInitialState<{
        error?: string;
        loading: boolean;
        spreadsheetLoading: boolean;
    }>({
        loading: false,
        spreadsheetLoading: false,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchProductTemperaturesSpreadsheet.pending, (state, action) => {
            state.spreadsheetLoading = true;
        });
        addCase(fetchProductTemperaturesSpreadsheet.rejected, (state, action) => {
            state.spreadsheetLoading = false;
        });
        addCase(fetchProductTemperaturesSpreadsheet.fulfilled, (state, action) => {
            state.spreadsheetLoading = false;
        });

        addCase(fetchProductTemperatures.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
        });
        addCase(fetchProductTemperatures.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload);
            state.loading = false;
        });
        addCase(fetchProductTemperatures.rejected, (state, { error }) => {
            adapter.setAll(state, []);
            state.error = error.message;
            state.loading = false;
        });
    },
});

export const { selectAll: selectProductTemperatures } = adapter.getSelectors<RootState>(
    state => state.productTempratures,
);

export const selectError = (state: RootState) => state.productTempratures.error;
export const selectLoading = (state: RootState) => state.productTempratures.loading;
export const selectSpreadsheetLoading = (state: RootState) =>
    state.productTempratures.spreadsheetLoading;

export const productTemperaturesReducer = slice.reducer;
