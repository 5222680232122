import { styled } from "@material-ui/core";
import { FC } from "react";
import { useSelector } from "react-redux";
import bundleLoader from "../../bundle-loader";
import { colors } from "../../constants/colors";
import { selectTimeZone } from "../../state/slices/customerSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { selectWeatherForecast } from "../../state/slices/weatherSlice";
import { formatDateTime, formatTemperature } from "../../utils/formatters";
import { Body2 } from "../Typography";

const weatherIcons = bundleLoader.importWeatherIcons();

const iconSize = 35;

const Container = styled("div")({
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
});
const WeatherIcon = styled("img")({
    width: iconSize,
    height: iconSize,
});
const WeatherItem = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
}));
const Temperature = styled(Body2)({
    color: colors.lightGrey,
});
const Weekday = styled(Body2)({
    marginTop: 5,
    marginBottom: 5,
    textTransform: "capitalize",
});

export const WeatherForecast: FC = () => {
    const weatherForecast = useSelector(selectWeatherForecast);
    const locale = useSelector(selectLocale);
    const timeZone = useSelector(selectTimeZone);

    return (
        <Container>
            {weatherForecast.map(({ symbolCode, time, temperature }) => {
                return (
                    <WeatherItem key={time}>
                        <WeatherIcon src={weatherIcons[`./${symbolCode}.svg`]} />
                        <Weekday size={12}>
                            {formatDateTime(time, locale, timeZone, { weekday: "long" })}
                        </Weekday>
                        <Temperature size={11}>
                            {formatTemperature(temperature, locale, {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            })}
                        </Temperature>
                    </WeatherItem>
                );
            })}
        </Container>
    );
};
