import { Box, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { colors } from "../../../../constants/colors";
import { ThemeType } from "../../../../theme";

type Severity = "error" | "warning";

type Style = { severity?: Severity };

const useStyles = makeStyles<ThemeType, Style>(theme => ({
    root: {
        backgroundColor: ({ severity }) => {
            switch (severity) {
                case "error":
                    return theme.palette.error.light;
                case "warning":
                    return colors.yellowExtraLight;
                default:
                    return colors.heroGreenExtraLight;
            }
        },
        margin: theme.spacing(1, -1),
        padding: theme.spacing(0.5),
    },
}));

type Props = {
    severity?: Severity;
};

export const Banner: FC<Props> = ({ severity, children }) => {
    const classes = useStyles({ severity });
    return (
        <Box role="banner" className={classes.root}>
            {children}
        </Box>
    );
};
