import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../components/Card";
import { LoadingButton } from "../../components/LoadingButton";
import { useDialog } from "../../hooks/useDialog";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { selectCustomer } from "../../state/slices/customerSlice";
import { generateInventoryReport, selectInventoryRequest } from "../../state/slices/reportSlice";
import { RequestStatus } from "../../state/types";
import { ProductTemperaturesDialog } from "../guidance/ProductTemperaturesDialog";

const useStyles = makeStyles({
    card: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        marginTop: 20,
        marginBottom: 20,
    },
    btn: {
        margin: "10px auto",
    },
});

export const Reports = () => {
    const { t } = useTranslation("reports");
    const tempMeasurementsDialog = useDialog();
    const customer = useAppSelector(selectCustomer);
    const inventoryRequest = useAppSelector(selectInventoryRequest);
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const handleGenerateInventoryReport = () => {
        void dispatch(generateInventoryReport());
    };

    return (
        <>
            <Card className={classes.card}>
                {customer?.canGenerateInventoryReport && (
                    <LoadingButton
                        loading={inventoryRequest === RequestStatus.Loading}
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        onClick={handleGenerateInventoryReport}
                    >
                        {t("links.inventory")}
                    </LoadingButton>
                )}

                {customer?.canGenerateProductTemperatureReports && (
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        onClick={tempMeasurementsDialog.open}
                    >
                        {t("links.productTemperature")}
                    </Button>
                )}
            </Card>
            <ProductTemperaturesDialog {...tempMeasurementsDialog} />
        </>
    );
};
