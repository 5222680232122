import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2, H3 } from "../../../components/Typography";
import {
    selectCartProductsCount,
    selectCost,
    selectVatCost,
} from "../../../state/slices/cartSlice";
import { selectCustomer } from "../../../state/slices/customerSlice";
import { selectLocale } from "../../../state/slices/userSlice";
import { SplitType } from "../../../state/types";
import { formatPercent, formatPrice } from "../../../utils/formatters";
import { CartSmallText } from "./CartSmallText";
import { HeaderColumn } from "./HeaderColumn";

const useStyles = makeStyles(theme => ({
    subText: {
        fontFamily: "Rubik-SemiBold",
        fontSize: "0.6em",
    },
    row: {
        "display": "flex",
        "flexFlow": "row wrap",
        "maxWidth": 250,
        "& > p": {
            marginRight: 5,
        },
        "& > p:last-child": {
            marginRight: 0,
        },
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
}));

export const PriceColumn: FC = () => {
    const { t } = useTranslation("order");
    const userLocale = useSelector(selectLocale);
    const classes = useStyles();
    const count = useSelector(selectCartProductsCount);
    const { total, frozen, chilled } = useSelector(selectCost);
    const customer = useSelector(selectCustomer);
    const vats = useSelector(selectVatCost);

    return (
        <HeaderColumn flexBasis={250}>
            <Body2>{t("cart.containCountProducts", { count })}</Body2>
            {customer?.showPrices && (
                <>
                    <H3 className={classes.noWrap}>
                        {formatPrice(total.cost, customer?.currency, userLocale)}
                        {customer?.hasVat && (
                            <span className={classes.subText}> {t("cart.excludingVat")}</span>
                        )}
                    </H3>
                    {customer?.splitPrice === SplitType.Frozen && (
                        <Box className={classes.row}>
                            <CartSmallText>
                                {t("cart.chilled")}
                                <b> {formatPrice(chilled.cost, customer.currency, userLocale)}</b>
                            </CartSmallText>
                            <CartSmallText>
                                {t("cart.frozen")}
                                <b> {formatPrice(frozen.cost, customer.currency, userLocale)}</b>
                            </CartSmallText>
                        </Box>
                    )}
                    {customer?.hasVat && (
                        <Box className={classes.row}>
                            {vats.map(vat => (
                                <CartSmallText key={`vat-${vat.percentage}`}>
                                    {t("cart.vat", {
                                        percentage: formatPercent(vat.percentage, userLocale),
                                    })}
                                    <b> {formatPrice(vat.total, customer?.currency, userLocale)}</b>
                                </CartSmallText>
                            ))}
                        </Box>
                    )}
                </>
            )}
        </HeaderColumn>
    );
};
