import { ReportsResource } from "../locales";

export const reports: ReportsResource = {
    pageTitle: "Rapporter",
    links: {
        productTemperature: "Generer produkttemperaturrapport",
        inventory: "Generer lagerbeholdningsrapport",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Nullstill",
                submit: "Filter",
            },
            label: {
                productName: "Produktnavn",
                fromDate: "Fra dato",
                toDate: "Til dato",
            },
        },
        table: {
            header: {
                productName: "Produktnavn",
                productNo: "Produktnummer",
                batch: "Batch",
                time: "Tid",
                module: "Modul",
                checkedBy: "Kontrollert av",
                temperature: "Temperatur",
            },
        },
        title: "Produkttemperaturer",
        spreadsheet: "product-temperatures.xlsx",
    },
};
