import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingButton } from "../../components/LoadingButton";
import { Page } from "../../components/Page";
import { Body1, H1 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { useAppDispatch } from "../../state/configureStore";
import {
    orderPDF,
    selectOrderByArticleNoPdfLoading,
    selectOrderPdfLoading,
} from "../../state/slices/pdfSlice";

const useStyles = makeStyles(theme => ({
    title: {
        margin: theme.spacing(2, 0, 4, 0),
        fontSize: "3rem",
    },
    continueShoppingBtn: {
        "backgroundColor": colors.darkGrey,
        "color": colors.white,
        "&:hover": {
            backgroundColor: colors.black,
            color: colors.white,
        },
    },
    printBtn: {
        marginTop: theme.spacing(2),
    },
}));

type Props = {
    orderNo: number;
    onContinueShopping: () => void;
};

export const PlaceOrderSuccessPage: FC<Props> = ({ onContinueShopping, orderNo }) => {
    const { t } = useTranslation(["order", "general"]);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const orderPdfLoading = useSelector(selectOrderPdfLoading);
    const orderByArticleNoPdfLoading = useSelector(selectOrderByArticleNoPdfLoading);

    return (
        <Page>
            <Box mb={10} display="flex" flexDirection="column" alignItems="center">
                <Body1 size={22} primary>
                    {t("order:placeOrderSuccess.thankYouForYourPurchase")}
                </Body1>
                <H1 className={classes.title} lunchBox primary uppercase>
                    {t("order:placeOrderSuccess.yourOrderHasNowBeenReceived")}
                </H1>
                <Button
                    variant="contained"
                    className={classes.continueShoppingBtn}
                    onClick={onContinueShopping}
                >
                    {t("order:placeOrderSuccess.continueShopping")}
                </Button>
                <LoadingButton
                    className={classes.printBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        dispatch(
                            orderPDF({
                                orderNo,
                                sortByArticleNo: false,
                            }),
                        )
                    }
                    loading={orderPdfLoading}
                >
                    {t("general:actions.print")}
                </LoadingButton>
                <LoadingButton
                    className={classes.printBtn}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        void dispatch(
                            orderPDF({
                                orderNo,
                                sortByArticleNo: true,
                            }),
                        )
                    }
                    loading={orderByArticleNoPdfLoading}
                >
                    {t("general:actions.printArticleNumberScheme")}
                </LoadingButton>
            </Box>
        </Page>
    );
};
