import { Box, BoxProps } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import React, { FC } from "react";

type Props = {
    show: boolean;
    delay?: number;
    size?: number;
} & BoxProps;

export const Loader: FC<Props> = ({ show, delay = 200, size, ...rest }) => {
    if (!show) return null;
    return (
        <Box {...rest}>
            <Fade
                in
                style={{
                    transitionDelay: show ? `${delay}ms` : "0ms",
                }}
            >
                <CircularProgress color="primary" size={size || 42} />
            </Fade>
        </Box>
    );
};
