import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import React, { FC } from "react";
import { Body2 } from "./Typography";

const useStyles = makeStyles(theme => ({
    copyrightText: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        textAlign: "center",
        opacity: 0.6,
    },
}));

type Props = {
    className?: string;
};

const CURRENT_YEAR = new Date().getFullYear();

export const CopyrightText: FC<Props> = ({ className }) => {
    const classes = useStyles();
    return (
        <Body2 className={cn(classes.copyrightText, className)}>
            © {CURRENT_YEAR} Picadeli. All Rights Reserved.
        </Body2>
    );
};
