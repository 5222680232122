import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FlexWrap } from "../../components/FlexWrap";
import { H2 } from "../../components/Typography";
import { News } from "../../state/types";
import { Breadcrumbs } from "./Breadcrumbs";
import { NewsCard } from "./NewsCard";

const useStyles = makeStyles({
    highlightedNews: {
        marginTop: 10,
        marginBottom: 10,
    },
});

type Props = {
    news: News[];
    onBack: () => void;
    onNewsClick: (item: News) => void;
};

export const NewsView: FC<Props> = ({ news, onNewsClick, onBack }) => {
    const classes = useStyles();
    const { t } = useTranslation("landing");
    const [highlightedNews, ...restNews] = news;
    return (
        <div>
            <Breadcrumbs links={[{ name: t("home"), onClick: onBack }]} />
            <H2>{t("news")}</H2>
            {highlightedNews && (
                <NewsCard
                    className={classes.highlightedNews}
                    highlight="size"
                    onClick={() => onNewsClick(highlightedNews)}
                    item={highlightedNews}
                    key={highlightedNews.newsId}
                />
            )}
            <FlexWrap
                items={restNews}
                itemsPerRow={2}
                rowKey={(news: News) => news.newsId.toString()}
                renderRow={(news: News) => (
                    <NewsCard onClick={() => onNewsClick(news)} item={news} key={news.newsId} />
                )}
                spacing={10}
            />
        </div>
    );
};
