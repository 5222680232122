import { GeneralResource } from "../locales";

export const general: GeneralResource = {
    actions: {
        review: "Gjennomgå",
        close: "Lukk",
        back: "Tilbake",
        ok: "OK",
        print: "Skriv ut",
        printArticleNumberScheme: "Skriv ut - sortert etter artikkelnummer",
        submit: "Send inn",
        save: "Lagre",
        edit: "Rediger",
        cancel: "Avbryt",
        loadMore: "Last inn mer",
    },
    articles: "Artikler",
    edit: "Rediger",
    navigationLinks: {
        createUser: "Opprett bruker",
        changePassword: "Endre passord",
        communication: "Kommunikasjon",
        guidance: "Veiledning",
        support: "Kundeservice",
        logout: "Logg ut",
        account: "Konto",
        myAccount: "Min konto",
        order: "Bestill",
        orderHistory: "Bestillingshistorikk",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "Ingen tid igjen",
        short: {
            days: "{{days}}d",
            hours: "{{hours}}t",
            minutes: "{{minutes}}m",
            seconds: "{{seconds}}s",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Bestilling mottatt",
        "created": "Bekreftet",
        "dispatched": "Plukking",
        "canceled": "Kansellert",
        "invoiced": "Fakturert",
    },
    price: {
        total: "Totalt",
        totalExclVat: "Totalt (ekskl. mva)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Food to Go",
        "salad-bar": "Salatbar",
    },
    salesUnit: {
        kg: "Kg",
        krt: "Pakke",
        pcs: "Stykk",
    },
    address: {
        invoice: "Fakturaadresse",
        delivery: "Leveringsadresse",
    },
    weight: "Vekt",
    loggedInAsCustomer: "Logget inn som kunde",
    locales: {
        "da-DK": "Dansk",
        "de-DE": "Deutsch",
        "en-US": "English US",
        "fi-FI": "Suomi",
        "fr-FR": "Français",
        "nb-NO": "Norsk",
        "nl-BE": "Nederlands (België)",
        "sv-SE": "Svenska",
    },
};
