import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useEmailValidator = () => {
    const { t } = useTranslation("form");
    const [error, setError] = useState<string>();

    return {
        validate: (email: string) => {
            if (isInvalidEmail(email)) {
                setError(t("invalidEmail"));
                return;
            }
            setError(undefined);
        },
        setError,
        error,
    };
};

export const isInvalidEmail = (email: string) => {
    return email.length > 0 && !/^\S+@\S+.\S+$/.test(email);
};
