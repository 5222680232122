import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import { DeliveryTerm } from "../types";

export const fetchDeliveryTerms = createAsyncThunk(
    "delivery-terms/fetch",
    async (deliveryDate: string) =>
        get<DeliveryTerm[]>(Endpoint.deliveryTerms, { params: { "delivery-date": deliveryDate } }),
);

type State = {
    loading: boolean;
    error?: string;
};

const deliveryTermsAdapter = createEntityAdapter<DeliveryTerm>({
    selectId: term => term.type,
});

const deliveryTermsSlice = createSlice({
    name: "deliveryTerms",
    initialState: deliveryTermsAdapter.getInitialState<State>({
        loading: false,
        error: undefined,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchDeliveryTerms.fulfilled, (state, action) => {
            deliveryTermsAdapter.upsertMany(state, action.payload);
            state.loading = false;
        });
        addCase(fetchDeliveryTerms.pending, state => {
            state.error = undefined;
            state.loading = true;
        });
        addCase(fetchDeliveryTerms.rejected, (state, { error }) => {
            state.error = error.message;
            state.loading = false;
        });
    },
});

export const selectDeliveryTerms = createSelector(
    deliveryTermsAdapter.getSelectors<RootState>(state => state.deliveryTerms).selectAll,
    deliveryTerms => deliveryTerms.filter(dt => dt.thresholdFree !== 0 || dt.thresholdMin !== 0),
);

export const selectDeliveryTermsError = (state: RootState) => state.deliveryTerms.error;

export const deliveryTermsReducer = deliveryTermsSlice.reducer;
