import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import { useAppSelector } from "../../../state/configureStore";
import { cartEntityId, selectCartItemById } from "../../../state/slices/cartSlice";
import { selectClosedInfoById } from "../../../state/slices/closedProductsSlice";
import { selectPlaceOrderError } from "../../../state/slices/ordersSlice";
import { selectProductById } from "../../../state/slices/productsSlice";
import { ProductType } from "../../../state/types";

const useStyles = makeStyles(theme => ({
    errorIcon: {
        color: colors.attentionRed,
        marginLeft: 5,
    },
}));

export const CartItemWarning: FC<{
    productId: string;
    productType: ProductType;
    warningThreshold?: number;
}> = ({ productId, productType }) => {
    const classes = useStyles();
    const product = useAppSelector(state => selectProductById(state, productId));
    const cartItem = useAppSelector(state =>
        selectCartItemById(state, cartEntityId({ productId, productType })),
    );
    const closedInfo = useAppSelector(state => selectClosedInfoById(state, productId));
    const placeOrderError = useSelector(selectPlaceOrderError);

    if (!product || !cartItem) {
        return null;
    }

    const aboveWarningThreshold =
        product.warningThreshold !== undefined &&
        cartItem.salesUnitQuantity !== undefined &&
        cartItem.salesUnitQuantity >= product.warningThreshold * product.orderMultiple;

    const validationError = (placeOrderError?.validationError?.invalidProductIds ?? []).includes(
        cartItem.productId,
    );

    const hasError = closedInfo !== undefined || aboveWarningThreshold || validationError;

    if (!hasError) {
        return null;
    }

    return <ErrorIcon className={classes.errorIcon} />;
};
