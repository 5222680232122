import { Box, BoxProps } from "@material-ui/core";
import React, { FC } from "react";

export const HeaderColumn: FC<BoxProps> = ({ ...props }) => {
    return (
        <Box
            display="flex"
            marginLeft="auto"
            marginRight="auto"
            padding={2}
            flexDirection="column"
            {...props}
        />
    );
};
