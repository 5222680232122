import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setUser as setSentryUser } from "@sentry/react";
import i18next from "i18next";
import { getPersistedLocale, Locale, persistLocale } from "../../assets/locales/locales";
import { clearAuth, isLoggedInAsCustomer, setAccessToken } from "../../network/authService";
import { Endpoint } from "../../network/endpoints";
import { get, post } from "../../network/restClient";
import { polyfillLanguage } from "../../polyfill";
import { history } from "../../utils/history";
import { translate } from "../../utils/translate";
import { RootState } from "../configureStore";
import { resetStore } from "../rootActions";
import { AccessToken, RequestStatus, User } from "../types";
import { showSnackbar } from "./notificationSlice";

export const login = createAsyncThunk(
    "user/login",
    async (userInfo: { username: string; password: string }) => {
        const accessToken = await post<AccessToken>(Endpoint.login, userInfo, {
            requireAuth: false,
        });
        setAccessToken(accessToken);
    },
);

export const logout = createAsyncThunk("user/logout", async (_, { dispatch }) => {
    clearAuth();
    dispatch(resetStore());
    setSentryUser(null);
    await post(
        Endpoint.logout,
        {},
        {
            requireAuth: false,
        },
    );
});

type ResetPasswordSuccess = {
    maskedEmail: string;
    customerSupportPhone: number;
};
export const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async (customerId: number, thunkAPI) => {
        const body = await post<ResetPasswordSuccess | undefined>(
            Endpoint.resetPassword,
            { customerId: isNaN(customerId) ? -1 : customerId },
            {
                requireAuth: false,
            },
        );
        const snackbarMessage = body
            ? translate("auth.forgotPassword.resetPasswordSuccessInfo", {
                  email: body.maskedEmail,
                  customerSupportPhone: body.customerSupportPhone,
              })
            : translate("auth.forgotPassword.resetPasswordSuccessNoContent");
        thunkAPI.dispatch(
            showSnackbar({
                persist: true,
                message: snackbarMessage,
                variant: "info",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "top",
                },
            }),
        );
        history.replace("/login");
    },
);

type ChangePasswordBody = {
    token: string;
    newPassword: string;
};
export const changePasswordWithToken = createAsyncThunk(
    "user/changePassword",
    async (body: ChangePasswordBody) => {
        await post(Endpoint.changePasswordWithToken, body, {
            requireAuth: false,
            snackbar: {
                successMessage: translate("auth.password.changePasswordSuccess"),
                showServerError: true,
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "top",
                },
            },
        });
        history.replace("/login");
    },
);

export const changePassword = createAsyncThunk(
    "user/update-user",
    async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
        await post(
            Endpoint.changePassword,
            {
                oldPassword,
                newPassword,
            },
            {
                snackbar: {
                    successMessage: translate("auth.password.changePasswordSuccess"),
                    showServerError: true,
                },
            },
        );
    },
);

export const register = createAsyncThunk(
    "user/register",
    async (body: { password: string; email?: string; token: string }) => {
        await post(Endpoint.register, body, {
            requireAuth: false,
            snackbar: {
                successMessage: translate("auth.register.snackbarSuccessMessage"),
                showServerError: false,
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "top",
                },
            },
        });
    },
);

export const generatePassword = createAsyncThunk("user/generate-password", async () => {
    return post<{ password: string }>(
        Endpoint.generatePassword,
        {},
        {
            snackbar: {
                successMessage: translate("auth.changePassword.passwordHasBeenUpdated"),
                showServerError: true,
            },
        },
    );
});

export const createUser = createAsyncThunk("user/create-user", async (body: { email?: string }) => {
    return post<{ password: string }>(Endpoint.createUser, body, {
        snackbar: {
            successMessage: translate("auth.password.createUserSuccess"),
            showServerError: true,
        },
    });
});

export const updateUserEmail = createAsyncThunk("user/email", async (email: string) => {
    await post(
        Endpoint.userEmail,
        { email },
        {
            snackbar: {
                successMessage: translate("myAccount.updateContactInfo.success"),
                errorMessage: translate("myAccount.updateContactInfo.error"),
            },
        },
    );
});

export const fetchUser = createAsyncThunk("user/fetch-user", async () => {
    const user = await get<User>(Endpoint.user);
    setSentryUser({
        id: user.userId.toString(),
        username: user.username,
        customerId: user.customerId,
        displayName: user.displayName,
    });
    return user;
});

export const setLocale = createAsyncThunk("user/locale", async (locale: Locale) => {
    await polyfillLanguage(locale);
    await i18next.changeLanguage(locale);
    persistLocale(locale);
    return locale;
});

type UserState = {
    isLoggedIn?: boolean;
    loginLoading: boolean;
    loginError?: string;
    resetPasswordLoading: boolean;
    resetPasswordError?: string;
    changePasswordLoading: boolean;
    changePasswordError?: string;
    user?: User;
    userError?: string;
    userLoading: boolean;
    locale: Locale;
    createUserRequest: RequestStatus;
    generatePasswordRequest: RequestStatus;
    newUserPassword?: string;
    changePasswordRequest: {
        status: RequestStatus;
        errorCode?: string;
        errorMessage?: string;
    };
    registerRequest: {
        status: RequestStatus;
        errorCode?: string;
        errorMessage?: string;
    };
    updateEmailRequest: RequestStatus;
};

const initialState: UserState = {
    loginLoading: false,
    resetPasswordLoading: false,
    userLoading: false,
    changePasswordLoading: false,
    locale: getPersistedLocale(),
    createUserRequest: RequestStatus.Idle,
    generatePasswordRequest: RequestStatus.Idle,
    changePasswordRequest: {
        status: RequestStatus.Idle,
    },
    registerRequest: {
        status: RequestStatus.Idle,
    },
    updateEmailRequest: RequestStatus.Idle,
};

const userSlice = createSlice({
    name: "user-slice",
    initialState,
    reducers: {
        clearLoginError: state => {
            state.loginError = undefined;
        },
        clearResetPasswordError: state => {
            state.resetPasswordError = undefined;
        },
        setLoggedIn: state => {
            state.isLoggedIn = true;
        },
        setLoggedOut: () => initialState,
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(setLocale.fulfilled, (state, { payload }) => {
            state.locale = payload;
        });
        addCase(login.pending, state => {
            state.loginLoading = true;
            state.isLoggedIn = false;
            state.loginError = undefined;
        });
        addCase(login.fulfilled, state => {
            state.loginLoading = false;
            state.isLoggedIn = true;
        });
        addCase(login.rejected, (state, { error }) => {
            state.loginLoading = false;
            state.loginError = error.message;
        });
        addCase(resetPassword.pending, state => {
            state.resetPasswordLoading = true;
            state.resetPasswordError = undefined;
        });
        addCase(resetPassword.fulfilled, state => {
            state.resetPasswordLoading = false;
        });
        addCase(resetPassword.rejected, (state, { error }) => {
            state.resetPasswordLoading = false;
            state.resetPasswordError = error.message;
        });

        addCase(changePasswordWithToken.pending, state => {
            state.changePasswordLoading = true;
            state.changePasswordError = undefined;
        });
        addCase(changePasswordWithToken.fulfilled, state => {
            state.changePasswordLoading = false;
        });
        addCase(changePasswordWithToken.rejected, (state, { error }) => {
            state.changePasswordLoading = false;
            state.changePasswordError = error.message;
        });
        addCase(fetchUser.fulfilled, (state, action) => {
            state.user = action.payload;
        });

        addCase(resetStore.type, state => {
            state.locale = getPersistedLocale();
        });

        addCase(createUser.pending, state => {
            state.createUserRequest = RequestStatus.Loading;
        });
        addCase(createUser.fulfilled, (state, action) => {
            state.createUserRequest = RequestStatus.Fulfilled;
            state.newUserPassword = action.payload.password;
        });
        addCase(createUser.rejected, state => {
            state.createUserRequest = RequestStatus.Rejected;
        });

        addCase(generatePassword.pending, state => {
            state.generatePasswordRequest = RequestStatus.Loading;
        });
        addCase(generatePassword.fulfilled, (state, action) => {
            state.generatePasswordRequest = RequestStatus.Fulfilled;
            state.newUserPassword = action.payload.password;
        });
        addCase(generatePassword.rejected, state => {
            state.generatePasswordRequest = RequestStatus.Rejected;
        });

        addCase(changePassword.pending, state => {
            state.changePasswordRequest = { status: RequestStatus.Loading };
        });
        addCase(changePassword.fulfilled, state => {
            state.changePasswordRequest = { status: RequestStatus.Fulfilled };
        });
        addCase(changePassword.rejected, (state, { error }) => {
            state.changePasswordRequest = {
                status: RequestStatus.Rejected,
                errorCode: error.code,
                errorMessage: error.message,
            };
        });

        addCase(updateUserEmail.pending, state => {
            state.updateEmailRequest = RequestStatus.Loading;
        });
        addCase(updateUserEmail.fulfilled, (state, action) => {
            state.updateEmailRequest = RequestStatus.Fulfilled;
            if (state.user && !isLoggedInAsCustomer()) {
                state.user.email = action.meta.arg;
            }
        });
        addCase(updateUserEmail.rejected, state => {
            state.updateEmailRequest = RequestStatus.Rejected;
        });

        addCase(register.pending, state => {
            state.registerRequest = { status: RequestStatus.Loading };
        });
        addCase(register.fulfilled, state => {
            state.registerRequest = { status: RequestStatus.Fulfilled };
        });
        addCase(register.rejected, (state, { error }) => {
            state.registerRequest = {
                status: RequestStatus.Rejected,
                errorCode: error.code,
                errorMessage: error.message,
            };
        });
    },
});

export const { clearLoginError, clearResetPasswordError, setLoggedIn, setLoggedOut } =
    userSlice.actions;

export const selectUserState = (state: RootState) => state.user;
export const selectLocale = (state: RootState) => state.user.locale;
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const selectUserError = (state: RootState) => state.user.userError;
export const selectUser = (state: RootState) => state.user.user;
export const selectNewUserPassword = (state: RootState) => state.user.newUserPassword;
export const selectGeneratePasswordRequest = (state: RootState) =>
    state.user.generatePasswordRequest;
export const selectCreateUserRequest = (state: RootState) => state.user.createUserRequest;
export const selectChangePasswordRequest = (state: RootState) => state.user.changePasswordRequest;
export const selectUpdateEmailRequest = (state: RootState) => state.user.updateEmailRequest;
export const selectRegisterRequest = (state: RootState) => state.user.registerRequest;
export const userReducer = userSlice.reducer;
