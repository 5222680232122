import * as deDE from "./de-DE";
import * as enUs from "./en-US";
import * as fiFI from "./fi-FI";
import * as frFR from "./fr-FR";
import * as nbNO from "./nb-NO";
import * as nlBE from "./nl-BE";
import * as svSE from "./sv-SE";

/**
 * Friendly reminder:
 * When adding a locale - don't forget to add polyfills for it
 * see "polyfill" module
 */
export const locales = {
    "da-DK": {},
    "de-DE": deDE,
    "en-US": enUs,
    "fi-FI": fiFI,
    "fr-FR": frFR,
    "nb-NO": nbNO,
    "nl-BE": nlBE,
    "sv-SE": svSE,
};

export type Locale = keyof typeof locales;

export const supportedLocales: Locale[] = Object.keys(locales) as Locale[];

export type MyAccountResource = RecursivePartial<typeof enUs.myAccount>;
export type CommunicationResource = RecursivePartial<typeof enUs.communication>;
export type PDFResource = RecursivePartial<typeof enUs.pdf>;
export type CustomerSupportResource = RecursivePartial<typeof enUs.customerSupport>;
export type ReportsResource = RecursivePartial<typeof enUs.reports>;
export type FormResource = RecursivePartial<typeof enUs.form>;
export type OrderResource = RecursivePartial<typeof enUs.order>;
export type AuthResource = RecursivePartial<typeof enUs.auth>;
export type ErrorResource = RecursivePartial<typeof enUs.error>;
export type GeneralResource = RecursivePartial<typeof enUs.general>;
export type PageNotFoundResource = RecursivePartial<typeof enUs.pageNotFound>;
export type OrderHistoryResource = RecursivePartial<typeof enUs.orderHistory>;
export type SnackbarResource = RecursivePartial<typeof enUs.snackbar>;
export type LandingResource = RecursivePartial<typeof enUs.landing>;
export type GuidanceResource = RecursivePartial<typeof enUs.guidance>;

type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
        ? Array<RecursivePartial<U>>
        : T[P] extends Record<string, unknown>
        ? RecursivePartial<T[P]>
        : T[P];
};

const fallbackLocale: Locale = "en-US";

export function persistLocale(locale: Locale) {
    localStorage.setItem("locale", locale);
}

export function getPersistedLocale(): Locale {
    const locale = localStorage.getItem("locale") ?? parseLocaleFromBrowser();
    if (locale !== undefined && locale in locales) {
        return locale as Locale;
    } else {
        return fallbackLocale;
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
function parseLocaleFromBrowser() {
    const browserLang = window.navigator.language;
    const keys = Object.keys(locales) as Locale[];
    return (
        keys.find(key => key.toLowerCase() === browserLang.toLowerCase()) ?? // Direct browser match
        keys.find(key => key.slice(0, 2).toLowerCase() === browserLang.slice(0, 2).toLowerCase()) // Matching at least lang string
    );
}

/**
 * Checks that the customer's locales are valid.
 * It returns the primary locale if it's valid, otherwise any secondary that's valid.
 * If none are valid it returns fallback locale.
 */
export function getValidCustomerLocale(customerLocale: { primary: Locale; secondary: Locale[] }) {
    return [customerLocale.primary, ...customerLocale.secondary].find(isValid) ?? fallbackLocale;
}

export function isValid(locale: string) {
    return locale in locales;
}
