import { Box, Container, ContainerProps, createStyles } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import errorImgSrc from "../assets/images/error.gif";
import { BackgroundImage } from "./BackgroundImage";
import { CopyrightText } from "./CopyrightText";
import { Loader } from "./Loader";
import { H2 } from "./Typography";

export const PageContent = withStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(0, 0),
            maxWidth: 1360,
        },
    }),
)((props: ContainerProps) => <Container {...props} />);

const useStyles = makeStyles({
    page: {},
    transparent: {
        backgroundColor: "transparent",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        padding: 50,
    },
});

type BackgroundImageProps = {
    fullHeight?: boolean;
    src: string;
};

type Props = {
    transparent?: boolean;
    background?: BackgroundImageProps;
    ContentComponent?: ElementType;
    loading?: boolean;
    error?: boolean;
    copyrightClassName?: string;
} & ContainerProps;

export const Page: React.FC<Props> = ({
    ContentComponent,
    background,
    className,
    transparent,
    loading = false,
    children,
    error,
    copyrightClassName,
    ...props
}) => {
    const classes = useStyles();
    const Content = ContentComponent !== undefined ? ContentComponent : PageContent;
    return (
        <Container
            maxWidth={false}
            className={cn(classes.page, { [classes.transparent]: transparent }, className)}
            {...props}
        >
            {background && (
                <BackgroundImage src={background.src} fullHeight={background.fullHeight} />
            )}
            <Content>
                {error ? (
                    <PageError />
                ) : loading ? (
                    <>
                        <Loader className={classes.loader} show />
                        <CopyrightText className={copyrightClassName} />
                    </>
                ) : (
                    <>
                        {children}
                        <CopyrightText className={copyrightClassName} />
                    </>
                )}
            </Content>
        </Container>
    );
};

const PageError = () => {
    const { t } = useTranslation("error");
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <img alt="error" src={errorImgSrc} />
            <H2>{t("ohNoSomethingWentWrong")}</H2>
        </Box>
    );
};
