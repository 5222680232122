import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2 } from "../../../../components/Typography";
import { selectCurrency } from "../../../../state/slices/customerSlice";
import { selectDeliveryTerms } from "../../../../state/slices/deliveryTermsSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatPrice } from "../../../../utils/formatters";

const useStyles = makeStyles(theme => ({
    deliveryValue: {
        "&:first-letter": {
            textTransform: "uppercase",
        },
    },
}));

export const FreeShippingInfo: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation("order");
    const deliveryTerms = useSelector(selectDeliveryTerms);
    const currency = useSelector(selectCurrency);
    const locale = useSelector(selectLocale);

    return (
        <Box textAlign="center" mt={1} minHeight={40} ml="auto" mr="auto" width={200}>
            {deliveryTerms.length > 0 && <Body2 bold>{t("cart.shipping.free.title")}</Body2>}
            {deliveryTerms.map(item => (
                <Body2 className={classes.deliveryValue} key={`threshold-${item.type}`}>
                    {t(`cart.shipping.free.values.${item.type}` as const, {
                        thresholdFree: formatPrice(item.thresholdFree, currency, locale),
                    })}
                </Body2>
            ))}
        </Box>
    );
};
