import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrderSuggestionProducts } from "../../../../state/slices/productMenuSlice";
import { selectUpcomingDeliveries } from "../../../../state/slices/productSelectors";
import { ProductType } from "../../../../state/types";
import { ProductTable } from "../../product-table/ProductTable";
import { ExcessWeightCell } from "./ExcessWeightCell";
import { OrderSuggestionQty } from "./OrderSuggestionQty";

export const OrderSuggestionTable: FC<{
    productType: ProductType;
}> = ({ productType }) => {
    const { t } = useTranslation(["order"]);
    const products = useSelector(selectOrderSuggestionProducts);
    const upcomingDeliveries = useSelector(selectUpcomingDeliveries);

    return (
        <ProductTable
            products={products}
            title={t("order:productMenu.orderSuggestionsMeals")}
            productType={productType}
            showProductPdfDownload
            customColumn={{
                title: t("order:productMenu.excessWeightTitle"),
                CellComponent: ExcessWeightCell,
            }}
            headerTexts={{ quantity: t("order:productMenu.numPackages") }}
            QuantityComponent={OrderSuggestionQty}
            upcomingDeliveryDates={upcomingDeliveries.getTableColumns(products)}
        />
    );
};
