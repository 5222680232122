import { OrderHistoryResource } from "../locales";

export const orderHistory: OrderHistoryResource = {
    claim: {
        orderRowTitle: "Réclamer {{productName}}",
        orderTitle: "Réclamation de commande",
        info: "En cas de livraison manquée ou d'autres réclamations majeures, appelez le service client {{phoneNumber}}",
        placeholderReason: "Selectionner la raison de votre réclamation.",
        bestBefore: "DLC :",
        batchCode: "Numéro de lot :",
        outerBoxBroken: "Carton endommagé:",
        additionalInfo: "Information supplémentaire :",
        contactForTheClaim: "Nom - Prénom :",
        contactEmail: "Adresse mail :",
        picture: "Image :",
        success: {
            title: "Merci",
            content:
                "Nous allons traiter votre réclamation dans les 48h et reviendrons vers vous si nous avons des questions.",
            bestRegards: "Bien cordialement",
            picadeliCustomerService: "Picadeli service client",
        },
    },
    claimStatus: {
        "not-available": "Indisponible",
        "call-customer-services": "Téléphoner au service client",
        "claimed": "Réclamé",
    },
    table: {
        details: {
            deliveryAddress: "Adresse de livraison par défaut",
            invoiceAddress: "Adresse de facturation par défaut",
            orderedProducts: "Articles commandés",
            table: {
                cost: "Prix",
                deliveredQuantity: "Livré",
                invoicedAt: "Facturé",
                weight: "Poids net alim",
                orderNumber: "Commande N°",
                orderedQuantity: "Commandé",
                claimOrder: "Réclamer l’intégralité de ma commande",
                picked: "Préparé",
                productId: "Article",
                productName: "Nom",
                reclamation: "Réclamation",
                subtotal: "Sous-total",
            },
            copyToCart: "Dupliquer la commande",
        },
        header: {
            orderRef: "Réf",
            createdBy: "Créé par",
            deliversTo: "Envoyez à",
            deliveryDate: "Date de livraison",
            status: "Statut",
            timeCreated: "Créé",
            totalCostExclVat: "Total (HT)",
            totalWeight: "Poids total",
        },
        review: "La revue",
    },
    thereIsNoOrderHistory: "Aucun historique de commande disponible.",
};
