export const myAccount = {
    accountSettings: "Account settings",
    addNewRecipient: "Add new recipient",
    alarmsAndNotifications: "Alarms & notifications",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Food To Go",
        saladBar: "Salad bar",
    },
    customerNo: "Customer number",
    customerUserIsMissing: "There is no user registered for the customer",
    email: "Email",
    emailInvoice: "Email invoice",
    foodAlarms: "Food alarms",
    language: "Language",
    missingContactPerson: "Missing contact person",
    missingInformation: "Missing information",
    name: "Name",
    orderConfirmations: "Order confirmations",
    passwordReset: "Password reset",
    passwordResetSentTo: "Password reset sent to",
    phone: "Phone",
    purchaseStats: "Purchase statistics",
    recipient: "Recipient",
    reports: "Reports",
    responsibleSingular: "Responsible",
    responsiblePlural: "Responsible",
    storeManager: "Store manager",
    storeManagerDescription:
        "Used for important announcements regarding the business relationship price changes or similar.",
    updateContactInfo: {
        success: "Successfully saved.",
        error: "Could not save.",
    },
    yourStore: "Your store",
};
