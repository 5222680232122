import { PageNotFoundResource } from "../locales";

export const pageNotFound: PageNotFoundResource = {
    title: "Ojdå!",
    subTitle: "Vi verkar inte kunna hitta sidan du letar efter.",
    errorCode: "Felkod: 404",
    usefulLinks: "Här är några hjälpfulla länkar istället:",
    links: {
        home: "Startsida",
        order: "Beställning",
        myAccount: "Mitt konto",
    },
};
