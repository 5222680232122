import { Box, Dialog, DialogContent, IconButton, styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { MouseEventHandler, useEffect, useState } from "react";
import { useDialog } from "../hooks/useDialog";
import { Loader } from "./Loader";

const StyledDialog = styled(Dialog)({
    cursor: "pointer",
});
const StyledClosedBtn = styled(IconButton)({
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 1,
    color: "white",
});
const StyledDialogContent = styled(DialogContent)({
    backgroundColor: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

type StyledImgProps = {
    onClick?: MouseEventHandler<HTMLImageElement>;
};
const StyledImg = styled("img")((props: StyledImgProps) => ({
    cursor: props.onClick !== undefined ? "pointer" : undefined,
}));

const placeholderSrc = (width?: number, height?: number) =>
    `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

type Props = {
    width?: number;
    height?: number;
    src: string;
    alt: string;
    useSpinner?: boolean;
    className?: string;
    fullscreen?: {
        src: string;
        imageWidth: number;
        imageHeight: number;
        srcSet?: string;
    };
    center?: boolean;
    mr?: number;
    srcSet?: string;
};

export const Image: React.FC<Props> = ({
    className,
    width,
    height,
    src,
    alt = "",
    useSpinner = false,
    fullscreen,
    center,
    mr,
    srcSet,
}) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string>(placeholderSrc(width, height));
    const [showImage, setShowImage] = useState<boolean>(true);
    const fullscreenDialog = useDialog();
    const showSpinner = !loaded && useSpinner;

    useEffect(() => {
        setImageSrc(src);
    }, [src]);

    const onLoadImage = () => setLoaded(true);
    const onError = () => setShowImage(false);

    return (
        <Box
            minWidth={width}
            maxWidth={width}
            minHeight={height}
            maxHeight={height}
            mr={mr}
            margin={center ? "auto" : undefined}
        >
            {fullscreen && (
                <StyledDialog
                    fullScreen
                    open={fullscreenDialog.isOpen}
                    onClick={e => {
                        e.stopPropagation();
                        fullscreenDialog.close();
                    }}
                >
                    <StyledClosedBtn
                        aria-label="close"
                        onClick={e => {
                            e.stopPropagation();
                            fullscreenDialog.close();
                        }}
                    >
                        <CloseIcon />
                    </StyledClosedBtn>
                    <StyledDialogContent>
                        <img
                            src={fullscreen.src}
                            width={fullscreen.imageWidth}
                            height={fullscreen.imageHeight}
                            srcSet={fullscreen.srcSet}
                            alt={alt}
                        />
                    </StyledDialogContent>
                </StyledDialog>
            )}
            {showImage && (
                <StyledImg
                    onClick={
                        fullscreen
                            ? e => {
                                  e.stopPropagation();
                                  fullscreenDialog.open();
                              }
                            : undefined
                    }
                    className={className}
                    src={imageSrc}
                    srcSet={srcSet}
                    width={showSpinner ? 0 : width}
                    height={showSpinner ? 0 : height}
                    alt={alt}
                    onLoad={onLoadImage}
                    onError={onError}
                />
            )}
            <Loader show={showSpinner} size={Math.min(60, (height ?? 0) - 20)} />
        </Box>
    );
};
