import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { Body2 } from "../../components/Typography";
import { colors } from "../../constants/colors";

const useStyles = makeStyles({
    missing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 67,
        backgroundColor: "#F4F6F5",
        borderRadius: 15,
        color: colors.lightGrey,
    },
});

export const MissingInfo: FC = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.missing}>
            <Body2 italic>{children}</Body2>
        </div>
    );
};
