import { SyntheticEvent } from "react";

export function parseValue(e: SyntheticEvent, elementId: string) {
    const target = e.target as typeof e.target & {
        elements: Record<string, { value: string }>;
    };
    return target.elements[elementId]?.value;
}

export function parseFormValue(
    e: HTMLFormElement | null = null,
    elementId: string,
): string | undefined {
    if (!e) {
        return undefined;
    }
    const elements = e.elements as unknown as Record<string, { value: string }>;
    if (!elements) {
        return undefined;
    }
    return elements[elementId]?.value;
}

export function parseChecked(e: SyntheticEvent, elementId: string) {
    const target = e.target as typeof e.target & {
        elements: Record<string, { checked: boolean }>;
    };
    return target.elements[elementId]?.checked;
}

export function formFile(e: SyntheticEvent, elementId: string) {
    const target = e.target as typeof e.target & {
        elements: Record<string, { files: FileList }>;
    };
    return target.elements[elementId].files[0];
}
