export enum Endpoint {
    accessToken = "/auth/access-token",
    login = "/auth/login",
    logout = "/auth/logout",
    resetPassword = "/auth/reset-password",
    changePasswordWithToken = "/auth/change-password-with-token",
    validateNewPassword = "/auth/validate-new-password",
    register = "/auth/register",

    user = "/user",
    createUser = "/user/create",
    generatePassword = "/user/generate-new-password",
    userEmail = "/user/email",
    changePassword = "/user/change-password",

    customer = "/customer",
    supportDetails = "/customer/support-details",
    deliveryTerms = "/customer/delivery-terms",
    productsTemperature = "/customer/product-temperatures",
    productsTemperatureSpreadsheet = "/customer/product-temperatures/spreadsheet",
    contacts = "/customer/contacts",
    emails = "/customer/emails",
    weatherForecast = "/customer/weather-forecast",
    newsAndMessages = "/customer/news-and-messages",
    assets = "/customer/assets",
    readMessage = "/customer/read-message",
    lastSelectedDeliveryDate = "/customer/last-delivery-date",
    copyOrderMats = "/customer/copy-order-mats",

    order = "/order",
    orderMat = "/order/order-mat",
    orderHistory = "/order/history",
    closedInfos = "/order/closed-infos",
    assortments = "/order/assortments",
    deliveryDates = "/order/delivery-dates",
    categories = "/order/categories",
    products = "/order/products",
    favorites = "/order/favorites",
    cart = "/order/cart",
    addToCart = "/order/cart/add",
    replaceCart = "/order/cart/replace",
    replaceCartWithOrderSuggestion = "/order/cart/replace-with-order-suggestion",
    claimCodes = "/order/claim-codes",
    claim = "/order/claim",
    claimImageUrl = "/order/claim-image-url",

    orderPdf = "/pdf/order",
    deliveryCalendarPdf = "/pdf/delivery-calendar",
    productPdf = "/pdf/product",
    productsPdf = "/pdf/products",
    planogramPdf = "/pdf/planogram",

    inventoryReport = "/reports/inventory",
}
