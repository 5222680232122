import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectCustomer } from "../../../../state/slices/customerSlice";
import { SplitType } from "../../../../state/types";
import { HeaderColumn } from "../HeaderColumn";
import { FrozenWeight } from "./FrozenWeight";
import { ProductTypesWeight } from "./ProductTypesWeight";
import { ShippingTotal } from "./ShippingTotal";
import { TotalWeight } from "./TotalWeight";

export const WeightColumn: FC = () => {
    const customer = useSelector(selectCustomer);

    return (
        <HeaderColumn flexBasis={250}>
            {customer?.splitWeight === SplitType.Frozen && <FrozenWeight />}
            {customer?.splitWeight === SplitType.ProductTypes && <ProductTypesWeight />}
            {customer?.splitWeight === SplitType.NoSplit && <TotalWeight />}
            <ShippingTotal />
        </HeaderColumn>
    );
};
