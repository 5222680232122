import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import React, { FC, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { Form } from "../../components/form/InputForm";
import { colors } from "../../constants/colors";
import { useConfirmPasswordField } from "../../hooks/form/useConfirmPasswordField";
import { useEmailField } from "../../hooks/form/useEmailField";
import { useNewPasswordField } from "../../hooks/form/useNewPasswordField";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ErrorCode } from "../../network";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { register, selectRegisterRequest } from "../../state/slices/userSlice";
import { isSuccess } from "../../utils/redux";
import { AuthInput } from "./AuthInput";
import { AuthPage } from "./AuthPage";
import { AuthSubmitButton } from "./AuthSubmitButton";

const useStyles = makeStyles(theme => ({
    btn: {
        minWidth: 260,
        alignSelf: "center",
    },
    sendBtn: {
        marginTop: theme.spacing(3),
    },
    backToLoginBtn: {
        marginTop: "1em",
        color: colors.white,
    },
    input: {
        marginLeft: 60,
        marginRight: 60,
        marginTop: "25px !important",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    customerId: {
        fontFamily: "Rubik-SemiBold",
    },
}));

export const RegisterPage: FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("auth");
    const request = useAppSelector(selectRegisterRequest);
    const history = useHistory();

    const {
        values: [token, customerId, customerName],
        isParsed,
    } = useQueryParams("token", "id", "name");
    const emailField = useEmailField();
    const { setError: setEmailError } = emailField;
    const passwordField = useNewPasswordField();
    const confirmPasswordField = useConfirmPasswordField(passwordField.value);

    useEffect(() => {
        if (request.errorCode === ErrorCode.EmailAlreadyExists && request.errorMessage) {
            setEmailError(request.errorMessage);
        }
    }, [request.errorCode, request.errorMessage, setEmailError]);

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        const thunkResponse = await dispatch(
            register({
                email: emailField.value.length === 0 ? undefined : emailField.value,
                password: passwordField.value,
                token: token ?? "",
            }),
        );
        if (isSuccess(thunkResponse, register)) {
            history.replace("/login");
        }
    };

    if (isParsed && (token === null || customerName === null || customerId === null)) {
        return <Redirect to="/login" />;
    }

    return (
        <AuthPage
            title={t("register.title")}
            description={
                <>
                    {t("register.welcome", { customerName })} <br />
                    {t("register.yourStoreNumberIs")}
                    <span className={classes.customerId}> {customerId}</span>
                </>
            }
            errorMessage={request.errorMessage}
        >
            <Form className={classes.form} onSubmit={handleSubmit}>
                <AuthInput
                    required
                    autoComplete="new-password"
                    label={t("password.setPassword")}
                    className={classes.input}
                    value={passwordField.value}
                    onChange={passwordField.onChange}
                    type="password"
                    errorMessage={passwordField.error}
                />
                <AuthInput
                    required
                    autoComplete="new-password"
                    label={t("password.confirmNewPassword")}
                    className={classes.input}
                    value={confirmPasswordField.value}
                    onChange={confirmPasswordField.onChange}
                    type="password"
                    errorMessage={confirmPasswordField.error}
                />
                <AuthInput
                    label={t("register.emailLabel")}
                    className={classes.input}
                    value={emailField.value}
                    onChange={emailField.onChange}
                    errorMessage={emailField.error}
                />
                <AuthSubmitButton
                    className={cn(classes.btn, classes.sendBtn)}
                    loading={false}
                    disabled={
                        Boolean(emailField.error) ||
                        Boolean(passwordField.error) ||
                        Boolean(confirmPasswordField.error) ||
                        passwordField.value.length === 0 ||
                        confirmPasswordField.value.length === 0
                    }
                >
                    {t("register.submit")}
                </AuthSubmitButton>
            </Form>
        </AuthPage>
    );
};
