import { makeStyles } from "@material-ui/core/styles";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2 } from "../../../../components/Typography";
import { selectClosedInfoEntities } from "../../../../state/slices/closedProductsSlice";
import { selectMenuEntities } from "../../../../state/slices/productMenuSlice";

const useStyles = makeStyles(theme => ({
    msg: {
        display: "block",
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.error.main,
    },
}));

type Props = { productId: string };

export const ClosedInfoCell: FC<Props> = memo(({ productId }) => {
    const classes = useStyles();
    const { t } = useTranslation(["order"]);
    const productMenuMap = useSelector(selectMenuEntities);
    const closedInfoMap = useSelector(selectClosedInfoEntities);
    const menuItem = productMenuMap[productId];
    if (!menuItem) {
        return null;
    }
    const closedInfoProducts = menuItem.products.filter(p => closedInfoMap[p.productId]);
    if (closedInfoProducts.length === 0) {
        return null;
    }
    return (
        <Body2 className={classes.msg}>
            {t("order:productMenu.someProductsCannotBeDelivered")}
        </Body2>
    );
});
