import { Backdrop, BackdropProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC, useEffect, useState } from "react";
import { Card } from "../Card";

const useStyles = makeStyles({
    sideBar: {
        overflow: "auto",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        margin: 40,
        display: "flex",
        flexDirection: "column",
        transform: "translateX(120%)",
        transition: "transform 195ms ease-in-out",
        zIndex: 2,
    },
    open: {
        transform: "translateX(0%)",
    },
    backdrop: {
        zIndex: 1337,
        background: "linear-gradient(270deg, #264741 0%, rgba(38, 71, 65, 0.3) 100%)",
    },
});

type Props = {
    isOpen: boolean;
    onBackdropClick: () => void;
    className?: string;
} & Omit<BackdropProps, "open">;

export const SidebarModal: FC<Props> = ({
    onExited,
    isOpen,
    onBackdropClick,
    className,
    children,
}) => {
    const [mounted, setMounted] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (isOpen) {
            setMounted(isOpen);
        }
    }, [isOpen]);

    return (
        <>
            <Backdrop
                open={isOpen}
                className={classes.backdrop}
                onClick={onBackdropClick}
                onExited={ev => {
                    if (onExited !== undefined) {
                        onExited(ev);
                    }
                    setMounted(false);
                }}
            >
                <Card
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    className={cn(classes.sideBar, className, { [classes.open]: isOpen })}
                >
                    {mounted ? children : null}
                </Card>
            </Backdrop>
        </>
    );
};
