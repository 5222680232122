import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorCode } from "../../network";
import { useAppSelector } from "../../state/configureStore";
import { selectChangePasswordRequest } from "../../state/slices/userSlice";

export const useOldPasswordField = () => {
    const { t } = useTranslation(["auth"]);
    const [value, setValue] = useState<string>("");
    const [error, setError] = useState<string>();
    const changePwRequest = useAppSelector(selectChangePasswordRequest);

    useEffect(() => {
        if (changePwRequest.errorCode === ErrorCode.OldPassword) {
            setError(changePwRequest.errorMessage);
        }
    }, [changePwRequest, setError, t]);

    return {
        error,
        value,
        onChange: (password: string) => setValue(password),
    };
};
