import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import alertSvg from "../../assets/images/icons/alert.svg";
import chiefSvg from "../../assets/images/icons/chief.svg";
import personSvg from "../../assets/images/icons/person.svg";
import { LocaleSelect } from "../../components/header/LocaleSelect";
import { SidebarModal } from "../../components/modal/SidebarModal";
import { Page } from "../../components/Page";
import { Body1, Body2, H1, H3 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { useDialog } from "../../hooks/useDialog";
import { useIsLoggedInAsCustomer } from "../../hooks/useIsLoggedInAsCustomer.ts";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import {
    deleteCustomerContact,
    fetchCustomerContacts,
    selectContactsByRole,
    selectEditContactRequest,
    selectFetchCustomerContactRequests,
    upsertCustomerContact,
} from "../../state/slices/customerContactsSlice";
import {
    selectCustomer,
    selectCustomerLocales,
    selectUpdateCustomerEmailsRequest,
    updateCustomerEmails,
} from "../../state/slices/customerSlice";
import {
    selectUpdateEmailRequest,
    selectUser,
    updateUserEmail,
} from "../../state/slices/userSlice";
import {
    CustomerContact,
    CustomerContactRole,
    CustomerEmails,
    RequestStatus,
} from "../../state/types";
import { isSuccess } from "../../utils/redux";
import { AccountCard, AccountCardHeader } from "./AccountCard";
import { AddressInformation } from "./AddressInformation";
import { Contact } from "./Contact";
import { ContactModal } from "./ContactModal";
import { EmailModal } from "./EmailModal";
import { Emails } from "./Emails";
import { Reports } from "./Reports";

const useStyles = makeStyles({
    header: {
        backgroundColor: "#C4F0DE",
        border: `1px solid #61BB95`,
        color: colors.black,
        display: "flex",
        padding: 30,
        borderRadius: 20,
    },
    storeName: {
        marginTop: 20,
        marginBottom: 20,
    },
    customerNo: {
        marginBottom: 5,
    },
    yourStore: {
        display: "flex",
        flexDirection: "column",
    },
    cardHeader: {
        display: "flex",
        borderBottom: "1px solid black",
    },
    sectionTitle: {},
    cardTitle: {
        marginLeft: 10,
    },
    left: {
        width: "50%",
        paddingRight: 15,
    },
    right: {
        paddingRight: 15,
        width: "50%",
    },
    content: {
        margin: "10px 0",
        display: "flex",
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
    },
    storeManagerDescription: {
        marginTop: 15,
        width: "60%",
    },
    localeSelect: {
        marginTop: 20,
    },
    langTitle: {
        marginLeft: 10,
    },
    langCard: {
        marginRight: 20,
    },
    resetPasswordCard: {
        marginLeft: 10,
        marginRight: 15,
    },
});

export const MyAccountPage: FC = () => {
    const classes = useStyles();
    const customer = useAppSelector(selectCustomer);
    const contacts = useAppSelector(selectContactsByRole);
    const editContactRequest = useAppSelector(selectEditContactRequest);
    const fetchCustomerContactRequest = useAppSelector(selectFetchCustomerContactRequests);
    const resetPasswordEmailRequest = useAppSelector(selectUpdateEmailRequest);
    const updateCustomerEmailsRequest = useAppSelector(selectUpdateCustomerEmailsRequest);
    const customerLocales = useAppSelector(selectCustomerLocales);
    const user = useAppSelector(selectUser);
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const editEmailDialog = useDialog<{
        emails: string[];
        onSubmit: (emails: string[]) => void;
        single?: boolean;
        title: string;
    }>();
    const editContactDialog = useDialog<{
        role: CustomerContactRole;
        contact?: CustomerContact;
        overline: string;
        title: string;
    }>();
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["myAccount", "general"]);
    const resetPasswordEmail = isLoggedInAsCustomer ? customer?.userEmail : user?.email;

    useEffect(() => {
        void dispatch(fetchCustomerContacts());
    }, [dispatch]);

    if (!customer) {
        return null;
    }

    const handleUpdateEmails = async (updates: Partial<CustomerEmails> = {}) => {
        const thunkResponse = await dispatch(
            updateCustomerEmails({ ...customer.emails, ...updates }),
        );
        if (isSuccess(thunkResponse, updateCustomerEmails)) {
            editEmailDialog.close();
        }
    };

    const handleUpsertContact = async (contact: CustomerContact) => {
        const thunkResponse = await dispatch(upsertCustomerContact(contact));
        if (isSuccess(thunkResponse, upsertCustomerContact)) {
            editContactDialog.close();
        }
    };

    const handleDeleteContact = async (role: CustomerContactRole) => {
        const thunkResponse = await dispatch(deleteCustomerContact(role));
        if (isSuccess(thunkResponse, deleteCustomerContact)) {
            editContactDialog.close();
        }
    };

    const handleEditContact = async (contact: CustomerContact) => {
        if (contact.name === "" && contact.email === "" && contact.phone === "") {
            await handleDeleteContact(contact.role);
        } else {
            await handleUpsertContact(contact);
        }
    };

    const handleUpdateResetPasswordEmail = async (email: string) => {
        const thunkResponse = await dispatch(updateUserEmail(email));
        if (isSuccess(thunkResponse, updateUserEmail)) {
            editEmailDialog.close();
        }
    };

    return (
        <Page
            loading={
                fetchCustomerContactRequest === RequestStatus.Loading &&
                Object.keys(contacts).length === 0
            }
            error={fetchCustomerContactRequest === RequestStatus.Rejected}
        >
            <SidebarModal isOpen={editEmailDialog.isOpen} onBackdropClick={editEmailDialog.close}>
                {editEmailDialog.data && (
                    <EmailModal
                        single={editEmailDialog.data.single}
                        initialEmails={editEmailDialog.data.emails}
                        onSubmit={editEmailDialog.data.onSubmit}
                        title={editEmailDialog.data.title}
                        close={editEmailDialog.close}
                        isLoading={
                            resetPasswordEmailRequest === RequestStatus.Loading ||
                            updateCustomerEmailsRequest === RequestStatus.Loading
                        }
                    />
                )}
            </SidebarModal>
            <SidebarModal
                isOpen={editContactDialog.isOpen}
                onBackdropClick={editContactDialog.close}
            >
                {editContactDialog.data && (
                    <ContactModal
                        initialContact={editContactDialog.data.contact}
                        role={editContactDialog.data.role}
                        overline={editContactDialog.data.overline}
                        title={editContactDialog.data.title}
                        onSubmit={handleEditContact}
                        close={editContactDialog.close}
                        isLoading={editContactRequest === RequestStatus.Loading}
                    />
                )}
            </SidebarModal>

            <div className={classes.header}>
                <div className={classes.yourStore}>
                    <H1 size={30}>{t("myAccount:yourStore")}</H1>
                    <Body1 className={classes.storeName}>{customer?.name}</Body1>
                    <Body2 className={classes.customerNo}>{t("myAccount:customerNo")}</Body2>
                    <Body2>{customer?.customerId}</Body2>
                </div>
                <AddressInformation />
            </div>

            {(customer.canGenerateProductTemperatureReports ||
                customer.canGenerateInventoryReport) && (
                <>
                    <Divider className={classes.divider} />
                    <H3 className={classes.sectionTitle}>{t("myAccount:reports")}</H3>
                    <Reports />
                </>
            )}

            <Divider className={classes.divider} />

            <H3 className={classes.sectionTitle}>{t("myAccount:accountSettings")}</H3>
            <div className={classes.content}>
                <AccountCard className={classes.langCard}>
                    <Box display="flex" alignItems="center">
                        <Body2 className={classes.langTitle} bold>
                            {t("myAccount:language")}
                        </Body2>
                    </Box>
                    <LocaleSelect locales={customerLocales} className={classes.localeSelect} />
                </AccountCard>

                <AccountCard className={classes.resetPasswordCard}>
                    {!customer.hasUser ? (
                        <Alert severity="warning">
                            <Body2>{t("myAccount:customerUserIsMissing")}</Body2>
                        </Alert>
                    ) : (
                        <Emails
                            title={t("myAccount:passwordResetSentTo")}
                            emails={resetPasswordEmail ? [resetPasswordEmail] : []}
                            onEdit={() =>
                                editEmailDialog.setData({
                                    single: true,
                                    title: t("myAccount:passwordReset"),
                                    emails: resetPasswordEmail ? [resetPasswordEmail] : [],
                                    onSubmit: async ([email]) =>
                                        handleUpdateResetPasswordEmail(email),
                                })
                            }
                        />
                    )}
                </AccountCard>
            </div>

            <Divider className={classes.divider} />

            <div className={classes.content}>
                <div className={classes.left}>
                    <H3 className={classes.sectionTitle}>{t("myAccount:responsiblePlural")}</H3>
                    <AccountCard>
                        <AccountCardHeader
                            icon={<img src={chiefSvg} alt="chief" />}
                            title={t("myAccount:storeManager")}
                        />
                        <Contact
                            contact={contacts[CustomerContactRole.StoreManager]}
                            onEdit={contact =>
                                editContactDialog.setData({
                                    contact,
                                    role: CustomerContactRole.StoreManager,
                                    overline: t("myAccount:responsibleSingular", { count: 1 }),
                                    title: t("myAccount:storeManager"),
                                })
                            }
                        />
                        <Body2 className={classes.storeManagerDescription}>
                            {t("myAccount:storeManagerDescription")}
                        </Body2>
                    </AccountCard>
                    <AccountCard>
                        <AccountCardHeader
                            icon={<img src={personSvg} alt="person" />}
                            title={t("myAccount:responsiblePlural")}
                        />
                        {customer.hasDeli && (
                            <Contact
                                contact={contacts[CustomerContactRole.DeliResponsible]}
                                title={t("myAccount:customerCategory.deli")}
                                onEdit={contact =>
                                    editContactDialog.setData({
                                        contact,
                                        role: CustomerContactRole.DeliResponsible,
                                        overline: t("myAccount:responsibleSingular"),
                                        title: t("myAccount:customerCategory.deli"),
                                    })
                                }
                            />
                        )}
                        {customer.hasFoodToGo && (
                            <Contact
                                contact={contacts[CustomerContactRole.FoodToGoResponsible]}
                                title={t("myAccount:customerCategory.foodToGo")}
                                onEdit={contact =>
                                    editContactDialog.setData({
                                        contact,
                                        role: CustomerContactRole.FoodToGoResponsible,
                                        overline: t("myAccount:responsibleSingular"),
                                        title: t("myAccount:customerCategory.foodToGo"),
                                    })
                                }
                            />
                        )}
                        {customer.hasSalad && (
                            <Contact
                                contact={contacts[CustomerContactRole.SaladBarResponsible]}
                                title={t("myAccount:customerCategory.saladBar")}
                                onEdit={contact =>
                                    editContactDialog.setData({
                                        contact,
                                        role: CustomerContactRole.SaladBarResponsible,
                                        overline: t("myAccount:responsibleSingular"),
                                        title: t("myAccount:customerCategory.saladBar"),
                                    })
                                }
                            />
                        )}
                    </AccountCard>
                </div>

                <div className={classes.right}>
                    <H3 className={classes.sectionTitle}>
                        {t("myAccount:alarmsAndNotifications")}
                    </H3>
                    <AccountCard>
                        <AccountCardHeader
                            icon={<img src={alertSvg} alt="alert" />}
                            title={t("myAccount:foodAlarms")}
                        />
                        {customer.hasDeli && (
                            <Contact
                                contact={contacts[CustomerContactRole.DeliAlarms]}
                                title={t("myAccount:customerCategory.deli")}
                                onEdit={contact =>
                                    editContactDialog.setData({
                                        contact,
                                        role: CustomerContactRole.DeliAlarms,
                                        overline: t("myAccount:foodAlarms"),
                                        title: t("myAccount:customerCategory.deli"),
                                    })
                                }
                            />
                        )}
                        {customer.hasFoodToGo && (
                            <Contact
                                contact={contacts[CustomerContactRole.FoodToGoAlarms]}
                                title={t("myAccount:customerCategory.foodToGo")}
                                onEdit={contact =>
                                    editContactDialog.setData({
                                        contact,
                                        role: CustomerContactRole.FoodToGoAlarms,
                                        overline: t("myAccount:foodAlarms"),
                                        title: t("myAccount:customerCategory.foodToGo"),
                                    })
                                }
                            />
                        )}
                        {customer.hasSalad && (
                            <Contact
                                contact={contacts[CustomerContactRole.SaladBarAlarms]}
                                title={t("myAccount:customerCategory.saladBar")}
                                onEdit={contact =>
                                    editContactDialog.setData({
                                        contact,
                                        role: CustomerContactRole.SaladBarAlarms,
                                        overline: t("myAccount:foodAlarms"),
                                        title: t("myAccount:customerCategory.saladBar"),
                                    })
                                }
                            />
                        )}
                    </AccountCard>

                    <AccountCard>
                        <Emails
                            title={t("myAccount:orderConfirmations")}
                            emails={customer.emails.orderConfirmationEmails}
                            onEdit={() =>
                                editEmailDialog.setData({
                                    emails: customer.emails.orderConfirmationEmails,
                                    title: t("myAccount:orderConfirmations"),
                                    onSubmit: async emails =>
                                        handleUpdateEmails({ orderConfirmationEmails: emails }),
                                })
                            }
                        />
                    </AccountCard>

                    <AccountCard>
                        <Emails
                            title={t("myAccount:purchaseStats")}
                            emails={customer.emails.purchaseStatsEmails}
                            onEdit={() =>
                                editEmailDialog.setData({
                                    emails: customer.emails.purchaseStatsEmails,
                                    title: t("myAccount:purchaseStats"),
                                    onSubmit: async emails =>
                                        handleUpdateEmails({ purchaseStatsEmails: emails }),
                                })
                            }
                        />
                    </AccountCard>

                    <AccountCard>
                        <Emails
                            title={t("myAccount:emailInvoice")}
                            emails={customer.emails.invoiceEmails}
                            onEdit={() =>
                                editEmailDialog.setData({
                                    emails: customer.emails.invoiceEmails,
                                    title: t("myAccount:emailInvoice"),
                                    onSubmit: async emails =>
                                        handleUpdateEmails({ invoiceEmails: emails }),
                                })
                            }
                        />
                    </AccountCard>
                </div>
            </div>
        </Page>
    );
};
