import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import { Assortment } from "../types";

export const fetchAssortments = createAsyncThunk("fetch-assortments", async () => {
    return get<Assortment[]>(Endpoint.assortments);
});

const assortmentEntityAdapter = createEntityAdapter<Assortment>({
    selectId: assortment => assortment.assortmentId,
});

const assortmentsSlice = createSlice({
    name: "assortments",
    initialState: assortmentEntityAdapter.getInitialState<{ error?: string; loading: boolean }>({
        loading: true,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchAssortments.fulfilled, (state, action) => {
            state.loading = false;
            assortmentEntityAdapter.setAll(state, action.payload);
        });
        addCase(fetchAssortments.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error.message;
        });
    },
});

export const { selectById: selectAssortmentById } = assortmentEntityAdapter.getSelectors<RootState>(
    state => state.assortments,
);

export const selectAssortmentsLoading = (state: RootState) => state.assortments.loading;
export const selectAssortmentsError = (state: RootState) => state.assortments.error;

export const assortmentsReducer = assortmentsSlice.reducer;
