export const pushDataLayer = (event: GtmEvents, value: GtmVariables = {}) => {
    try {
        window.dataLayer.push({ event, ...value });
    } catch (error) {
        console.error(`Failed to push ${event} to dataLayer`, value);
    }
};

export enum GtmEvents {
    Navigation = "navigation_event",
    Customer = "customer_event",
    News = "news_read_event",
    File = "file_read_event",
    Video = "video_read_event",
    OrderAssistanceMeal = "order_assistance_meal_event",
}

// These variables map to GTMs DataLayer variables
export interface GtmVariables {
    // Customer (shared)
    customerId?: number;
    customerName?: string;
    companyCode?: number;
    customerClass?: string;

    // Cms (shared)
    cmsAssetId?: number;

    // Navigation
    navigationTarget?: string;

    // News
    newsTitle?: string;

    // File
    fileName?: string;
    fileUrl?: string;

    // Video
    videoUrl?: string;
    videoName?: string;
    videoCategory?: string;
}
