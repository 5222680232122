import { MyAccountResource } from "../locales";

export const myAccount: MyAccountResource = {
    accountSettings: "Kontoeinstellungen",
    addNewRecipient: "Neuen Empfänger hinzufügen",
    alarmsAndNotifications: "Benachrichtigungen & Bestellinfos",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Food To Go",
        saladBar: "Salatbar",
    },
    customerNo: "Kundennummer",
    customerUserIsMissing: "Für den Kunden ist kein Benutzer registriert",
    email: "E-Mail-Adresse",
    emailInvoice: "Rechnung per E-Mail",
    foodAlarms: "Benachrichtigungen",
    language: "Sprache",
    missingContactPerson: "Ansprechperson fehlt",
    missingInformation: "Informationen fehlen",
    name: "Name",
    orderConfirmations: "Bestellbestätigungen",
    passwordReset: "Passwort zurücksetzen",
    passwordResetSentTo: "Passwortzurücksetzung gesendet an",
    phone: "Telefon",
    purchaseStats: "Bestellstatistik",
    recipient: "Empfänger",
    responsibleSingular: "Zuständige Person",
    responsiblePlural: "Zuständige Person",
    storeManager: "Marktleiter/in",
    storeManagerDescription:
        "Wird für wichtige Ankündigungen bezüglich Preisänderungen oder ähnlichem verwendet.",
    updateContactInfo: {
        success: "Erfolgreich gespeichert.",
        error: "Speichern fehlgeschlagen.",
    },
    yourStore: "Mein Geschäft",
};
