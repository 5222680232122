import { Box, InputBase, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import cn from "classnames";
import React, { FC, useRef } from "react";
import { colors } from "../../constants/colors";
import { Checkbox } from "../Checkbox";

const useStyles = makeStyles(theme => ({
    selectInput: {
        padding: `10px 10px`,
        border: `2px solid #d4ccc2`,
        borderRadius: 4,
    },
    label: {
        color: colors.black,
        fontWeight: 700,
        marginBottom: 3,
        fontSize: theme.typography.pxToRem(16),
    },
    inputBase: {
        padding: `2px 5px`,
        border: `2px solid #d4ccc2`,
        borderRadius: 4,
        fontSize: 14,
    },
    focused: {
        border: `2px solid ${colors.grey}`,
        transition: "border-color .2s ease, opacity .2s ease",
    },
    inputError: {
        "background": colors.attentionRed,
        "color": colors.white,
        "fontSize": 12,
        "textAlign": "center",
        "padding": 5,
        "visibility": "hidden",
        "fontWeight": "bold",
        "&.invalid": {
            visibility: "visible",
        },
    },
}));

type BaseInput = {
    id: string;
    value?: unknown;
    label?: string;
    required?: boolean;
    hidden?: boolean;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    defaultValue?: string | number | readonly string[];
    placeHolder?: string;
    maxLength?: number;
};

type FileInput = {
    type: "file";
} & BaseInput;

type TextInput = {
    type: "text";
} & BaseInput;

type PasswordInput = {
    type: "password";
} & BaseInput;

type EmailInput = {
    type: "email";
} & BaseInput;

type NumberInput = {
    type: "number";
    min?: number;
    max?: number;
} & BaseInput;

type TextAreaInput = {
    type: "text-area";
    rows: number;
} & BaseInput;

type DateInput = {
    type: "date";
    min?: string;
    max?: string;
} & BaseInput;

type CheckboxInput = {
    type: "checkbox";
} & BaseInput;

type SelectInput = {
    type: "select";
    menuItems: Array<{ id: string; description: string }>;
    onChange: SelectInputProps["onChange"];
} & Omit<BaseInput, "onChange">;

export type Input =
    | TextInput
    | PasswordInput
    | NumberInput
    | SelectInput
    | FileInput
    | DateInput
    | CheckboxInput
    | EmailInput
    | TextAreaInput;

export const InputResolver: FC<{ input: Input; className?: string }> = ({ input, className }) => {
    const classes = useStyles();
    const ref = useRef<HTMLInputElement>(null);

    if (input.hidden) {
        return null;
    }

    const labelId = `${input.id}-label`;
    const baseProps = {
        id: input.id,
        name: input.id,
        required: input.required,
        inputRef: ref,
        value: input.value,
        defaultValue: input.defaultValue,
        placeholder: input.placeHolder,
        classes: { focused: classes.focused },
    };
    let Component;
    switch (input.type) {
        case "text":
        case "email":
        case "password":
            Component = (
                <InputBase
                    type={input.type}
                    onChange={input.onChange}
                    inputProps={{ maxLength: input.maxLength }}
                    {...baseProps}
                    className={classes.inputBase}
                />
            );
            break;
        case "date":
            Component = (
                <InputBase
                    type={input.type}
                    onChange={input.onChange}
                    inputProps={{ max: input.max, min: input.min }}
                    {...baseProps}
                    className={classes.inputBase}
                />
            );
            break;
        case "number":
            Component = (
                <InputBase
                    type="number"
                    onChange={input.onChange}
                    inputProps={{ min: input.min, max: input.max }}
                    {...baseProps}
                    className={classes.inputBase}
                />
            );
            break;
        case "text-area":
            Component = (
                <InputBase
                    type="text"
                    multiline={true}
                    rows={input.rows}
                    onChange={input.onChange}
                    inputProps={{ maxLength: input.maxLength }}
                    {...baseProps}
                    className={classes.inputBase}
                />
            );
            break;
        case "select":
            Component = (
                <Select
                    {...baseProps}
                    defaultValue={input.defaultValue}
                    labelId={labelId}
                    displayEmpty
                    required={true}
                    onChange={input.onChange}
                    classes={{
                        root: classes.selectInput,
                    }}
                    disableUnderline
                >
                    <MenuItem value={input.label} disabled>
                        {input.label}
                    </MenuItem>
                    {input.menuItems.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.description}
                        </MenuItem>
                    ))}
                </Select>
            );
            break;
        case "file":
            Component = <InputBase onChange={input.onChange} {...baseProps} type="file" />;
            break;
        case "checkbox": {
            Component = (
                <div>
                    <Checkbox onChange={input.onChange} {...baseProps} classes={{}} />
                </div>
            );
            break;
        }
        default:
            return null;
    }

    return (
        <Box
            className={cn("input-container", className)}
            mt={1}
            mb={1}
            display="flex"
            flexDirection="column"
        >
            {input?.label && (
                <InputLabel htmlFor={input.id} className={classes.label} required={input.required}>
                    {input.label}
                </InputLabel>
            )}
            {Component}
            <div id={`${input.id}-error`} className={classes.inputError} />
        </Box>
    );
};
