import { configureStore, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { resetStore } from "./rootActions";
import { assetsReducer } from "./slices/assetsSlice";
import { assortmentsReducer } from "./slices/assortmentsSlice";
import { cartReducer } from "./slices/cartSlice";
import { categoriesReducer } from "./slices/categoriesSlice";
import { claimsReducer } from "./slices/claimCodesSlice";
import { closedInfoReducer } from "./slices/closedProductsSlice";
import { customerContactsReducer } from "./slices/customerContactsSlice";
import { customerReducer } from "./slices/customerSlice";
import { deliveryDatesReducer } from "./slices/deliveryDatesSlice";
import { deliveryTermsReducer } from "./slices/deliveryTermsSlice";
import { dialogReducer } from "./slices/dialogsSlice";
import { favoritesReducer } from "./slices/favoritesSlice";
import { messagesReducer } from "./slices/messageSlice";
import { newsReducer } from "./slices/newsSlice";
import { notificationsReducer } from "./slices/notificationSlice";
import { orderDetailsReducer } from "./slices/orderDetailsSlice";
import { orderMatsReducer } from "./slices/orderMatsSlice";
import { ordersReducer } from "./slices/ordersSlice";
import { pdfReducer } from "./slices/pdfSlice";
import { productMenuReducer } from "./slices/productMenuSlice";
import { productSelectionReducer } from "./slices/productSelectionSlice";
import { productsReducer } from "./slices/productsSlice";
import { productTemperaturesReducer } from "./slices/productTemperaturesSlice";
import { reportsReducer } from "./slices/reportSlice";
import { supportReducer } from "./slices/supportSlice";
import { userReducer } from "./slices/userSlice";
import { weatherReducer } from "./slices/weatherSlice";

export const appReducer = combineReducers({
    assets: assetsReducer,
    assortments: assortmentsReducer,
    cart: cartReducer,
    categories: categoriesReducer,
    claimCodes: claimsReducer,
    closedInfo: closedInfoReducer,
    customer: customerReducer,
    customerContacts: customerContactsReducer,
    deliveryDates: deliveryDatesReducer,
    deliveryTerms: deliveryTermsReducer,
    dialog: dialogReducer,
    favorites: favoritesReducer,
    messages: messagesReducer,
    news: newsReducer,
    notifications: notificationsReducer,
    orderDetails: orderDetailsReducer,
    orderMats: orderMatsReducer,
    orders: ordersReducer,
    pdf: pdfReducer,
    productMenu: productMenuReducer,
    products: productsReducer,
    productSection: productSelectionReducer,
    productTempratures: productTemperaturesReducer,
    reports: reportsReducer,
    support: supportReducer,
    user: userReducer,
    weather: weatherReducer,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (
    state: RootState | undefined,
    action: PayloadAction<typeof resetStore>,
) => {
    // Reset all data in redux store to initial.
    if (action.type === resetStore.type) {
        state = undefined;
    }

    return appReducer(state, action);
};

export type ThunkApiConfig = {
    state: RootState;
};

export const store = configureStore({
    reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type StoreType = typeof store;
