import { Container as MuiContainer, ContainerProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1360,
    },
}));

export const Container: FC<ContainerProps> = ({ className, ...props }) => {
    const classes = useStyles();
    return (
        <MuiContainer maxWidth={false} className={cn(classes.container, className)} {...props} />
    );
};
