import { withStyles } from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";
import React from "react";

interface StyledTabsProps {
    value: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export const TabsContainer = withStyles(theme => ({
    indicator: {
        display: "none",
    },
}))((props: StyledTabsProps) => <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />);
