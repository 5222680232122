import { IconButton, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PictureAsPdf } from "@material-ui/icons";
import React, { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingButton } from "../../../components/LoadingButton";
import { colors } from "../../../constants/colors";
import { useAppDispatch } from "../../../state/configureStore";
import { fetchProductSheet, productSheetLoading } from "../../../state/slices/pdfSlice";
import { Product } from "../../../state/types";

const useStyles = makeStyles(theme => ({
    popover: {
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    dataSheetButton: {
        margin: theme.spacing(1),
    },
    pdfIcon: {
        color: colors.darkGrey,
    },
}));

export const ProductVersionPDFs: FC<{ product: Product }> = ({ product }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { t } = useTranslation(["order"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const isFetchingPdf = useSelector(productSheetLoading);

    if (!product.availableVersions.length) {
        return null;
    }

    const handleDownload = (version: number) => {
        void dispatch(fetchProductSheet({ productId: product.productId, version }));
        setAnchorEl(null);
    };

    const handleIconClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (product.availableVersions.length > 1) {
            setAnchorEl(e.currentTarget);
        } else {
            handleDownload(product.availableVersions[0]);
        }
    };

    return (
        <>
            <IconButton tabIndex={-1} aria-label="pdf" onClick={handleIconClick}>
                <PictureAsPdf fontSize="small" className={classes.pdfIcon} />
            </IconButton>
            {anchorEl !== null && (
                <Popover
                    classes={{ paper: classes.popover }}
                    open
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    {product.availableVersions.map(version => (
                        <LoadingButton
                            className={classes.dataSheetButton}
                            key={version}
                            loading={
                                isFetchingPdf.find(
                                    fetch =>
                                        fetch.productId === product.productId &&
                                        fetch.version === version,
                                )?.loading === true
                            }
                            onClick={() => {
                                handleDownload(version);
                            }}
                            variant="outlined"
                        >
                            {t("order:productTable.dataSheet", { version })}
                        </LoadingButton>
                    ))}
                </Popover>
            )}
        </>
    );
};
