import { shouldPolyfill } from "@formatjs/intl-pluralrules/should-polyfill";
import { Locale } from "../assets/locales/locales";

export async function polyfill(locale: Locale) {
    if (!shouldPolyfill(locale.slice(0, 2))) {
        return;
    }
    await import("@formatjs/intl-pluralrules/polyfill-force");
    switch (locale) {
        case "en-US":
            await import(`@formatjs/intl-pluralrules/locale-data/en`);
            break;
        case "sv-SE":
            await import(`@formatjs/intl-pluralrules/locale-data/sv`);
            break;
        case "fr-FR":
            await import(`@formatjs/intl-pluralrules/locale-data/fr`);
            break;
        case "fi-FI":
            await import(`@formatjs/intl-pluralrules/locale-data/fi`);
            break;
        case "nl-BE":
            await import(`@formatjs/intl-pluralrules/locale-data/nl`);
            break;
        case "de-DE":
            await import(`@formatjs/intl-pluralrules/locale-data/de`);
            break;
        case "da-DK":
            await import(`@formatjs/intl-pluralrules/locale-data/da`);
            break;
        case "nb-NO":
            await import(`@formatjs/intl-pluralrules/locale-data/nb`);
            break;
        default:
            console.error(`Missing intl-pluralrules polyfill for ${locale}`);
            await import(`@formatjs/intl-pluralrules/locale-data/en`);
    }
}
