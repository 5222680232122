import { PageNotFoundResource } from "../locales";

export const pageNotFound: PageNotFoundResource = {
    title: "Hupsista!",
    subTitle: "Emme näköjään löydä etsimääsi sivua.",
    errorCode: "Virhekoodi: 404",
    usefulLinks: "Tässä on sen sijaan joitain hyödyllisiä linkkejä:",
    links: {
        home: "Koti",
        order: "Verrkokauppa",
        myAccount: "Asiakastili",
    },
};
