import { MyAccountResource } from "../locales";

export const myAccount: MyAccountResource = {
    accountSettings: "Kontoinnstillinger",
    addNewRecipient: "Legg til ny mottaker",
    alarmsAndNotifications: "Alarmer og varsler",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Food To Go",
        saladBar: "Salatbar",
    },
    customerNo: "Kundenummer",
    customerUserIsMissing: "Det er ingen bruker registrert for kunden",
    email: "E-post",
    emailInvoice: "E-postfaktura",
    foodAlarms: "Matalarmer",
    language: "Språk",
    missingContactPerson: "Mangler kontaktperson",
    missingInformation: "Mangler informasjon",
    name: "Navn",
    orderConfirmations: "Bestillingsbekreftelser",
    passwordReset: "Tilbakestill passord",
    passwordResetSentTo: "Tilbakestilling av passord sendt til",
    phone: "Telefon",
    purchaseStats: "Kjøpsstatistikk",
    recipient: "Mottaker",
    reports: "Rapporter",
    responsibleSingular: "Ansvarlig",
    responsiblePlural: "Ansvarlig",
    storeManager: "Butikksjef",
    storeManagerDescription:
        "Brukes for viktige kunngjøringer angående forretningsforhold, prisendringer eller lignende.",
    updateContactInfo: {
        success: "Vellykket lagret.",
        error: "Kunne ikke lagre.",
    },
    yourStore: "Din butikk",
};
