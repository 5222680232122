import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { ThemeType } from "../../theme";

type Props = {
    imageUrl: string;
    imageWidth: number | string;
    imageHeight?: number | string;
    className?: string;
};
const useStyles = makeStyles<ThemeType, Props>({
    image: {
        minWidth: props => props.imageWidth,
        maxWidth: props => props.imageWidth,
        minHeight: props => props.imageHeight,
        maxHeight: props => props.imageHeight,
        borderRadius: 10,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
});

export const CoverImage: FC<Props> = ({ imageUrl, imageHeight, imageWidth, className }) => {
    const classes = useStyles({ imageUrl, imageHeight, imageWidth });
    return <img src={imageUrl} alt="inspirational" className={cn(classes.image, className)} />;
};
