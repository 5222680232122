import { Button, makeStyles } from "@material-ui/core";
import React, { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDialog } from "../../hooks/useDialog";
import { selectOrderRowClaimCodes } from "../../state/slices/claimCodesSlice";
import { ClaimCode, OrderRow } from "../../state/types";
import { formFile, parseChecked, parseValue } from "../../utils/form";
import { ClaimDialog, formId } from "./ClaimDialog";

const useStyles = makeStyles({
    claimBtn: {
        height: 33,
    },
});

export const ClaimOrderRow: FC<{ erpOrderNo: number; orderRow: OrderRow }> = ({
    erpOrderNo,
    orderRow,
}) => {
    const classes = useStyles();
    const { t } = useTranslation(["orderHistory"]);
    const dialog = useDialog();
    const { claimCodes, categoryClaimCodes } = useSelector(selectOrderRowClaimCodes);
    const codesMatchingCategory = categoryClaimCodes.filter(
        code => code.categoryId === orderRow.categoryId,
    );

    return (
        <>
            <Button className={classes.claimBtn} variant="contained" onClick={dialog.open}>
                {t("orderHistory:table.details.table.reclamation")}
            </Button>
            <ClaimDialog
                open={dialog.isOpen}
                close={dialog.close}
                claimCodes={codesMatchingCategory.length > 0 ? codesMatchingCategory : claimCodes}
                toClaimBody={(claimCode: ClaimCode, e: SyntheticEvent) => {
                    const imageFile = formFile(e, formId.image);
                    return {
                        erpOrderNo,
                        productId: orderRow.productId,
                        claimCode: claimCode?.description,
                        quantity: parseInt(parseValue(e, formId.quantity), 10),
                        batch: parseValue(e, formId.batch),
                        bestBefore: parseValue(e, formId.bestBefore) || undefined,
                        boxBroken: parseChecked(e, formId.boxBroken),
                        info: parseValue(e, formId.info),
                        contactName: parseValue(e, formId.contactName),
                        contactEmail: parseValue(e, formId.contactEmail),
                        image: imageFile,
                        imageType: imageFile?.type,
                    };
                }}
                maxQuantity={orderRow.deliveredQuantity ?? 0}
                title={t("orderHistory:claim.orderRowTitle", {
                    productName: orderRow.legalName?.toUpperCase() ?? orderRow.productName,
                    interpolation: {
                        escapeValue: false,
                    },
                })}
            />
        </>
    );
};
