import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { del, get, post } from "../../network/restClient";
import { translate } from "../../utils/translate";
import { RootState } from "../configureStore";
import { CustomerContact, CustomerContactRole, RequestStatus } from "../types";

export const upsertCustomerContact = createAsyncThunk(
    "customer-contacts/upsert",
    async (contact: CustomerContact) => {
        await post(Endpoint.contacts, contact, {
            snackbar: {
                errorMessage: translate("myAccount.updateContactInfo.error"),
                successMessage: translate("myAccount.updateContactInfo.success"),
            },
        });
        return contact;
    },
);

export const deleteCustomerContact = createAsyncThunk(
    "customer-contacts/delete",
    async (role: CustomerContactRole) => {
        await del(
            Endpoint.contacts,
            { role },
            {
                snackbar: {
                    errorMessage: translate("myAccount.updateContactInfo.error"),
                    successMessage: translate("myAccount.updateContactInfo.success"),
                },
            },
        );
    },
);

export const fetchCustomerContacts = createAsyncThunk("customer-contacts/fetch", async () =>
    get<CustomerContact[]>(Endpoint.contacts),
);

type State = {
    editContactRequest: RequestStatus;
    fetchCustomerContactRequests: RequestStatus;
    entries: CustomerContact[];
};

const initialState: State = {
    editContactRequest: RequestStatus.Idle,
    fetchCustomerContactRequests: RequestStatus.Idle,
    entries: [],
};

const slice = createSlice({
    name: "customerContacts",
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchCustomerContacts.pending, state => {
            state.fetchCustomerContactRequests = RequestStatus.Loading;
        });
        addCase(fetchCustomerContacts.rejected, state => {
            state.fetchCustomerContactRequests = RequestStatus.Rejected;
        });
        addCase(fetchCustomerContacts.fulfilled, (state, action) => {
            state.fetchCustomerContactRequests = RequestStatus.Fulfilled;
            state.entries = action.payload;
        });

        addCase(upsertCustomerContact.pending, state => {
            state.editContactRequest = RequestStatus.Loading;
        });
        addCase(upsertCustomerContact.rejected, state => {
            state.editContactRequest = RequestStatus.Rejected;
        });
        addCase(upsertCustomerContact.fulfilled, (state, action) => {
            const updatedContact = action.payload;
            const entry = state.entries.find(c => c.role === updatedContact.role);
            if (!entry) {
                state.entries.push(updatedContact);
            } else {
                entry.name = updatedContact.name;
                entry.phone = updatedContact.phone;
                entry.email = updatedContact.email;
            }
            state.editContactRequest = RequestStatus.Fulfilled;
        });

        addCase(deleteCustomerContact.pending, state => {
            state.editContactRequest = RequestStatus.Loading;
        });
        addCase(deleteCustomerContact.rejected, state => {
            state.editContactRequest = RequestStatus.Rejected;
        });
        addCase(deleteCustomerContact.fulfilled, (state, action) => {
            const role = action.meta.arg;
            state.entries = state.entries.filter(c => c.role !== role);
            state.editContactRequest = RequestStatus.Fulfilled;
        });
    },
});

export const selectContacts = (state: RootState) => state.customerContacts.entries;
export const selectContactsByRole = createSelector(selectContacts, contacts => {
    return contacts.reduce((map, curr) => {
        map[curr.role] = curr;
        return map;
    }, {} as Record<CustomerContactRole, CustomerContact | undefined>);
});
export const selectSaladBarContact = createSelector(
    selectContactsByRole,
    contactsByRole => contactsByRole[CustomerContactRole.SaladBarResponsible],
);

export const selectEditContactRequest = (state: RootState) =>
    state.customerContacts.editContactRequest;

export const selectFetchCustomerContactRequests = (state: RootState) =>
    state.customerContacts.fetchCustomerContactRequests;

export const customerContactsReducer = slice.reducer;
