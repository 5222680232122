import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { FC, SyntheticEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { colors } from "../../constants/colors";
import { GtmEvents, pushDataLayer } from "../../utils/pushDataLayer";

const useStyles = makeStyles({
    navBtn: {
        "padding": "0 1rem",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "height": 60,
        "borderRadius": 10,
        "color": colors.darkGrey,
        "boxSizing": "border-box",
        "textDecoration": "none",
        "textTransform": "uppercase",
        "fontSize": 20,
        "&:hover": {
            textDecoration: "none",
        },
        "position": "relative",
    },
    text: {
        fontSize: 16,
        textTransform: "none",
    },
    selected: {
        backgroundColor: colors.heroGreenExtraLight,
        color: colors.heroGreenDark,
    },
    border: {
        position: "absolute",
        height: 4,
        left: 0,
        right: 0,
        bottom: -20,
        backgroundColor: colors.heroGreen,
    },
});

type Props = {
    to?: string;
    highlightedPaths?: string[];
    onClick?: (ev: SyntheticEvent) => void;
    text: string;
    navId: string;
};

export const NavLink: FC<Props> = ({ to, onClick, text, highlightedPaths = [], navId }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const isSelected = location.pathname === to || highlightedPaths.includes(location.pathname);
    const testId = `nav-button-${text.toLocaleLowerCase().split(" ").join("-")}`;

    return (
        <Link
            data-testid={testId}
            onClick={(ev: SyntheticEvent) => {
                pushDataLayer(GtmEvents.Navigation, {
                    navigationTarget: navId,
                });

                if (to) {
                    history.push(to);
                }
                if (onClick) {
                    onClick(ev);
                }
            }}
            className={cn(classes.navBtn, { [classes.selected]: isSelected })}
            component="button"
        >
            <span title={text ?? ""} className={classes.text}>
                {text}
            </span>
            <div className={cn({ [classes.border]: isSelected })} />
        </Link>
    );
};
