import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../state/configureStore";
import {
    selectProductSearch,
    selectUpcomingDeliveries,
} from "../../../state/slices/productSelectors";
import { FilterType, Product, ProductType } from "../../../state/types";
import { ProductSelectionQuantity } from "../quantity/ProductSelectionQuantity";
import { OrderMatTable } from "./OrderMatTable";
import { ProductTable } from "./ProductTable";

type Props = {
    productType: ProductType;
    products: Array<{
        category: string;
        products: Product[];
    }>;
    showFavorite: boolean;
    filter: FilterType;
    showChangeProductButton?: boolean;
    selectProductButton?: string;
    showSelectProductButtonIcon?: boolean;
    onChangeProduct?: (productId: string) => void;
    disabledImageDialog?: boolean;
    showOrderMat?: boolean;
};

// Temporary fix for rendering tables for react-testing-library since it does not support infinite scroll
// We should move away from rtl and write these tests in Browserstack/Gherkin
const INITIAL_TABLES_IN_VIEW = process.env.NODE_ENV === "test" ? 1000 : 1;
export const ProductTables: FC<Props> = ({
    showFavorite,
    products,
    productType,
    filter,
    showChangeProductButton,
    showSelectProductButtonIcon,
    selectProductButton,
    onChangeProduct,
    disabledImageDialog,
    showOrderMat,
}) => {
    const ref: RefObject<HTMLDivElement> = useRef(null);
    const [endIndex, setEndIndex] = useState(INITIAL_TABLES_IN_VIEW);
    const tablesInView = useMemo(() => products.slice(0, endIndex), [endIndex, products]);
    const hasMore = tablesInView.length < products.length;
    const search = useSelector(selectProductSearch);
    const upcomingDeliveries = useAppSelector(selectUpcomingDeliveries);

    const handleLoadMore = () => {
        if (hasMore) {
            setEndIndex(curr => curr + 1);
        }
    };

    useEffect(() => {
        setEndIndex(INITIAL_TABLES_IN_VIEW);
    }, [setEndIndex, search, filter]);

    // We want to run this every render to make sure we fill up
    // the available screen with tables.
    // eslint-disable-next-line
    useEffect(() => {
        if (ref && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const loadMore = window.innerHeight > rect.height;
            if (loadMore && hasMore) {
                setEndIndex(curr => curr + 1);
            }
        }
    });

    return (
        <div ref={ref}>
            <InfiniteScroll
                dataLength={tablesInView.length}
                next={handleLoadMore}
                hasMore={hasMore}
                loader={null}
                scrollThreshold={0.8}
                onScroll={() => {
                    // At some point when zooming out too much the next function is not triggered properly.
                    // Check if we're at the bottom of the page. If so, then we try to load more:
                    const atBottom =
                        Math.ceil(window.innerHeight + window.scrollY) >=
                        document.documentElement.scrollHeight;
                    if (atBottom) {
                        handleLoadMore();
                    }
                }}
            >
                {tablesInView.map(t =>
                    showOrderMat ? (
                        <OrderMatTable
                            key={`${productType}-products-table-${t.category}`}
                            products={t.products}
                            title={t.category}
                        />
                    ) : (
                        <ProductTable
                            key={`${productType}-products-table-${t.category}`}
                            title={t.category}
                            products={t.products}
                            productType={productType}
                            QuantityComponent={ProductSelectionQuantity}
                            alternateBgColors
                            showFavorite={showFavorite}
                            showProductPdfDownload={true}
                            showChangeProductButton={showChangeProductButton}
                            selectProductButton={selectProductButton}
                            showSelectProductButtonIcon={showSelectProductButtonIcon}
                            onChangeProduct={onChangeProduct}
                            disabledImageDialog={disabledImageDialog}
                            upcomingDeliveryDates={upcomingDeliveries.getTableColumns(
                                products.map(p => p.products).flat(),
                            )}
                        />
                    ),
                )}
            </InfiniteScroll>
        </div>
    );
};
