import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, useState } from "react";
import { colors } from "../../../constants/colors";
import { useAppSelector } from "../../../state/configureStore";
import { selectClosedInfoById } from "../../../state/slices/closedProductsSlice";
import { ThemeType } from "../../../theme";
import { ClosedInfoMessage } from "../product-table/ClosedInfoMessage";
import { DebouncedTextField } from "./DebouncedTextField";

type Props = {
    productId: string;
    quantity?: number;
    onQuantityChange: (quantity: number) => void;
    warningThreshold?: number;
    debounce?: boolean;
};

type StyleProps = {
    quantityWarning: boolean;
};

const useStyles = makeStyles<ThemeType, StyleProps>(theme => ({
    qtyBox: {
        marginLeft: "auto",
        backgroundColor: "#fff",
        borderRadius: 9,
        display: "inline-block",
        height: 40,
        position: "relative",
        textAlign: "center",
        width: 130,
        border: props =>
            props.quantityWarning
                ? `2px solid${colors.attentionRed}`
                : `2px solid${colors.darkWhite}`,
    },
    changeQty: {
        "border": "solid 2px #6fbc85",
        "borderRadius": 6,
        "cursor": "pointer",
        "height": 28,
        "position": "absolute",
        "width": 28,
        "&:hover": {
            color: colors.white,
            backgroundColor: colors.heroGreen,
        },
        "userSelect": "none",
    },
    qtyInput: {
        border: "none",
        outline: 0,
        fontSize: 18,
        fontWeight: "bold",
        height: "100%",
        lineHeight: "100%",
        margin: "0 35px",
        padding: 0,
        textAlign: "center",
        width: "calc(100% - 70px)",
    },
}));

export const Quantity: FC<Props> = ({
    productId,
    onQuantityChange,
    quantity,
    warningThreshold,
    debounce,
}) => {
    const closedInfo = useAppSelector(state => selectClosedInfoById(state, productId));
    const [isFocused, setFocused] = useState(false);
    const classes = useStyles({
        quantityWarning:
            warningThreshold !== undefined &&
            quantity !== undefined &&
            quantity >= warningThreshold,
    });

    if (closedInfo?.closedInfo) {
        return <ClosedInfoMessage>{closedInfo.closedInfo}</ClosedInfoMessage>;
    }

    return (
        <div className={classes.qtyBox} onClick={ev => ev.stopPropagation()}>
            <ButtonBase
                data-testid="decrement-quantity-button"
                className={classes.changeQty}
                style={{ left: 4, top: 4 }}
                onClick={() => onQuantityChange((quantity ?? 0) - 1)}
                tabIndex={-1}
            >
                -
            </ButtonBase>
            <ButtonBase
                data-testid="increment-quantity-button"
                tabIndex={-1}
                className={classes.changeQty}
                style={{ right: 4, top: 4 }}
                onClick={() => onQuantityChange((quantity ?? 0) + 1)}
            >
                +
            </ButtonBase>
            {debounce ? (
                <DebouncedTextField
                    quantity={quantity}
                    onQuantityChange={onQuantityChange}
                    className={classes.qtyInput}
                />
            ) : (
                <input
                    data-testid="qty"
                    className={classes.qtyInput}
                    type="text"
                    value={quantity ?? ""}
                    placeholder={isFocused ? undefined : "0"}
                    onFocus={event => {
                        event.target.select();
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    onChange={ev => {
                        const parsedQty = parseInt(ev.currentTarget.value, 10);
                        onQuantityChange(isNaN(parsedQty) ? 0 : parsedQty);
                    }}
                />
            )}
        </div>
    );
};
