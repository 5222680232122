import { useState } from "react";

export const useFormField = () => {
    const [value, setValue] = useState<string>("");

    return {
        value,
        onChange: (data: string) => setValue(data),
        asInt: () => parseInt(value, 10),
    };
};
