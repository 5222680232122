import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { DateTime } from "luxon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FullscreenImage } from "../../../components/FullscreenImage";
import { Body1 } from "../../../components/Typography";
import { colors } from "../../../constants/colors";
import { useDialog } from "../../../hooks/useDialog";
import { selectCustomer, selectTimeZone } from "../../../state/slices/customerSlice";
import { selectSelectedWeek } from "../../../state/slices/orderMatsSlice";
import { Product } from "../../../state/types";
import { OrderMatRow } from "./OrderMatRow";

const useStyles = makeStyles(theme => ({
    container: {
        "marginTop": theme.spacing(2),
        "& + $container": {
            marginTop: theme.spacing(4),
        },
    },
    table: {
        lineHeight: 24,
    },
    th: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textTransform: "uppercase",
        fontSize: 14,
        lineHeight: "1rem",
        backgroundColor: colors.darkWhite,
    },
    iconCell: {
        width: 24,
    },
    productPdf: {
        width: 24,
    },
    productImg: {
        width: 75,
    },
    productName: {
        width: "24%",
    },
    unit: {
        width: "9%",
    },
    title: {
        color: colors.white,
        backgroundColor: colors.heroGreen,
        padding: theme.spacing(1.5, 2),
        textTransform: "uppercase",
    },
}));

type Props = {
    products: Product[];
    title?: string;
};

export const OrderMatTable: FC<Props> = ({ title, products }) => {
    const { t } = useTranslation("order");
    const classes = useStyles();
    const customer = useSelector(selectCustomer);
    const imageDialog = useDialog<{
        src: string;
        imageWidth: number;
        imageHeight: number;
        alt: string;
    }>();
    const selectedWeek = useSelector(selectSelectedWeek);
    const timeZone = useSelector(selectTimeZone);
    const startOfWeek = DateTime.fromObject({ ...selectedWeek, zone: timeZone });

    if (!selectedWeek) return null;

    return (
        <div className={classes.container}>
            <FullscreenImage
                close={imageDialog.close}
                isOpen={imageDialog.isOpen}
                img={imageDialog.data}
            />
            {title !== undefined && <Body1 className={classes.title}>{title}</Body1>}
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            size="small"
                            component="th"
                            padding="none"
                            className={cn(classes.th, classes.iconCell)}
                        >
                            {/* favorite */}
                        </TableCell>
                        <TableCell
                            className={cn(classes.th, classes.iconCell)}
                            size="small"
                            padding="none"
                            component="th"
                        >
                            {/* Product PDFs */}
                        </TableCell>
                        <TableCell
                            className={cn(classes.th, classes.productImg)}
                            component="th"
                            size="small"
                        >
                            {/* productImage */}
                        </TableCell>
                        <TableCell className={cn(classes.th)} component="th">
                            {t("productTable.name")}
                        </TableCell>
                        {customer?.showPrices && (
                            <TableCell className={cn(classes.th, classes.unit)} component="th">
                                {t("productTable.pricePerUnit")}
                            </TableCell>
                        )}
                        <TableCell className={classes.th} component="th">
                            {t("productTable.monday")} {startOfWeek.toFormat("dd")}
                        </TableCell>
                        <TableCell className={classes.th} component="th">
                            {t("productTable.tuesday")}{" "}
                            {startOfWeek.plus({ days: 1 }).toFormat("dd")}
                        </TableCell>
                        <TableCell className={classes.th} component="th">
                            {t("productTable.wednessday")}{" "}
                            {startOfWeek.plus({ days: 2 }).toFormat("dd")}
                        </TableCell>
                        <TableCell className={classes.th} component="th">
                            {t("productTable.thursday")}{" "}
                            {startOfWeek.plus({ days: 3 }).toFormat("dd")}
                        </TableCell>
                        <TableCell className={classes.th} component="th">
                            {t("productTable.friday")}{" "}
                            {startOfWeek.plus({ days: 4 }).toFormat("dd")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product, index) => {
                        const rowId = `product-table-row-${product.productId}-${title}`;
                        return (
                            <OrderMatRow
                                key={rowId}
                                product={product}
                                showBackgroundTint={index % 2 === 1}
                                onImageClick={imageDialog.setData}
                                selectedWeek={selectedWeek}
                                startOfWeek={startOfWeek}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};
