import { AuthResource } from "../locales";

export const auth: AuthResource = {
    createUser: {
        title: "Skapa användare",
        submit: "Skapa användare",
        newPasswordIs: "Lösenordet är:",
        emailLabel: "Epost för återställning av lösenord:",
    },
    common: {
        backToLoginBtn: "Tillbaka till inloggning",
    },
    login: {
        title: "Kundportal",
        description: "Logga in nedan för att beställa dina varor direkt i webshoppen",
        forgotPasswordBtn: "Glömt lösenord?",
        loginBtn: "Logga in",
        passwordLbl: "Lösenord",
        usernameLbl: "Kundnummer",
    },
    forgotPassword: {
        title: "Glömt lösenord",
        resetPasswordSuccessNoContent:
            "En länk skickades till din e-post där du kan ändra lösenordet. Om du inte har fått något e-postmeddelande, ring kundtjänst.",
        resetPasswordSuccessInfo:
            "En länk för återställning av lösenord skickades till {{email}}. Om du inte har fått något e-postmeddelande, ring kundtjänst på {{customerSupportPhone}}",
        customerIdLbl: "Kundnummer",
        submitBtn: "Skicka mig ett nytt!",
        description:
            "Vänligen ange ditt kundnummer så skickar vi en lösenordsåterställning till den registrerade e-postadressen..",
    },
    changePassword: {
        title: "Byt lösenord",
        description: "Skriv in ett nytt lösenord för att uppdatera ditt konto.",
        generatePasswordDescription:
            "Tryck på knappen för att uppdatera kunden med ett nytt genererat lösenord.",
        generateNewPassword: "Generera nytt lösenord",
        passwordHasBeenUpdated: "Lösenordet har uppdaterats.",
    },
    password: {
        managePassword: "Hantera lösenord",
        passwordEmpty: "Lösenordet är tomt.",
        passwordMismatch: "Lösenorden matchar inte.",
        createUserSuccess: "Användaren har skapats.",
        changePasswordSuccess: "Lösenordet har ändrats.",
        oldPassword: "Nuvarande lösenord",
        changePassword: "Uppdatera lösenord",
        setPassword: "Välj lösenord",
        newPassword: "Nytt lösenord",
        confirmNewPassword: "Repetera lösenordet",
        submit: "Byt lösenord",
    },
    register: {
        title: "Välj ditt lösenord",
        welcome: "Välkommen {{customerName}}.",
        yourStoreNumberIs: "Ditt butiksnummer och användarnamn är:",
        emailLabel: "Epost för återställning av lösenord",
        submit: "Spara",
        snackbarSuccessMessage: "Ditt nya lösenord sparades.",
    },
};
