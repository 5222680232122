import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router";
import { AppDataFetcher } from "./AppDataFetcher";
import { AuthenticatedRoute, UnauthenticatedRoute } from "./AppRoute";
import { InactivityDetector } from "./components/ActivityDetector";
import { Header } from "./components/header/Header";
import { SnackbarSubscriber } from "./components/snackbar/SnackbarSubscriber";
import { GAPageTracker } from "./GAPageTracker";
import { fetchAndSetToken, setCustomerFromQueryParams } from "./network/authService";
import { ChangePasswordPage } from "./pages/auth/ChangePasswordPage";
import { ForgotPasswordPage } from "./pages/auth/ForgotPasswordPage";
import { LoginPage } from "./pages/auth/LoginPage";
import { RegisterPage } from "./pages/auth/RegisterPage";
import { CommunicationPage } from "./pages/communication/CommunicationPage";
import { ErrorPage } from "./pages/ErrorPage";
import { GuidancePage } from "./pages/guidance/GuidancePage";
import { LandingPage } from "./pages/landing/LandingPage";
import { MyAccountPage } from "./pages/my-account/MyAccountPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { CreditFreezeDialog } from "./pages/order/CreditFreezeDialog";
import { DeliveryInfoDialog } from "./pages/order/delivery-dates-dialog/DeliveryInfoDialog";
import { Orders } from "./pages/order/Orders";
import { OrderHistoryPage } from "./pages/order-history/OrderHistoryPage";
import { SuccessDialog } from "./pages/order-history/SuccessDialog";
import { selectCustomer } from "./state/slices/customerSlice";
import { selectIsLoggedIn, setLoggedIn, setLoggedOut } from "./state/slices/userSlice";
import { history } from "./utils/history";
import { GtmEvents, pushDataLayer } from "./utils/pushDataLayer";

export const App: FC = () => {
    const [tokenLoading, setTokenLoading] = useState(true);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const customer = useSelector(selectCustomer);
    const dispatch = useDispatch();

    useEffect(() => {
        setCustomerFromQueryParams();
        fetchAndSetToken()
            .then(() => dispatch(setLoggedIn()))
            .catch(() => dispatch(setLoggedOut()))
            .finally(() => setTokenLoading(false));
    }, [dispatch]);

    useEffect(() => {
        pushDataLayer(GtmEvents.Customer, {
            customerId: customer?.customerId,
            customerName: customer?.name,
            companyCode: customer?.companyCode,
            customerClass: customer?.customerClass,
        });
    }, [customer?.companyCode, customer?.customerClass, customer?.customerId, customer?.name]);

    if (tokenLoading) {
        return null;
    }

    return (
        <>
            <AppDataFetcher />
            <InactivityDetector />

            <SuccessDialog />
            <CreditFreezeDialog />

            <Router history={history}>
                <GAPageTracker />
                <SnackbarSubscriber />
                {isLoggedIn && <Header />}
                {isLoggedIn && <DeliveryInfoDialog />}

                <Switch>
                    {/* Routes both authenticated & unauthenticated users can access */}
                    <Route component={ErrorPage} path="/error" />

                    {/* Routes unauthenticated users can access */}
                    <UnauthenticatedRoute component={LoginPage} path="/login" />
                    <UnauthenticatedRoute component={ForgotPasswordPage} path="/forgot-password" />
                    <UnauthenticatedRoute component={RegisterPage} path="/register" />
                    <UnauthenticatedRoute
                        component={ChangePasswordPage}
                        path="/change-password/:token"
                    />

                    {/* Routes authenticated users can access */}
                    <AuthenticatedRoute component={LandingPage} exact path="/" />
                    <AuthenticatedRoute component={Orders} path="/order" />
                    <AuthenticatedRoute component={OrderHistoryPage} path="/order-history" />
                    <AuthenticatedRoute component={MyAccountPage} path="/my-account" />
                    <AuthenticatedRoute component={GuidancePage} exact path="/guidance" />
                    <AuthenticatedRoute component={CommunicationPage} exact path="/communication" />
                    <AuthenticatedRoute component={NotFoundPage} />
                </Switch>
            </Router>
        </>
    );
};
