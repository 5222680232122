export const auth = {
    createUser: {
        title: "Create user",
        submit: "Create user",
        newPasswordIs: "Password is:",
        emailLabel: "Email to reset password",
    },
    common: {
        backToLoginBtn: "Back to login",
    },
    login: {
        title: "Customer portal",
        description: "Log in below to order your goods directly in the webshop",
        forgotPasswordBtn: "Forgot password?",
        loginBtn: "Log in",
        passwordLbl: "Password",
        usernameLbl: "Customer number",
    },
    forgotPassword: {
        title: "Forgot password",
        resetPasswordSuccessNoContent:
            "A link was sent to your email where you can change the password. If you didn't receive a email, call customer service.",
        resetPasswordSuccessInfo:
            "A reset password link was sent to {{email}}. If you didn't receive an email, call customer service at {{customerSupportPhone}}",
        customerIdLbl: "Customer number",
        submitBtn: "Send me a new!",
        description:
            "Please enter your customer number and we will send a password reset to the registered email.",
    },
    changePassword: {
        title: "Change password",
        description: "Enter a new password to update your account.",
        generatePasswordDescription:
            "Press the button to update the user with a new generated password.",
        passwordHasBeenUpdated: "Password has been updated",
        generateNewPassword: "Generate new password",
    },
    password: {
        managePassword: "Manage password",
        passwordEmpty: "Password is empty.",
        createUserSuccess: "User has been created.",
        passwordMismatch: "Passwords do not match.",
        changePasswordSuccess: "The password has been changed.",
        changePassword: "Change password",
        setPassword: "Set password",
        oldPassword: "Old password",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        submit: "Change password",
    },
    register: {
        title: "Select your password",
        welcome: "Welcome {{customerName}}.",
        yourStoreNumberIs: "Your store number and user name is:",
        emailLabel: "Email to reset password",
        submit: "Save",
        snackbarSuccessMessage: "Your new password was saved.",
    },
};
