import { GeneralResource } from "../locales";

export const general: GeneralResource = {
    actions: {
        review: "Prüfen",
        close: "Schließen",
        back: "Zurück",
        ok: "OK",
        print: "Drucken",
        printArticleNumberScheme: "Drucken - Nach Artikelnummer sortiert",
        submit: "Senden",
        save: "Speichern",
        edit: "Bearbeiten",
        cancel: "Abbrechen",
        loadMore: "Mehr laden",
    },
    articles: "Produktliste",
    edit: "Bearbeiten",
    navigationLinks: {
        createUser: "Benutzer erstellen",
        changePassword: "Passwort ändern",
        communication: "Kommunikation",
        guidance: "Anleitungen",
        support: "Kundenservice",
        logout: "Abmelden",
        account: "Konto",
        myAccount: "Mein Konto",
        order: "Bestellung",
        orderHistory: "Bestellverlauf",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "Keine Zeit übrig",
        short: {
            days: "{{days}} Tag",
            hours: "{{hours}} Std.",
            minutes: "{{minutes}} Min.",
            seconds: "{{seconds}} Sek.",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Bestellung eingegangen",
        "created": "Bestätigt",
        "dispatched": "In Bearbeitung",
        "canceled": "Storniert",
        "invoiced": "In Rechnung gestellt",
    },
    price: {
        total: "Summe",
        totalExclVat: "Summe (ohne MwSt.)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Food to Go",
        "salad-bar": "Salatbar",
    },
    salesUnit: {
        kg: "kg",
        krt: "Karton",
        pcs: "Stück",
    },
    address: {
        invoice: "Rechnungsadresse",
        delivery: "Lieferadresse",
    },
    weight: "Gewicht",
    loggedInAsCustomer: "Als Kunde angemeldet",
    locales: {
        "sv-SE": "Svenska",
        "en-US": "English US",
        "fr-FR": "Français",
        "fi-FI": "Suomi",
        "nl-BE": "Nederlands (België)",
        "de-DE": "Deutsch",
    },
};
