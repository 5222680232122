import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useConfirmPasswordField = (newPassword: string) => {
    const { t } = useTranslation(["auth"]);
    const [error, setError] = useState<string>();
    const [value, setValue] = useState("");

    useEffect(() => {
        setError(
            value.length > 0 && newPassword !== value
                ? t("auth:password.passwordMismatch")
                : undefined,
        );
    }, [newPassword, value, t]);

    return {
        error,
        value,
        onChange: (password: string) => setValue(password),
    };
};
