import { ReportsResource } from "../locales";

export const reports: ReportsResource = {
    pageTitle: "Rapports",
    links: {
        productTemperature: "Créer un rapport de température du produit",
        inventory: "Créer un rapport d'inventaire",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Réinitialiser",
                submit: "Filtrer",
            },
            label: {
                productName: "Product name",
                fromDate: "From date",
                toDate: "To date",
            },
        },
        table: {
            header: {
                productName: "Nom du produit",
                productNo: "Article",
                batch: "Lot",
                time: "Temps",
                module: "Module",
                checkedBy: "Vérifié par",
                temperature: "Température",
            },
        },
        title: "Températures des produits",
        spreadsheet: "temperatures-des-produits.xlsx",
    },
};
