import { makeStyles } from "@material-ui/core/styles";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/InputForm";
import { LoadingButton } from "../../components/LoadingButton";
import { ModalHeader } from "../../components/modal/ModalHeader";
import { EmailTextField, TextField } from "../../components/TextField";
import { useEmailValidator } from "../../hooks/form/useEmailValidator";
import { CustomerContact, CustomerContactRole } from "../../state/types";

const useStyles = makeStyles({
    container: {
        width: 360,
    },
    submit: {
        marginLeft: "auto",
        marginTop: 20,
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
});

type Props = {
    initialContact?: CustomerContact;
    role: CustomerContactRole;
    isLoading: boolean;
    close: () => void;
    onSubmit: (contact: CustomerContact) => void;
    icon?: JSX.Element;
    overline: string;
    title: string;
};

export const ContactModal: FC<Props> = ({
    initialContact,
    role,
    close,
    onSubmit,
    isLoading,
    overline,
    title,
    icon,
}) => {
    const [contact, setContact] = useState<CustomerContact>({
        email: initialContact?.email ?? "",
        name: initialContact?.name ?? "",
        phone: initialContact?.phone ?? "",
        role,
    });
    const classes = useStyles();
    const { t } = useTranslation(["myAccount", "general"]);
    const emailValidator = useEmailValidator();

    const handleChange = (prop: "email" | "name" | "phone") => (value: string) =>
        setContact(cur => ({ ...cur, [prop]: value }));

    return (
        <div className={classes.container}>
            <ModalHeader overline={overline} title={title} close={close} />

            <Form className={classes.form} onSubmit={() => onSubmit(contact)}>
                <TextField
                    label={t("myAccount:name")}
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={handleChange("name")}
                />
                <EmailTextField
                    label={t("myAccount:email")}
                    value={contact.email}
                    onChange={value => {
                        const trimmedValue = value.trim();
                        handleChange("email")(trimmedValue);
                        emailValidator.validate(trimmedValue);
                    }}
                />
                <TextField
                    name="phone"
                    autoComplete="tel"
                    label={t("myAccount:phone")}
                    type="phone"
                    value={contact.phone}
                    onChange={handleChange("phone")}
                />
                <LoadingButton
                    color="primary"
                    variant="contained"
                    className={classes.submit}
                    type="submit"
                    disableElevation
                    disabled={Boolean(emailValidator.error)}
                    loading={isLoading}
                    onClick={() => onSubmit(contact)}
                >
                    {t("general:actions.save")}
                </LoadingButton>
            </Form>
        </div>
    );
};
