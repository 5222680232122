import { OrderResource } from "../locales";

export const order: OrderResource = {
    addProductsToCart: "Lisää tuotteita ostoskoriin",
    availableRotationProducts: "Vaihtotuote",
    banner: "Joitakin tuotteita on ostoskorissasi epätavallisen suuri määrä. Tarkista korostettujen tuotteiden määrät ennen tilauksen tekemistä.",
    cart: {
        excludingVat: "ei sis. alv",
        chilled: "Kylmäsäilytys",
        couldNotDeliverChosenToDate:
            "Merkittyjen tuotteiden toimitus ei onnistu valittuna toimituspäivänä. Valitse seuraavista vaihtoehdoista:",
        changeDeliveryDate: "Muuta toimituspäivää",
        containCountProducts: "Ostoskorissasi on {{count}} tuotetta",
        containCountProducts_plural: "Ostoskorissasi on {{count}} tuotetta",
        shipping: {
            banner: {
                total: "Pientoimituslisä",
                frozen: "Pientoimituslisä",
                chilled: "Pientoimituslisä",
            },
            free: {
                title: "Ilmainen toimitus",
                values: {
                    total: "Yli {{thresholdFree}} tilauksiin",
                    frozen: "Pakaste: tilaus > {{thresholdFree}}",
                    chilled: "Kylmätuote: tilaus > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Laskulle lisätään pientoimituslisä {{charge}}, kun toimituspäivän kokonaisostot jäävät alle {{thresholdFree}}.",
                belowMin:
                    "Tilauksesi loppusumma ei yllä vähimmäistilausrajoitukseen, joka on {{thresholdMin}}. Lisää vähintään {{remainingCost}}:n arvosta tavaraa, niin voit tehdä tilauksen.",
            },
            total: {
                total: "Toimitus yhteensä",
                frozen: "Pakaste",
                chilled: "Kylmäsäilytys",
            },
        },
        hideCartProducts: "Piilota ostoskorin tuotteet",
        frozen: "Pakaste",
        placeOrder: "Tee tilaus",
        removeProductsThatCannotBeDelivered: "Poista kaikki tuotteet, joita ei voida toimittaa",
        selectedDeliveryDate: "Valittu toimituspäivä",
        showCart: "Näytä ostoskori",
        showCartProducts: "Näytä ostoskorin tuotteet",
        totalShipping: "Toimitus yhteensä",
        title: "Ostoskori",
        vat: "Alv ({{percentage}})",
        weight: {
            "product-types": "Paino: Salaattibaari / Deli / Ruokaa mukaan",
            "frozen": "Paino: Kylmätuote/pakaste",
            "no-split": "Kokonaispaino",
        },
    },
    creditFreeze: {
        title: "Tärkeää tietoa",
        body:
            "Kauppasi Picadeli-tilillä on maksamattomia laskuja, jotka on maksettava ennen kuin voit tehdä tilauksia uudelleen.\n" +
            "\n" +
            "Ota yhteyttä Picadelin asiakastukeen selvittääksesi maksamattomat laskut ja päästäksesi tilaamaan uudelleen.\n" +
            "\n" +
            "Soita: {{phone}}",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Toimituskalenteri puuttuu",
        title: "Toimitustiedot",
        myDeliveryCalendar: "Oma toimituskalenteri",
        orderRef: "Viite: {{orderRef}}",
        orderRefLabel: "Anna viite (valinnainen)",
        selectedDeliveryDate: "Valittu toimituspäivä",
        pickDeliveryDate: "Valitse toimituspäivä",
        timeLeftToOrder: "Aikaa jäljellä tilauksen tekemiseen",
        hideAdvancedOptions: "Piilota lisäasetukset",
        showAdvancedOptions: "Näytä lisäasetukset",
        zeroPriceOrder: "Nollahintainen tilaus",
        adminCalendar: "Ylläpitäjän kalenteri",
        adminCalendarAnyDeliveryDate: "Ohita toimituskalenteri (FLS mode)",
        adminCalendarWarning:
            "Ole varovainen. Käytä tätä vaihtoehtoa vain, jos tiedät mitä olet tekemässä!",
        adminCalendarDeliveryDateWarning:
            "OK, aika toisen varoituksen: If you select a date in this mode, you will NOT be able to see any closed products AND the order will likely cause an error message in EDI Incoming Orders in Jeeves.",
        table: {
            header: {
                deliveryDate: "Toimituspäivä",
                assortment: "Valikoima",
                lastOrderDeadline: "Tilattava viimeistään",
                onTheWay: "Matkalla",
            },
            btn: {
                select: "Valitse",
                selectAndCreateOrderProposal: "Valitse ja luo tilausehdotelma",
            },
            orderSuggestionAvailable: "Tilausehdotelma on saatavilla",
        },
    },
    filter: {
        showAllProducts: "Kaikki tuotteet",
        showPlanogramProducts: "Planogramman tuotteet",
        showFavorites: "Näytä suosikit",
        showAccessories: "Lisätarvikkeet",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Lisää tilausehdotelma ostoskoriin",
        replaceCartDialog: {
            content:
                "Kun tilausehdotelman tuotteet lisätään ostoskoriin, korissa jo olevat tuotteet poistetaan.",
            title: "Korvaa ostoskori",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "Tilauksesi on nyt vastaanotettu",
        thankYouForYourPurchase: "Kiitos ostosten tekemisestä",
        yourOrderHasNowBeenReceived: "Tilauksesi on nyt vastaanotettu",
        continueShopping: "Jatka ostoksia",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Tilauksen tekeminen ei onnistunut",
        removeInvalidProductsBtn: "Poista kelpaamattomat tuotteet",
    },
    planogramPdfBtn: "Planogramma PDF:nä",
    productAlreadyInCart: "Jo ostoskorissa",
    productAlreadyInList: "Tilaa tuoteluettelosta",
    productHasBeenReplaced: "Olet vaihtanut tuotteesta {{productName}}",
    productTable: {
        articleNo: "tuotenro",
        comparisonPrice: "EUR / kg",
        comparisonPriceNonFood: "EUR / kg",
        dataSheet: "Tietolomake versio {{version}}",
        icon: {
            frozen: "Pakaste",
            inPlanogram: "Planogrammissa",
            orderAssortment: "Tilausvalikoima",
            news: "Uutiset",
        },
        name: "nimi",
        onTheWay: "tulossa",
        pricePerUnit: "yks.hinta",
        selectRotationProduct: "Vaihda",
        selectPriceListProduct: "Valitse",
        unit: "yks.",
    },
    productMenu: {
        orderHelpMeals: "Order help meals",
        orderSuggestionsMeals: "Order suggestion meals",
        orderSuggestionsMealsDescription:
            "Here you can see the amount of each product needed for the number of meals you have chosen. The surplus / deficit column shows how much is left over or missing. NOTE Do not forget items for the bulk sale.",
        pricePerPortion: "Price / Portion",
        numPackages: "Number of packages",
        numPortions: "Number of portions",
        perPortion: "Per portion",
        excessWeightTitle: "Surplus / deficit",
        meals: "Meals",
        mealsDescription:
            "Order the desired number of portions per dish. If you order several meals containing, for example, mashed potatoes, the quantity is added together to minimize possible wastage.",
        createOrderSuggestion: "Create order suggestion",
        someProductsCannotBeDelivered:
            "One or more products in this meal can not be delivered. Click for more information.",
    },
    searchProduct: "Hae tuotenumerolla tai nimellä",
    selectAReplacement: "Valitse vaihtoehtoinen tuote",
    thresholdWarningDialog: {
        pleaseCheck:
            "Varmista ennen tilauksen tekemistä, että tuotteiden määrät on merkitty tilaukseen oikein.",
        title: "Epätavallisen suuri määrä",
        weHaveNoticed:
            "Huomasimme alle listattujen tuotteiden määrien olevan epätavallisen suuria:",
    },
    unavailableRotationProducts: "Suljettu tuote",
};
