import { OrderHistoryResource } from "../locales";

export const orderHistory: OrderHistoryResource = {
    claim: {
        orderRowTitle: "Reklamer {{productName}}",
        orderTitle: "Reklamer ordre",
        info: "Ved manglende levering eller andre større klager, ring kundeservice {{phoneNumber}}",
        placeholderReason: "Velg en grunn for reklamasjonen.",
        bestBefore: "Best før:",
        batchCode: "Batchkode:",
        outerBoxBroken: "Ytre eske skadet:",
        additionalInfo: "Tilleggsinformasjon:",
        contactForTheClaim: "Navn på personen som reklamerer:",
        contactEmail: "E-postadresse:",
        picture: "Bilde:",
        success: {
            title: "Takk for din reklamasjon",
            content:
                "Vi vil behandle din reklamasjon innen 48 timer og komme tilbake til deg hvis vi har noen spørsmål.",
            bestRegards: "Med vennlig hilsen",
            picadeliCustomerService: "Picadeli Kundeservice",
        },
        fullOrderText: "Du er i ferd med å reklamere på alle produktene i denne bestillingen.",
    },
    claimStatus: {
        "not-available": "Ikke tilgjengelig",
        "call-customer-services": "Ring kundeservice",
        "claimed": "Reklamert",
    },
    table: {
        details: {
            deliveryAddress: "Leveringsadresse",
            invoiceAddress: "Fakturaadresse",
            orderedProducts: "Bestilte produkter",
            table: {
                claimOrder: "Reklamer hele bestillingen",
                cost: "Kostnad",
                deliveredQuantity: "Levert",
                invoicedAt: "Fakturert",
                weight: "Nettovekt",
                orderNumber: "Ordrenr.",
                orderedQuantity: "Bestilt",
                picked: "Plukket",
                productId: "Art.nr.",
                productName: "Artikkelnavn",
                reclamation: "Reklamasjon",
                subtotal: "Delsum",
            },
            copyToCart: "Kopier bestilling til handlekurv",
        },
        header: {
            orderRef: "Ref",
            createdBy: "Opprettet av",
            deliversTo: "Levert til",
            deliveryDate: "Leveringsdato",
            status: "Status",
            timeCreated: "Opprettet",
            totalCostExclVat: "Totalkostnad ekskl. MVA",
            totalWeight: "Totalvekt",
        },
        review: "Gjennomgå",
    },
    thereIsNoOrderHistory: "Det er ingen bestillingshistorikk.",
};
