import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import editSvg from "../../assets/images/icons/edit.svg";
import { Body2 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { MissingInfo } from "./MissingInfo";

const useStyles = makeStyles({
    container: {},
    header: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        marginLeft: 10,
    },
    editBtn: {
        marginLeft: "auto",
    },
    missing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 67,
        backgroundColor: "#F4F6F5",
        borderRadius: 15,
        color: colors.darkGrey,
    },
    text: {
        lineHeight: "25px",
    },
});

type Props = {
    title: string;
    emails: string[];
    onEdit: () => void;
};

export const Emails: FC<Props> = ({ title, emails, onEdit }) => {
    const classes = useStyles();
    const { t } = useTranslation(["myAccount"]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <EmailIcon />
                <Body2 bold className={classes.title}>
                    {title}
                </Body2>
                <IconButton className={classes.editBtn} onClick={onEdit}>
                    <img src={editSvg} alt="edit" />
                </IconButton>
            </div>
            {emails.length === 0 && <MissingInfo>{t("myAccount:missingInformation")}</MissingInfo>}
            {emails.map(email => (
                <Body2 className={classes.text} key={email}>
                    {email}
                </Body2>
            ))}
        </div>
    );
};
