import { Typography, TypographyProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { ThemeType } from "../theme";

type Style = { size?: number };

export const headingFonts = (theme: Theme | ThemeType) => ({
    h1: {
        fontFamily: "Rubik-SemiBold",
        fontSize: theme.typography.pxToRem(32),
        margin: 0,
    },
    h2: {
        fontFamily: "Rubik-SemiBold",
        fontSize: theme.typography.pxToRem(26),
        margin: 0,
    },
    h3: {
        fontFamily: "Rubik-SemiBold",
        fontSize: theme.typography.pxToRem(20),
        margin: 0,
    },
    h4: {
        fontFamily: "Rubik-SemiBold",
        fontSize: theme.typography.pxToRem(16),
        margin: 0,
    },
    h5: {
        fontFamily: "Rubik-SemiBold",
        fontSize: theme.typography.pxToRem(13),
        margin: 0,
    },
    h6: {
        fontFamily: "Rubik-SemiBold",
        fontSize: theme.typography.pxToRem(11),
        margin: 0,
    },
});

const useStyles = makeStyles<ThemeType, Style>(theme => ({
    base: {
        fontSize: props => (props?.size ? theme.typography.pxToRem(props.size) : undefined),
    },
    uppercase: {
        textTransform: "uppercase",
    },
    bold: {
        fontFamily: "Rubik-SemiBold",
    },
    lunchBox: {
        fontFamily: "LunchBox !important",
        textTransform: "uppercase",
    },
    overline: {
        fontSize: theme.typography.pxToRem(14),
    },
    caption: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(15),
    },
    body1: {
        fontSize: theme.typography.pxToRem(16),
    },
    body2: {
        fontSize: theme.typography.pxToRem(14),
    },
    primary: {
        color: theme.palette.primary.main,
    },
    overflowEllipsis: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    italic: {
        fontFamily: "Rubik-Italic",
    },
    preWrap: {
        whiteSpace: "pre-wrap",
    },
    ...headingFonts(theme),
}));

export type Props = {
    lunchBox?: boolean;
    bold?: boolean;
    primary?: boolean;
    uppercase?: boolean;
    size?: number;
    italic?: boolean;
    overflowEllipsis?: boolean;
    preWrap?: boolean;
} & TypographyProps;

const Base: FC<Props> = ({
    overflowEllipsis,
    size,
    className,
    lunchBox,
    bold,
    primary,
    uppercase,
    italic,
    preWrap,
    ...props
}) => {
    const classes = useStyles({ size });

    return (
        <Typography
            {...props}
            className={cn(classes.base, className, {
                [classes.overflowEllipsis]: overflowEllipsis,
                [classes.bold]: bold,
                [classes.lunchBox]: lunchBox,
                [classes.primary]: primary,
                [classes.uppercase]: uppercase,
                [classes.italic]: italic,
                [classes.preWrap]: preWrap,
            })}
        />
    );
};

export const Body1: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="body1" className={cn(className, classes.body1)} {...props} />;
};

export const Body2: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="body2" className={cn(className, classes.body2)} {...props} />;
};

export const Caption: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="caption" className={cn(className, classes.caption)} {...props} />;
};

export const Overline: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="overline" className={cn(className, classes.overline)} {...props} />;
};

export const H1: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="h1" className={cn(className, classes.h1)} {...props} />;
};

export const H2: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="h2" className={cn(className, classes.h2)} {...props} />;
};

export const H3: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="h3" className={cn(className, classes.h3)} {...props} />;
};

export const H4: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="h4" className={cn(className, classes.h4)} {...props} />;
};

export const H5: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="h5" className={cn(className, classes.h5)} {...props} />;
};

export const H6: FC<Props> = ({ className, ...props }) => {
    const classes = useStyles({});
    return <Base variant="h6" className={cn(className, classes.h6)} {...props} />;
};
