import { Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/InputForm";
import { LoadingButton } from "../../components/LoadingButton";
import { ModalHeader } from "../../components/modal/ModalHeader";
import { EmailTextField } from "../../components/TextField";
import { colors } from "../../constants/colors";
import { isInvalidEmail } from "../../hooks/form/useEmailValidator";

const useStyles = makeStyles({
    container: {
        width: 360,
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    email: {
        "display": "flex",
        "alignItems": "center",
        "& .MuiFormControl-root": {
            flex: 1,
        },
    },
    footer: {
        display: "flex",
        marginTop: 20,
    },
    saveBtn: {
        marginLeft: "auto",
    },
    removeBtn: {
        margin: "25px 5px 0 5px",
        color: colors.attentionRed,
    },
});

type Props = {
    initialEmails: string[];
    close: () => void;
    onSubmit: (emails: string[]) => void;
    isLoading: boolean;

    /**
     *  support of a single email or multiple email addresses
     */
    single?: boolean;
    title: string;
};

export const EmailModal: FC<Props> = ({
    initialEmails,
    close,
    onSubmit,
    isLoading,
    title,
    single,
}) => {
    const { t } = useTranslation(["myAccount", "general"]);
    const [emails, setEmails] = useState<string[]>(
        initialEmails.length === 0 ? [""] : initialEmails,
    );
    const classes = useStyles();

    const handleAddNewEmail = () => {
        setEmails(emails => [...emails, ""]);
    };

    const handleRemoveEmail = (idx: number) => {
        setEmails(currentEmails => currentEmails.filter((_, _idx) => _idx !== idx));
    };

    const handleEditEmail = (idx: number, newEmail: string) => {
        setEmails(currentEmails => {
            const copy = [...currentEmails];
            copy[idx] = newEmail;
            return copy;
        });
    };

    const handleOnSubmit = () => onSubmit(emails.filter(e => e.length > 0));

    return (
        <div className={classes.container}>
            <ModalHeader title={title} close={close} />

            <Form className={classes.form} onSubmit={handleOnSubmit} noValidate>
                {emails.map((email, idx) => (
                    <div className={classes.email} key={idx}>
                        <EmailTextField
                            label={`${t("myAccount:recipient")} ${single ? "" : idx + 1}`}
                            value={email}
                            onChange={value => handleEditEmail(idx, value)}
                        />
                        {!single && (
                            <IconButton
                                className={classes.removeBtn}
                                onClick={() => handleRemoveEmail(idx)}
                            >
                                <RemoveCircleIcon />
                            </IconButton>
                        )}
                    </div>
                ))}

                <div className={classes.footer}>
                    {!single && (
                        <Button onClick={handleAddNewEmail}>
                            {t("myAccount:addNewRecipient")}
                        </Button>
                    )}
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        disabled={emails.some(isInvalidEmail)}
                        className={classes.saveBtn}
                        loading={isLoading}
                        onClick={handleOnSubmit}
                    >
                        {t("general:actions.save")}
                    </LoadingButton>
                </div>
            </Form>
        </div>
    );
};
