import { PageNotFoundResource } from "../locales";

export const pageNotFound: PageNotFoundResource = {
    title: "Oups!",
    subTitle: "La page que vous recherchez semble introuvable.",
    errorCode: "Code d'erreur: 404",
    usefulLinks: "Voici quelques liens utiles à la place:",
    links: {
        home: "Page d'accueil",
        order: "Commander",
        myAccount: "Mon compte",
    },
};
