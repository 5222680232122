import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/InputForm";
import { LoadingButton } from "../../components/LoadingButton";
import { ModalHeader } from "../../components/modal/ModalHeader";
import { TextField } from "../../components/TextField";
import { Body2 } from "../../components/Typography";
import { useEmailField } from "../../hooks/form/useEmailField";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import {
    createUser,
    selectCreateUserRequest,
    selectNewUserPassword,
} from "../../state/slices/userSlice";
import { RequestStatus } from "../../state/types";

const useStyles = makeStyles(theme => ({
    container: {
        width: 360,
    },
    alert: {
        margin: theme.spacing(1, 0),
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        marginTop: theme.spacing(2),
        textAlign: "center",
    },
    button: {
        margin: "20px auto",
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    newPassword: {
        fontFamily: "Rubik-SemiBold",
        letterSpacing: "2px",
    },
}));

export const CreateUserModal: FC<{ close: () => void }> = ({ close }) => {
    const { t } = useTranslation(["myAccount", "auth", "general"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const request = useAppSelector(selectCreateUserRequest);
    const createUserPassword = useAppSelector(selectNewUserPassword);
    const emailField = useEmailField();

    const handleSubmit = async () => {
        await dispatch(
            createUser({
                email: emailField.value || undefined,
            }),
        );
    };

    return (
        <div className={classes.container}>
            <ModalHeader title={t("auth:createUser.title")} close={close} />
            <Form className={classes.form} onSubmit={handleSubmit}>
                {createUserPassword ? (
                    <Alert severity="success" className={classes.alert}>
                        <Body2>
                            {t("auth:createUser.newPasswordIs")}{" "}
                            <span className={classes.newPassword}>{createUserPassword}</span>
                        </Body2>
                    </Alert>
                ) : (
                    <Alert severity="warning" className={classes.alert}>
                        <Body2>{t("myAccount:customerUserIsMissing")}</Body2>
                    </Alert>
                )}
                <TextField
                    disabled={Boolean(createUserPassword)}
                    label={t("auth:createUser.emailLabel")}
                    value={emailField.value}
                    onChange={emailField.onChange}
                    autoComplete="email"
                    type="email"
                    errorMessage={emailField.error}
                />
                <LoadingButton
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disableElevation
                    loading={request === RequestStatus.Loading}
                    disabled={Boolean(emailField.error) || Boolean(createUserPassword)}
                >
                    {t("auth:createUser.submit")}
                </LoadingButton>
            </Form>
        </div>
    );
};
