import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState, ThunkApiConfig } from "../configureStore";
import { selectBypassDeadline, selectIsBypassProductAvailability } from "./orderDetailsSlice";
import { fetchProducts } from "./productsSlice";

export type ClosedInfo = {
    productId: string;
    closedInfo: string;
};

export const fetchClosedInfos = createAsyncThunk<ClosedInfo[], string, ThunkApiConfig>(
    "fetch/closed-infos",
    async (deliveryDate: string, { getState }) => {
        const state = getState();

        return get<ClosedInfo[]>(Endpoint.closedInfos, {
            params: {
                "delivery-date": deliveryDate,
                "bypass-deadline": selectBypassDeadline(state) ? "true" : undefined,
                "bypass-product-availability": selectIsBypassProductAvailability(state)
                    ? "true"
                    : undefined,
            },
        });
    },
);

const adapter = createEntityAdapter<ClosedInfo>({
    selectId: entity => entity.productId,
});

const slice = createSlice({
    name: "closedInfos",
    initialState: adapter.getInitialState({}),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchProducts.fulfilled, (state, action) => {
            const closedInfos = action.payload.products.flatMap(p => {
                if (!p.closedInfo) {
                    return [];
                }
                return { productId: p.productId, closedInfo: p.closedInfo };
            });
            adapter.setAll(state, closedInfos);
        });
        addCase(fetchClosedInfos.fulfilled, (state, { payload }) => {
            adapter.setAll(state, payload);
        });
    },
});

export const {
    selectById: selectClosedInfoById,
    selectAll: selectClosedInfos,
    selectEntities: selectClosedInfoEntities,
} = adapter.getSelectors<RootState>(state => state.closedInfo);

export const closedInfoReducer = slice.reducer;
