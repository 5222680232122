import { LandingResource } from "../locales";

export const landing: LandingResource = {
    news: "News",
    messages: "Nachrichten",
    home: "Startseite",
    showAllNews: "Alle News anzeigen ({{count}})",
    showAllMessages: "Alle anzeigen",
    myMessages: "Meine Nachrichten",
    noMessages: "Sie haben keine Nachrichten",
    contactUs: "Kontakt",
};
