import { OrderResource } from "../locales";

export const order: OrderResource = {
    addProductsToCart: "Ajouter au panier",
    availableRotationProducts: "Remplacement disponible",
    banner: "Quantité trop élevée. Vérifier la quantité de ces produits avant de passer votre commande.",
    cart: {
        excludingVat: "Hors TVA",
        chilled: "Frais",
        couldNotDeliverChosenToDate:
            "Le(s) produit(s) suivant(s) ne peuvent pas être livrés à la date sélectionnée. Sélectionner l'une des options suivantes :",
        changeDeliveryDate: "Modifier la date de livraison",
        containCountProducts: "Il y a {{count}} article dans votre panier",
        containCountProducts_plural: "Il y a {{count}} articles dans votre panier",
        shipping: {
            banner: {
                total: "Livraison",
                frozen: "Surgelé",
                chilled: "Frais – Sec – Economat",
            },
            free: {
                title: "Livraison gratuite",
                values: {
                    total: "Pour les commandes supérieures à {{thresholdFree}}",
                    frozen: "Surgelé : commande > {{thresholdFree}}",
                    chilled: "Frais – Sec – Economat : commande > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Votre commande ne peut pas être livrée sans frais car elle n’atteint pas le franco de {{thresholdFree}}. Des frais de livraison de {{charge}} seront appliqués. Pour être livré gratuitement, ajoutez {{remainingCost}} !",
                belowMin:
                    "Votre commande n’atteint pas le minimum de commande de {{thresholdMin}}. Ajoutez au moins {{remainingCost}} de marchandise pour débloquer la livraison !",
            },
            total: {
                total: "Livraison totale",
                frozen: "Surgelé",
                chilled: "Frais",
            },
        },
        hideCartProducts: "Masquer les articles du panier",
        frozen: "Surgelé",
        placeOrder: "Passer la commande",
        removeProductsThatCannotBeDelivered:
            "Supprimer tous les produits qui ne peuvent pas être livrés",
        selectedDeliveryDate: "Date de livraison sélectionnée",
        showCart: "Afficher le panier",
        showCartProducts: "Montrer les articles du panier",
        totalShipping: "Livraison totale :",
        title: "Panier",
        vat: "Taxe ({{percentage}})",
        weight: {
            "product-types": "Poids Bar à salades / Deli / Food To Go",
            "frozen": "Poids Frais / Surgelé",
            "no-split": "Poids",
        },
    },
    confirmMinThresholdOrderDialog: {
        title: "Confirmer",
        body: "Êtes-vous sûr de vouloir passer une commande inférieure au montant minimum de commande ?",
        checkboxLabel: "Je confirme que cette commande est OK",
        yes: "Oui, continuez et passez cette commande",
        no: "Non, je ne souhaite pas passer cette commande",
    },
    creditFreeze: {
        title: "Information importante",
        body:
            "Votre niveau d’encours impayés est trop élevé. Sans règlement préalable de vos factures, vous ne pouvez plus passer de commande.\n\n" +
            "Merci de contacter le: {{phone}}",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Calendrier de livraison manquant",
        title: "Informations de livraison",
        myDeliveryCalendar: "Mon calendrier de livraison",
        orderRef: "Reference: {{orderRef}}",
        orderRefLabel: "Entrez la référence (optionnel)",
        selectedDeliveryDate: "Date de livraison choisie",
        pickDeliveryDate: "Sélectionner la date de livraison :",
        timeLeftToOrder: "Temps restant pour commander",
        hideAdvancedOptions: "Masquer les options avancées",
        showAdvancedOptions: "Montrer les options avancées",
        zeroPriceOrder: "Commande à prix zéro",
        adminCalendar: "Admin Calendrier",
        adminCalendarAnyDeliveryDate: "Calendrier de livraison de contournement (FLS mode)",
        adminCalendarWarning:
            "Faire attention. N'utilisez cette option que si vous savez ce que vous faites !",
        adminCalendarDeliveryDateWarning:
            "OK, il est temps pour un autre avertissement : If you select a date in this mode, you will NOT be able to see any closed products AND the order will likely cause an error message in EDI Incoming Orders in Jeeves.",
        table: {
            header: {
                deliveryDate: "La date de livraison",
                assortment: "Assortiment",
                lastOrderDeadline: "Date limite de commande",
                onTheWay: "En transit",
            },
            btn: {
                select: "Sélectionner",
                selectAndCreateOrderProposal: "Sélectionner et créer une suggestion de commande",
            },
            orderSuggestionAvailable: "Suggestion de commande disponible",
        },
    },
    filter: {
        showAllProducts: "Tout afficher",
        showPlanogramProducts: "Afficher le planogramme",
        showFavorites: "Afficher les favoris",
        showAccessories: "Accessoires",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Ajouter la suggestion de commande au panier",
        replaceCartDialog: {
            content:
                "L'ajout des produits de la suggestion de commande au panier supprime le contenu du panier existant.",
            title: "Remplacer le panier",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "Votre commande a été reçue.",
        thankYouForYourPurchase: "Merci pour votre achat",
        yourOrderHasNowBeenReceived: "Votre commande a été reçue",
        continueShopping: "Continuer vos achats",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Impossible de passer la commande.",
        removeInvalidProductsBtn: "Supprimer les produits invalides",
    },
    planogramPdfBtn: "Planogramme PDF",
    productAlreadyInCart: "Déjà dans le panier",
    productAlreadyInList: "Commander dans la liste des produits",
    productHasBeenReplaced: "Vous avez remplacé {{productName}}",
    productTable: {
        articleNo: "article",
        comparisonPrice: "prix/kg(pi.)",
        comparisonPriceNonFood: "prix/pièce",
        dataSheet: "Fiche produit version {{version}}",
        icon: {
            frozen: "Surgelé",
            inPlanogram: "En planogramme",
            orderAssortment: "Assortiment de commandes",
            news: "Nouvelles",
        },
        name: "nom",
        onTheWay: "en livraison",
        pricePerUnit: "prix/unité",
        selectRotationProduct: "Changer",
        selectPriceListProduct: "Choisir",
        unit: "unité",
    },
    productMenu: {
        orderHelpMeals: "Aide à la commande de repas",
        orderSuggestionsMeals: "Suggestion de commande pour les repas",
        orderSuggestionsMealsDescription:
            "Ici, vous pouvez voir la quantité de chaque produit nécessaire pour le nombre de repas que vous avez choisi. La colonne surplus/déficit montre combien il reste ou manque. REMARQUE N'oubliez pas les articles pour la vente en gros.",
        pricePerPortion: "Prix / Portion",
        numPackages: "Nombre de colis",
        numPortions: "Nombre de portions",
        perPortion: "Par portion",
        excessWeightTitle: "Surplus / Déficit",
        meals: "Repas",
        mealsDescription:
            "Commandez le nombre de portions souhaité par plat. Si vous commandez plusieurs repas contenant par exemple de la purée de pommes de terre, la quantité est additionnée pour minimiser les éventuels gaspillages.",
        createOrderSuggestion: "Créer une suggestion de commande",
        someProductsCannotBeDelivered:
            "Un ou plusieurs produits de ce repas ne peuvent être livrés. Cliquez pour plus d'informations.",
    },
    searchProduct: "Recherche par code article ou nom de produit",
    selectAReplacement: "Choisir un produit de remplacement",
    thresholdWarningDialog: {
        pleaseCheck:
            "Veuillez vérifier que la quantité correspond au montant souhaité avant de passer votre commande.",
        title: "Quantité trop élevée",
        weHaveNoticed: "Les produits suivants ont une quantité trop élevée :",
    },
    unavailableRotationProducts: "Produit fermé",
};
