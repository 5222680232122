import { GuidanceResource } from "../locales";

export const guidance: GuidanceResource = {
    campaignMaterial: "Kampanjamateriaalit",
    guidance: "Info ja ohjeet",
    planogram: "Planogramma",
    currentPlanogram: "Nykyinen planogramma",
    futurePlanogram: "Tuleva planogramma {{start}}",
    labels: "Etiketit",
    deviationAndClaims: "Poikkeavuudet ja reklamaatiot",
    risks: "Riskit",
    certificates: "Sertifikaatit",
};
