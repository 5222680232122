export const guidance = {
    campaignMaterial: "Campaign material",
    guidance: "Guidance",
    planogram: "Planogram",
    currentPlanogram: "Current Planogram",
    futurePlanogram: "Future planogram {{start}}",
    labels: "Labels",
    deviationAndClaims: "Deviation and claims",
    risks: "Risks",
    certificates: "Certificates",
};
