import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";

type ForecastTime = {
    time: string;
    symbolCode: string;
    temperature: number;
};

export const fetchWeatherForecast = createAsyncThunk("fetch/weather-forecast", async () => {
    return get<ForecastTime[]>(Endpoint.weatherForecast);
});

const adapter = createEntityAdapter<ForecastTime>({
    selectId: entity => entity.time,
});

const slice = createSlice({
    name: "weatherForecast",
    initialState: adapter.getInitialState(),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchWeatherForecast.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload);
        });
    },
});

export const weatherReducer = slice.reducer;

export const { selectAll: selectWeatherForecast } = adapter.getSelectors<RootState>(
    state => state.weather,
);
