import { CustomerSupportResource } from "../locales";

export const customerSupport: CustomerSupportResource = {
    title: "Tervetuloa Picadelin asiakastukeen",
    contactUs: "Ota meihin yhteyttä",
    openingHours: "Aukioloajat",
    email: "Sähköposti: {{value}}",
    phone: "Puhelin: {{value}}",
    openingHoursStart: "Avataan klo {{value}}",
    openingHoursEnd: "Suljetaan klo {{value}}",
};
