import { AuthResource } from "../locales";

export const auth: AuthResource = {
    createUser: {
        title: "Benutzer erstellen",
        submit: "Benutzer erstellen",
        newPasswordIs: "Passwort ist:",
        emailLabel: "E-Mail, um das Passwort zurückzusetzen",
    },
    common: {
        backToLoginBtn: "Zurück zur Anmeldung",
    },
    login: {
        title: "Kundenportal",
        description: "Melden Sie sich hier an, um Ihre Produkte direkt im Webshop zu bestellen.",
        forgotPasswordBtn: "Passwort vergessen?",
        loginBtn: "Anmelden",
        passwordLbl: "Passwort",
        usernameLbl: "Kundennummer",
    },
    forgotPassword: {
        title: "Passwort vergessen",
        resetPasswordSuccessNoContent:
            "Wir haben einen Link an Ihre E-Mail-Adresse gesendet, über den Sie das Passwort ändern können. Wenn Sie keine E-Mail erhalten haben, rufen Sie den Kundendienst an.",
        resetPasswordSuccessInfo:
            "Wir haben einen Link an {{email}} gesendet. Wenn Sie keine E-Mail erhalten haben, rufen Sie den Kundendienst unter {{customerSupportPhone}} an.",
        customerIdLbl: "Kundennummer",
        submitBtn: "Sende mir ein neues Passwort!",
        description:
            "Geben Sie bitte Ihre E-Mail-Adresse ein. Sie erhalten dann einen Link zum Zurücksetzen Ihres Passworts.",
    },
    changePassword: {
        title: "Passwort ändern",
        description: "Geben Sie ein neues Passwort ein, um Ihr Benutzerkonto zu aktualisieren.",
        generatePasswordDescription:
            "Drücken Sie die Schaltfläche, um den Benutzer mit einem neu generierten Passwort zu aktualisieren.",
        passwordHasBeenUpdated: "Passwort wurde aktualisiert",
        generateNewPassword: "Neues Passwort generieren",
    },
    password: {
        managePassword: "Passwort verwalten",
        passwordEmpty: "Das Passwort fehlt.",
        createUserSuccess: "Der Benutzer wurde erstellt.",
        passwordMismatch: "Die Passwörter stimmen nicht überein.",
        changePasswordSuccess: "Das Passwort wurde geändert.",
        changePassword: "Passwort ändern",
        setPassword: "Passwort festlegen",
        oldPassword: "Altes Passwort",
        newPassword: "Neues Passwort",
        confirmNewPassword: "Neues Passwort wiederholen",
        submit: "Passwort ändern",
    },
    register: {
        title: "Wählen Sie Ihr Passwort",
        welcome: "Willkommen {{customerName}}.",
        yourStoreNumberIs: "Ihre Geschäftsnummer und Ihr Benutzername lauten:",
        emailLabel: "E-Mail zum Zurücksetzen des Passworts",
        submit: "Speichern",
        snackbarSuccessMessage: "Ihr neues Passwort wurde gespeichert.",
    },
};
