import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { H1 } from "../../../components/Typography";

const useStyles = makeStyles(theme => ({
    title: {
        textTransform: "uppercase",
    },
}));

export const CartTitle: FC<{ className?: string }> = ({ className }) => {
    const classes = useStyles();
    const { t } = useTranslation("order");

    return (
        <H1 primary className={cn(classes.title, className)} lunchBox>
            {t("cart.title")}
        </H1>
    );
};
