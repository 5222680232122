import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { Loader } from "./Loader";

const useStyles = makeStyles({
    loading: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
});

type Props = ButtonProps & { loading: boolean; delay?: number };

export const LoadingButton: FC<Props> = ({ loading, delay, disabled, children, ...props }) => {
    const classes = useStyles();

    return (
        <Button disabled={disabled || loading} {...props}>
            {children}
            <Loader className={classes.loading} size={20} show={loading} delay={delay ?? 0} />
        </Button>
    );
};
