import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { Endpoint } from "../../network/endpoints";
import { get, post, put } from "../../network/restClient";
import { translate } from "../../utils/translate";
import { RootState } from "../configureStore";
import { ClaimCode, ClaimCodeType } from "../types";
import { openSuccessDialog } from "./dialogsSlice";

export type ClaimOrderBody = {
    rows: Array<{
        productId: string;
        erpOrderNo: number;
    }>;
    claimCode: string;
    info: string;
    contactName: string;
    contactEmail: string;
};

export type ClaimOrderRowBody = {
    erpOrderNo: number;
    productId: string;
    claimCode: string;
    quantity: number;
    bestBefore?: string;
    batch?: string;
    boxBroken?: boolean;
    info?: string;
    image?: File;
    imageType?: string;
    contactName: string;
    contactEmail: string;
};

export const fetchClaimCodes = createAsyncThunk("fetch-claims", async () => {
    return get<ClaimCode[]>(Endpoint.claimCodes);
});

export const claim = createAsyncThunk(
    "claim/post",
    async (body: ClaimOrderBody | ClaimOrderRowBody, { dispatch }) => {
        let imageUploaded = false;
        if ("image" in body && body.image && body.imageType) {
            const claimImageUrl = await get<string>(Endpoint.claimImageUrl, {
                params: { erpOrderNo: body.erpOrderNo, productId: body.productId },
            });
            await put(claimImageUrl, body.image, {
                headers: { "Content-Type": body.imageType },
                requireAuth: false,
                withCredentials: false,
            });
            imageUploaded = true;
        }
        await post(Endpoint.claim, {
            ...body,
            image: undefined,
            imageType: undefined,
            imageUploaded,
        });
        dispatch(
            openSuccessDialog({
                title: translate("orderHistory.claim.success.title"),
                bodyText: [translate("orderHistory.claim.success.content")],
                bestRegardText: [
                    translate("orderHistory.claim.success.bestRegards"),
                    translate("orderHistory.claim.success.picadeliCustomerService"),
                ],
            }),
        );
    },
);

const adapter = createEntityAdapter<ClaimCode>({
    selectId: item => item.claimCodeId,
});

const slice = createSlice({
    name: "claim-codes",
    initialState: adapter.getInitialState<{
        claimCodesError?: string;
        loadingClaimCodes: boolean;
        claimError?: string;
        claimLoading: boolean;
    }>({
        loadingClaimCodes: true,
        claimLoading: false,
    }),
    reducers: {
        clearClaimError: state => {
            state.claimError = undefined;
        },
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchClaimCodes.fulfilled, (state, action) => {
            state.loadingClaimCodes = false;
            adapter.setAll(state, action.payload);
        });
        addCase(fetchClaimCodes.rejected, (state, { error }) => {
            state.loadingClaimCodes = false;
            state.claimCodesError = error.message;
        });

        addCase(claim.pending, (state, action) => {
            state.claimLoading = true;
            state.claimError = undefined;
        });
        addCase(claim.fulfilled, (state, action) => {
            state.claimLoading = false;
        });
        addCase(claim.rejected, (state, action) => {
            state.claimLoading = false;
            state.claimError = action.error.message;
            Sentry.captureMessage("Could not claim", {
                level: "warning",
                extra: {
                    error: action.error,
                    meta: action.meta,
                },
            });
        });
    },
});

export const { selectAll: selectClaimCodes } = adapter.getSelectors<RootState>(
    state => state.claimCodes,
);

export const selectOrderRowClaimCodes = createSelector(selectClaimCodes, codes => {
    const orderRowClaimCodes = codes.filter(c => c.type === ClaimCodeType.OrderRow);
    return {
        claimCodes: orderRowClaimCodes.filter(claimCode => !claimCode.categoryId),
        categoryClaimCodes: orderRowClaimCodes.filter(claimCode => claimCode.categoryId),
    };
});

export const selectOrderClaimCodes = createSelector(selectClaimCodes, codes =>
    codes.filter(c => c.type === ClaimCodeType.Order),
);

export const selectHasOrderClaimCodes = createSelector(
    selectOrderClaimCodes,
    codes => codes.length > 0,
);

export const { clearClaimError } = slice.actions;

export const selectClaimCodesLoading = (state: RootState) => state.claimCodes.loadingClaimCodes;
export const selectClaimCodesError = (state: RootState) => state.claimCodes.claimCodesError;

export const selectClaimLoading = (state: RootState) => state.claimCodes.claimLoading;
export const selectClaimError = (state: RootState) => state.claimCodes.claimError;

export const claimsReducer = slice.reducer;
