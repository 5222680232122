import { MyAccountResource } from "../locales";

export const myAccount: MyAccountResource = {
    accountSettings: "Account instellingen",
    addNewRecipient: "ANieuwe ontvanger toevoegen",
    alarmsAndNotifications: "Alarmen en meldingen",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Meenemen",
        saladBar: "Saladebar",
    },
    customerNo: "Klantnummer",
    customerUserIsMissing: "Er is geen gebruiker geregistreerd voor de klant",
    email: "E-mailadres",
    emailInvoice: "E-mailfactuur",
    foodAlarms: "Voedselalarmen",
    language: "Taal",
    missingContactPerson: "Ontbrekende contactpersoon",
    missingInformation: "Ontbrekende informatie",
    name: "Naam",
    orderConfirmations: "Bestelbevestigingen",
    passwordReset: "Wachtwoordherstel",
    passwordResetSentTo: "Wachtwoordherstel verzonden naar",
    phone: "Telefoonnummer",
    purchaseStats: "Aankoopstatistieken",
    recipient: "Ontvanger",
    responsibleSingular: "Verantwoordelijke",
    responsiblePlural: "Verantwoordelijke",
    storeManager: "Winkelbeheerder",
    storeManagerDescription:
        "Wordt gebruikt voor belangrijke aankondigingen, bijvoorbeeld prijswijzigingen.",
    updateContactInfo: {
        success: "Opgeslagen.",
        error: "Opslaan niet mogelijk.",
    },
    yourStore: "Uw winkel",
};
