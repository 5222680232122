import { CustomerSupportResource } from "../locales";

export const customerSupport: CustomerSupportResource = {
    title: "Velkommen til Picadeli kundeservice",
    contactUs: "Kontakt oss",
    openingHours: "Åpningstider",
    email: "E-post: {{value}}",
    phone: "Telefon: {{value}}",
    openingHoursStart: "Åpner kl: {{value}}",
    openingHoursEnd: "Stenger kl: {{value}}",
};
