import { AuthResource } from "../locales";

export const auth: AuthResource = {
    createUser: {
        title: "Gebruiker maken",
        submit: "Gebruiker maken",
        newPasswordIs: "Wachtwoord is:",
        emailLabel: "E-mail om wachtwoord te resetten",
    },
    common: {
        backToLoginBtn: "Terug naar aanmelden",
    },
    login: {
        title: "KLANTPORTAAL",
        description: "Meld u hieronder aan om uw artikelen rechtstreeks in de webshop te bestellen",
        forgotPasswordBtn: "Wachtwoord vergeten?",
        loginBtn: "Aanmelden",
        passwordLbl: "Wachtwoord",
        usernameLbl: "Klantnummer",
    },
    forgotPassword: {
        title: "Wachtwoord vergeten",
        resetPasswordSuccessNoContent:
            "Er is een link naar uw e-mailadres gestuurd waar u het wachtwoord kunt wijzigen. Als je geen e-mail hebt ontvangen, bel dan de klantenservice.",
        resetPasswordSuccessInfo:
            "Er is een link voor het opnieuw instellen van het wachtwoord verzonden naar {{email}}. Als u geen e-mail heeft ontvangen, belt u de klantenservice op {{customerSupportPhone}}.",
        customerIdLbl: "Klantnummer",
        submitBtn: "Stuur me een nieuwe!",
        description: "Voer uw e-mailadres in. U ontvangt een link om uw wachtwoord te herstellen.",
    },
    changePassword: {
        title: "Verander wachtwoord",
        description: "Voer een nieuw wachtwoord in om uw account bij te werken.",
        generatePasswordDescription:
            "Druk op de knop om de gebruiker bij te werken met een nieuw gegenereerd wachtwoord.",
        passwordHasBeenUpdated: "Wachtwoord is bijgewerkt",
        generateNewPassword: "Genereer een nieuw wachtwoord",
    },
    password: {
        managePassword: "Wachtwoord beheren",
        passwordEmpty: "Wachtwoord is leeg.",
        createUserSuccess: "Gebruiker is gemaakt.",
        passwordMismatch: "Wachtwoorden komen niet overeen.",
        changePasswordSuccess: "Het wachtwoord is gewijzigd.",
        changePassword: "Wachtwoord wijzigen",
        setPassword: "Wachtwoord instellen",
        oldPassword: "Oud wachtwoord",
        newPassword: "Nieuw wachtwoord",
        confirmNewPassword: "Nieuw wachtwoord bevestigen",
        submit: "Wachtwoord wijzigen",
    },
    register: {
        title: "Selecteer je wachtwoord",
        welcome: "Welkom {{customerName}}.",
        yourStoreNumberIs: "Uw winkelnummer en gebruikersnaam is:",
        emailLabel: "E-mail om wachtwoord te resetten",
        submit: "Opslaan",
        snackbarSuccessMessage: "Uw nieuwe wachtwoord is opgeslagen.",
    },
};
