import { withStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../components/TextField";
import { useDebounceState } from "../../hooks/useDebounce";

const StyledTextField = withStyles(theme => ({
    root: {
        marginLeft: "auto",
        fontSize: theme.typography.pxToRem(15),
        width: 250,
    },
}))(TextField);

type Props = {
    onChange: (searchStr: string) => void;
};

export const ProductSearch: FC<Props> = ({ onChange }) => {
    const { t } = useTranslation("order");
    const [value, setValue] = useDebounceState<string>("", {
        delay: 300,
        onDebounceChange: onChange,
    });

    function handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            onChange(value);
        }
    }

    return (
        <StyledTextField
            data-testid="product-search"
            onKeyDown={handleInputKeyDown}
            placeholder={t("searchProduct")}
            variant="outlined"
            value={value}
            onChange={setValue}
        />
    );
};
