import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    Dictionary,
    isAnyOf,
} from "@reduxjs/toolkit";
import { isLoggedInAsCustomer } from "../../network/authService";
import { Endpoint } from "../../network/endpoints";
import { get, post } from "../../network/restClient";
import { groupBy, isDefined, splitOut, uniqueBy } from "../../utils/array";
import { formatDate } from "../../utils/formatters";
import { history } from "../../utils/history";
import { translate } from "../../utils/translate";
import { RootState, ThunkApiConfig } from "../configureStore";
import {
    CartItem,
    CartProduct,
    DeliveryTermType,
    Product,
    ProductType,
    RequestStatus,
} from "../types";
import { selectClosedInfoEntities } from "./closedProductsSlice";
import { selectCustomer } from "./customerSlice";
import { selectAllDeliveryDates } from "./deliveryDatesSlice";
import { selectDeliveryTerms } from "./deliveryTermsSlice";
import { showSnackbar } from "./notificationSlice";
import { selectDeliveryDate, selectIsZeroPriceOrder } from "./orderDetailsSlice";
import { selectOrderMatProducts } from "./orderMatsSlice";
import { placeOrder, selectPlaceOrderLoading } from "./ordersSlice";
import { selectAllProducts, selectProductById, selectProductEntities } from "./productsSlice";
import { selectLocale } from "./userSlice";

type ThresholdInfo = {
    thresholdExceeded: boolean;
};

export type CartItemResponse = Array<{
    salesUnitQuantity: number;
    quantity: number;
    productType: ProductType;
    productId: string;
}>;

export const fetchCart = createAsyncThunk<{ cartItems: CartItem[] }, void, ThunkApiConfig>(
    "cart/fetch",
    async _ => {
        const cartItems = await get<CartItemResponse>(Endpoint.cart, {
            snackbar: { showServerError: true },
        }).then(fromDto);
        return { cartItems };
    },
);

export const changeProductInCart = createAsyncThunk<
    { cartItems: CartItem[] },
    { replacedProduct: CartItem; replacingProduct: CartItem },
    ThunkApiConfig
>("cart/change-product", async ({ replacedProduct, replacingProduct }, { getState }) => {
    const productEntities = selectProductEntities(getState());
    const newItems = await post<CartItemResponse>(
        Endpoint.cart,
        toDto([{ ...replacedProduct, salesUnitQuantity: 0 }, replacingProduct], productEntities),
    ).then(fromDto);

    return {
        cartItems: newItems,
    };
});

export const addProductsToCart = createAsyncThunk<
    void,
    Array<{ productId: string; quantity: number }>,
    ThunkApiConfig
>("cart/replace-cart", async (body, { dispatch }) => {
    try {
        await post(Endpoint.addToCart, body);
        await dispatch(fetchCart());
        dispatch(
            showSnackbar({
                variant: "success",
                message: translate("snackbar.addToCartSuccess"),
            }),
        );
        history.push("/order");
    } catch (e) {
        dispatch(
            showSnackbar({ variant: "error", message: translate("error.ohNoSomethingWentWrong") }),
        );
        throw e;
    }
});

export const addToCart = createAsyncThunk<
    { cartItems: CartItem[] } & ThresholdInfo,
    { cartItems: CartItem[]; scrollToCart?: boolean },
    ThunkApiConfig
>("cart/update", async ({ cartItems: newItems, scrollToCart }, { getState }) => {
    const productEntities = selectProductEntities(getState());
    const existingCartItems = selectCartEntities(getState());
    const mergedItems = newItems.map(item => ({
        ...item,
        salesUnitQuantity:
            item.salesUnitQuantity +
            (existingCartItems[cartEntityId(item)]?.salesUnitQuantity ?? 0),
    }));
    const updatedItems = await post<CartItemResponse>(
        Endpoint.cart,
        toDto(mergedItems, productEntities),
        {
            snackbar: { showServerError: true },
        },
    ).then(fromDto);
    const thresholdExceeded = mergedItems.some(({ productId, salesUnitQuantity }) => {
        const product = productEntities[productId];
        return (
            product !== undefined &&
            salesUnitQuantity >= product.warningThreshold * product.orderMultiple
        );
    });
    return { cartItems: updatedItems, thresholdExceeded };
});

export const updateCartItem = createAsyncThunk<
    { cartItems: CartItem[] } & ThresholdInfo,
    CartItem,
    ThunkApiConfig
>("cart/change-sales-quantity", async (change, { getState }) => {
    const productEntities = selectProductEntities(getState());
    const updatedItems = await post<CartItemResponse>(
        Endpoint.cart,
        toDto([change], productEntities),
        {
            snackbar: { showServerError: true },
        },
    ).then(fromDto);
    const product = selectProductById(getState(), change.productId);
    return {
        cartItems: updatedItems,
        thresholdExceeded:
            product !== undefined &&
            change.salesUnitQuantity >= product.warningThreshold * product.orderMultiple,
    };
});

export const removeItems = createAsyncThunk<{ cartItems: CartItem[] }, string[], ThunkApiConfig>(
    "cart/remove-items",
    async (entityIds, { getState }) => {
        const cartItems = selectCartItems(getState());
        const productEntities = selectProductEntities(getState());
        const updates = cartItems
            .filter(item => entityIds.includes(cartEntityId(item)))
            .map(item => ({ ...item, salesUnitQuantity: 0 }));
        const updatedItems = await post<CartItemResponse>(
            Endpoint.cart,
            toDto(updates, productEntities),
            {
                snackbar: { showServerError: true },
            },
        ).then(fromDto);
        return { cartItems: updatedItems };
    },
);

export const replaceWithOrderSuggestion = createAsyncThunk<void, string, ThunkApiConfig>(
    "cart/replaceWithOrderSuggestion",
    async (deliveryDate: string, { dispatch }) => {
        await post<CartItem[]>(
            Endpoint.replaceCartWithOrderSuggestion,
            { deliveryDate },
            { snackbar: { showServerError: true } },
        );
        void dispatch(fetchCart());
    },
);

const toDto = (items: CartItem[], productEntities: Dictionary<Product>) =>
    items
        .map(cartItem => {
            const product = productEntities[cartItem.productId];
            if (!product) {
                return null;
            }
            return {
                productType: cartItem.productType,
                productId: cartItem.productId,
                quantity: cartItem.salesUnitQuantity * product.orderMultiple,
            };
        })
        .filter(isDefined);

const fromDto = (response: CartItemResponse) =>
    response
        .map(dto => {
            return {
                productType: dto.productType,
                productId: dto.productId,
                salesUnitQuantity: dto.salesUnitQuantity,
            };
        })
        .filter(isDefined);

export const cartEntityAdapter = createEntityAdapter<CartItem>({
    selectId: cartEntityId,
    sortComparer: (a, b) => {
        if (a.productType !== b.productType) {
            return a.productType.localeCompare(b.productType);
        }
        return a.productId.localeCompare(b.productId);
    },
});

export function cartEntityId(entity: { productId: string; productType: ProductType }) {
    return `${entity.productId}${entity.productType}`;
}

const cartSlice = createSlice({
    name: "cart",
    initialState: cartEntityAdapter.getInitialState({
        replaceCartStatus: RequestStatus.Idle,
        addToCartStatus: RequestStatus.Idle,
        updateCartItemStatus: RequestStatus.Idle,
        scrollToCart: true,
    }),
    reducers: {},
    extraReducers: ({ addMatcher, addCase }) => {
        addCase(placeOrder.fulfilled, state => {
            cartEntityAdapter.setAll(state, []);
        });
        addCase(addProductsToCart.pending, state => {
            state.replaceCartStatus = RequestStatus.Loading;
        });
        addCase(addProductsToCart.fulfilled, state => {
            state.replaceCartStatus = RequestStatus.Fulfilled;
        });
        addCase(addProductsToCart.rejected, state => {
            state.replaceCartStatus = RequestStatus.Rejected;
        });
        addCase(replaceWithOrderSuggestion.pending, state => {
            state.replaceCartStatus = RequestStatus.Loading;
        });
        addCase(replaceWithOrderSuggestion.fulfilled, state => {
            state.replaceCartStatus = RequestStatus.Fulfilled;
        });
        addCase(replaceWithOrderSuggestion.rejected, state => {
            state.replaceCartStatus = RequestStatus.Rejected;
        });
        addCase(addToCart.fulfilled, state => {
            state.addToCartStatus = RequestStatus.Fulfilled;
        });
        addCase(addToCart.pending, (state, { meta }) => {
            state.addToCartStatus = RequestStatus.Loading;
            state.scrollToCart = meta.arg.scrollToCart ?? true;
        });
        addCase(addToCart.rejected, state => {
            state.addToCartStatus = RequestStatus.Rejected;
        });
        addCase(updateCartItem.fulfilled, state => {
            state.updateCartItemStatus = RequestStatus.Fulfilled;
        });
        addCase(updateCartItem.pending, state => {
            state.updateCartItemStatus = RequestStatus.Loading;
        });
        addCase(updateCartItem.rejected, (state, action) => {
            const cartItem = action.meta.arg;
            cartEntityAdapter.updateOne(state, { id: cartEntityId(cartItem), changes: cartItem });
            state.updateCartItemStatus = RequestStatus.Rejected;
        });
        addMatcher(
            isAnyOf(
                fetchCart.fulfilled,
                addToCart.fulfilled,
                updateCartItem.fulfilled,
                removeItems.fulfilled,
                changeProductInCart.fulfilled,
            ),
            (state, { payload: { cartItems } }) => {
                cartEntityAdapter.setAll(state, cartItems);
            },
        );
    },
});

export const cartReducer = cartSlice.reducer;

export const {
    selectAll: selectCartItems,
    selectById: selectCartItemById,
    selectEntities: selectCartEntities,
} = cartEntityAdapter.getSelectors<RootState>(state => state.cart);

export const selectAddToCartStatus = (state: RootState) => state.cart.addToCartStatus;
export const selectUpdateCartItemStatus = (state: RootState) => state.cart.updateCartItemStatus;

export const selectCartProducts = createSelector(
    selectProductEntities,
    selectClosedInfoEntities,
    selectCartItems,
    selectIsZeroPriceOrder,
    (selectIds, closedInfos, cartItems, isZeroPriceOrder) =>
        cartItems
            .map(cartItem => {
                const product = selectIds[cartItem.productId];
                if (!product) return undefined;
                return {
                    ...product,
                    closedInfo: closedInfos[product.productId]?.closedInfo,
                    price: isZeroPriceOrder ? 0 : product.price,
                    comparisonPrice: isZeroPriceOrder ? 0 : product.comparisonPrice,
                    salesUnitQuantity: cartItem.salesUnitQuantity,
                    type: cartItem.productType,
                };
            })
            .filter(isDefined),
);

export const selectExcludedNonFoodProducts = createSelector(selectCartProducts, cartProducts =>
    cartProducts.filter(product => !product.isNonFood),
);

export const selectCartProductsCount = createSelector(
    selectCartProducts,
    selectOrderMatProducts,
    (products, orderMatProducts) =>
        uniqueBy(products, p => p.productId).length +
        orderMatProducts.reduce((acc, cur) => (acc += cur.salesUnitQuantity), 0),
);

export const selectCost = createSelector(
    selectCartProducts,
    selectOrderMatProducts,
    (cartProducts, orderMatProducts) => {
        const toCostEntry = (list: CartProduct[]) => ({
            numProducts: list.length,
            cost: calcCost(list),
        });
        return {
            total: toCostEntry([...cartProducts, ...orderMatProducts]),
            frozen: toCostEntry(cartProducts.filter(p => p.isFrozen)),
            chilled: toCostEntry(cartProducts.filter(p => !p.isFrozen)),
        };
    },
);

export const selectVatCost = createSelector(selectCartProducts, cartProducts =>
    Object.entries(groupBy(cartProducts, product => product?.vat ?? 0))
        .map(([vatKey, products]) => {
            const percentage = parseFloat(vatKey);
            return {
                percentage,
                total: calcCost(products) * percentage,
            };
        })
        .sort((a, b) => a.percentage - b.percentage),
);

export const selectFrozenWeight = createSelector(selectExcludedNonFoodProducts, cartProducts => {
    const { rest: chilled, splitted: frozen } = splitOut(cartProducts, item => item.isFrozen);
    return { chilled: calcDrainedWeight(chilled), frozen: calcDrainedWeight(frozen) };
});

export const selectTotalWeight = createSelector(
    selectExcludedNonFoodProducts,
    selectOrderMatProducts,
    (cartProducts, orderMatProducts) =>
        calcDrainedWeight(cartProducts) + calcDrainedWeight(orderMatProducts),
);

export const selectProductTypesWeight = createSelector(
    selectExcludedNonFoodProducts,
    selectOrderMatProducts,
    (cartProducts, orderMatProducts) => {
        const products = {
            deli: cartProducts.filter(p => p.type === ProductType.Deli),
            saladBar: cartProducts.filter(p => p.type === ProductType.SaladBar),
            foodToGo: [
                ...cartProducts.filter(p => p.type === ProductType.FoodToGo),
                ...orderMatProducts,
            ],
        };
        return {
            [ProductType.Deli]: calcDrainedWeight(products.deli),
            [ProductType.SaladBar]: calcDrainedWeight(products.saladBar),
            [ProductType.FoodToGo]: calcDrainedWeight(products.foodToGo),
        };
    },
);

export const selectCartItemsAboveThreshold = createSelector(
    selectProductEntities,
    selectCartItems,
    (selectIds, cartItems) => {
        const products: Array<Product & { salesUnitQuantity: number }> = [];
        for (const item of cartItems) {
            const product = selectIds[item.productId];
            if (!product) {
                console.warn(`Missing product from cart: ${item.productId}`);
                continue;
            }
            if (item.salesUnitQuantity >= product.warningThreshold * product.orderMultiple) {
                products.push({ ...product, salesUnitQuantity: item.salesUnitQuantity });
            }
        }
        return products;
    },
);

export const selectClosedInfoProducts = createSelector(selectCartProducts, cartProducts =>
    cartProducts.filter(p => p.closedInfo !== undefined),
);

export const selectCartProductsByType = createSelector(
    selectAllProducts,
    selectCartProducts,
    (state: RootState) => state.products.selectedRotationProducts,
    selectLocale,
    (products, cartProducts, selectedRotationProducts, locale) => {
        return Object.entries(groupBy(cartProducts, item => item.type)).map(
            ([productType, cartItems]) => ({
                productType: productType as ProductType,
                products: cartItems.sort((a, b) => {
                    const productA =
                        getSelectedRotationProduct(
                            a.productId,
                            products,
                            selectedRotationProducts,
                        ) ?? a;
                    const productB =
                        getSelectedRotationProduct(
                            b.productId,
                            products,
                            selectedRotationProducts,
                        ) ?? b;
                    return productA.name.localeCompare(productB.name, locale);
                }),
            }),
        );
    },
);

export const selectCartDates = createSelector(
    selectDeliveryDate,
    selectAllDeliveryDates,
    selectLocale,
    selectCustomer,
    (selectedDate, deliveryDateList, locale, customer) => {
        if (!selectedDate) {
            return [];
        }
        const deliveryDates = deliveryDateList.map(d => d.deliveryDate);
        const selectedIndex = deliveryDates.indexOf(selectedDate);
        const maxOffset = 2;
        return deliveryDates
            .filter((_, index) => Math.abs(selectedIndex - index) <= maxOffset)
            .map(dateStr => ({
                dayOfMonth: formatDate(dateStr, locale, { day: "2-digit" }),
                dayOfWeek: formatDate(dateStr, locale, { weekday: "short" }),
                selected: dateStr === selectedDate,
            }));
    },
);

export const selectShippingInfo = createSelector(
    selectDeliveryTerms,
    selectCost,
    selectIsZeroPriceOrder,
    (deliveryTerms, cost, isZeroPriceOrder) =>
        deliveryTerms
            .map(deliveryTerm => {
                const costEntry = parseCostEntry(deliveryTerm.type, cost);
                if (costEntry === undefined) {
                    return undefined;
                }

                const orderValue = costEntry.cost + deliveryTerm.existingOrderAmount;
                const isFreeShipping = orderValue >= deliveryTerm.thresholdFree || isZeroPriceOrder;
                const isBelowMinThreshold =
                    orderValue < deliveryTerm.thresholdMin && !isZeroPriceOrder;

                let shippingCost: number | undefined;
                if (isBelowMinThreshold) {
                    shippingCost = undefined;
                } else if (isFreeShipping) {
                    shippingCost = 0;
                } else {
                    shippingCost = deliveryTerm.price;
                }

                return {
                    ...deliveryTerm,
                    isFreeShipping,
                    isBelowMinThreshold,
                    shippingCost,
                    orderValue,
                    hasProducts: costEntry.numProducts > 0,
                };
            })
            .filter(isDefined),
);

export const selectIsOrderBelowMinThreshold = createSelector(selectShippingInfo, shippingInfo =>
    shippingInfo.filter(info => info.hasProducts).some(info => info.isBelowMinThreshold),
);

export const selectPlaceOrderDisabled = createSelector(
    selectCustomer,
    selectPlaceOrderLoading,
    selectClosedInfoProducts,
    selectCartItems,
    selectIsOrderBelowMinThreshold,
    (customer, isPlacingOrder, closedInfoProducts, cartItems, isBelowMinThreshold) => {
        return (
            customer?.creditFreeze === true ||
            isPlacingOrder ||
            closedInfoProducts.length > 0 ||
            cartItems.length === 0 ||
            (isLoggedInAsCustomer() ? false : isBelowMinThreshold)
        );
    },
);

export const selectReplaceCartStatus = (state: RootState) => state.cart.replaceCartStatus;

export const selectScrollToCart = (state: RootState) => state.cart.scrollToCart;

function parseCostEntry(type: DeliveryTermType, cost: ReturnType<typeof selectCost>) {
    switch (type) {
        case DeliveryTermType.Chilled:
            return cost.chilled;
        case DeliveryTermType.Frozen:
            return cost.frozen;
        case DeliveryTermType.Total:
            return cost.total;
        default: {
            console.error("Missing handler for DeliveryTerm", type);
            return undefined;
        }
    }
}

function calcDrainedWeight(list: CartProduct[]) {
    return list.reduce(
        (sum, curr) => sum + curr.drainedWeight * curr.salesUnitQuantity * curr.orderMultiple,
        0,
    );
}

function calcCost(list: CartProduct[]) {
    return list.reduce(
        (sum, curr) => sum + (curr.price ?? 0) * curr.salesUnitQuantity * curr.orderMultiple,
        0,
    );
}

function getSelectedRotationProduct(
    productId: string,
    products: Product[],
    selectedRotationProducts: Record<string, string | undefined>,
) {
    const rotationProductId = Object.entries(selectedRotationProducts).find(
        ([_, rp]) => rp === productId,
    )?.[0];
    return products.find(p => p.productId === rotationProductId);
}
