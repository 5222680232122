import { Box, Collapse, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import cn from "classnames";
import { DateTime } from "luxon";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RotateButton } from "../../components/RotateButton";
import { colors } from "../../constants/colors";
import { useIsLoggedInAsCustomer } from "../../hooks/useIsLoggedInAsCustomer.ts";
import { useToggle } from "../../hooks/useToggle";
import { selectCurrency, selectCustomer } from "../../state/slices/customerSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { HistoricOrder } from "../../state/types";
import { sum } from "../../utils/array";
import { formatDate, formatDateTime, formatPrice, formatWeight } from "../../utils/formatters";
import { OrderTableDetails } from "./OrderTableDetails";

const useStyles = makeStyles(theme => ({
    tableRow: {
        "& > td": {
            borderBottom: "none",
        },
    },
    greyBg: {
        background: colors.darkWhite,
    },
    detailsCell: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    boldCell: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(20),
    },
    deliveryDate: {
        textTransform: "capitalize",
    },
    supervisedIcon: {
        marginLeft: 10,
    },
    reviewBtn: {
        fontSize: "1rem",
    },
}));

type Props = {
    order: HistoricOrder;
    isOddRow: boolean;
};

export const OrderTableRow: FC<Props> = ({ order, isOddRow }) => {
    const tableRowRef = useRef<HTMLTableRowElement>(null);
    const classes = useStyles();
    const locale = useSelector(selectLocale);
    const customer = useSelector(selectCustomer);
    const currency = useSelector(selectCurrency);
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const { t } = useTranslation("general");
    const openToggle = useToggle(false);

    const tableRowClasses = cn(classes.tableRow, { [classes.greyBg]: isOddRow });

    return (
        <>
            <TableRow ref={tableRowRef} className={tableRowClasses}>
                {isLoggedInAsCustomer && (
                    <TableCell padding="none" size="small">
                        {order.createdBy?.loggedInAsCustomer && (
                            <Tooltip title={order.createdBy?.displayName ?? ""}>
                                <SupervisedUserCircleIcon className={classes.supervisedIcon} />
                            </Tooltip>
                        )}
                    </TableCell>
                )}
                {customer?.groupOrdersOnDeliveryNoteId && (
                    <TableCell className={classes.boldCell}>
                        {order?.rows[0]?.deliveryNoteId ?? ""}
                    </TableCell>
                )}
                <TableCell className={classes.deliveryDate}>
                    {formatDate(order.deliveryDate, locale, {
                        day: "2-digit",
                        month: "2-digit",
                        weekday: "short",
                        year: "numeric",
                    })}
                </TableCell>
                <TableCell>
                    {formatDateTime(
                        order.timeCreated,
                        locale,
                        customer?.timeZone,
                        DateTime.DATETIME_SHORT,
                    )}
                </TableCell>
                <TableCell>{customer?.name}</TableCell>
                <TableCell>{formatWeight(order.totalWeight, locale)}</TableCell>
                <TableCell>
                    <i>{t(`orderStatus.${order.status}` as const)}</i>
                </TableCell>
                {customer?.showPrices && order.costs && (
                    <TableCell className={classes.boldCell} align="right">
                        {formatPrice(
                            sum(order.costs, item => item.costExVat),
                            currency,
                            locale,
                        )}
                    </TableCell>
                )}
                <TableCell align="right">
                    <RotateButton
                        className={classes.reviewBtn}
                        isOpen={openToggle.value}
                        onClick={openToggle.toggle}
                        openText={t("actions.review")}
                        closeText={t("actions.close")}
                    />
                </TableCell>
            </TableRow>
            <TableRow className={tableRowClasses}>
                <TableCell
                    className={classes.detailsCell}
                    colSpan={(tableRowRef.current?.children ?? []).length}
                >
                    <Collapse in={openToggle.value} mountOnEnter unmountOnExit>
                        <Box margin={1}>
                            <OrderTableDetails order={order} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
