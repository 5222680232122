import { SerializedError } from "@reduxjs/toolkit";

export abstract class NetworkError extends Error {}

export class CustomerIsMissingError extends NetworkError {
    public constructor() {
        super("Customer is missing");
    }
}

export class RequestCanceled extends NetworkError {
    public constructor() {
        super("Request was canceled");
    }
}

export enum ErrorCode {
    OldPassword = "old-password",
    EmailAlreadyExists = "email-already-exists",
}

export function localizedError(body: { message: string; errorCode?: ErrorCode }): SerializedError {
    return {
        name: "Localized error",
        message: body.message,
        code: body.errorCode,
    };
}
