import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body1 } from "../../../../components/Typography";
import { selectCartItemsAboveThreshold } from "../../../../state/slices/cartSlice";
import { Banner } from "./Banner";

export const QuantityWarningBanner: FC = () => {
    const { t } = useTranslation("order");
    const visible = useSelector(selectCartItemsAboveThreshold).length > 0;

    if (!visible) {
        return null;
    }

    return (
        <Banner severity="warning">
            <Body1>{t("banner")}</Body1>
        </Banner>
    );
};
