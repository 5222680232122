export const pdf = {
    fileName: {
        deliveryCalendar: "delivery-calendar.pdf",
        order: "order-{{orderNo}}.pdf",
        products: {
            "deli": "deli-products.pdf",
            "food-to-go": "food-to-go-products.pdf",
            "salad-bar": "salad-bar-products.pdf",
        },
        planogram: "planogram.pdf",
    },
};
