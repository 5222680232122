import { ReportsResource } from "../locales";

export const reports: ReportsResource = {
    pageTitle: "Raportit",
    links: {
        productTemperature: "Luo tuotelämpötilaraportti",
        inventory: "Luo varastoraportti",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Poista suodatin",
                submit: "Suodata",
            },
            label: {
                productName: "Tuotteen nimi",
                fromDate: "FAlkaen-pvm",
                toDate: "Päättyen-pvm",
            },
        },
        table: {
            header: {
                productName: "Tuotteen nimi",
                productNo: "Tuotenumero",
                batch: "Erä",
                time: "Aika",
                module: "Moduuli",
                checkedBy: "Tarkistanut",
                temperature: "Lämpötila",
            },
        },
        title: "Tuotteiden lämpötilat",
        spreadsheet: "product-temperatures.xlsx",
    },
};
