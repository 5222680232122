import { CustomerSupportResource } from "../locales";

export const customerSupport: CustomerSupportResource = {
    title: "Willkommen beim Picadeli-Kundendienst!",
    contactUs: "Kontakt",
    openingHours: "Öffnungszeiten",
    email: "E-Mail-Adresse: {{value}}",
    phone: "Telefonnummer: {{value}}",
    openingHoursStart: "Öffnet um: {{value}}",
    openingHoursEnd: "Schließt um: {{value}}",
};
