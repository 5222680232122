import { Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Files } from "../../components/Files";
import { H3 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { useAppSelector } from "../../state/configureStore";
import { selectAssetsGroupedByCategory } from "../../state/slices/assetsSlice";
import { Asset, AssetCategory } from "../../state/types";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    tab: {
        minWidth: 100,
    },
    posterPanel: {
        minHeight: 100,
    },
    files: {
        display: "flex",
        flexDirection: "column",
    },
});

type PosterTab = { label: string; assetCategory: AssetCategory };

export const Posters: FC<{ tabs: PosterTab[]; title: string; mainCategory: AssetCategory }> = ({
    tabs,
    mainCategory,
    title,
}) => {
    const [selectedTab, setTab] = useState<PosterTab | undefined>(undefined);
    const assets = useAppSelector(selectAssetsGroupedByCategory);
    const classes = useStyles();
    const activeTabs = useMemo(
        () => tabs.filter(tab => assets[tab.assetCategory] !== undefined),
        [tabs, assets],
    );
    const files = assets[mainCategory] ?? [];
    const activeTabIndex = activeTabs.findIndex(t => t.label === selectedTab?.label);

    useEffect(() => {
        if (selectedTab === undefined && activeTabs.length) {
            setTab(activeTabs[0]);
        }
    }, [activeTabs, selectedTab]);

    if (!activeTabs.length && !files.length) {
        return null;
    }

    return (
        <div className={classes.container}>
            <H3>{title}</H3>
            <Files assets={files} className={classes.files} />
            <Tabs
                value={activeTabIndex === -1 ? 0 : activeTabIndex}
                onChange={(ev, tabIdx: number) => {
                    setTab(activeTabs[tabIdx]);
                }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: colors.heroGreen,
                    },
                }}
            >
                {activeTabs.map(tab => (
                    <Tab key={tab.assetCategory} className={classes.tab} label={tab.label} />
                ))}
            </Tabs>
            {selectedTab && <PosterPanel assets={assets[selectedTab.assetCategory] ?? []} />}
        </div>
    );
};

const PosterPanel: FC<{ assets: Asset[] }> = ({ assets }) => {
    const classes = useStyles();
    return (
        <div className={classes.posterPanel}>
            <Files className={classes.files} assets={assets} />
        </div>
    );
};
