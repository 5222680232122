import { Box, Button, Divider, makeStyles } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Link } from "../../components/Link";
import { Loader } from "../../components/Loader";
import { Page } from "../../components/Page";
import { Body1 } from "../../components/Typography";
import { useAppDispatch } from "../../state/configureStore";
import { fetchClaimCodes } from "../../state/slices/claimCodesSlice";
import {
    fetchOrderHistory,
    selectHistoricOrders,
    selectIsLoadingOrderHistory,
    selectOrderHistoryError,
} from "../../state/slices/ordersSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { OrdersTable } from "./OrdersTable";

const useStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(3, 0),
    },
}));

export const OrderHistoryPage: FC = () => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation(["general", "orderHistory"]);
    const orders = useSelector(selectHistoricOrders);
    const isLoading = useSelector(selectIsLoadingOrderHistory);
    const error = useSelector(selectOrderHistoryError);
    const locale = useSelector(selectLocale);
    const [page, setPage] = useState(0);

    useEffect(() => {
        void dispatch(fetchClaimCodes());
        void dispatch(fetchOrderHistory({ page: 0 }));
    }, [dispatch, locale]);

    return (
        <Page>
            <OrdersTable orders={orders} />

            {error && <ErrorMessage error={{ body: error }} />}

            {!error && !isLoading && orders.length === 0 && (
                <Box display="flex" justifyContent="center">
                    <Body1>
                        <i>{t("orderHistory:thereIsNoOrderHistory")}</i>
                    </Body1>
                </Box>
            )}

            {isLoading ? (
                <Loader show display="flex" justifyContent="center" mb={6} mt={6} />
            ) : (
                <Box mb={6} mt={6} textAlign="center">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!isLoading && orders.length === 0}
                        onClick={() => {
                            void dispatch(fetchOrderHistory({ page: page + 1 }));
                            setPage(page + 1);
                        }}
                    >
                        {t("general:actions.loadMore")}
                    </Button>
                </Box>
            )}

            <Divider className={classes.divider} />
            <Link color="textPrimary" onClick={() => history.goBack()}>
                <small>« </small>
                {t("general:actions.back")}
            </Link>
        </Page>
    );
};
