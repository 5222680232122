import { MyAccountResource } from "../locales";

export const myAccount: MyAccountResource = {
    accountSettings: "Paramètres du compte",
    addNewRecipient: "Ajouter nouveau destinataire",
    alarmsAndNotifications: "Alarmes et notifications",
    customerCategory: {
        deli: "Deli",
        foodToGo: "Food To Go",
        saladBar: "Bar à salades",
    },
    customerNo: "Identifiant client",
    customerUserIsMissing: "Il n'y a pas d'utilisateur enregistré pour le client",
    email: "Email",
    emailInvoice: "Facture par email",
    foodAlarms: "Alerte sanitaire",
    missingContactPerson: "Personne de contact manquante",
    missingInformation: "Informations manquantes",
    name: "Nom",
    orderConfirmations: "Confirmations de commande",
    passwordReset: "Réinitialisation de mot de passe",
    passwordResetSentTo: "Réinitialisation du mot de passe envoyée à",
    phone: "Téléphone",
    purchaseStats: "Statistiques de ventes",
    recipient: "Récepteur",
    responsibleSingular: "Responsable",
    responsiblePlural: "Responsables",
    storeManager: "Exécutif",
    storeManagerDescription:
        "Utile pour les messages importants sur la relation commerciale, les changements de prix etc.",
    updateContactInfo: {
        success: "Enregistré avec succès.",
        error: "Impossible d'enregistrer.",
    },
    yourStore: "Votre magasin",
};
