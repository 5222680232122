import i18n from "i18next";
import { locales } from "../assets/locales/locales";

type DeepKeys<T, S extends string> = T extends unknown
    ? S extends `${infer I1}.${infer I2}`
        ? I1 extends keyof T
            ? `${I1}.${DeepKeys<T[I1], I2>}`
            : keyof T & string
        : S extends keyof T
        ? `${S}`
        : keyof T & string
    : "";

export const translate = <S extends string>(
    key: DeepKeys<typeof locales["en-US"], S>,
    options: Record<string, string | number> = {},
    fallback = "",
) => {
    try {
        return i18n.t(key.replace(".", ":"), fallback, options);
    } catch (e) {
        console.error(e);
        return fallback;
    }
};
