import { CustomerSupportResource } from "../locales";

export const customerSupport: CustomerSupportResource = {
    title: "Välkommen till Picadeli kundtjänst",
    contactUs: "Kontakta oss",
    openingHours: "Öppningstider",
    email: "Epost: {{value}}",
    phone: "Telefon: {{value}}",
    openingHoursStart: "Öppnar kl: {{value}}",
    openingHoursEnd: "Stänger kl: {{value}}",
};
