import React, { FC, FormEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
    formRef?: MutableRefObject<HTMLFormElement | null>;
};

export const Form: FC<Props> = ({ formRef, onSubmit, children, ...props }) => {
    const localRef = useRef<HTMLFormElement>(null);
    const ref = formRef ? formRef : localRef;
    const [showValidation, setShowValidationMessages] = useState(false);
    const { t } = useTranslation("form");

    useEffect(() => {
        if (showValidation) {
            validate();
        }
    });

    const validate = () => {
        if (!ref || !ref.current) return false;

        const formEl = ref.current;
        const formLength = formEl.length ?? 0;

        if (!formEl.checkValidity()) {
            for (let i = 0; i < formLength; i++) {
                const elem = formEl[i] as HTMLInputElement;
                const errorEl = document.getElementById(`${elem.id}-error`);

                if (!errorEl) {
                    continue;
                }

                if (!elem.validity.valid) {
                    let errorMsg = elem.validationMessage ?? "";

                    if (elem.validity.valueMissing) {
                        errorMsg = t("required");
                    } else if (elem.validity.rangeOverflow) {
                        errorMsg = t("rangeOverflow", { max: elem.max });
                    } else if (elem.validity.rangeUnderflow) {
                        errorMsg = t("rangeUnderflow", { min: elem.min });
                    } else if (elem.validity.typeMismatch && elem.type === "email") {
                        errorMsg = t("invalidEmail");
                    }

                    errorEl.textContent = errorMsg;
                    errorEl.classList.add("invalid");
                } else {
                    errorEl.textContent = "";
                    errorEl.classList.remove("invalid");
                }
            }

            return false;
        }

        for (let i = 0; i < formLength; i++) {
            const elem = formEl[i] as HTMLInputElement;
            const errorEl = document.getElementById(`${elem.id}-error`);
            if (errorEl) {
                errorEl.textContent = "";
                errorEl.classList.remove("invalid");
            }
        }

        return true;
    };

    const submitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setShowValidationMessages(true);

        if (!validate()) {
            return;
        }

        if (onSubmit) {
            onSubmit(event);
        }
    };

    const handleFormChange = () => {
        if (setShowValidationMessages) {
            setShowValidationMessages(false);
        }
    };

    return (
        <form ref={ref} onSubmit={submitHandler} onChange={handleFormChange} {...props} noValidate>
            {children}
        </form>
    );
};
