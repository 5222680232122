export const general = {
    actions: {
        review: "Review",
        close: "Close",
        back: "Back",
        ok: "OK",
        print: "Print",
        printArticleNumberScheme: "Print - sorted by article number",
        submit: "Submit",
        save: "Save",
        edit: "Edit",
        cancel: "Cancel",
        loadMore: "Load more",
    },
    articles: "Articles",
    edit: "Edit",
    navigationLinks: {
        createUser: "Create user",
        changePassword: "Change password",
        communication: "Communication",
        guidance: "Guidance",
        support: "Customer support",
        logout: "Log out",
        account: "Account",
        myAccount: "My account",
        order: "Order",
        orderHistory: "Order history",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "No time left",
        short: {
            days: "{{days}}d",
            hours: "{{hours}}h",
            minutes: "{{minutes}}m",
            seconds: "{{seconds}}s",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Order Received",
        "created": "Confirmed",
        "dispatched": "Picking",
        "canceled": "Canceled",
        "invoiced": "Invoiced",
    },
    price: {
        total: "Total",
        totalExclVat: "Total (Excluding Vat)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Food to Go",
        "salad-bar": "Salad Bar",
    },
    salesUnit: {
        kg: "Kg",
        krt: "Package",
        pcs: "Piece",
    },
    address: {
        invoice: "Invoice address",
        delivery: "Delivery address",
    },
    weight: "Weight",
    loggedInAsCustomer: "Logged in as customer",
    locales: {
        "da-DK": "Dansk",
        "de-DE": "Deutsch",
        "en-US": "English US",
        "fi-FI": "Suomi",
        "fr-FR": "Français",
        "nb-NO": "Norsk",
        "nl-BE": "Nederlands (België)",
        "sv-SE": "Svenska",
    },
};
