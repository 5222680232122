import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SnackbarProvider as NotistackProvider, SnackbarProviderProps } from "notistack";
import React from "react";
import { colors } from "../../constants/colors";

export const SnackbarProvider = withStyles(theme =>
    createStyles({
        contentRoot: {
            fontWeight: "bold",
            maxWidth: 400,
        },
        variantError: {
            backgroundColor: `${colors.attentionRed} !important`,
        },
        variantSuccess: {
            backgroundColor: `${colors.heroGreenDark} !important`,
        },
        variantInfo: {
            backgroundColor: `${colors.blue} !important`,
        },
        variantWarning: {
            backgroundColor: `${colors.attentionRed} !important`,
        },
    }),
)((props: SnackbarProviderProps) => <NotistackProvider {...props} />);
