import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectShippingInfo } from "../../../../state/slices/cartSlice";
import { selectCurrency } from "../../../../state/slices/customerSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatPrice } from "../../../../utils/formatters";
import { capitalizeFirstLetter } from "../../../../utils/string";
import { CartSmallText } from "../CartSmallText";

export const ShippingTotal = () => {
    const { t } = useTranslation("order");
    const currency = useSelector(selectCurrency);
    const shippingInfo = useSelector(selectShippingInfo);
    const userLocale = useSelector(selectLocale);

    if (!shippingInfo.length) {
        return null;
    }

    return (
        <Box>
            <CartSmallText>
                {shippingInfo.map((info, index) => (
                    <React.Fragment key={`${info.type}-title`}>
                        {capitalizeFirstLetter(t(`cart.shipping.total.${info.type}` as const))}
                        {index < shippingInfo.length - 1 && " / "}
                    </React.Fragment>
                ))}
            </CartSmallText>
            <CartSmallText bold>
                {shippingInfo.map((info, index) => (
                    <React.Fragment key={`${info.type}-cost`}>
                        {info.shippingCost !== undefined
                            ? formatPrice(info.shippingCost, currency, userLocale)
                            : "-"}
                        {index < shippingInfo.length - 1 && " / "}
                    </React.Fragment>
                ))}
            </CartSmallText>
        </Box>
    );
};
