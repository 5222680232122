export function groupBy<T>(list: T[], key: (item: T) => string | number) {
    return list.reduce((map, curr) => {
        const id = key(curr);
        if (!map[id]) {
            map[id] = [];
        }
        map[id].push(curr);
        return map;
    }, {} as Record<string | number, T[]>);
}

export function uniqueBy<T>(list: T[], key: (item: T) => string | number): T[] {
    return list.reduce<T[]>(
        (uniqueList, item) =>
            uniqueList.find(u => key(u) === key(item)) ? uniqueList : [...uniqueList, item],
        [],
    );
}

export function unique<T>(list: T[]): T[] {
    return Array.from(new Set(list));
}

export function notEmpty<T>(list: T[]) {
    return list.length > 0;
}

export function isDefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}

export function splitOut<T>(
    list: T[],
    predicate: (item: T) => boolean,
): { splitted: T[]; rest: T[] } {
    return list.reduce<{ splitted: T[]; rest: T[] }>(
        (acc, item) => {
            predicate(item) ? acc.splitted.push(item) : acc.rest.push(item);
            return acc;
        },
        { splitted: [], rest: [] },
    );
}

export const removeEmptyStrings = (object: Record<string, string>) =>
    Object.entries(object)
        .filter(([key, value]) => value.length > 0)
        .reduce((map, [key, value]) => ({ ...map, [key]: value }), {});

export function sum<T>(list: T[], key: (item: T) => number) {
    return list.reduce((acc, item) => acc + key(item), 0);
}
