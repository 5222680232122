import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { downloadFile } from "../../utils/blob";
import { formatDateTime } from "../../utils/formatters";
import { RootState, ThunkApiConfig } from "../configureStore";
import { RequestStatus } from "../types";
import { selectCustomer } from "./customerSlice";

export const generateInventoryReport = createAsyncThunk<void, void, ThunkApiConfig>(
    "reports/generate-inventory-report",
    async (_, { getState }) => {
        const blob = await get<Blob>(Endpoint.inventoryReport, {
            responseType: "blob",
            snackbar: { showServerError: true },
        });
        const customer = selectCustomer(getState());
        if (customer) {
            downloadFile(
                blob,
                `${customer.customerId}_inventory_${formatDateTime(
                    new Date().toISOString(),
                    customer.locale.primary,
                    customer.timeZone,
                    DateTime.DATE_SHORT,
                )}.xlsx`,
            );
        }
    },
);

type State = {
    inventoryRequest: RequestStatus;
};

const initialState: State = {
    inventoryRequest: RequestStatus.Idle,
};

const reportSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(generateInventoryReport.pending, state => {
            state.inventoryRequest = RequestStatus.Loading;
        });
        addCase(generateInventoryReport.fulfilled, state => {
            state.inventoryRequest = RequestStatus.Fulfilled;
        });
        addCase(generateInventoryReport.rejected, state => {
            state.inventoryRequest = RequestStatus.Rejected;
        });
    },
});

export const reportsReducer = reportSlice.reducer;

export const selectInventoryRequest = (state: RootState) => state.reports.inventoryRequest;
