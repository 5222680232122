import { Locale } from "../assets/locales/locales";
import { polyfill as polyfillDateTime } from "./dateTime";
import { polyfill as polyfillNumberFormat } from "./numberFormat";
import { polyfill as pluralRules } from "./pluralRules";

export async function polyfillLanguage(locale: Locale) {
    await pluralRules(locale);
    await polyfillNumberFormat(locale);
    await polyfillDateTime(locale);
}
