import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { DateTime } from "luxon";
import { ComponentType, FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FullscreenImage } from "../../../components/FullscreenImage";
import { Body1 } from "../../../components/Typography";
import { colors } from "../../../constants/colors";
import { useDialog } from "../../../hooks/useDialog";
import { selectCustomer } from "../../../state/slices/customerSlice";
import { selectLocale } from "../../../state/slices/userSlice";
import { Product, ProductType } from "../../../state/types";
import { formatDate } from "../../../utils/formatters";
import { ProductTableRow } from "./ProductTableRow";

const useStyles = makeStyles(theme => ({
    container: {
        "marginTop": theme.spacing(2),
        "& + $container": {
            marginTop: theme.spacing(4),
        },
    },
    table: {
        lineHeight: 24,
    },
    th: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textTransform: "uppercase",
        fontSize: 14,
        lineHeight: "1rem",
        backgroundColor: colors.darkWhite,
    },
    iconCell: {
        width: 24,
    },
    productPdf: {
        width: 24,
    },
    productImg: {
        width: 75,
    },
    changeProduct: {
        width: 50,
    },
    productName: {
        width: "24%",
    },
    unit: {
        width: "9%",
    },
    quantity: {
        width: "16%",
        textAlign: "center",
    },
    articleNo: {
        width: 90,
    },
    icons: {
        width: 100,
    },
    delete: {
        width: 40,
    },
    title: {
        color: colors.white,
        backgroundColor: colors.heroGreen,
        padding: theme.spacing(1.5, 2),
        textTransform: "uppercase",
    },
    transitHeader: {
        whiteSpace: "nowrap",
    },
    transitDate: {
        whiteSpace: "nowrap",
        fontSize: 12,
    },
}));

type Props = {
    products: Product[];
    title?: string;
    onDelete?: (productId: string) => void;
    productType: ProductType;
    QuantityComponent: ComponentType<{ productId: string; productType: ProductType }>;
    collapsed?: {
        colSpan: number;
        Component: ComponentType<{ productId: string; productType: ProductType }>;
    };
    WarningComponent?: ComponentType<{ productId: string; productType: ProductType }>;
    customColumn?: {
        title: string;
        CellComponent: ComponentType<{ productId: string; productType: ProductType }>;
    };
    alternateBgColors?: boolean;
    showFavorite?: boolean;
    showProductPdfDownload?: boolean;
    hideComparePriceCell?: boolean;
    hideUnitCell?: boolean;
    qtyHeader?: string;
    headerTexts?: {
        quantity?: string;
        pricePerUnit?: string;
    };
    showChangeProductButton?: boolean;
    selectProductButton?: string;
    showSelectProductButtonIcon?: boolean;
    onChangeProduct?: (productId: string) => void;
    allowReplacingCartItem?: boolean;
    disabledImageDialog?: boolean;
    upcomingDeliveryDates?: string[];
};

export const ProductTable: FC<Props> = ({
    title,
    products,
    productType,
    onDelete,
    QuantityComponent,
    collapsed,
    customColumn,
    WarningComponent,
    alternateBgColors,
    showFavorite,
    showProductPdfDownload,
    hideComparePriceCell = false,
    hideUnitCell = false,
    headerTexts,
    showChangeProductButton,
    selectProductButton,
    showSelectProductButtonIcon,
    onChangeProduct,
    allowReplacingCartItem,
    disabledImageDialog,
    upcomingDeliveryDates = [],
}) => {
    const { t } = useTranslation("order");
    const classes = useStyles();
    const locale = useSelector(selectLocale);
    const customer = useSelector(selectCustomer);
    const imageDialog = useDialog<{
        src: string;
        imageWidth: number;
        imageHeight: number;
        alt: string;
    }>();

    const isNonFood = products.every(row => row.isNonFood);

    return (
        <div className={classes.container}>
            <FullscreenImage
                close={imageDialog.close}
                isOpen={imageDialog.isOpen}
                img={imageDialog.data}
            />
            {title !== undefined && <Body1 className={classes.title}>{title}</Body1>}
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            size="small"
                            component="th"
                            padding="none"
                            className={cn(classes.th, classes.iconCell)}
                        >
                            {/* warning */}
                            {/* favorite */}
                        </TableCell>
                        <TableCell
                            className={cn(classes.th, classes.articleNo)}
                            size="small"
                            padding="none"
                            align="center"
                            component="th"
                        >
                            {t("productTable.articleNo")}
                        </TableCell>
                        {showProductPdfDownload && (
                            <TableCell
                                className={cn(classes.th, classes.iconCell)}
                                size="small"
                                padding="none"
                                component="th"
                            >
                                {/* Product PDFs */}
                            </TableCell>
                        )}
                        <TableCell
                            className={cn(classes.th, classes.productImg)}
                            component="th"
                            size="small"
                        >
                            {/* productImage */}
                        </TableCell>
                        {showChangeProductButton === true && (
                            <TableCell
                                className={cn(classes.th, classes.changeProduct)}
                                component="th"
                                size="small"
                            >
                                {/* Change product */}
                            </TableCell>
                        )}
                        <TableCell className={cn(classes.th)} component="th">
                            {t("productTable.name")}
                        </TableCell>
                        <TableCell
                            className={cn(classes.th, classes.icons)}
                            component="th"
                            align="right"
                            size="small"
                        >
                            {/* icons */}
                        </TableCell>
                        {!hideUnitCell && (
                            <TableCell className={cn(classes.th, classes.unit)} component="th">
                                {t("productTable.unit")}
                            </TableCell>
                        )}
                        {customer?.showPrices && (
                            <TableCell className={cn(classes.th, classes.unit)} component="th">
                                {headerTexts?.pricePerUnit ?? t("productTable.pricePerUnit")}
                            </TableCell>
                        )}
                        {!hideComparePriceCell && customer?.showPrices && (
                            <TableCell className={cn(classes.th, classes.unit)} component="th">
                                {isNonFood
                                    ? t("productTable.comparisonPriceNonFood")
                                    : t("productTable.comparisonPrice")}
                            </TableCell>
                        )}
                        {upcomingDeliveryDates.map(deliveryDate => (
                            <TableCell
                                key={deliveryDate}
                                className={cn(classes.th)}
                                align="center"
                                component="th"
                            >
                                <span className={classes.transitHeader}>
                                    {t("productTable.onTheWay")}
                                </span>{" "}
                                <br />
                                <span className={classes.transitDate}>
                                    {formatDate(deliveryDate, locale, DateTime.DATE_SHORT)}
                                </span>
                            </TableCell>
                        ))}
                        {customColumn && (
                            <TableCell className={cn(classes.th, classes.unit)} component="th">
                                {customColumn.title}
                            </TableCell>
                        )}
                        {!selectProductButton && (
                            <TableCell className={cn(classes.th, classes.quantity)} component="th">
                                {/* quantity */}
                                {headerTexts?.quantity}
                            </TableCell>
                        )}
                        {onDelete !== undefined && (
                            <TableCell
                                className={cn(classes.th, classes.delete)}
                                component="th"
                            ></TableCell>
                        )}
                        {selectProductButton !== undefined && (
                            <TableCell className={classes.th} component="th">
                                {/* Select rotation product button */}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product, index) => {
                        const rowId = `product-table-row-${product.productId}-${productType}-${title}`;
                        return (
                            <ProductTableRow
                                key={rowId}
                                rowId={rowId}
                                showFavorite={showFavorite}
                                product={product}
                                productType={productType}
                                QuantityComponent={QuantityComponent}
                                collapsed={collapsed}
                                WarningComponent={WarningComponent}
                                CustomColumnCellComponent={customColumn?.CellComponent}
                                hideComparePriceCell={hideComparePriceCell}
                                hideUnitCell={hideUnitCell}
                                onDelete={onDelete}
                                showBackgroundTint={alternateBgColors && index % 2 === 1}
                                showProductPdfDownload={showProductPdfDownload}
                                onImageClick={
                                    !disabledImageDialog ? imageDialog.setData : undefined
                                }
                                showChangeProductButton={showChangeProductButton}
                                selectProductButton={selectProductButton}
                                showSelectProductButtonIcon={showSelectProductButtonIcon}
                                allowReplacingCartItem={allowReplacingCartItem}
                                onChangeProduct={onChangeProduct}
                                upcomingDeliveryDates={upcomingDeliveryDates}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};
