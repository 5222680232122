import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { addToCart, updateCartItem } from "./cartSlice";
import { fetchDeliveryDates } from "./deliveryDatesSlice";

type SuccessDialog = {
    title: string;
    bodyText: string[];
    bestRegardText: string[];
};

type State = {
    thresholdWarningIsOpen: boolean;
    deliveryInfoDialog: {
        canClose: boolean;
        isOpen: boolean;
        missingDeliveryCalendar: boolean;
    };
    successDialog?: SuccessDialog;
};

const initialState: State = {
    thresholdWarningIsOpen: false,
    deliveryInfoDialog: {
        canClose: false,
        isOpen: false,
        missingDeliveryCalendar: false,
    },
    successDialog: undefined,
};

const dialogsSlice = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        closeThresholdWarningDialog: state => {
            state.thresholdWarningIsOpen = false;
        },
        openDeliveryInfoDialog: (state, action: PayloadAction<{ canClose: boolean }>) => {
            state.deliveryInfoDialog.isOpen = true;
            state.deliveryInfoDialog.canClose = action.payload.canClose;
        },
        closeDeliveryInfoDialog: state => {
            state.deliveryInfoDialog.isOpen = false;
        },
        openSuccessDialog: (state, action: PayloadAction<SuccessDialog>) => {
            state.successDialog = action.payload;
        },
        closeSuccessDialog: state => {
            state.successDialog = undefined;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(addToCart.fulfilled, (state, { payload: { thresholdExceeded } }) => {
            state.thresholdWarningIsOpen = thresholdExceeded;
        });
        addCase(updateCartItem.fulfilled, (state, { payload: { thresholdExceeded } }) => {
            state.thresholdWarningIsOpen = thresholdExceeded;
        });
        addCase(fetchDeliveryDates.fulfilled, (state, { payload: deliveryDates }) => {
            state.deliveryInfoDialog.missingDeliveryCalendar = deliveryDates.length === 0;
        });
    },
});

export const {
    closeThresholdWarningDialog,
    openDeliveryInfoDialog,
    openSuccessDialog,
    closeSuccessDialog,
    closeDeliveryInfoDialog,
} = dialogsSlice.actions;

export const dialogReducer = dialogsSlice.reducer;

export const thresholdWarningIsOpen = (state: RootState) => state.dialog.thresholdWarningIsOpen;

export const selectDeliveryInfoDialog = (state: RootState) => state.dialog.deliveryInfoDialog;

export const selectSuccessDialog = (state: RootState) => state.dialog.successDialog;
