import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { File } from "../../components/File";
import { FlexWrap } from "../../components/FlexWrap";
import { Body2, H3 } from "../../components/Typography";
import { Videos } from "../../components/Videos";
import { useAppSelector } from "../../state/configureStore";
import { selectAssetsGroupedByCategory } from "../../state/slices/assetsSlice";
import { Asset, AssetCategory } from "../../state/types";

const useStyles = makeStyles(theme => ({
    container: {
        "& + $container": {
            marginTop: theme.spacing(2),
        },
    },
    image: {
        width: "100%",
    },
    assetName: {
        textAlign: "center",
    },
}));

type Props = { title: string; assetCategory: AssetCategory };

export const CommunicationAssets: FC<Props> = ({ title, assetCategory }) => {
    const assets = useAppSelector(selectAssetsGroupedByCategory)[assetCategory] ?? [];
    const videos = assets.filter(asset => asset.type === "video");
    const images = assets.filter(asset => asset.type === "image");
    const files = assets.filter(asset => asset.type === "file" || asset.type === "audio");
    const classes = useStyles();
    if (assets.length === 0) {
        return null;
    }
    return (
        <div className={classes.container}>
            <H3>{title}</H3>
            <Videos videosPerRow={3} assets={videos} />
            <FlexWrap
                items={images}
                itemsPerRow={4}
                rowKey={(asset: Asset) => asset.assetId.toString()}
                renderRow={(asset: Asset) => (
                    <>
                        <img alt={asset.name} className={classes.image} src={asset.url} />
                        {asset.name && (
                            <Body2 className={classes.assetName} italic>
                                {asset.name}
                            </Body2>
                        )}
                    </>
                )}
                spacing={10}
            />
            <FlexWrap
                items={files}
                itemsPerRow={3}
                rowKey={(asset: Asset) => asset.assetId.toString()}
                renderRow={(asset: Asset) => <File asset={asset} />}
                spacing={10}
            />
        </div>
    );
};
