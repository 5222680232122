import { Button } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../state/configureStore";
import { selectClosedInfoEntities } from "../../../../state/slices/closedProductsSlice";
import { OrderSuggestion, selectOrderSuggestions } from "../../../../state/slices/productMenuSlice";

type Props = {
    onAddToCart: (orderSuggestions: OrderSuggestion[]) => void;
};

export const AddOrderSuggestionToCartButton: FC<Props> = ({ onAddToCart }) => {
    const { t } = useTranslation(["order"]);
    const orderSuggestions = useAppSelector(selectOrderSuggestions);
    const closedInfoMap = useAppSelector(selectClosedInfoEntities);
    const validOrderSuggestions = orderSuggestions
        .filter(row => closedInfoMap[row.product.productId] === undefined)
        .filter(row => row.salesUnitQuantity > 0);

    return (
        <Button
            disabled={validOrderSuggestions.length === 0}
            color="primary"
            onClick={() => onAddToCart(validOrderSuggestions)}
            variant="contained"
        >
            {t("order:addProductsToCart")}
        </Button>
    );
};
