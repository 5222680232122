import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { H2, Overline } from "../Typography";

const useStyles = makeStyles({
    closeBtn: {
        color: "#000",
        transform: "translate(12px, -12px)",
        marginLeft: "auto",
    },
    overline: {
        display: "block",
        textTransform: "none",
        lineHeight: "17px",
        opacity: 0.6,
        marginBottom: 2,
    },
    title: {
        fontSize: 20,
        lineHeight: "24px",
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
    },
});

export const ModalHeader: FC<{ title: string; overline?: string; close: () => void }> = ({
    title,
    overline,
    close,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            <div>
                {overline && <Overline className={classes.overline}>{overline}</Overline>}
                <H2 className={classes.title}>{title}</H2>
            </div>
            <IconButton className={classes.closeBtn} onClick={close}>
                <CloseIcon />
            </IconButton>
        </div>
    );
};
