import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../state/configureStore";
import {
    addFavorite,
    removeFavorite,
    selectFavoriteById,
} from "../../../state/slices/favoritesSlice";

const useStyles = makeStyles(theme => ({
    btn: {
        margin: 5,
    },
}));

export const FavoriteButton: FC<{ productId: string }> = ({ productId }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const favorite = useAppSelector(state => selectFavoriteById(state, productId));
    return (
        <IconButton
            tabIndex={-1}
            className={classes.btn}
            data-testid={`favorite-btn-${productId}`}
            onClick={ev => {
                ev.stopPropagation();
                if (favorite !== undefined) {
                    void dispatch(removeFavorite(productId));
                } else {
                    void dispatch(addFavorite(productId));
                }
            }}
        >
            {favorite !== undefined ? <StarIcon /> : <StarOutlineIcon />}
        </IconButton>
    );
};
