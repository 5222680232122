import { Button, makeStyles } from "@material-ui/core";
import cn from "classnames";
import React, { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "../../components/form/InputForm";
import { useConfirmPasswordField } from "../../hooks/form/useConfirmPasswordField";
import { useNewPasswordField } from "../../hooks/form/useNewPasswordField";
import { useAppDispatch } from "../../state/configureStore";
import { changePasswordWithToken, selectUserState } from "../../state/slices/userSlice";
import { AuthInput } from "./AuthInput";
import { AuthPage } from "./AuthPage";
import { AuthSubmitButton } from "./AuthSubmitButton";

const useStyles = makeStyles(theme => ({
    btn: {
        minWidth: 260,
        alignSelf: "center",
    },
    sendBtn: {
        marginTop: theme.spacing(3),
    },
    backToLoginBtn: {
        marginTop: "1em",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
}));

export const ChangePasswordPage: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { changePasswordLoading } = useSelector(selectUserState);
    const token = decodeURIComponent(useParams<{ token: string }>().token);
    const { t } = useTranslation("auth");

    const newPasswordField = useNewPasswordField();
    const confirmPasswordField = useConfirmPasswordField(newPasswordField.value);

    const handleChangePassword = (ev: FormEvent) => {
        ev.preventDefault();
        void dispatch(changePasswordWithToken({ token, newPassword: newPasswordField.value }));
    };

    return (
        <AuthPage title={t("changePassword.title")} description={t("changePassword.description")}>
            <Form className={classes.form} onSubmit={handleChangePassword}>
                <AuthInput
                    autoFocus
                    label={t("password.newPassword")}
                    value={newPasswordField.value}
                    onChange={newPasswordField.onChange}
                    type="password"
                    autoComplete="newPassword"
                    errorMessage={newPasswordField.error}
                />
                <AuthInput
                    label={t("password.confirmNewPassword")}
                    value={confirmPasswordField.value}
                    onChange={confirmPasswordField.onChange}
                    type="password"
                    errorMessage={confirmPasswordField.error}
                />
                <AuthSubmitButton
                    className={cn(classes.btn, classes.sendBtn)}
                    loading={changePasswordLoading}
                    disabled={
                        Boolean(newPasswordField.error) ||
                        Boolean(confirmPasswordField.error) ||
                        newPasswordField.value.length === 0 ||
                        confirmPasswordField.value.length === 0
                    }
                >
                    {t("password.submit")}
                </AuthSubmitButton>

                <Button
                    className={cn(classes.btn, classes.backToLoginBtn)}
                    onClick={() => history.replace("/login")}
                >
                    {t("common.backToLoginBtn")}
                </Button>
            </Form>
        </AuthPage>
    );
};
