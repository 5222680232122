import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Body1, H1 } from "../../components/Typography";
import { closeSuccessDialog, selectSuccessDialog } from "../../state/slices/dialogsSlice";

export const SuccessDialog = () => {
    const { t } = useTranslation(["general"]);
    const dispatch = useDispatch();
    const dialog = useSelector(selectSuccessDialog);

    return (
        <Dialog open={dialog !== undefined}>
            <DialogTitle disableTypography>
                <H1 primary>{dialog?.title}</H1>
            </DialogTitle>
            <DialogContent>
                {dialog?.bodyText.map((row, index) => (
                    <Body1 key={index}>{row}</Body1>
                ))}

                <Box mt={2}>
                    {dialog?.bestRegardText.map((row, index) => (
                        <Body1 key={index} primary>
                            {row}
                        </Body1>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => dispatch(closeSuccessDialog())}
                    variant="contained"
                >
                    {t("general:actions.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
