import { GuidanceResource } from "../locales";

export const guidance: GuidanceResource = {
    campaignMaterial: "Matériel de campagne",
    guidance: "Guides & Routines",
    planogram: "Planogramme",
    currentPlanogram: "Planogramme actuel",
    futurePlanogram: "Futurs planogrammes {{start}}",
    labels: "Affichage",
    deviationAndClaims: "Formulaires",
    risks: "Documents contractuels",
    certificates: "Certificats conformite",
};
