import React from "react";
import { useSelector } from "react-redux";
import { Route as ReactRouterDomRoute, RouteProps } from "react-router";
import { Redirect } from "react-router-dom";
import { selectIsLoggedIn } from "./state/slices/userSlice";

export const AuthenticatedRoute = (props: RouteProps) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }
    return <ReactRouterDomRoute {...props} />;
};

export const UnauthenticatedRoute = (props: RouteProps) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    if (isLoggedIn) {
        return <Redirect to="/" />;
    }
    return <ReactRouterDomRoute {...props} />;
};
