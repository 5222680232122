import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { CartTitle } from "./CartTitle";
import { HeaderColumn } from "./HeaderColumn";

type CartHeaderProps = {
    classes?: {
        title: string;
    };
};

export const CartHeader: FC<CartHeaderProps> = ({ classes, children }) => (
    <Grid item container wrap="nowrap" data-testid="cart">
        <HeaderColumn>
            <CartTitle className={classes?.title} />
        </HeaderColumn>

        {children}
    </Grid>
);
