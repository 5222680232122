import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Loader } from "../../../components/Loader";
import { LoadingIconButton } from "../../../components/LoadIconButton";
import { Body1 } from "../../../components/Typography";
import { colors } from "../../../constants/colors";
import { useAppDispatch } from "../../../state/configureStore";
import { selectAssortmentsLoading } from "../../../state/slices/assortmentsSlice";
import { selectDeliveryDatesLoading } from "../../../state/slices/deliveryDatesSlice";
import {
    deliveryCalendarPDF,
    selectDeliveryCalendarPdfLoading,
} from "../../../state/slices/pdfSlice";
import { DeliveryDate } from "../../../state/types";
import { DeliveryDateRow } from "./DeliveryDateRow";

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        fontSize: theme.typography.pxToRem(14),
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
    },
    th: {
        height: "100%",
        color: theme.palette.primary.contrastText,
        textTransform: "none",
        fontWeight: "bold",
    },
    line: {
        flex: 1,
        height: 1,
        border: `1px solid ${colors.darkGrey}`,
    },
    tableBody: {
        "&:hover": {
            backgroundColor: colors.heroGreenExtraLight,
        },
    },
    printIcon: {
        color: colors.white,
    },
    loadingIconButton: {
        marginLeft: 20,
    },
}));

type Props = {
    onSelectDate: (dateStr: string) => void;
    deliveryDates: DeliveryDate[];
};

export const DeliveryDatesTable: FC<Props> = ({ onSelectDate, deliveryDates }) => {
    const { t } = useTranslation("order");
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const deliveryCalendarPdfLoading = useSelector(selectDeliveryCalendarPdfLoading);
    const loadingDeliveryDates = useSelector(selectDeliveryDatesLoading);
    const loadingAssortments = useSelector(selectAssortmentsLoading);
    const now = new Date();

    if ((loadingDeliveryDates && deliveryDates.length === 0) || loadingAssortments) {
        return <Loader show display="flex" justifyContent="center" mt={6} mb={6} />;
    } else if (deliveryDates.length === 0) {
        return <Body1>{t("deliveryInfo.missingDeliveryCalendar")}</Body1>;
    }

    return (
        <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell className={classes.th} component="th">
                        {t("deliveryInfo.table.header.deliveryDate")}
                    </TableCell>
                    <TableCell className={classes.th} component="th">
                        {t("deliveryInfo.table.header.lastOrderDeadline")}
                    </TableCell>
                    <TableCell className={classes.th} component="th">
                        {t("deliveryInfo.table.header.assortment")}
                    </TableCell>
                    <TableCell className={classes.th} component="th" padding="none">
                        <LoadingIconButton
                            loading={deliveryCalendarPdfLoading}
                            onClick={() => void dispatch(deliveryCalendarPDF())}
                            className={classes.loadingIconButton}
                        >
                            <PrintIcon className={classes.printIcon} />
                        </LoadingIconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
            {deliveryDates.map((dd, dIndex) => (
                <TableBody key={`delivery-date-${dd.deliveryDate}`} className={classes.tableBody}>
                    {dd.assortments.map((da, aIndex) => (
                        <DeliveryDateRow
                            key={`assortmentId-${dd.deliveryDate}-${da.assortmentId}`}
                            assortmentId={da.assortmentId}
                            deliveryDate={dd.deliveryDate}
                            isOrderSuggestionAvailable={dd.isOrderSuggestionAvailable}
                            deadline={da.deadline}
                            onSelectDate={onSelectDate}
                            isFirstAssortment={aIndex === 0}
                            isLastAssortment={aIndex === dd.assortments.length - 1}
                            isLastDeliveryDate={dIndex === deliveryDates.length - 1}
                            hasPassedDeadline={new Date(da.deadline) < now}
                        />
                    ))}
                </TableBody>
            ))}
        </Table>
    );
};
