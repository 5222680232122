import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Body1, H3 } from "../../components/Typography";
import { useDialog } from "../../hooks/useDialog";
import { useAppSelector } from "../../state/configureStore";
import { selectCustomer } from "../../state/slices/customerSlice";
import { selectSupportDetails } from "../../state/slices/supportSlice";

export const CreditFreezeDialog = () => {
    const { t } = useTranslation(["order", "general"]);
    const { open, isOpen, close } = useDialog();
    const customer = useAppSelector(selectCustomer);
    const supportDetails = useAppSelector(selectSupportDetails);

    useEffect(() => {
        if (customer?.creditFreeze) {
            open();
        }
    }, [customer, open]);

    return (
        <Dialog open={isOpen} onBackdropClick={close}>
            <DialogTitle disableTypography>
                <H3 color="error" align="center">
                    {t("order:creditFreeze.title")}
                </H3>
            </DialogTitle>
            <DialogContent>
                <Body1 preWrap>
                    {t("order:creditFreeze.body", {
                        phone: supportDetails?.phone,
                    })}
                </Body1>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{t("general:actions.ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};
