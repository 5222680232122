import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../components/Link";
import { Page } from "../components/Page";
import { Body1, H1 } from "../components/Typography";

const useStyles = makeStyles(theme => ({
    subTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    errorCode: {
        marginBottom: theme.spacing(2),
    },
    link: {
        display: "block",
    },
}));

export const NotFoundPage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation("pageNotFound");

    return (
        <Page>
            <H1 size={42}>{t("title")}</H1>
            <Body1 className={classes.subTitle} size={25}>
                {t("subTitle")}
            </Body1>
            <Body1 className={classes.errorCode} bold>
                {t("errorCode")}
            </Body1>
            <Body1>{t("usefulLinks")}</Body1>
            <Link className={classes.link} to="/">
                {t("links.home")}
            </Link>
            <Link className={classes.link} to="/order">
                {t("links.order")}
            </Link>
            <Link className={classes.link} to="/my-account">
                {t("links.myAccount")}
            </Link>
        </Page>
    );
};
