import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../../components/Checkbox";
import { DialogHeader } from "../../../../components/DialogHeader";
import { Body1 } from "../../../../components/Typography";
import { useToggle } from "../../../../hooks/useToggle";

type Props = {
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
};

export const ConfirmMinThresholdOrderDialog: FC<Props> = ({ onClose, onConfirm, open }) => {
    const { t } = useTranslation(["order"]);
    const confirmEnabled = useToggle(false);

    return (
        <Dialog maxWidth="md" open={open} onEnter={confirmEnabled.reset}>
            <DialogHeader
                title={t("order:confirmMinThresholdOrderDialog.title")}
                onClose={onClose}
            />
            <DialogContent>
                <Body1>{t("order:confirmMinThresholdOrderDialog.body")}</Body1>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={confirmEnabled.value}
                                onClick={confirmEnabled.toggle}
                            />
                        }
                        label={t("order:confirmMinThresholdOrderDialog.checkboxLabel")}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    {t("order:confirmMinThresholdOrderDialog.no")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!confirmEnabled.value}
                    onClick={() => onConfirm()}
                >
                    {t("order:confirmMinThresholdOrderDialog.yes")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
