import { ButtonProps, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { LoadingButton } from "../../components/LoadingButton";

const useStyles = makeStyles(theme => ({
    btn: {
        marginTop: theme.spacing(4),
    },
}));

type Props = { loading: boolean } & ButtonProps;

export const AuthSubmitButton: FC<Props> = ({ ...props }) => {
    const classes = useStyles();

    return (
        <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            className={classes.btn}
            {...props}
        />
    );
};
