import { useRef, useState } from "react";
import { RequestCanceled } from "../../network";
import { Endpoint } from "../../network/endpoints";
import { post } from "../../network/restClient";
import { useDebounceState } from "../useDebounce";

const debounceValidationDelayMs = 300;

export const useNewPasswordField = () => {
    const abortController = useRef<AbortController>();
    const [error, setError] = useState<string>();
    const [value, setValue] = useDebounceState<string>("", {
        delay: debounceValidationDelayMs,
        onDebounceChange: async (password: string) => {
            abortController?.current?.abort();
            if (password.length === 0) {
                setError(undefined);
                return;
            }
            abortController.current = new AbortController();
            const { error, canceled } = await validateNewPasswordAsync(
                password,
                abortController.current,
            );
            if (!canceled) {
                setError(error);
            }
        },
    });

    return {
        error,
        value,
        onChange: (password: string) => setValue(password),
    };
};

type ValidationError = string | undefined;

async function validateNewPasswordAsync(
    password: string,
    abortController: AbortController,
): Promise<{ error?: ValidationError; canceled?: boolean }> {
    try {
        const { errors } = await post<{
            errors: string[];
        }>(
            Endpoint.validateNewPassword,
            { password },
            { requireAuth: false, signal: abortController.signal },
        );
        return { error: errors[0] };
    } catch (e) {
        if (e instanceof RequestCanceled) {
            return { canceled: true };
        }
    }
    return { error: undefined };
}
