import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { Body2 } from "../../../components/Typography";

const useStyles = makeStyles(theme => ({
    msg: {
        marginLeft: "auto",
        display: "block",
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.error.main,
    },
}));

export const ClosedInfoMessage: FC = ({ children }) => {
    const classes = useStyles();
    return <Body2 className={classes.msg}>{children}</Body2>;
};
