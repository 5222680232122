import { Button, ButtonBase, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { H3 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { Message, News } from "../../state/types";
import { ContactUs } from "./ContactUs";
import { NewsCard } from "./NewsCard";
import { ShortMessage } from "./ShortMessage";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
    },
    showAllNewsLink: {
        marginLeft: "auto",
        cursor: "pointer",
        fontFamily: "Rubik-Italic",
        fontSize: 13,
    },
    sectionHeader: {
        display: "flex",
        alignItems: "flex-end",
    },
    messageWrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: 60,
        marginTop: 20,
        marginBottom: 30,
        borderRadius: 20,
        backgroundColor: colors.white,
        padding: 30,
        overflow: "hidden",
        filter: "drop-shadow(0px 4px 25px rgba(24, 55, 42, 0.05))",
    },
    messageDivider: {
        marginTop: 20,
        marginBottom: 20,
    },
    noMessages: {
        textAlign: "center",
        lineHeight: "60px",
    },
    showMoreMessages: {
        alignSelf: "center",
    },
    news: {
        width: "50%",
        marginRight: 10,
    },
    messages: {
        width: "50%",
        marginLeft: 10,
    },
    newsItem: {
        margin: "20px 0",
    },
});

type Props = {
    news: News[];
    messages: Message[];
    hasNoMessages: boolean;
    onNewsClick: (news: News) => void;
    onMessageClick: (message: Message) => void;
    onShowAllNews: () => void;
    onShowAllMessages: () => void;
};
export const HomeView: FC<Props> = ({
    news,
    messages,
    onNewsClick,
    onMessageClick,
    onShowAllNews,
    onShowAllMessages,
    hasNoMessages,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("landing");

    return (
        <div className={classes.container}>
            <div className={classes.news}>
                <div className={classes.sectionHeader}>
                    <H3>{t("news")}</H3>
                    {news.length > 3 && (
                        <ButtonBase
                            onClick={() => onShowAllNews()}
                            className={classes.showAllNewsLink}
                        >
                            {t("showAllNews", { count: news.length })}
                        </ButtonBase>
                    )}
                </div>
                {news.slice(0, 3).map((item, idx) => (
                    <NewsCard
                        className={classes.newsItem}
                        highlight={idx === 0 ? "color" : undefined}
                        onClick={() => onNewsClick(item)}
                        item={item}
                        key={item.newsId}
                    />
                ))}
            </div>
            <div className={classes.messages}>
                <H3>{t("myMessages")}</H3>
                <div className={classes.messageWrapper}>
                    {hasNoMessages && <div className={classes.noMessages}> {t("noMessages")}</div>}
                    {messages.slice(0, 2).map((message, idx) => (
                        <React.Fragment key={message.messageId}>
                            {idx !== 0 && <Divider className={classes.messageDivider} />}
                            <ShortMessage
                                onClick={() => onMessageClick(message)}
                                message={message}
                                key={message.messageId}
                            />
                        </React.Fragment>
                    ))}
                    {messages.length > 2 && (
                        <>
                            <Divider className={classes.messageDivider} />
                            <Button
                                variant="outlined"
                                className={classes.showMoreMessages}
                                onClick={onShowAllMessages}
                            >
                                {t("showAllMessages")}
                            </Button>
                        </>
                    )}
                </div>

                <H3>{t("contactUs")}</H3>
                <ContactUs />
            </div>
        </div>
    );
};
