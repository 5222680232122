import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    onMenuQuantityChange,
    selectMenuQuantityByProductId,
} from "../../../../state/slices/productMenuSlice";
import { Quantity } from "../../quantity/Quantity";

type Props = {
    productId: string;
};
export const MenuPortionQuantity: FC<Props> = ({ productId }) => {
    const dispatch = useDispatch();
    const portionQuantity = useSelector(selectMenuQuantityByProductId(productId));

    return (
        <Quantity
            productId={productId}
            quantity={portionQuantity}
            onQuantityChange={quantity => dispatch(onMenuQuantityChange({ productId, quantity }))}
        />
    );
};
