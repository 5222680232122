import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { TabPanel } from "@material-ui/lab";
import React from "react";

export const TabContent = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0),
        },
    }),
)(({ tabIndex, ...props }: { tabIndex: number }) => (
    <TabPanel value={tabIndex.toString()} {...props} />
));
