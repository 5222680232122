import { DialogActions, DialogContent, styled } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DialogHeader } from "../../../../components/DialogHeader";
import { ProductType } from "../../../../state/types";
import { CreateOrderSuggestionButton } from "./CreateOrderSuggestionButton";
import { MenuTable } from "./MenuTable";

const StyledDialogAction = styled(DialogActions)({
    justifyContent: "center",
});

type Props = {
    productType: ProductType;
    onClose: () => void;
};
export const MenuDialogView: FC<Props> = ({ productType, onClose }) => {
    const { t } = useTranslation(["order"]);

    return (
        <>
            <DialogHeader
                title={t("order:productMenu.meals")}
                subTitle={t("order:productMenu.mealsDescription")}
                onClose={onClose}
            />
            <DialogContent>
                <MenuTable productType={productType} />
            </DialogContent>
            <StyledDialogAction>
                <CreateOrderSuggestionButton />
            </StyledDialogAction>
        </>
    );
};
