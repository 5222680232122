import * as Sentry from "@sentry/react";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { store } from "../state/configureStore";
import { showSnackbar } from "../state/slices/notificationSlice";
import { logout } from "../state/slices/userSlice";
import { ErrorCode, localizedError, RequestCanceled } from "./networkError";
import { RequestConfig } from "./restTypes";

type ResponseError = {
    message?: unknown;
    errorCode?: ErrorCode;
};

export function setupHTTPInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(axiosConfig => {
        if (!axiosConfig.headers) {
            axiosConfig.headers = {};
        }

        axiosConfig.headers["Accept-Language"] = store.getState().user.locale;

        return axiosConfig;
    });

    instance.interceptors.response.use(
        (response: AxiosResponse<unknown>) => {
            const axiosConfig = response.config as RequestConfig;
            const snackbar = axiosConfig.snackbar;
            if (snackbar?.successMessage) {
                const { successMessage, errorMessage, ...options } = snackbar;
                store.dispatch(
                    showSnackbar({
                        message: snackbar?.successMessage,
                        variant: "success",
                        ...options,
                    }),
                );
            }
            return response;
        },
        (error: AxiosError<ResponseError>) => {
            Sentry.addBreadcrumb({
                category: "HTTP interceptor",
                message: [
                    `error.message: ${error.message}`,
                    `error.status: ${error.status ?? ""}`,
                    `error.code: ${error.code ?? ""}`,
                    `error.response.status: ${error.response?.status ?? ""}`,
                    `error.response.statusText: ${error.response?.statusText ?? ""}`,
                    `error.response.data: ${error.response?.data ?? ""}`,
                ].join(",\n"),
                level: "warning",
            });

            if (axios.isCancel(error)) {
                throw new RequestCanceled();
            }
            const axiosConfig = error.config as RequestConfig;
            const snackBar = axiosConfig.snackbar;
            if (snackBar?.errorMessage || snackBar?.showServerError) {
                const snackbar = axiosConfig.snackbar;
                if (snackbar) {
                    const { errorMessage, showServerError, successMessage, ...options } = snackbar;
                    const message = error.response?.data?.message ?? errorMessage ?? error.message;
                    if (typeof message === "string") {
                        store.dispatch(
                            showSnackbar({
                                message,
                                variant: "error",
                                ...options,
                            }),
                        );
                    }
                }
            }

            if (error.response?.status === 401) {
                store
                    .dispatch(logout())
                    .then(() => console.log("Logged out due to 401 response"))
                    .catch(e => console.warn("Failed to log out", e));
            }

            if (axiosConfig.throwRaw) {
                throw error;
            }

            const errorMsg = error.response?.data?.message;
            if (typeof errorMsg === "string") {
                const errorCode = error?.response?.data?.errorCode;
                throw localizedError({ message: errorMsg, errorCode });
            }

            throw error;
        },
    );
}
