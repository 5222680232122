import { makeStyles } from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React, { FC, useEffect } from "react";
import { colors } from "../constants/colors";
import { useDialog } from "../hooks/useDialog";
import { Asset } from "../state/types";
import { GtmEvents, pushDataLayer } from "../utils/pushDataLayer";
import { Card } from "./Card";
import { ModalHeader } from "./modal/ModalHeader";
import { SidebarModal } from "./modal/SidebarModal";
import { Body1 } from "./Typography";

const useStyles = makeStyles({
    videos: {
        display: "flex",
        flexWrap: "wrap",
        marginLeft: -10,
        marginRight: -10,
    },
    videoCard: {
        margin: 10,
        cursor: "pointer",
    },
    video: {
        height: "100%",
        borderRadius: 10,
    },
    videoWrapper: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        height: 100,
        overflow: "hidden",
        borderRadius: 10,
    },
    play: {
        color: colors.white,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    videoTitle: {
        marginTop: 15,
    },
});

export const Videos: FC<{
    assets?: Asset[];
    videosPerRow: number;
}> = ({ assets = [], videosPerRow }) => {
    const classes = useStyles();
    const videos = assets.filter(asset => asset.type === "video");
    const playDialog = useDialog<Asset>();

    return (
        <div className={classes.videos}>
            <VideModal
                video={playDialog.data}
                isOpen={playDialog.isOpen}
                close={playDialog.close}
                onExited={() => playDialog.setData(undefined)}
            />
            {videos.map(video => (
                <div style={{ width: `${100 / videosPerRow}%` }} key={video.assetId}>
                    <Card className={classes.videoCard} onClick={() => playDialog.setData(video)}>
                        <div className={classes.videoWrapper}>
                            <video className={classes.video}>
                                <source src={`${video.url}#t=1`} type={video.mime} />
                                Your browser does not support the video tag.
                            </video>
                            <PlayArrowIcon className={classes.play} />
                        </div>
                        <Body1 className={classes.videoTitle} overflowEllipsis size={18}>
                            {video.name}
                        </Body1>
                    </Card>
                </div>
            ))}
        </div>
    );
};

const useModalStyles = makeStyles({
    wrapper: {
        "width": "calc(100vw - 80px)",
        "& img": {
            width: "100%",
            borderRadius: 10,
            marginTop: 14,
        },
    },
    video: {
        height: "calc(100% - 48px)",
    },
});

type Props = { video?: Asset; isOpen: boolean; close: () => void; onExited: () => void };
export const VideModal: FC<Props> = ({ video, isOpen, close, onExited }) => {
    const classes = useModalStyles();

    useEffect(() => {
        if (!isOpen) return;

        pushDataLayer(GtmEvents.Video, {
            cmsAssetId: video?.assetId,
            videoUrl: video?.url,
            videoName: video?.name,
            videoCategory: video?.category,
        });
    }, [isOpen, video?.assetId, video?.category, video?.name, video?.url]);

    return (
        <SidebarModal
            className={classes.wrapper}
            isOpen={isOpen}
            onBackdropClick={close}
            onExited={onExited}
        >
            <ModalHeader title={video?.name ?? ""} overline="video" close={close} />
            {video && (
                <video className={classes.video} controls src={video.url} autoPlay>
                    <source src={video.url} type={video.mime} />
                    Your browser does not support the video tag.
                </video>
            )}
        </SidebarModal>
    );
};
