import { OrderHistoryResource } from "../locales";

export const orderHistory: OrderHistoryResource = {
    claim: {
        orderRowTitle: "Reklamera {{productName}}",
        orderTitle: "Reklamera order",
        info: "Vid utebliven leverans eller annan större reklamation ring kundsupport {{phoneNumber}}",
        placeholderReason: "Välj anledning för reklamationen.",
        bestBefore: "Bäst före:",
        batchCode: "Batch-kod:",
        outerBoxBroken: "Ytterkartong trasig:",
        additionalInfo: "Ytterligare information:",
        contactForTheClaim: "Kontaktperson för reklamation:",
        contactEmail: "Kontaktpersonens e-postadress:",
        picture: "Bild",
        success: {
            title: "Tack för din reklamation",
            content:
                "Vi behandlar din reklamation inom 48 timmar och återkommer till dig om vi har några frågor.",
            bestRegards: "Vänliga hälsningar",
            picadeliCustomerService: "Picadeli kundservice",
        },
        fullOrderText: "Du kommer nu att reklamera alla produkter i den här ordern.",
    },
    claimStatus: {
        "not-available": "Inte tillgänglig",
        "call-customer-services": "Ring kundsupport",
        "claimed": "Reklamerad",
    },
    table: {
        details: {
            deliveryAddress: "Leveransadress",
            invoiceAddress: "Fakturaadress",
            orderedProducts: "Beställda produkter",
            table: {
                claimOrder: "Reklamera hela ordern",
                cost: "Pris",
                deliveredQuantity: "Levererat",
                invoicedAt: "Fakturerad",
                weight: "Nettovikt",
                orderNumber: "Ordernr",
                orderedQuantity: "Beställt",
                picked: "Plockad",
                productId: "Artnr",
                productName: "Produktnamn",
                reclamation: "Reklamation",
                subtotal: "Delsumma",
            },
            copyToCart: "Kopiera beställningen till varukogen",
        },
        header: {
            createdBy: "Created by",
            deliversTo: "Skickas till",
            deliveryDate: "Lev datum",
            status: "Status",
            timeCreated: "Skapad",
            totalCostExclVat: "Total ex moms",
            totalWeight: "Total vikt",
        },
        review: "Granska",
    },
    thereIsNoOrderHistory: "Det finns ingen orderhistorik.",
};
