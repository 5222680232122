import { Box, Container, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import errorImgSrc from "../assets/images/error.gif";
import { colors } from "../constants/colors";
import { ErrorMessage } from "./ErrorMessage";
import { Body1 } from "./Typography";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        backgroundColor: colors.heroGreenLight,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        width: "100vw",
    },
});

type Props = {
    message: string;
};

export const ErrorView: FC<Props> = ({ message, children }) => {
    const { t } = useTranslation("error");
    const classes = useStyles();

    if (message.length === 0) {
        return null;
    }

    return (
        <Container className={classes.root} maxWidth={false}>
            <img alt="error" src={errorImgSrc} />
            <ErrorMessage error={{ body: message }} />
            <Box margin={2}>
                <Body1 bold>{t("refreshAndTryAgain")}</Body1>
            </Box>
            {children}
        </Container>
    );
};
