import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Body1, H2 } from "../Typography";

type Props = {
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
};

export const ClearCartConfirmationDialog: FC<Props> = ({ onCancel, onConfirm, open }) => {
    const { t } = useTranslation(["general", "order"]);

    return (
        <Dialog open={open}>
            <DialogTitle disableTypography>
                <H2>{t("order:orderSuggestion.replaceCartDialog.title")}</H2>
            </DialogTitle>
            <DialogContent>
                <Body1>{t("order:orderSuggestion.replaceCartDialog.content")}</Body1>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}>{t("general:actions.cancel")}</Button>
                <Button onClick={() => onConfirm()}>{t("general:actions.ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};
