import { PageNotFoundResource } from "../locales";

export const pageNotFound: PageNotFoundResource = {
    title: "Oups!",
    subTitle: "Vi kan ikke finne siden du leter etter",
    errorCode: "Feilkode: 404",
    usefulLinks: "Her er noen nyttige lenker i stedet:",
    links: {
        home: "Hjem",
        order: "Bestill",
        myAccount: "Min konto",
    },
};
