import { styled } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingButton } from "../../../components/LoadingButton";
import { OrderSuggestionButton } from "../../../components/orderSuggestionButton/OrderSuggestionButton";
import { useAppDispatch } from "../../../state/configureStore";
import { selectCustomer } from "../../../state/slices/customerSlice";
import { selectDeliveryDate } from "../../../state/slices/orderDetailsSlice";
import { planogramPdf, selectPlanogramPdfLoading } from "../../../state/slices/pdfSlice";
import {
    selectHasAccessories,
    selectSaladBarFilter,
    selectSaladBarProducts,
} from "../../../state/slices/productSelectors";
import { filterByType } from "../../../state/slices/productsSlice";
import { FilterType, ProductType, ReplaceOrderProductPolicy } from "../../../state/types";
import { ProductTables } from "../product-table/ProductTables";
import { FilterButton } from "./FilterButton";
import { TabContent } from "./TabContent";
import { TabHeader } from "./TabHeader";

const StyledFilterButtons = styled("div")({
    marginRight: 10,
});

export const SaladBarTab: FC<{ tabIndex: number }> = ({ tabIndex }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("order");
    const filter = useSelector(selectSaladBarFilter);
    const deliveryDate = useSelector(selectDeliveryDate);
    const planogramPdfLoading = useSelector(selectPlanogramPdfLoading);
    const planograms = useSelector(selectCustomer)?.planograms ?? [];
    const hasActivePlanogram = deliveryDate && planograms.find(p => p.start <= deliveryDate);
    const products = useSelector(selectSaladBarProducts);
    const customer = useSelector(selectCustomer);
    const hasAccessories = useSelector(selectHasAccessories);

    return (
        <TabContent tabIndex={tabIndex}>
            <TabHeader>
                <StyledFilterButtons>
                    {customer?.replaceOrderProductPolicy ===
                        ReplaceOrderProductPolicy.ReplaceFreely && (
                        <FilterButton
                            onClick={() =>
                                dispatch(
                                    filterByType({
                                        productType: ProductType.SaladBar,
                                        filterType: FilterType.all,
                                    }),
                                )
                            }
                            highlight={filter === FilterType.all}
                        >
                            {t("filter.showAllProducts")}
                        </FilterButton>
                    )}
                    <FilterButton
                        onClick={() =>
                            dispatch(
                                filterByType({
                                    productType: ProductType.SaladBar,
                                    filterType: FilterType.planogram,
                                }),
                            )
                        }
                        highlight={filter === FilterType.planogram}
                    >
                        {t("filter.showPlanogramProducts")}
                    </FilterButton>
                    {hasAccessories && (
                        <FilterButton
                            onClick={() =>
                                dispatch(
                                    filterByType({
                                        productType: ProductType.SaladBar,
                                        filterType: FilterType.accessories,
                                    }),
                                )
                            }
                            highlight={filter === FilterType.accessories}
                        >
                            {t("filter.showAccessories")}
                        </FilterButton>
                    )}
                </StyledFilterButtons>
                {hasActivePlanogram && (
                    <LoadingButton
                        loading={planogramPdfLoading}
                        onClick={() => {
                            if (deliveryDate) {
                                void dispatch(planogramPdf(deliveryDate));
                            }
                        }}
                    >
                        {t("planogramPdfBtn")}
                    </LoadingButton>
                )}
                <OrderSuggestionButton deliveryDate={deliveryDate} />
            </TabHeader>

            <ProductTables
                showFavorite={false}
                productType={ProductType.SaladBar}
                products={products}
                filter={filter}
                showChangeProductButton={filter !== FilterType.all}
            />
        </TabContent>
    );
};
