import { Method } from "axios";
import { store } from "../state/configureStore";
import { logout } from "../state/slices/userSlice";
import { fetchAndSetToken, getAccessToken, hasAccessToken, isTokenExpired } from "./authService";
import { axiosInstance } from "./createInstance";
import { CustomerIsMissingError } from "./networkError";
import { MakeRequestOpt, RequestConfig, RequestOptions } from "./restTypes";

const defaultRequestOptions: RequestOptions = {
    requireAuth: true,
};

export async function get<T>(url: string, opt: Partial<RequestOptions> = {}) {
    return makeRequest<T>("GET", { url, ...opt });
}

export async function post<T>(url: string, data?: unknown, opt: Partial<RequestOptions> = {}) {
    return makeRequest<T>("POST", { url, data, ...opt });
}

export async function put<T>(url: string, data?: unknown, opt: Partial<RequestOptions> = {}) {
    return makeRequest<T>("PUT", { url, data, ...opt });
}

export async function del<T>(url: string, data?: unknown, opt: Partial<RequestOptions> = {}) {
    return makeRequest<T>("DELETE", { url, data, ...opt });
}

async function makeRequest<T>(method: Method, opt: MakeRequestOpt) {
    const config: RequestConfig = {
        method,
        headers: {},
        ...defaultRequestOptions,
        ...opt,
    };

    if (config.requireAuth) {
        config.headers["Authorization"] = await getAuthorizationHeader();
    }

    const response = await axiosInstance.request<T>(config);
    return response.data;
}

async function getAuthorizationHeader() {
    if (!hasAccessToken() || isTokenExpired()) {
        try {
            await fetchAndSetToken();
        } catch (e) {
            if (e instanceof CustomerIsMissingError) {
                store
                    .dispatch(logout())
                    .then(() => console.log("Logged out due to missing customer"))
                    .catch(() => console.warn("Failed to log out"));
            }
            throw e;
        }
    }

    const token = getAccessToken();
    return `Bearer ${token?.accessToken}`;
}
