import { GuidanceResource } from "../locales";

export const guidance: GuidanceResource = {
    campaignMaterial: "Kampanjmaterial",
    guidance: "Instruktioner och manualer",
    planogram: "Planogram",
    currentPlanogram: "Nuvarande planogram",
    futurePlanogram: "Framtida planogram {{start}}",
    labels: "Etiketter",
    deviationAndClaims: "Avvikelser & Reklamation",
    risks: "Risker",
    certificates: "Certifikat",
};
