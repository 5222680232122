import { useEffect, useRef } from "react";

export const useInterval = (callback: () => void, delay: number, start = true) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (!start) {
            return undefined;
        }

        const id = setInterval(() => savedCallback.current(), delay);
        return () => clearInterval(id);
    }, [delay, start]);
};
