import { AuthResource } from "../locales";

export const auth: AuthResource = {
    createUser: {
        title: "Luo käyttäjä",
        submit: "Luo käyttäjä",
        newPasswordIs: "Salasana on",
        emailLabel: "Sähköposti salasanan vaihtamiseksi",
    },
    common: {
        backToLoginBtn: "Takaisin kirjautumiseen",
    },
    login: {
        title: "ASIAKASPORTAALI",
        description: "Kirjaudu sisään alta tilataksesi tuotteet suoraan verkkokaupasta",
        forgotPasswordBtn: "Unohtuiko salasana?",
        loginBtn: "Kirjaudu sisään",
        passwordLbl: "Salasana",
        usernameLbl: "Asiakasnumero",
    },
    forgotPassword: {
        title: "Unohtuiko salasana",
        resetPasswordSuccessNoContent:
            "Sähköpostiisi lähetettiin linkki, jonka kautta voit vaihtaa salasanan. Jos et ole saanut sähköpostia, soita asiakaspalveluun.",
        resetPasswordSuccessInfo:
            "Sähköpostiosoitteeseen {{email}} lähetettiin linkki, jonka kautta voit vaihtaa salasanasi. IJos et ole saanut sähköpostia, soita asiakaspalveluun {{customerSupportPhone}}",
        customerIdLbl: "Asiakasnumero",
        submitBtn: "Lähetä minulle uusi!",
        description: "Kirjoita sähköpostiosoitteesi. Saat linkin salasanan vaihtamiseen.",
    },
    changePassword: {
        title: "Vaihda salasana",
        description: "Anna uusi salasana päivittääksesi tilisi.",
        generatePasswordDescription:
            "Päivitä käyttäjä uudella luodulla salasanalla painamalla painiketta",
        passwordHasBeenUpdated: "Salasana on vaihdettu",
        generateNewPassword: "Luo uusi salasana",
    },
    password: {
        managePassword: "Hallinnoi salasanaa",
        passwordEmpty: "Salasanaa ei ole.",
        createUserSuccess: "Käyttäjä on luotu.",
        passwordMismatch: "Salasanat eivät täsmää.",
        changePasswordSuccess: "Salasana on vaihdettu.",
        changePassword: "Vaihda salasana",
        setPassword: "Aseta salasana",
        oldPassword: "Vanha salasana",
        newPassword: "Uusi salasana",
        confirmNewPassword: "Vahvista uusi salasana",
        submit: "Vaihda salasana",
    },
    register: {
        title: "Valitse salasanasi",
        welcome: "Tervetuloa {{customerName}}.",
        yourStoreNumberIs: "Asiakasnumerosi ja käyttäjäsi on:",
        emailLabel: "Sähköposti salasanan vaihtamista varten:",
        submit: "Tallentaa",
        snackbarSuccessMessage: "Uusi salasanasi on tallennettu.",
    },
};
