import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2, H3 } from "../../../../components/Typography";
import { selectFrozenWeight } from "../../../../state/slices/cartSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatWeight } from "../../../../utils/formatters";
import { WeightDivider } from "./WeightDivider";

export const FrozenWeight: FC = () => {
    const { t } = useTranslation("order");
    const locale = useSelector(selectLocale);
    const { chilled, frozen } = useSelector(selectFrozenWeight);

    return (
        <>
            <Body2>{t("cart.weight.frozen")}</Body2>
            <H3 noWrap>
                {formatWeight(chilled, locale)}
                <WeightDivider />
                {formatWeight(frozen, locale)}
            </H3>
        </>
    );
};
