import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { CartItem, ProductType } from "../types";
import { addToCart, cartEntityId } from "./cartSlice";

export const productSelectionEntityAdapter = createEntityAdapter<CartItem>({
    selectId: productSelectionEntityId,
});

const productSelectionSlice = createSlice({
    name: "product-selection",
    initialState: productSelectionEntityAdapter.getInitialState({}),
    reducers: {
        changeSalesUnitQuantity: (state, { payload: entity }: PayloadAction<CartItem>) => {
            if (entity.salesUnitQuantity <= 0) {
                productSelectionEntityAdapter.removeOne(state, cartEntityId(entity));
            } else {
                productSelectionEntityAdapter.upsertOne(state, entity);
            }
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(addToCart.fulfilled, (state, { payload }) => {
            productSelectionEntityAdapter.removeAll(state);
        });
    },
});

export const { changeSalesUnitQuantity } = productSelectionSlice.actions;

export const { selectAll: selectAllProductSelection, selectById: selectProductSelectionById } =
    productSelectionEntityAdapter.getSelectors<RootState>(state => state.productSection);

export const productSelectionReducer = productSelectionSlice.reducer;

export function productSelectionEntityId(entity: { productId: string; productType: ProductType }) {
    return `${entity.productId}${entity.productType}`;
}
