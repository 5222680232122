import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../components/Card";
import { Body1, Overline } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { News } from "../../state/types";
import { ThemeType } from "../../theme";
import { InnerHtmlOverflow } from "./OverflowInnerHtml";

const useStyles = makeStyles<ThemeType, { imageUrl?: string }>({
    wrapper: {
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        maxHeight: 140,
        height: 140,
    },
    highlightColor: {
        backgroundColor: "#C4F0DE",
        color: colors.black,
    },
    highlightSize: {
        "color": colors.black,
        "padding": 20,
        "height": 240,
        "maxHeight": 240,
        "& $image": {
            minWidth: 200,
            maxWidth: 200,
            minHeight: 200,
            maxHeight: 200,
        },
        "& $content": {
            height: 200,
        },
        "& $title": {
            marginTop: 10,
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        height: 100,
    },
    contentHighlight: {},
    overline: {
        textTransform: "none",
        lineHeight: "17px",
        opacity: 0.6,
    },
    title: {
        lineHeight: "21px",
        marginTop: 3,
    },
    image: {
        minWidth: 100,
        maxWidth: 100,
        minHeight: 100,
        maxHeight: 100,
        borderRadius: 10,
        marginRight: 20,
        backgroundImage: props => `url(${props.imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
});

type Props = {
    item: News;
    onClick: (item: News) => void;
    highlight?: "size" | "color";
    className?: string;
};
export const NewsCard: FC<Props> = ({ item, highlight, className, onClick }) => {
    const classes = useStyles({ imageUrl: item.imageUrl });
    const { t } = useTranslation("landing");
    return (
        <Card
            className={cn(classes.wrapper, className, {
                [classes.highlightColor]: highlight === "color",
                [classes.highlightSize]: highlight === "size",
            })}
            onClick={() => onClick(item)}
        >
            {item.imageUrl && <div className={classes.image} />}

            <div className={cn(classes.content)}>
                <Overline className={classes.overline} italic>
                    {t("news")}
                </Overline>
                <Body1 size={highlight === "size" ? 24 : 18} className={classes.title}>
                    {item.title}
                </Body1>
                <InnerHtmlOverflow html={item.body} size={highlight === "size" ? "big" : "small"} />
            </div>
        </Card>
    );
};
