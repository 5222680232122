import { Button, Collapse, IconButton, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Cached, Info } from "@material-ui/icons";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import cn from "classnames";
import { ComponentType, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import checkIconSvg from "../../../assets/images/icons/check.svg";
import clockSvg from "../../../assets/images/icons/clock.svg";
import frozenIconSvg from "../../../assets/images/icons/frozen.svg";
import newsIconSvg from "../../../assets/images/icons/news.svg";
import { colors } from "../../../constants/colors";
import { isLoggedInAsCustomer } from "../../../network/authService";
import { useAppSelector } from "../../../state/configureStore";
import { selectCartItemById } from "../../../state/slices/cartSlice";
import { selectCurrency, selectCustomer } from "../../../state/slices/customerSlice";
import {
    selectOriginalProduct,
    selectProductPastDeadline,
    selectSaladBarFilter,
    selectSaladBarProducts,
    selectSelectedRotationProducts,
    selectUpcomingDeliveries,
} from "../../../state/slices/productSelectors";
import { selectAllProducts } from "../../../state/slices/productsSlice";
import { selectLocale } from "../../../state/slices/userSlice";
import { FilterType, Product, ProductType, ReplaceOrderProductPolicy } from "../../../state/types";
import { ThemeType } from "../../../theme";
import { formatPrice } from "../../../utils/formatters";
import { ChangeProductMenu } from "./ChangeProductMenu";
import { ClosedInfoMessage } from "./ClosedInfoMessage";
import { FavoriteButton } from "./FavoriteButton";
import { ProductVersionPDFs } from "./ProductVersionPDFs";

const dressingsCategoryId = "p_195";

const useStyles = makeStyles<ThemeType, { disabled?: boolean; replaced: boolean }>(() => ({
    "root": ({ disabled, replaced }) => ({
        "height": 63,
        "&:last-of-type": {
            "& > td": {
                borderBottom: "none !important",
            },
        },
        "backgroundColor": disabled ? "#efe9e9" : replaced ? colors.heroGreenExtraLight : undefined,
    }),
    "icon": {
        width: 34,
        height: 24,
    },
    "productImage": {
        display: "block",
        borderRadius: "50%",
        padding: 2,
        minWidth: 64,
        minHeight: 64,
        maxWidth: 64,
        maxHeight: 64,
        cursor: "pointer",
    },
    "backgroundTint": ({ disabled, replaced }) => ({
        backgroundColor: disabled
            ? "#dad1d1"
            : replaced
            ? colors.heroGreenExtraLight
            : colors.darkWhite,
    }),
    "clickable": {
        cursor: "pointer",
    },
    "animated": {
        animation: "$flashanim 1250ms",
    },
    "@keyframes flashanim": {
        "0%": {
            backgroundColor: "rgb(215, 234, 217, 0)",
        },
        "20%": {
            backgroundColor: "rgb(215, 234, 217, 1)",
        },
        "40%": {
            backgroundColor: "rgb(215, 234, 217, 0)",
        },
        "60%": {
            backgroundColor: "rgb(215, 234, 217, 1)",
        },
        "80%": {
            backgroundColor: "rgb(215, 234, 217, 0)",
        },
        "100%": {
            backgroundColor: "rgb(215, 234, 217, 1)",
        },
    },
    "changeProductCellContent": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
    },
    "changeProductTooltip": {
        marginLeft: 24,
    },
    "changeProductButton": {
        border: "1px solid grey",
    },
    "selectProductButtonIcon": {
        marginRight: 8,
    },
    "closedInfo": {
        maxWidth: "200px",
    },
}));

type ProductRowProps = {
    product: Product;
    productType: ProductType;
    onDelete?: (productId: string) => void;
    showBackgroundTint?: boolean;
    QuantityComponent?: ComponentType<{ productId: string; productType: ProductType }>;
    collapsed?: {
        colSpan: number;
        Component: ComponentType<{ productId: string; productType: ProductType }>;
    };
    CustomColumnCellComponent?: ComponentType<{ productId: string; productType: ProductType }>;
    WarningComponent?: ComponentType<{
        productId: string;
        productType: ProductType;
    }>;
    showFavorite?: boolean;
    rowId: string;
    showProductPdfDownload?: boolean;
    hideComparePriceCell?: boolean;
    hideUnitCell?: boolean;
    onImageClick?: (img: {
        src: string;
        imageWidth: number;
        imageHeight: number;
        alt: string;
    }) => void;
    showChangeProductButton?: boolean;
    selectProductButton?: string;
    showSelectProductButtonIcon?: boolean;
    onChangeProduct?: (productId: string, override: string) => void;
    allowReplacingCartItem?: boolean;
    disabled?: boolean;
    upcomingDeliveryDates: string[];
};

export const ProductTableRow: FC<ProductRowProps> = ({
    showFavorite,
    product,
    productType,
    QuantityComponent,
    collapsed,
    CustomColumnCellComponent,
    WarningComponent,
    onDelete,
    showBackgroundTint,
    rowId,
    hideComparePriceCell,
    hideUnitCell,
    showProductPdfDownload,
    onImageClick,
    showChangeProductButton,
    selectProductButton,
    showSelectProductButtonIcon,
    onChangeProduct,
    allowReplacingCartItem,
    disabled,
    upcomingDeliveryDates,
}) => {
    const [isRowCollapsed, setCollapseRow] = useState(false);
    const [isFlashing, setIsFlashing] = useState(false);
    const [replacementMenuEl, setReplacementMenuEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslation(["general", "order"]);
    const userLocale = useSelector(selectLocale);
    const currency = useSelector(selectCurrency);
    const customer = useSelector(selectCustomer);
    const filter = useSelector(selectSaladBarFilter);
    const cartItem = useAppSelector(state =>
        selectCartItemById(state, `${product.productId}${productType}`),
    );
    const itemInCart = Boolean(cartItem);
    const allProducts = useSelector(selectAllProducts);
    const selectedRotationProducts = useSelector(selectSelectedRotationProducts);
    const selectedRotationProduct = allProducts.find(
        p => p.productId === selectedRotationProducts[product.productId],
    );
    const productInList = Boolean(
        useSelector(selectSaladBarProducts)
            .flatMap(p => p.products)
            .map(p => selectedRotationProducts[p.productId] ?? p.productId)
            .includes(product.productId),
    );

    const productClosedDueToPastDeadline = useAppSelector(state =>
        selectProductPastDeadline(product.productId)(state),
    );

    const shownProduct =
        selectProductButton || filter === FilterType.all
            ? product
            : selectedRotationProduct ?? product;

    const isRotationProduct = Object.values(selectedRotationProducts).includes(
        shownProduct.productId,
    );
    const upcomingDeliveries = useAppSelector(selectUpcomingDeliveries);
    const originalProduct = useAppSelector(state =>
        selectOriginalProduct(shownProduct.productId)(state),
    );

    const classes = useStyles({ disabled, replaced: Boolean(originalProduct) });

    let allowChange = false;
    switch (customer?.replaceOrderProductPolicy) {
        case ReplaceOrderProductPolicy.ReplaceFreely:
            allowChange = product.rotationProducts.length > 0;
            break;
        case ReplaceOrderProductPolicy.ReplaceClosedWithAny:
            allowChange = Boolean(product.closedInfo) || product.rotationProducts.length > 0;
            break;
        case ReplaceOrderProductPolicy.ReplaceClosedWithRotation:
            allowChange = Boolean(product.closedInfo) && product.rotationProducts.length > 0;
            break;
        default:
            break;
    }
    allowChange =
        allowChange &&
        !product.isNonFood &&
        (!productClosedDueToPastDeadline || isLoggedInAsCustomer()) &&
        !product.categoryIds.includes(dressingsCategoryId);

    return (
        <>
            <TableRow
                onClick={() => setCollapseRow(cur => !cur)}
                data-testid={rowId}
                className={cn({
                    [classes.backgroundTint]: showBackgroundTint,
                    [classes.clickable]: collapsed !== undefined,
                    [classes.animated]: isFlashing,
                })}
                classes={{ root: classes.root }}
                onAnimationEnd={() => setIsFlashing(false)}
            >
                <TableCell scope="row" padding="none" size="small">
                    {showFavorite && <FavoriteButton productId={shownProduct.productId} />}
                    {WarningComponent && (
                        <WarningComponent
                            productId={shownProduct.productId}
                            productType={productType}
                        />
                    )}
                </TableCell>

                <TableCell size="small" padding="none" align="center">
                    {shownProduct.productId}
                </TableCell>

                {showProductPdfDownload && (
                    <TableCell padding="none" size="small">
                        <ProductVersionPDFs product={shownProduct} />
                    </TableCell>
                )}

                <TableCell padding="none" size="small">
                    <img
                        onClick={() =>
                            onImageClick?.({
                                src: shownProduct.imageSrc["640"],
                                imageWidth: 640,
                                imageHeight: 640,
                                alt: shownProduct.name,
                            })
                        }
                        alt={shownProduct.name}
                        src={shownProduct.imageSrc["60"] ?? null}
                        srcSet={`
                            ${shownProduct.imageSrc["60"]} 1x,
                            ${shownProduct.imageSrc["120"]} 2x
                        `}
                        onError={event =>
                            ((event.target as HTMLImageElement).style.display = "none")
                        }
                        className={classes.productImage}
                    />
                </TableCell>
                {showChangeProductButton && (
                    <TableCell>
                        {(allowChange || selectedRotationProduct || isRotationProduct) && (
                            <div className={classes.changeProductCellContent}>
                                <IconButton
                                    className={classes.changeProductButton}
                                    data-testid="replace-product-button"
                                    onClick={event => setReplacementMenuEl(event.currentTarget)}
                                    size="small"
                                >
                                    <Cached />
                                </IconButton>
                                <ChangeProductMenu
                                    allowReplacingCartItem={allowReplacingCartItem}
                                    anchorEl={replacementMenuEl}
                                    onClose={() => setReplacementMenuEl(null)}
                                    onReplace={() => setIsFlashing(true)}
                                    product={product}
                                />
                                {originalProduct && (
                                    <Tooltip
                                        className={classes.changeProductTooltip}
                                        placement="top"
                                        title={t("order:productHasBeenReplaced", {
                                            productName: originalProduct.name,
                                            interpolation: { escapeValue: false },
                                        })}
                                    >
                                        <Info />
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </TableCell>
                )}
                <TableCell>{shownProduct.name}</TableCell>
                <TableCell align="right">
                    {shownProduct.isFrozen && (
                        <Tooltip title={t("order:productTable.icon.frozen")}>
                            <img src={frozenIconSvg} alt="frozen" className={classes.icon} />
                        </Tooltip>
                    )}
                    {shownProduct.isNews && (
                        <Tooltip title={t("order:productTable.icon.news")}>
                            <img src={newsIconSvg} alt="news" className={classes.icon} />
                        </Tooltip>
                    )}
                    {shownProduct.isFreshCut && (
                        <Tooltip title={t("order:productTable.icon.orderAssortment")}>
                            <img src={clockSvg} alt="order assortment" className={classes.icon} />
                        </Tooltip>
                    )}
                    {shownProduct.isInPlanogram && (
                        <Tooltip title={t("order:productTable.icon.inPlanogram")}>
                            <img src={checkIconSvg} alt="planogram" className={classes.icon} />
                        </Tooltip>
                    )}
                </TableCell>
                {!hideUnitCell && (
                    <TableCell>
                        {shownProduct.salesUnit !== undefined
                            ? t(`general:salesUnit.${shownProduct.salesUnit}` as const)
                            : ""}
                    </TableCell>
                )}
                {customer?.showPrices && (
                    <TableCell>
                        {formatPrice(
                            (shownProduct.price ?? 0) * shownProduct.orderMultiple,
                            currency,
                            userLocale,
                        )}
                    </TableCell>
                )}
                {!hideComparePriceCell && customer?.showPrices && (
                    <TableCell>
                        {formatPrice(shownProduct.comparisonPrice ?? 0, currency, userLocale)}
                    </TableCell>
                )}
                {upcomingDeliveryDates.map(deliveryDate => (
                    <TableCell key={deliveryDate} align="center">
                        {upcomingDeliveries.getCount(shownProduct.productId, deliveryDate)}
                    </TableCell>
                ))}
                {CustomColumnCellComponent && (
                    <TableCell>
                        <CustomColumnCellComponent
                            productId={shownProduct.productId}
                            productType={productType}
                        />
                    </TableCell>
                )}
                {QuantityComponent && !selectProductButton && (
                    <TableCell align="right">
                        <QuantityComponent
                            productId={shownProduct.productId}
                            productType={productType}
                        />
                    </TableCell>
                )}
                {onDelete !== undefined && (
                    <TableCell>
                        <IconButton
                            className="remove-product-button"
                            data-testid="remove-product-button"
                            tabIndex={-1}
                            onClick={ev => {
                                ev.stopPropagation();
                                onDelete(shownProduct.productId);
                            }}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </TableCell>
                )}
                {selectProductButton !== undefined && (
                    <TableCell
                        align="right"
                        className={cn({
                            [classes.closedInfo]: Boolean(
                                product.closedInfo ||
                                    (!allowReplacingCartItem && itemInCart) ||
                                    productInList,
                            ),
                        })}
                    >
                        {product.closedInfo ? (
                            <ClosedInfoMessage>{product.closedInfo}</ClosedInfoMessage>
                        ) : !allowReplacingCartItem && itemInCart ? (
                            <ClosedInfoMessage>{t("order:productAlreadyInCart")}</ClosedInfoMessage>
                        ) : productInList ? (
                            <ClosedInfoMessage>{t("order:productAlreadyInList")}</ClosedInfoMessage>
                        ) : (
                            <Button
                                color="primary"
                                onClick={() =>
                                    onChangeProduct?.(shownProduct.productId, product.productId)
                                }
                                variant="contained"
                            >
                                {(showSelectProductButtonIcon ?? true) && (
                                    <Cached className={classes.selectProductButtonIcon} />
                                )}
                                {selectProductButton}
                            </Button>
                        )}
                    </TableCell>
                )}
            </TableRow>
            {collapsed && (
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={collapsed.colSpan}
                    >
                        <Collapse in={isRowCollapsed} timeout="auto" mountOnEnter unmountOnExit>
                            <collapsed.Component
                                productId={shownProduct.productId}
                                productType={productType}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
