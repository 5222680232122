import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { groupBy } from "../../utils/array";
import { RootState } from "../configureStore";
import { Asset, RequestStatus } from "../types";

export const fetchAssets = createAsyncThunk("fetch-assets", async () => {
    return get<Asset[]>(Endpoint.assets);
});

const adapter = createEntityAdapter<Asset>({
    selectId: entity => entity.assetId,
});

const slice = createSlice({
    name: "assets",
    initialState: adapter.getInitialState<{ error?: string; requestStatus: RequestStatus }>({
        requestStatus: RequestStatus.Idle,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchAssets.pending, (state, action) => {
            state.requestStatus = RequestStatus.Loading;
        });
        addCase(fetchAssets.fulfilled, (state, action) => {
            state.requestStatus = RequestStatus.Fulfilled;
            adapter.setAll(state, action.payload);
        });
        addCase(fetchAssets.rejected, (state, { error }) => {
            state.requestStatus = RequestStatus.Rejected;
        });
    },
});

export const { selectAll: selectAssets } = adapter.getSelectors<RootState>(state => state.assets);

export const selectAssetsGroupedByCategory = createSelector(selectAssets, assets =>
    groupBy(assets, d => d.category),
);

export const selectRequestStatus = (state: RootState) => state.assets.requestStatus;

export const assetsReducer = slice.reducer;
