import { Button, Container, makeStyles } from "@material-ui/core";
import cn from "classnames";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import errorImgSrc from "../assets/images/error.gif";
import { Page } from "../components/Page";
import { Body1, H3 } from "../components/Typography";
import { colors } from "../constants/colors";
import { useQueryParams } from "../hooks/useQueryParams";
import { selectIsLoggedIn } from "../state/slices/userSlice";
import { AuthPage } from "./auth/AuthPage";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
        borderRadius: 10,
        padding: theme.spacing(4, 2),
    },
    transparentContainer: {
        backgroundColor: "rgba(0,0,0,0.3)",
        color: colors.white,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    errorImage: {
        width: 400,
    },
    errorText: {
        fontWeight: "bold",
        margin: theme.spacing(2, 6),
    },
}));

export const ErrorPage = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const history = useHistory();
    const {
        values: [body, title],
        isParsed,
    } = useQueryParams("body", "title");

    useEffect(() => {
        if (!body && isParsed) {
            const redirectRoute = isLoggedIn ? "/" : "/login";
            console.log(`Missing message body in query param. Redirecting to ${redirectRoute}`);
            history.replace(redirectRoute);
        }
    }, [body, isParsed, isLoggedIn, history]);

    if (body === null) {
        return null;
    }

    if (isLoggedIn) {
        return <AuthorizedErrorPage body={body} title={title ?? undefined} />;
    } else {
        return <UnauthorizedErrorPage body={body} title={title ?? undefined} />;
    }
};

type Props = {
    title?: string;
    body: string;
};
export const UnauthorizedErrorPage: FC<Props> = ({ title, body }) => {
    const { t } = useTranslation("error");
    const classes = useStyles();
    const history = useHistory();
    return (
        <AuthPage title={title ?? t("ohNoSomethingWentWrong")} description={body}>
            <img alt="error" src={errorImgSrc} className={classes.errorImage} />
            <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={() => history.replace("/login")}
            >
                {t("goToLogin")}
            </Button>
        </AuthPage>
    );
};

export const AuthorizedErrorPage: FC<Props> = ({ title, body }) => {
    const { t } = useTranslation("error");
    const classes = useStyles();
    return (
        <Page
            ContentComponent={props => (
                <Container maxWidth="md" className={cn(classes.container)} {...props} />
            )}
        >
            <H3>{title ?? t("ohNoSomethingWentWrong")}</H3>
            <Body1 className={classes.errorText}>{body}</Body1>
            <img alt="error" src={errorImgSrc} className={classes.errorImage} />
        </Page>
    );
};
