import { Dialog } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/configureStore";
import { addToCart } from "../../../state/slices/cartSlice";
import {
    clearSelections,
    OrderSuggestion,
    selectOrderSuggestionProducts,
} from "../../../state/slices/productMenuSlice";
import { ProductType } from "../../../state/types";
import { MenuDialogView } from "./menu-table/MenuDialogView";
import { OrderSuggestionDialogView } from "./order-suggestion/OrderSuggestionDialogView";

type Props = {
    onClose: () => void;
    isOpen: boolean;
    productType: ProductType;
};

export const ProductMenuDialog: FC<Props> = ({ onClose, isOpen, productType }) => {
    const dispatch = useAppDispatch();
    const isOrderSuggestionDialogView = useSelector(selectOrderSuggestionProducts).length > 0;

    const handleAddToCart = async (orderSuggestions: OrderSuggestion[]) => {
        const cartItems = orderSuggestions.map(row => ({
            salesUnitQuantity: row.salesUnitQuantity,
            productType,
            productId: row.product.productId,
        }));
        // Intentionally close dialog before dispatching addToCart()
        // since this event will trigger scrolling to the Cart component.
        onClose();
        await dispatch(addToCart({ cartItems }));
    };

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            onBackdropClick={onClose}
            onExited={() => {
                dispatch(clearSelections());
            }}
            open={isOpen}
        >
            {isOrderSuggestionDialogView ? (
                <OrderSuggestionDialogView
                    productType={productType}
                    onAddToCart={handleAddToCart}
                    onClose={onClose}
                />
            ) : (
                <MenuDialogView productType={productType} onClose={onClose} />
            )}
        </Dialog>
    );
};
