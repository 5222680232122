import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../state/configureStore";
import {
    changeSalesUnitQuantity,
    productSelectionEntityId,
    selectProductSelectionById,
} from "../../../state/slices/productSelectionSlice";
import { ProductType } from "../../../state/types";
import { Quantity } from "./Quantity";

type Props = {
    productId: string;
    productType: ProductType;
};

export const ProductSelectionQuantity: FC<Props> = ({ productId, productType }) => {
    const dispatch = useDispatch();
    const item = useAppSelector(state =>
        selectProductSelectionById(state, productSelectionEntityId({ productId, productType })),
    );

    return (
        <Quantity
            productId={productId}
            onQuantityChange={salesUnitQuantity =>
                dispatch(changeSalesUnitQuantity({ productId, salesUnitQuantity, productType }))
            }
            quantity={item?.salesUnitQuantity}
        />
    );
};
