import {
    Box,
    Button,
    ButtonBase,
    Container,
    Divider,
    makeStyles,
    Menu,
    MenuItem,
} from "@material-ui/core";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import cn from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import background from "../../assets/images/bg-image.jpg";
import logo from "../../assets/images/logo.svg";
import whiteLogo from "../../assets/images/logo-white.svg";
import { colors } from "../../constants/colors";
import { useDialog } from "../../hooks/useDialog";
import { useIsLoggedInAsCustomer } from "../../hooks/useIsLoggedInAsCustomer.ts";
import { ChangePasswordModal } from "../../pages/my-account/ChangePasswordModal";
import { CreateUserModal } from "../../pages/my-account/CreateUserModal";
import { GeneratePasswordModal } from "../../pages/my-account/GeneratePasswordModal";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { selectCustomer } from "../../state/slices/customerSlice";
import { selectDeliveryDateById } from "../../state/slices/deliveryDatesSlice";
import { openDeliveryInfoDialog } from "../../state/slices/dialogsSlice";
import { selectDeliveryDate, selectOrderRef } from "../../state/slices/orderDetailsSlice";
import { logout, selectLocale } from "../../state/slices/userSlice";
import { ThemeType } from "../../theme";
import { formatDate } from "../../utils/formatters";
import { Link } from "../Link";
import { SidebarModal } from "../modal/SidebarModal";
import { Body2, H3 } from "../Typography";
import { AssortmentTimeLeft } from "./AssortmentTimeLeft";
import { NavLink } from "./NavLink";
import { WeatherForecast } from "./WeatherForecast";

const useStyles = makeStyles<
    ThemeType,
    {
        src: string;
    }
>(theme => ({
    logoBtn: {
        transform: "translateX(-10px)",
        marginBottom: 20,
    },
    container: {
        display: "flex",
        position: "relative",
        backgroundColor: colors.white,
        boxShadow: "0 10px 20px 0 rgb(0 0 0 / 10%)",
        borderRadius: 20,
        padding: "20px 30px",
    },
    bgImage: {
        "maxWidth": 1360,
        "padding": 40,
        "backgroundImage": props => `url(${props.src})`,
        "backgroundRepeat": "no-repeat",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "borderRadius": 20,
        "margin": "40px auto",

        "@media (max-width:1400px)": {
            margin: "40px 20px",
        },
    },
    selectDeliveryBtn: {
        marginTop: 20,
        height: 40,
    },
    darkMode: {
        background: colors.darkGrey,
        color: colors.white,
        borderRadius: 10,
        borderRight: "none !important",
        padding: `${theme.spacing(1, 1.2)} !important`,
    },
    supervisedUserIcon: {
        marginLeft: theme.spacing(1),
    },
    topSection: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        paddingBottom: 16,
        minHeight: 100,
    },
    menu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: 20,
    },
    leftSection: {
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        padding: "0 30px 0 0",
        marginRight: 30,
        flexBasis: 260,
    },
    customerName: {
        color: colors.lightGrey,
        minHeight: 18,
    },
    selectedDeliveryBody: {
        marginTop: 5,
    },
    logo: {
        height: 40,
    },
}));

export const Header: FC = () => {
    const [anchorMenuEl, setAnchorMenuEl] = useState<Element | null>(null);
    const locale = useAppSelector(selectLocale);
    const customer = useAppSelector(selectCustomer);
    const orderRef = useAppSelector(selectOrderRef);
    const selectedDeliveryDate = useAppSelector(selectDeliveryDate);
    const delivery = useAppSelector(state =>
        selectDeliveryDateById(state, selectedDeliveryDate ?? ""),
    );
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const changePasswordDialog = useDialog();
    const createUserDialog = useDialog();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["general", "order"]);
    const classes = useStyles({ src: background });
    const handleCloseMenu = () => setAnchorMenuEl(null);

    return (
        <div className={classes.bgImage} data-testid="header">
            <Container className={classes.container} maxWidth="lg">
                <Box
                    className={cn(classes.leftSection, {
                        [classes.darkMode]: isLoggedInAsCustomer,
                    })}
                >
                    <ButtonBase
                        disableRipple
                        className={classes.logoBtn}
                        onClick={() => history.push("/")}
                    >
                        <img
                            alt="logo"
                            className={classes.logo}
                            src={isLoggedInAsCustomer ? whiteLogo : logo}
                        />
                    </ButtonBase>
                    <Box display="flex" justifyContent="space-between">
                        <div>
                            <Body2 className={classes.customerName}>
                                {customer && `${customer.customerId}, ${customer.name}`}
                            </Body2>
                            {isLoggedInAsCustomer && (
                                <Body2 size={13} bold>
                                    {t("general:loggedInAsCustomer")}
                                </Body2>
                            )}
                        </div>
                        {isLoggedInAsCustomer && (
                            <SupervisedUserCircleIcon className={classes.supervisedUserIcon} />
                        )}
                    </Box>
                    <H3 noWrap>{t("order:deliveryInfo.title")}</H3>
                    {!selectedDeliveryDate ? (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.selectDeliveryBtn}
                            onClick={() => dispatch(openDeliveryInfoDialog({ canClose: true }))}
                        >
                            {t("order:deliveryInfo.pickDeliveryDate")}
                        </Button>
                    ) : (
                        <>
                            <Body2 className={classes.selectedDeliveryBody}>
                                {t("order:deliveryInfo.selectedDeliveryDate")}:<br />
                                {formatDate(selectedDeliveryDate, locale, {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}{" "}
                                <Link
                                    onClick={() =>
                                        dispatch(openDeliveryInfoDialog({ canClose: true }))
                                    }
                                >
                                    ({t("general:edit")})
                                </Link>
                            </Body2>
                            {orderRef && (
                                <Body2>{t("order:deliveryInfo.orderRef", { orderRef })}</Body2>
                            )}
                        </>
                    )}
                </Box>

                <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
                    <Box className={classes.topSection}>
                        {delivery &&
                            delivery.assortments.map((deliveryAssortment, idx) => (
                                <React.Fragment
                                    key={`delivery-assortment-${deliveryAssortment.assortmentId}`}
                                >
                                    {idx !== 0 && <Divider orientation="vertical" flexItem />}
                                    <AssortmentTimeLeft deliveryAssortment={deliveryAssortment} />
                                </React.Fragment>
                            ))}
                        <WeatherForecast />
                    </Box>
                    <Box className={classes.menu}>
                        <NavLink
                            to="/order"
                            text={t("general:navigationLinks.order")}
                            navId="order"
                        />
                        <NavLink
                            to="/order-history"
                            text={t("general:navigationLinks.orderHistory")}
                            navId="order-history"
                        />
                        <NavLink
                            to="/guidance"
                            text={t("general:navigationLinks.guidance")}
                            navId="guidance"
                        />

                        {customer?.showCommunication && (
                            <NavLink
                                to="/communication"
                                text={t("general:navigationLinks.communication")}
                                navId="communication"
                            />
                        )}

                        <NavLink
                            onClick={ev => setAnchorMenuEl(ev.currentTarget)}
                            text={t("general:navigationLinks.account")}
                            highlightedPaths={["/my-account"]}
                            navId="my-account"
                        />
                        <Menu
                            anchorEl={anchorMenuEl}
                            keepMounted
                            open={Boolean(anchorMenuEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem
                                onClick={() => {
                                    history.push("/my-account");
                                    handleCloseMenu();
                                }}
                            >
                                {t("general:navigationLinks.myAccount")}
                            </MenuItem>
                            {customer?.hasUser && (
                                <MenuItem
                                    onClick={() => {
                                        changePasswordDialog.open();
                                        handleCloseMenu();
                                    }}
                                >
                                    {t("general:navigationLinks.changePassword")}
                                </MenuItem>
                            )}
                            {customer?.hasUser === false && (
                                <MenuItem
                                    onClick={() => {
                                        createUserDialog.open();
                                        handleCloseMenu();
                                    }}
                                >
                                    {t("general:navigationLinks.createUser")}
                                </MenuItem>
                            )}
                            <Divider />
                            <MenuItem
                                onClick={() => {
                                    void (async () => {
                                        await dispatch(logout());
                                        window.location.reload();
                                    })();
                                    handleCloseMenu();
                                }}
                            >
                                {t("general:navigationLinks.logout")}
                            </MenuItem>
                        </Menu>

                        {customer?.hasUser && (
                            <SidebarModal
                                isOpen={changePasswordDialog.isOpen}
                                onBackdropClick={changePasswordDialog.close}
                            >
                                {isLoggedInAsCustomer ? (
                                    <GeneratePasswordModal close={changePasswordDialog.close} />
                                ) : (
                                    <ChangePasswordModal close={changePasswordDialog.close} />
                                )}
                            </SidebarModal>
                        )}
                        <SidebarModal
                            isOpen={createUserDialog.isOpen}
                            onBackdropClick={createUserDialog.close}
                        >
                            <CreateUserModal close={createUserDialog.close} />
                        </SidebarModal>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};
