import { useMemo, useState } from "react";

export const useToggle = (defaultValue: boolean) => {
    const [value, setValue] = useState(defaultValue);

    return useMemo(
        () => ({
            value,
            toggle: () => setValue(currValue => !currValue),
            reset: () => setValue(defaultValue),
        }),
        [value, setValue, defaultValue],
    );
};
