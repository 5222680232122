import { AppBar, Container, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingButton } from "../../components/LoadingButton";
import { colors } from "../../constants/colors";
import { useAppDispatch } from "../../state/configureStore";
import { addToCart, selectAddToCartStatus } from "../../state/slices/cartSlice";
import { selectAllProductSelection } from "../../state/slices/productSelectionSlice";
import { RequestStatus } from "../../state/types";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
    },
    addToCartBtn: {
        marginLeft: "auto",
    },
    appBar: {
        backgroundColor: colors.white,
        top: "auto",
        bottom: 0,
    },
}));

export const OrderPageFooter = () => {
    const classes = useStyles();
    const productSelection = useSelector(selectAllProductSelection);
    const addToCartStatus = useSelector(selectAddToCartStatus);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("order");

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Container className={classes.container} maxWidth="lg">
                    <LoadingButton
                        loading={addToCartStatus === RequestStatus.Loading}
                        className={classes.addToCartBtn}
                        variant="contained"
                        color="primary"
                        data-testid="cart-btn"
                        onClick={() => {
                            if (productSelection.length) {
                                void dispatch(addToCart({ cartItems: productSelection }));
                            }
                        }}
                    >
                        {t("addProductsToCart")}
                    </LoadingButton>
                </Container>
            </Toolbar>
        </AppBar>
    );
};
