import { Select, Theme, withStyles } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select/Select";
import React from "react";

export const IconSelect = withStyles((theme: Theme) => ({
    root: {
        display: "flex",
    },
    icon: {
        display: "none",
    },
    select: {
        padding: `0 !important`,
        background: "none !important",
    },
}))((props: SelectProps) => <Select disableUnderline {...props} />);
