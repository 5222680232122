import { ReportsResource } from "../locales";

export const reports: ReportsResource = {
    pageTitle: "Berichte",
    links: {
        productTemperature: "Produkttemperaturbericht erstellen",
        inventory: "Inventarbericht generieren",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Zurücksetzen",
                submit: "Filter",
            },
            label: {
                productName: "Produktname",
                fromDate: "Startdatum",
                toDate: "Enddatum",
            },
        },
        table: {
            header: {
                productName: "Produktname",
                productNo: "Produktnummer",
                batch: "Batch",
                time: "Zeit",
                module: "Modul",
                checkedBy: "Geprüft von",
                temperature: "Temperatur",
            },
        },
        title: "Produkttemperaturen",
        spreadsheet: "Produkttemperaturen.xlsx",
    },
};
