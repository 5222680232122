import { GuidanceResource } from "../locales";

export const guidance: GuidanceResource = {
    campaignMaterial: "Kampagnenmaterial",
    guidance: "Anleitungen und Produktinfos",
    planogram: "Planogramm",
    currentPlanogram: "Aktuelles Planogramm",
    futurePlanogram: "Zukünftiges Planogramm {{start}}",
    labels: "Etiketten",
    deviationAndClaims: "Abweichungen und Reklamationen",
    risks: "Risiken",
    certificates: "Zertifikate",
};
