import { OrderResource } from "../locales";

export const order: OrderResource = {
    addProductsToCart: "Produkte in den Warenkorb legen",
    availableRotationProducts: "Verfügbarer Ersatz",
    banner: "Sie haben eine ungewöhnlich große Menge in Ihrem Warenkorb. Bitte überprüfen Sie die Menge der markierten Artikel, bevor Sie Ihre Bestellung aufgeben.",
    cart: {
        excludingVat: "ohne MwSt.",
        chilled: "Frischware",
        couldNotDeliverChosenToDate:
            "Das/die markierte/n Produkt/e konnten nicht zum gewünschten Liefertermin geliefert werden. Wählen Sie eine der folgenden Optionen aus:",
        changeDeliveryDate: "Lieferdatum ändern",
        containCountProducts: "Ihr Warenkorb enthält {{count}} Produkt.",
        containCountProducts_plural: "Ihr Warenkorb enthält {{count}} Produkte.",
        shipping: {
            banner: {
                total: "Versandkosten",
                frozen: "TK",
                chilled: "Frischware",
            },
            free: {
                title: "Kostenloser Versand",
                values: {
                    total: "Für Bestellungen über {{thresholdFree}}",
                    frozen: "TK: Bestellen > {{thresholdFree}}",
                    chilled: "Frischware: Bestellen > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Ihre Bestellung kann nicht kostenlos geliefert werden, da der Bestellwert für den Gratisversand in Höhe von {{thresholdFree}} nicht erreicht ist. Es wird eine Liefergebühr von {{charge}} erhoben. Fügen Sie für den Gratisversand noch Waren im Wert von {{remainingCost}} hinzu!",
                belowMin:
                    "Ihre Bestellung hat noch nicht den Mindestbestellwert von {{thresholdMin}} erreicht. Fügen Sie Waren im Wert von mind. {{remainingCost}} hinzu.",
            },
            total: {
                total: "Versandkosten insgesamt",
                frozen: "TK",
                chilled: "Frischware",
            },
        },
        hideCartProducts: "Produkte im Warenkorb ausblenden",
        frozen: "TK",
        placeOrder: "Bestellung aufgeben",
        removeProductsThatCannotBeDelivered:
            "Entfernen Sie alle Produkte, die nicht geliefert werden können.",
        selectedDeliveryDate: "Ausgewählter Liefertermin",
        showCart: "Warenkorb anzeigen",
        showCartProducts: "Produkte im Warenkorb anzeigen",
        totalShipping: "Versandkosten insgesamt:",
        title: "Warenkorb",
        vat: "MwSt. ({{percentage}})",
        weight: {
            "product-types": "Gewicht Salatbar / Deli / Food To Go",
            "frozen": "Gewicht Frischware / TK",
            "no-split": "Gewicht",
        },
    },
    creditFreeze: {
        title: "Wichtige Informationen",
        body:
            "Auf Ihrem Picadeli-Konto befinden sich unbezahlte Rechnungen.\n" +
            "\n" +
            "Ihre Bestellfunktion wird eingestellt, bis Ihre Rechnungen beglichen werden.\n" +
            "\n" +
            "Bitte kontaktieren Sie den Picadeli-Kundenservice für eventuelle Rückfragen.\n" +
            "\n" +
            "Rufen Sie an unter: {{phone}}",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Lieferkalender fehlt",
        title: "Lieferdetails",
        myDeliveryCalendar: "Mein Lieferkalender",
        orderRef: "Referenz: {{orderRef}}",
        orderRefLabel: "Referenz eingeben (optional)",
        selectedDeliveryDate: "Ausgewählter Liefertermin",
        pickDeliveryDate: "Liefertermin auswählen",
        timeLeftToOrder: "Bestellfrist",
        hideAdvancedOptions: "Erweiterte Optionen ausblenden",
        showAdvancedOptions: "Erweiterte Optionen anzeigen",
        zeroPriceOrder: "Bestellung zum Nullpreis",
        adminCalendar: "Admin-Kalender",
        adminCalendarAnyDeliveryDate: "Lieferkalender umgehen (FLS mode)",
        adminCalendarWarning:
            "Vorsichtig sein. Verwenden Sie diese Option nur, wenn Sie wissen, was Sie tun!",
        adminCalendarDeliveryDateWarning:
            "OK, Zeit für eine weitere Warnung: If you select a date in this mode, you will NOT be able to see any closed products AND the order will likely cause an error message in EDI Incoming Orders in Jeeves.",
        table: {
            header: {
                deliveryDate: "Liefertermin",
                assortment: "Produktkategorie",
                lastOrderDeadline: "Bestellschluss",
                onTheWay: "Unterwegs",
            },
            btn: {
                select: "Auswählen",
                selectAndCreateOrderProposal: "Bestellvorschlag auswählen und erstellen",
            },
            orderSuggestionAvailable: "Bestellvorschlag vorhanden",
        },
    },
    filter: {
        showAllProducts: "Alle Produkte",
        showPlanogramProducts: "Planogramm-Produkte",
        showFavorites: "Favoriten anzeigen",
        showAccessories: "Zubehör",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Bestellvorschlag zum Warenkorb hinzufügen",
        replaceCartDialog: {
            content:
                "Indem Sie die Produkte des Bestellvorschlags zum Warenkorb hinzufügen, werden die vorhandenen Produkte entfernt.",
            title: "Warenkorb ersetzen",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "Ihre Bestellung ist eingegangen.",
        thankYouForYourPurchase: "Vielen Dank für Ihren Einkauf",
        yourOrderHasNowBeenReceived: "Ihre Bestellung ist eingegangen",
        continueShopping: "Weiter einkaufen",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Die Bestellung konnte nicht aufgegeben werden.",
        removeInvalidProductsBtn: "Ungültige Produkte entfernen",
    },
    planogramPdfBtn: "Planogramm-PDF",
    productAlreadyInCart: "Bereits im Warenkorb",
    productAlreadyInList: "Bestellen Sie in der Produktliste",
    productHasBeenReplaced: "Sie haben {{productName}} ersetzt",
    productTable: {
        articleNo: "Artikelnr.",
        comparisonPrice: "Preis/kg",
        comparisonPriceNonFood: "Preis/St",
        dataSheet: "Produktblatt V. {{version}}",
        icon: {
            frozen: "TK",
            inPlanogram: "Im Planogramm",
            orderAssortment: "Produktkategorie",
            news: "News",
        },
        name: "Produktname",
        onTheWay: "unterwegs",
        pricePerUnit: "Preis/Einheit",
        selectRotationProduct: "Wechseln",
        selectPriceListProduct: "Wählen",
        unit: "Einheit",
    },
    productMenu: {
        orderHelpMeals: "Order help meals",
        orderSuggestionsMeals: "Order suggestion meals",
        orderSuggestionsMealsDescription:
            "Here you can see the amount of each product needed for the number of meals you have chosen. The surplus / deficit column shows how much is left over or missing. NOTE Do not forget items for the bulk sale.",
        pricePerPortion: "Price / Portion",
        numPackages: "Number of packages",
        numPortions: "Number of portions",
        perPortion: "Per portion",
        excessWeightTitle: "Surplus / deficit",
        meals: "Meals",
        mealsDescription:
            "Order the desired number of portions per dish. If you order several meals containing, for example, mashed potatoes, the quantity is added together to minimize possible wastage.",
        createOrderSuggestion: "Create order suggestion",
        someProductsCannotBeDelivered:
            "One or more products in this meal can not be delivered. Click for more information.",
    },
    searchProduct: "Nach Artikelnummer oder Name suchen",
    selectAReplacement: "Ersatzprodukt auswählen",
    thresholdWarningDialog: {
        pleaseCheck:
            "Bitte überprüfen Sie, ob die Menge der gewünschten Menge entspricht, bevor Sie Ihre Bestellung aufgeben.",
        title: "Eine ungewöhnlich große Menge",
        weHaveNoticed:
            "WWir haben festgestellt, dass die Menge der folgenden Produkte ungewöhnlich groß ist:",
    },
    unavailableRotationProducts: "Geschlossenes Produkt",
};
