type Context = {
    keys: () => string[];
    resolve: (path: string) => string;
};
type ResolvePath = (path: string) => string;
type RequireContext = Context & ResolvePath;

const bundleLoader = {
    importWeatherIcons: () => {
        const requireWeatherSvg: RequireContext = require.context(
            "./assets/images/icons/weather",
            false,
            /\.svg$/,
        );
        return requireWeatherSvg.keys().reduce<Record<string, string>>((images, path) => {
            images[path] = requireWeatherSvg(path);
            return images;
        }, {});
    },
};

export default bundleLoader;
