import { createAsyncThunk } from "@reduxjs/toolkit";
import { Endpoint } from "../network/endpoints";
import { get } from "../network/restClient";
import { Message, News } from "./types";

export const fetchNewsAndMessages = createAsyncThunk("fetch-news-and-messages", async () => {
    return get<{
        news: News[];
        messages: Message[];
    }>(Endpoint.newsAndMessages);
});
