import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { DateTime } from "luxon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import { selectCurrency, selectCustomer } from "../../../state/slices/customerSlice";
import { Week } from "../../../state/slices/orderMatsSlice";
import { selectLocale } from "../../../state/slices/userSlice";
import { Product } from "../../../state/types";
import { formatPrice, getWeightUnit } from "../../../utils/formatters";
import { FavoriteButton } from "./FavoriteButton";
import { OrderMatCells } from "./OrderMatCells";
import { ProductVersionPDFs } from "./ProductVersionPDFs";

const useStyles = makeStyles(() => ({
    root: {
        "height": 63,
        "&:last-of-type": {
            "& > td": {
                borderBottom: "none !important",
            },
        },
    },
    productImage: {
        display: "block",
        borderRadius: "50%",
        padding: 2,
        minWidth: 64,
        minHeight: 64,
        maxWidth: 64,
        maxHeight: 64,
        cursor: "pointer",
    },
    backgroundTint: {
        backgroundColor: colors.darkWhite,
    },
}));

type Props = {
    product: Product;
    showBackgroundTint?: boolean;
    onImageClick?: (img: {
        src: string;
        imageWidth: number;
        imageHeight: number;
        alt: string;
    }) => void;
    selectedWeek: Week;
    startOfWeek: DateTime;
};

export const OrderMatRow: FC<Props> = ({
    product,
    showBackgroundTint,
    onImageClick,
    selectedWeek,
    startOfWeek,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("order");

    const userLocale = useSelector(selectLocale);
    const currency = useSelector(selectCurrency);
    const customer = useSelector(selectCustomer);

    const price = formatPrice((product.price ?? 0) * product.orderMultiple, currency, userLocale);
    const comparisonPrice = formatPrice(product.comparisonPrice ?? 0, currency, userLocale);
    const weightUnit = getWeightUnit(userLocale);

    return (
        <TableRow
            className={cn({
                [classes.backgroundTint]: showBackgroundTint,
            })}
            classes={{ root: classes.root }}
        >
            <TableCell scope="row" padding="none" size="small">
                <FavoriteButton productId={product.productId} />
            </TableCell>

            <TableCell padding="none" size="small">
                <ProductVersionPDFs product={product} />
            </TableCell>

            <TableCell padding="none" size="small">
                <img
                    onClick={() =>
                        onImageClick?.({
                            src: product.imageSrc["640"],
                            imageWidth: 640,
                            imageHeight: 640,
                            alt: product.name,
                        })
                    }
                    alt={product.name}
                    src={product.imageSrc["60"] ?? null}
                    srcSet={`
                            ${product.imageSrc["60"]} 1x,
                            ${product.imageSrc["120"]} 2x
                        `}
                    onError={event => ((event.target as HTMLImageElement).style.display = "none")}
                    className={classes.productImage}
                />
            </TableCell>
            <TableCell>
                <Tooltip title={product.productId} placement="top">
                    <div>{product.name}</div>
                </Tooltip>
            </TableCell>
            {customer?.showPrices && (
                <TableCell>
                    <Tooltip
                        title={t("productTable.comparisonTooltip", {
                            unit: weightUnit,
                            comparisonPrice,
                        })}
                        placement="top"
                    >
                        <div>{price}</div>
                    </Tooltip>
                </TableCell>
            )}
            <OrderMatCells
                product={product}
                selectedWeek={selectedWeek}
                startOfWeek={startOfWeek}
            />
        </TableRow>
    );
};
