import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMenuProducts } from "../../../../state/slices/productMenuSlice";
import { selectUpcomingDeliveries } from "../../../../state/slices/productSelectors";
import { ProductType } from "../../../../state/types";
import { ProductTable } from "../../product-table/ProductTable";
import { ClosedInfoCell } from "./ClosedInfoCell";
import { CollapsedMenu } from "./CollapsedMenu";
import { MenuPortionQuantity } from "./MenuPortionQuantity";

type Props = {
    productType: ProductType;
};

export const MenuTable: FC<Props> = ({ productType }) => {
    const { t } = useTranslation(["order"]);
    const products = useSelector(selectMenuProducts);
    const upcomingDeliveries = useSelector(selectUpcomingDeliveries);

    return (
        <ProductTable
            products={products}
            title={t("order:productMenu.meals")}
            productType={productType}
            hideComparePriceCell
            hideUnitCell
            QuantityComponent={MenuPortionQuantity}
            customColumn={{
                title: "",
                CellComponent: ClosedInfoCell,
            }}
            collapsed={{
                Component: CollapsedMenu,
                colSpan: 8,
            }}
            headerTexts={{
                pricePerUnit: t("order:productMenu.pricePerPortion"),
                quantity: t("order:productMenu.numPortions"),
            }}
            upcomingDeliveryDates={upcomingDeliveries.getTableColumns(products)}
        />
    );
};
