import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

const useStyles = makeStyles({
    link: {
        "marginBottom": 3,
        "cursor": "pointer",
        "textDecoration": "underline",
        "&:hover": {
            textDecoration: "underline",
        },
    },
});

type BreadcrumbLink = {
    name: string;
    onClick: () => void;
};
type Props = {
    links: BreadcrumbLink[];
};
export const Breadcrumbs: FC<Props> = ({ links }) => {
    const classes = useStyles();
    return (
        <div>
            {links.map(l => (
                <Link
                    key={l.name}
                    className={classes.link}
                    onClick={() => {
                        l.onClick();
                    }}
                >
                    {l.name}
                </Link>
            ))}
        </div>
    );
};
