import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../components/form/InputForm";
import { LoadingButton } from "../../components/LoadingButton";
import { ModalHeader } from "../../components/modal/ModalHeader";
import { Body2 } from "../../components/Typography";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import {
    generatePassword,
    selectGeneratePasswordRequest,
    selectNewUserPassword,
} from "../../state/slices/userSlice";
import { RequestStatus } from "../../state/types";

const useStyles = makeStyles(theme => ({
    container: {
        width: 360,
    },
    alert: {
        margin: theme.spacing(1, 0),
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    button: {
        margin: "20px auto",
    },
    newPassword: {
        fontFamily: "Rubik-SemiBold",
        letterSpacing: "2px",
    },
}));

export const GeneratePasswordModal: FC<{ close: () => void }> = ({ close }) => {
    const { t } = useTranslation(["myAccount", "auth", "general"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const request = useAppSelector(selectGeneratePasswordRequest);
    const newUserPassword = useAppSelector(selectNewUserPassword);

    const handleSubmit = async () => {
        await dispatch(generatePassword());
    };

    return (
        <div className={classes.container}>
            <ModalHeader title={t("auth:password.managePassword")} close={close} />
            <Form className={classes.form} onSubmit={handleSubmit}>
                {newUserPassword ? (
                    <Alert severity="success" className={classes.alert}>
                        <Body2>
                            {t("auth:createUser.newPasswordIs")}{" "}
                            <span className={classes.newPassword}>{newUserPassword}</span>
                        </Body2>
                    </Alert>
                ) : (
                    <Alert severity="info" className={classes.alert}>
                        <Body2>{t("auth:changePassword.generatePasswordDescription")}</Body2>
                    </Alert>
                )}
                <LoadingButton
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disableElevation
                    loading={request === RequestStatus.Loading}
                >
                    {t("auth:changePassword.generateNewPassword")}
                </LoadingButton>
            </Form>
        </div>
    );
};
