import { useCallback, useState } from "react";

export const useDialog = <T>(initialData: T | undefined = undefined) => {
    const [isOpen, setOpen] = useState(false);
    const [data, setData] = useState<T | undefined>(initialData);

    const open = useCallback(() => setOpen(true), []);
    const close = useCallback(() => setOpen(false), []);
    const toggle = useCallback(() => setOpen(currOpen => !currOpen), []);
    const setDataAndOpen = useCallback((d?: T) => {
        setData(d);
        setOpen(d !== undefined);
    }, []);

    return {
        isOpen,
        open,
        close,
        toggle,
        setData: setDataAndOpen,
        data,
    };
};

export type UseDialogProps = ReturnType<typeof useDialog>;
export type DialogProps<T> = { data?: T } & Omit<UseDialogProps, "data">;
