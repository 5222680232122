import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { downloadFile } from "../../utils/blob";
import { translate } from "../../utils/translate";
import { RootState } from "../configureStore";
import { ProductType } from "../types";

type PdfState = {
    deliveryCalendarLoading: boolean;
    orderLoading: boolean;
    orderByArticleNoLoading: boolean;
    productsPdfLoading: boolean;
    planogramPdfLoading: boolean;
    productSheetLoading: Array<{
        loading: boolean;
        productId: string;
        version: number;
    }>;
};

const initialState: PdfState = {
    deliveryCalendarLoading: false,
    orderLoading: false,
    orderByArticleNoLoading: false,
    productsPdfLoading: false,
    planogramPdfLoading: false,
    productSheetLoading: [],
};

export const fetchProductSheet = createAsyncThunk(
    "pdf/fetchProductSheet",
    async (product: { productId: string; version: number }, { dispatch }) => {
        dispatch(setLoading({ productId: product.productId, version: product.version }));
        const blob = await get<Blob>(Endpoint.productPdf, {
            responseType: "blob",
            snackbar: { showServerError: true },
            params: {
                "product-id": product.productId,
                "version": product.version.toString(),
            },
        });
        downloadFile(blob, `${product.productId}-v${product.version}.pdf`);
    },
);

export const deliveryCalendarPDF = createAsyncThunk("pdf/deliveryCalendar", async () => {
    const blob = await get<Blob>(Endpoint.deliveryCalendarPdf, {
        responseType: "blob",
        snackbar: { showServerError: true },
    });
    downloadFile(blob, translate("pdf.fileName.deliveryCalendar"));
});

export const orderPDF = createAsyncThunk(
    "pdf/order",
    async (args: {
        orderNo?: number;
        erpOrderNo?: number;
        deliveryNoteId?: number;
        sortByArticleNo: boolean;
    }) => {
        const orderNo = args.orderNo || args.erpOrderNo;
        const fileName = orderNo || args.deliveryNoteId;

        if (!fileName) {
            throw new Error("Missing one of order no/erp/deliveryNote order no");
        }

        const blob = await get<Blob>(Endpoint.orderPdf, {
            params: {
                "order-no": args.orderNo,
                "erp-order-no": args.orderNo ? undefined : args.erpOrderNo,
                "delivery-note-id": args.deliveryNoteId,
                "sort-by-article-no": `${args.sortByArticleNo}`,
            },
            responseType: "blob",
            snackbar: { showServerError: true },
        });

        downloadFile(blob, translate("pdf.fileName.order", { orderNo: fileName }));
    },
);

export const planogramPdf = createAsyncThunk("pdf/planogram", async (deliveryDate: string) => {
    const blob = await get<Blob>(Endpoint.planogramPdf, {
        params: {
            "delivery-date": deliveryDate,
        },
        responseType: "blob",
        snackbar: { showServerError: true },
    });
    downloadFile(blob, translate("pdf.fileName.planogram"));
});

type ProductPdfArg = {
    deliveryDate: string;
    productType: ProductType;
};
export const productsPdf = createAsyncThunk(
    "pdf/products",
    async ({ deliveryDate, productType }: ProductPdfArg) => {
        const blob = await get<Blob>(Endpoint.productsPdf, {
            params: { "delivery-date": deliveryDate, "product-type": productType },
            responseType: "blob",
            snackbar: { showServerError: true },
        });
        downloadFile(blob, translate(`pdf.fileName.products.${productType}` as const));
    },
);

const pdfSlice = createSlice({
    name: "pdf",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<{ productId: string; version: number }>) => {
            const {
                payload: { productId, version },
            } = action;
            const element = state.productSheetLoading.find(
                fetch => fetch.productId === productId && fetch.version === version,
            );
            if (!element) {
                state.productSheetLoading.push({
                    loading: true,
                    productId,
                    version,
                });
                return;
            }
            element.loading = true;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(deliveryCalendarPDF.fulfilled, state => {
            state.deliveryCalendarLoading = false;
        });
        addCase(deliveryCalendarPDF.pending, state => {
            state.deliveryCalendarLoading = true;
        });
        addCase(deliveryCalendarPDF.rejected, state => {
            state.deliveryCalendarLoading = false;
        });
        addCase(fetchProductSheet.fulfilled, (state, { meta }) => {
            const { productId, version } = meta.arg;
            const element = state.productSheetLoading.find(
                fetch => fetch.productId === productId && fetch.version === version,
            );
            if (element) {
                element.loading = false;
            }
        });
        addCase(fetchProductSheet.rejected, (state, { meta }) => {
            const { productId, version } = meta.arg;
            const element = state.productSheetLoading.find(
                fetch => fetch.productId === productId && fetch.version === version,
            );
            if (element) {
                element.loading = false;
            }
        });
        addCase(orderPDF.fulfilled, (state, { meta }) => {
            if (meta.arg.sortByArticleNo) {
                state.orderByArticleNoLoading = false;
            } else {
                state.orderLoading = false;
            }
        });
        addCase(orderPDF.pending, (state, { meta }) => {
            if (meta.arg.sortByArticleNo) {
                state.orderByArticleNoLoading = true;
            } else {
                state.orderLoading = true;
            }
        });
        addCase(orderPDF.rejected, (state, { meta }) => {
            if (meta.arg.sortByArticleNo) {
                state.orderByArticleNoLoading = false;
            } else {
                state.orderLoading = false;
            }
        });

        addCase(productsPdf.pending, state => {
            state.productsPdfLoading = true;
        });
        addCase(productsPdf.rejected, state => {
            state.productsPdfLoading = false;
        });
        addCase(productsPdf.fulfilled, state => {
            state.productsPdfLoading = false;
        });

        addCase(planogramPdf.pending, state => {
            state.planogramPdfLoading = true;
        });
        addCase(planogramPdf.rejected, state => {
            state.planogramPdfLoading = false;
        });
        addCase(planogramPdf.fulfilled, state => {
            state.planogramPdfLoading = false;
        });
    },
});

const { setLoading } = pdfSlice.actions;

export const pdfReducer = pdfSlice.reducer;

export const productSheetLoading = (state: RootState) => state.pdf.productSheetLoading;
export const selectProductsPdfLoading = (state: RootState) => state.pdf.productsPdfLoading;
export const selectDeliveryCalendarPdfLoading = (state: RootState) =>
    state.pdf.deliveryCalendarLoading;
export const selectOrderPdfLoading = (state: RootState) => state.pdf.orderLoading;
export const selectOrderByArticleNoPdfLoading = (state: RootState) =>
    state.pdf.orderByArticleNoLoading;
export const selectPlanogramPdfLoading = (state: RootState) => state.pdf.planogramPdfLoading;
