import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { post } from "../../network/restClient";
import { RootState } from "../configureStore";
import { fetchNewsAndMessages } from "../sharedActions";
import { Message, RequestStatus } from "../types";

export const readMessage = createAsyncThunk("messages/read", async (message: Message) => {
    if (message.read) {
        return;
    }
    await post(`${Endpoint.readMessage}`, {
        messageId: message.messageId,
    });
});

const adapter = createEntityAdapter<Message>({
    selectId: entity => entity.messageId,
});

const slice = createSlice({
    name: "messages",
    initialState: adapter.getInitialState<{ error?: string; requestStatus: RequestStatus }>({
        requestStatus: RequestStatus.Idle,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchNewsAndMessages.pending, (state, action) => {
            state.requestStatus = RequestStatus.Loading;
        });
        addCase(fetchNewsAndMessages.fulfilled, (state, action) => {
            state.requestStatus = RequestStatus.Fulfilled;
            adapter.setAll(state, action.payload.messages);
        });
        addCase(fetchNewsAndMessages.rejected, (state, { error }) => {
            state.requestStatus = RequestStatus.Rejected;
        });
        addCase(readMessage.fulfilled, (state, { meta }) => {
            const message = meta.arg;
            adapter.updateOne(state, { changes: { read: true }, id: message.messageId });
        });
    },
});

export const { selectAll: selectMessages } = adapter.getSelectors<RootState>(
    state => state.messages,
);

export const selectRequestStatus = (state: RootState) => state.messages.requestStatus;

export const messagesReducer = slice.reducer;
