import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "../../components/Card";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Loader } from "../../components/Loader";
import { Body1 } from "../../components/Typography";
import { selectError, selectLoading, selectSupportDetails } from "../../state/slices/supportSlice";

const useStyles = makeStyles(theme => ({
    section: {
        "display": "flex",
        "flexDirection": "column",
        "& + $section": {
            marginTop: 10,
        },
    },
    contactUs: {
        marginTop: 10,
    },
}));

export const ContactUs = () => {
    const { t } = useTranslation("customerSupport");
    const supportDetails = useSelector(selectSupportDetails);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const classes = useStyles();

    return (
        <Card className={classes.contactUs}>
            {loading && !supportDetails ? (
                <Loader show display="flex" justifyContent="center" mb={6} />
            ) : error ? (
                <ErrorMessage error={{ body: error }} />
            ) : (
                <>
                    <Body1>{supportDetails?.introText}</Body1>
                    <InfoSection
                        label={t("openingHours")}
                        values={[
                            supportDetails?.openingHoursStart &&
                                t("openingHoursStart", { value: supportDetails.openingHoursStart }),
                            supportDetails?.openingHoursEnd &&
                                t("openingHoursEnd", { value: supportDetails.openingHoursEnd }),
                        ]}
                    />
                    <InfoSection
                        label={t("contactUs")}
                        values={[
                            supportDetails?.email
                                ? t("email", { value: supportDetails.email })
                                : null,
                            supportDetails?.phone
                                ? t("phone", { value: supportDetails.phone })
                                : null,
                        ]}
                    />
                </>
            )}
        </Card>
    );
};

type Value = string | null | undefined;
const InfoSection: FC<{ label?: string; values: Value[] }> = ({ label, values }) => {
    const classes = useStyles();
    const nonNullValues = values.filter(v => v);
    if (nonNullValues.length === 0) {
        return null;
    }
    return (
        <Box className={classes.section}>
            {label && <Body1 bold>{label}</Body1>}
            {nonNullValues.map(value => (
                <Body1 key={value}>{value}</Body1>
            ))}
        </Box>
    );
};
