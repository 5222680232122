import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import { SupportDetails } from "../types";

export const fetchSupportDetails = createAsyncThunk("fetch-support-details", async () =>
    get<SupportDetails>(Endpoint.supportDetails),
);

type State = {
    loading: boolean;
    error?: string;
    details?: SupportDetails;
};

const initialState: State = {
    loading: false,
};

const supportSlice = createSlice({
    name: "support",
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchSupportDetails.pending, state => {
            state.loading = true;
            state.error = undefined;
        });
        addCase(fetchSupportDetails.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.details = payload;
        });
        addCase(fetchSupportDetails.rejected, (state, action) => {
            state.error = action.error.message;
            state.details = undefined;
        });
    },
});

export const supportReducer = supportSlice.reducer;

export const selectSupportDetails = (state: RootState) => state.support.details;
export const selectLoading = (state: RootState) => state.support.loading;
export const selectError = (state: RootState) => state.support.error;
