import { useState } from "react";
import { useEmailValidator } from "./useEmailValidator";

export const useEmailField = () => {
    const { validate, error, setError } = useEmailValidator();
    const [value, setValue] = useState<string>("");

    return {
        value,
        error,
        setError,
        onChange: (email: string) => {
            setValue(email);
            validate(email);
        },
    };
};
