import { useEffect } from "react";
import { useIsLoggedInAsCustomer } from "../../hooks/useIsLoggedInAsCustomer.ts";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useAppDispatch } from "../../state/configureStore";
import { fetchDeliveryDates } from "../../state/slices/deliveryDatesSlice";
import { closeDeliveryInfoDialog } from "../../state/slices/dialogsSlice";
import { setOrderDetails } from "../../state/slices/orderDetailsSlice";

export const useSelectDeliveryDateFromQueryParams = () => {
    const dispatch = useAppDispatch();
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const {
        values: [deliveryDate],
        isParsed,
    } = useQueryParams("delivery-date");

    useEffect(() => {
        if (isParsed && deliveryDate) {
            dispatch(closeDeliveryInfoDialog());
            dispatch(
                setOrderDetails({
                    deliveryDate,
                    orderSuggestion: false,
                    zeroPriceOrder: false,
                    bypassDeadline: isLoggedInAsCustomer,
                }),
            );
            void dispatch(fetchDeliveryDates());
        }
    }, [dispatch, deliveryDate, isParsed, isLoggedInAsCustomer]);
};
