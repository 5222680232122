import { Button, makeStyles } from "@material-ui/core";
import React, { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../hooks/useDialog";
import { useAppSelector } from "../../state/configureStore";
import { selectOrderClaimCodes } from "../../state/slices/claimCodesSlice";
import { ClaimCode } from "../../state/types";
import { parseValue } from "../../utils/form";
import { ClaimDialog, formId } from "./ClaimDialog";

const useStyles = makeStyles(theme => ({
    claimBtn: {
        height: 33,
    },
}));

type Props = {
    rows: Array<{
        productId: string;
        erpOrderNo: number;
    }>;
};

export const ClaimOrder: FC<Props> = ({ rows }) => {
    const classes = useStyles();
    const { t } = useTranslation(["orderHistory"]);
    const dialog = useDialog();
    const claimCodes = useAppSelector(selectOrderClaimCodes);

    return (
        <>
            <Button className={classes.claimBtn} variant="contained" onClick={dialog.open}>
                {t("orderHistory:table.details.table.claimOrder")}
            </Button>
            <ClaimDialog
                open={dialog.isOpen}
                close={dialog.close}
                claimCodes={claimCodes}
                toClaimBody={(claimCode: ClaimCode, e: SyntheticEvent) => ({
                    rows,
                    claimCode: claimCode.description,
                    info: parseValue(e, formId.info),
                    contactName: parseValue(e, formId.contactName),
                    contactEmail: parseValue(e, formId.contactEmail),
                })}
                title={t("orderHistory:claim.orderTitle")}
                informativeText={t("orderHistory:claim.fullOrderText")}
            />
        </>
    );
};
