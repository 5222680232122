import { config } from "../config";

export function trackPage(page: string) {
    if (!config.isProd) {
        return;
    }

    if (window.gtag) {
        console.log("Tracking page", page);
        window.gtag("config", "G-YRMC01HNHQ", { page_path: page });
    } else {
        console.warn("Can't track page: gtag is undefined");
    }
}
