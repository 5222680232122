import { OrderHistoryResource } from "../locales";

export const orderHistory: OrderHistoryResource = {
    claim: {
        orderRowTitle: "Reklamaatio: {{productName}}",
        orderTitle: "Reklamoi tilaus",
        info: "Jos tilaustasi ei ole toimitettu tai jos sinulla on jokin muu valitus/reklamaatio, soita asiakastukeen numeroon {{phoneNumber}}",
        placeholderReason: "Valitse yhteydenoton syy.",
        bestBefore: "Parasta ennen -päiväys:",
        batchCode: "Eräkoodi:",
        outerBoxBroken: "Uloin pakkaus vaurioitunut:",
        additionalInfo: "Lisätietoja:",
        contactForTheClaim: "Valituksen laatijan nimi:",
        contactEmail: "Sähköpostiosoite:",
        picture: "Kuva:",
        success: {
            title: "Kiitos yhteydenotostasi",
            content:
                "Käsittelemme valituksesi 48 tunnin kuluessa ja palaamme asiaan, jos meillä on kysyttävää.",
            bestRegards: "Parhain terveisin,",
            picadeliCustomerService: "Picadelin asiakaspalvelu",
        },
        fullOrderText: "Olet lunastamassa kaikki tämän tilauksen tuotteet.",
    },
    claimStatus: {
        "not-available": "Ei reklamointioikeutta",
        "call-customer-services": "Soita asiakaspalveluun",
        "claimed": "Reklamoitu",
    },
    table: {
        details: {
            deliveryAddress: "Toimitusosoite",
            invoiceAddress: "Laskutusosoite",
            orderedProducts: "Tilatut tuotteet",
            table: {
                cost: "Kustannukset",
                deliveredQuantity: "Toimitettu",
                invoicedAt: "Laskutettu",
                weight: "Nettopaino",
                orderNumber: "Tilausnro",
                orderedQuantity: "Tilattu",
                picked: "Noudettu",
                productId: "Tuotenro",
                productName: "Tuotteen nimi",
                reclamation: "Reklamoi",
                subtotal: "Välisumma",
                claimOrder: "Reklamoi koko tilaus",
            },
            copyToCart: "Kopioi tilaus ostoskoriin",
        },
        header: {
            orderRef: "Viite",
            createdBy: "Luonut",
            deliversTo: "Toimitus osoitteeseen",
            deliveryDate: "Toimituspäivä",
            status: "Tila",
            timeCreated: "Luotu",
            totalCostExclVat: "Kokonaishinta ilman arvonlisäveroa",
            totalWeight: "Kokonaispaino",
        },
        review: "Tarkista",
    },
    thereIsNoOrderHistory: "Ei tilaushistoriaa.",
};
