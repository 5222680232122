export const order = {
    addProductsToCart: "Legg produkter i handlekurv",
    availableRotationProducts: "Tilgjengelig erstatning",
    banner: "Du har en uvanlig stor mengde i handlekurven. Vennligst bekreft mengden for de uthevede varene før du legger inn bestillingen.",
    cart: {
        excludingVat: "ekskludert MVA",
        chilled: "Kjølt",
        couldNotDeliverChosenToDate:
            "Merkede produkt(er) kan ikke leveres på valgt leveringsdato. Velg en av følgende alternativer:",
        changeDeliveryDate: "Endre leveringsdato",
        containCountProducts: "Handlekurven din inneholder {{count}} produkt",
        containCountProducts_plural: "Handlekurven din inneholder {{count}} produkter",
        shipping: {
            banner: {
                total: "Frakt",
                frozen: "Frosset",
                chilled: "Kjølt",
            },
            free: {
                title: "Gratis frakt",
                values: {
                    total: "For bestillinger over {{thresholdFree}}",
                    frozen: "Frosset: bestilling > {{thresholdFree}}",
                    chilled: "Kjølt: bestilling > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Din bestilling kan ikke leveres gratis fordi den ikke når gratisgrensen på {{thresholdFree}}. Et leveringsgebyr på {{charge}} vil bli lagt til. For gratis levering, legg til {{remainingCost}}!",
                belowMin:
                    "Din bestilling når ikke minimumsbestillingen på {{thresholdMin}}. Legg til minst {{remainingCost}} for å aktivere levering!",
            },
            total: {
                total: "Total frakt",
                frozen: "Frosset",
                chilled: "Kjølt",
            },
        },
        hideCartProducts: "Skjul produkter i handlekurven",
        frozen: "Frosset",
        orderMatText: "Bestillinger vil bli lagt inn automatisk når bestillingsfristen er nådd.",
        placeOrder: "Legg inn bestilling",
        removeProductsThatCannotBeDelivered: "Fjern alle produkter som ikke kan leveres",
        selectedDeliveryDate: "Valgt leveringsdato",
        showCart: "Vis handlekurv",
        showCartProducts: "Vis produkter i handlekurven",
        totalShipping: "Total frakt:",
        title: "Handlekurv",
        vat: "MVA ({{percentage}})",
        weight: {
            "product-types": "Vekt Salatbar / Deli / Food To Go",
            "frozen": "Vekt Kjølt / Frosset",
            "no-split": "Vekt",
        },
    },
    confirmMinThresholdOrderDialog: {
        title: "Bekreft",
        body: "Er du sikker på at du vil legge inn en bestilling under minimumsbeløpet?",
        checkboxLabel: "Jeg bekrefter at denne bestillingen er OK",
        yes: "Ja, fortsett og legg inn denne bestillingen",
        no: "Nei, jeg ønsker ikke å legge inn denne bestillingen",
    },
    creditFreeze: {
        title: "Viktig informasjon",
        body:
            "Din butiks Picadeli-konto har ubetalte fakturaer som må gjøres opp før du kan legge inn bestillinger igjen.\n\n" +
            "Vennligst kontakt Picadeli Kundeservice for å løse de utestående fakturaene og få tilgang til bestilling igjen.\n\n" +
            "Ring: {{phone}}",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Mangler leveringskalender",
        title: "Leveringsinformasjon",
        myDeliveryCalendar: "Min leveringskalender",
        orderRef: "Referanse: {{orderRef}}",
        orderRefLabel: "Angi referanse (valgfritt)",
        selectedDeliveryDate: "Valgt leveringsdato",
        pickDeliveryDate: "Velg leveringsdato",
        timeLeftToOrder: "Tid igjen til bestilling",
        hideAdvancedOptions: "Skjul avanserte alternativer",
        showAdvancedOptions: "Vis avanserte alternativer",
        zeroPriceOrder: "Nullprisbestilling",
        adminCalendar: "Admin-kalender",
        adminCalendarAnyDeliveryDate: "Omgå leveringskalender (FLS-modus)",
        adminCalendarWarning: "Vær forsiktig. Bruk kun dette alternativet hvis du vet hva du gjør!",
        adminCalendarDeliveryDateWarning:
            "OK, tid for en annen advarsel: If you select a date in this mode, you will NOT be able to see any closed products AND the order will likely cause an error message in EDI Incoming Orders in Jeeves.",
        table: {
            header: {
                deliveryDate: "Leveringsdato",
                assortment: "Sortiment",
                lastOrderDeadline: "Bestillingsfrist",
                onTheWay: "På vei",
            },
            btn: {
                select: "Velg",
                selectAndCreateOrderProposal: "Velg og opprett ordreutkast",
            },
            orderSuggestionAvailable: "Bestillingsforslag tilgjengelig",
        },
    },
    filter: {
        showAllProducts: "Alle produkter",
        showPlanogramProducts: "Planogramprodukter",
        showFavorites: "Vis favoritter",
        showAccessories: "Tilbehør",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Legg til bestillingsforslag i handlekurv",
        replaceCartDialog: {
            content:
                "Å legge til produktene i bestillingsforslaget i handlekurven vil føre til at eksisterende produkter fjernes.",
            title: "Erstatt handlekurv",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "Din bestilling er nå mottatt.",
        thankYouForYourPurchase: "Takk for ditt kjøp",
        yourOrderHasNowBeenReceived: "Din bestilling er nå mottatt",
        continueShopping: "Fortsett å handle",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Kunne ikke legge inn bestilling.",
        removeInvalidProductsBtn: "Fjern ugyldige produkter",
    },
    planogramPdfBtn: "Planogram PDF",
    productAlreadyInCart: "Allerede i handlekurven",
    productAlreadyInList: "Bestill i produktlisten",
    productHasBeenReplaced: "Du har erstattet {{productName}}",
    productTable: {
        articleNo: "Art.nr",
        comparisonPrice: "jf. pris",
        comparisonPriceNonFood: "jf. pris",
        dataSheet: "Datablad vers. {{version}}",
        icon: {
            frozen: "Frosset",
            inPlanogram: "I planogram",
            orderAssortment: "Bestillingssortiment",
            news: "Nyheter",
        },
        name: "navn",
        onTheWay: "under transport",
        pricePerUnit: "pris/enhet",
        selectRotationProduct: "Bytt",
        selectPriceListProduct: "Velg",
        unit: "enhet",
        monday: "Man",
        tuesday: "Tir",
        wednessday: "Ons",
        thursday: "Tor",
        friday: "Fre",
        comparisonTooltip: "Pris per {{unit}}: {{comparisonPrice}}",
        quantityLastWeek: "FU: {{quantityLastWeek}}",
        lastWeek: "FU = Forrige uke",
        copyOrderMats: "Kopier automatisk ordremal til fremtidige uker",
    },
    productMenu: {
        orderHelpMeals: "Order help meals",
        orderSuggestionsMeals: "Order suggestion meals",
        orderSuggestionsMealsDescription:
            "Here you can see the amount of each product needed for the number of meals you have chosen. The surplus / deficit column shows how much is left over or missing. NOTE Do not forget items for the bulk sale.",
        pricePerPortion: "Price / Portion",
        numPackages: "Number of packages",
        numPortions: "Number of portions",
        perPortion: "Per portion",
        excessWeightTitle: "Surplus / deficit",
        meals: "Meals",
        mealsDescription:
            "Order the desired number of portions per dish. If you order several meals containing, for example, mashed potatoes, the quantity is added together to minimize possible wastage.",
        createOrderSuggestion: "Create order suggestion",
        someProductsCannotBeDelivered:
            "One or more products in this meal can not be delivered. Click for more information.",
    },
    searchProduct: "Søk etter artikkelnummer eller navn",
    selectAReplacement: "Velg erstatningsprodukt",
    thresholdWarningDialog: {
        pleaseCheck:
            "Vennligst bekreft at antallet er ønsket mengde før du legger inn bestillingen.",
        title: "En uvanlig stor mengde",
        weHaveNoticed:
            "Vi har lagt merke til at mengden av produktet/pro­duktene nedenfor er uvanlig stor:",
    },
    unavailableRotationProducts: "Lukket produkt",
};
