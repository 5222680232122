export const orderHistory = {
    claim: {
        orderRowTitle: "Claim {{productName}}",
        orderTitle: "Claim order",
        info: "In the event of a missing delivery or other major complaints, call customer support {{phoneNumber}}",
        placeholderReason: "Select a reason for the claim.",
        bestBefore: "Best before:",
        batchCode: "Batch code:",
        outerBoxBroken: "Outer box damaged:",
        additionalInfo: "Additional information:",
        contactForTheClaim: "Name of the person making the claim:",
        contactEmail: "Email address:",
        picture: "Picture:",
        success: {
            title: "Thank you for your claim",
            content:
                "We will process your claim within 48 hours and come back to you if we have any questions.",
            bestRegards: "Best regards",
            picadeliCustomerService: "Picadeli Customer Service",
        },
        fullOrderText: "You are about to claim all the products in this order.",
    },
    claimStatus: {
        "not-available": "Not available",
        "call-customer-services": "Call customer service",
        "claimed": "Claimed",
    },
    table: {
        details: {
            deliveryAddress: "Delivery address",
            invoiceAddress: "Invoice address",
            orderedProducts: "Ordered products",
            table: {
                claimOrder: "Claim Full Order",
                cost: "Cost",
                deliveredQuantity: "Delivered",
                invoicedAt: "Invoiced",
                weight: "Net weight",
                orderNumber: "Order no.",
                orderedQuantity: "Ordered",
                picked: "Picked",
                productId: "Article no.",
                productName: "Article name",
                reclamation: "Claim",
                subtotal: "Subtotal",
            },
            copyToCart: "Copy order to cart",
        },
        header: {
            orderRef: "Ref",
            createdBy: "Created by",
            deliversTo: "Delivered to",
            deliveryDate: "Delivery date",
            status: "Status",
            timeCreated: "Created",
            totalCostExclVat: "Total cost excl VAT",
            totalWeight: "Total weight",
        },
        review: "Review",
    },
    thereIsNoOrderHistory: "There is no order history.",
};
