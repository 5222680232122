import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../state/configureStore";
import { logout, selectIsLoggedIn } from "../state/slices/userSlice";

const INACTIVITY_MS = 30 * 60 * 1000; // 30 minutes

export const InactivityDetector: FC = () => {
    const dispatch = useAppDispatch();
    const [lastActivity, setLastActivity] = useState<Date>(new Date());
    const userIsLoggedIn = useSelector(selectIsLoggedIn);
    const updateLastActivity = () => setLastActivity(new Date());

    useEffect(() => {
        const timer = setTimeout(() => {
            if (userIsLoggedIn) {
                console.log("Logging out the user due to inactivity");

                void (async () => {
                    await dispatch(logout());
                    window.location.reload();
                })();
            }
        }, INACTIVITY_MS);

        return () => clearTimeout(timer);
    }, [dispatch, lastActivity, userIsLoggedIn]);

    useEffect(() => {
        window.addEventListener("click", updateLastActivity);
        window.addEventListener("keypress", updateLastActivity);

        return () => {
            window.removeEventListener("click", updateLastActivity);
            window.removeEventListener("keypress", updateLastActivity);
        };
    }, []);

    return null;
};
