import { LandingResource } from "../locales";

export const landing: LandingResource = {
    news: "Uutiset",
    messages: "Viestit",
    home: "Koti",
    showAllNews: "Näytä kaikki uutiset ({{count}})",
    showAllMessages: "Näytä kaikki",
    myMessages: "Omat viestit",
    noMessages: "Sinulle ei ole viestejä",
    contactUs: "Ota meihin yhteyttä",
};
