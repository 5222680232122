import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrow from "@material-ui/icons/KeyboardArrowUp";
import cn from "classnames";
import React, { FC } from "react";

const iconRotationSpeed = 200;

const useStyles = makeStyles({
    "root": {
        whiteSpace: "nowrap",
    },
    "icon": {
        transform: "rotate(360deg)",
        transition: `transform ${iconRotationSpeed}ms ease-in-out`,
    },
    "iconClosed": {
        animation: `$rotate ${iconRotationSpeed}ms ease-in-out`,
        transform: "rotate(180deg)",
    },
    "@keyframes rotate": {
        "0%": { transform: "rotate(0)" },
        "100%": { transform: "rotate(180deg)" },
    },
});

type Props = {
    isOpen: boolean;
    openText: string;
    closeText: string;
    onClick: () => void;
    className?: string;
    size?: "small" | "medium" | "large";
};

export const RotateButton: FC<Props> = ({
    isOpen,
    onClick,
    openText,
    closeText,
    size,
    className,
}) => {
    const classes = useStyles();

    return (
        <Button
            className={cn(classes.root, className)}
            size={size}
            onClick={onClick}
            endIcon={
                <KeyboardArrow
                    className={cn(classes.icon, {
                        [classes.iconClosed]: !isOpen,
                    })}
                />
            }
        >
            {isOpen ? closeText : openText}
        </Button>
    );
};
