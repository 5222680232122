import {
    Button,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { DateTime } from "luxon";
import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DialogHeader } from "../../components/DialogHeader";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Form } from "../../components/form/InputForm";
import { InputResolver } from "../../components/form/InputResolver";
import { LoadingButton } from "../../components/LoadingButton";
import { UseDialogProps } from "../../hooks/useDialog";
import { useAppDispatch } from "../../state/configureStore";
import { selectCustomer } from "../../state/slices/customerSlice";
import {
    fetchProductTemperatures,
    fetchProductTemperaturesSpreadsheet,
    selectError,
    selectLoading,
    selectProductTemperatures,
    selectSpreadsheetLoading,
} from "../../state/slices/productTemperaturesSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { formatDateTime, formatTemperature } from "../../utils/formatters";

const useStyles = makeStyles(theme => ({
    dialog: {
        minHeight: "calc(100% - 64px)",
    },
    filter: {
        "display": "flex",
        "alignItems": "center",
        "& > .input-container": {
            marginRight: theme.spacing(1),
        },
        "marginBottom": theme.spacing(2),
    },
    filterBtn: {
        margin: "8px 8px 0 8px",
    },
}));

type Props = UseDialogProps;

const initialForm = {
    fromDate: "",
    toDate: "",
    productName: "",
};

export const ProductTemperaturesDialog: FC<Props> = ({ isOpen, close }) => {
    const { t } = useTranslation("reports");
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const locale = useSelector(selectLocale);
    const spreadsheetLoading = useSelector(selectSpreadsheetLoading);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const rows = useSelector(selectProductTemperatures);
    const customer = useSelector(selectCustomer);

    const [form, setForm] = useState<{
        fromDate: string;
        toDate: string;
        productName: string;
    }>(initialForm);

    useEffect(() => {
        if (isOpen) {
            void dispatch(fetchProductTemperatures({}));
        }
    }, [dispatch, isOpen]);

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        fetch();
    };

    const fetch = () => void dispatch(fetchProductTemperatures(form));

    const handleDownloadSpreadsheet = () =>
        void dispatch(fetchProductTemperaturesSpreadsheet(form));

    const handleReset = () => {
        setForm(initialForm);
        void dispatch(fetchProductTemperatures(initialForm));
    };

    return (
        <Dialog
            open={isOpen}
            onEscapeKeyDown={close}
            onBackdropClick={close}
            maxWidth="xl"
            className={classes.dialog}
        >
            <DialogHeader title={t("temperatureMeasurements.title")} onClose={close} />
            <DialogContent>
                <Form onSubmit={handleSubmit} className={classes.filter}>
                    <InputResolver
                        input={{
                            type: "date",
                            id: "fromDate",
                            value: form.fromDate,
                            onChange: ev => setForm(cur => ({ ...cur, fromDate: ev.target.value })),
                            label: t("temperatureMeasurements.filter.label.fromDate"),
                        }}
                    />
                    <InputResolver
                        input={{
                            type: "date",
                            id: "toDate",
                            value: form.toDate,
                            onChange: ev => setForm(cur => ({ ...cur, toDate: ev.target.value })),
                            label: t("temperatureMeasurements.filter.label.toDate"),
                        }}
                    />
                    <InputResolver
                        input={{
                            type: "text",
                            id: "productName",
                            value: form.productName,
                            onChange: ev =>
                                setForm(cur => ({ ...cur, productName: ev.target.value })),
                            label: t("temperatureMeasurements.filter.label.productName"),
                        }}
                    />
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        className={classes.filterBtn}
                        type="submit"
                        loading={loading}
                    >
                        {t("temperatureMeasurements.filter.btn.submit")}
                    </LoadingButton>
                    <LoadingButton
                        className={classes.filterBtn}
                        loading={spreadsheetLoading}
                        startIcon={<CloudDownloadIcon />}
                        onClick={handleDownloadSpreadsheet}
                    >
                        .XLSX
                    </LoadingButton>

                    <Button
                        className={classes.filterBtn}
                        onClick={handleReset}
                        startIcon={<RotateLeftIcon />}
                    >
                        {t("temperatureMeasurements.filter.btn.reset")}
                    </Button>
                </Form>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("temperatureMeasurements.table.header.time")}</TableCell>
                            <TableCell>
                                {t("temperatureMeasurements.table.header.productNo")}
                            </TableCell>
                            <TableCell>
                                {t("temperatureMeasurements.table.header.productName")}
                            </TableCell>
                            <TableCell>{t("temperatureMeasurements.table.header.batch")}</TableCell>
                            <TableCell>
                                {t("temperatureMeasurements.table.header.module")}
                            </TableCell>
                            <TableCell>
                                {t("temperatureMeasurements.table.header.checkedBy")}
                            </TableCell>
                            <TableCell>
                                {t("temperatureMeasurements.table.header.temperature")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={`${row.timeMeasured}${row.productId}${row.module}`}>
                                <TableCell>
                                    {formatDateTime(
                                        row.timeMeasured,
                                        locale,
                                        customer?.timeZone,
                                        DateTime.DATETIME_SHORT,
                                    )}
                                </TableCell>
                                <TableCell>{row.productId}</TableCell>
                                <TableCell>{row.productName}</TableCell>
                                <TableCell>{row.batchCode}</TableCell>
                                <TableCell>{row.module}</TableCell>
                                <TableCell>{row.temperatureCheckedBy}</TableCell>
                                <TableCell>{formatTemperature(row.temperature, locale)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {error && <ErrorMessage error={{ body: error }} />}
            </DialogContent>
        </Dialog>
    );
};
