import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { FC } from "react";
import { Card } from "../../components/Card";
import { H3 } from "../../components/Typography";

const useStyles = makeStyles({
    root: {
        marginTop: 20,
        flex: 1,
    },
    cardHeader: {
        display: "flex",
        padding: "25px 20px",
        marginTop: -20,
        marginLeft: -20,
        marginRight: -20,
        boxShadow: "0px 4px 14px rgb(24 55 42 / 5%)",
    },
    cardTitle: {
        marginLeft: 10,
    },
    icon: {
        width: 24,
        height: 24,
    },
});

type Props = {
    title: string;
    icon: JSX.Element;
};

export const AccountCard: FC<{ className?: string }> = ({ children, className }) => {
    const classes = useStyles();
    return <Card className={cn(className, classes.root)}>{children}</Card>;
};

export const AccountCardHeader: FC<Props> = ({ icon, title }) => {
    const classes = useStyles();

    return (
        <div className={classes.cardHeader}>
            <div className={classes.icon}>{icon}</div>
            <H3 className={classes.cardTitle}>{title}</H3>
        </div>
    );
};
