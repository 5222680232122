import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TabContext } from "@material-ui/lab";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import printIcon from "../../assets/images/icons/print.png";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Loader } from "../../components/Loader";
import { LoadingIconButton } from "../../components/LoadIconButton";
import { Page } from "../../components/Page";
import { H1 } from "../../components/Typography";
import { useAppDispatch } from "../../state/configureStore";
import { openDeliveryInfoDialog } from "../../state/slices/dialogsSlice";
import { selectDeliveryDate } from "../../state/slices/orderDetailsSlice";
import { clearPlaceOrderSuccess, selectPlaceOrderSuccess } from "../../state/slices/ordersSlice";
import { productsPdf, selectProductsPdfLoading } from "../../state/slices/pdfSlice";
import {
    selectProductsError,
    selectProductsLoading,
    selectProductTypes,
} from "../../state/slices/productSelectors";
import { searchProduct } from "../../state/slices/productsSlice";
import { ProductType } from "../../state/types";
import { Cart } from "./cart/Cart";
import { OrderPageFooter } from "./OrderPageFooter";
import { PlaceOrderSuccessPage } from "./PlaceOrderSuccessPage";
import { ProductSearch } from "./ProductSearch";
import { DeliTab } from "./tabs/DeliTab";
import { FoodToGoTab } from "./tabs/FoodToGoTab";
import { OrderTab as Tab } from "./tabs/OrderTab";
import { SaladBarTab } from "./tabs/SaladBarTab";
import { TabsContainer } from "./tabs/TabsContainer";
import { useSelectDeliveryDateFromQueryParams } from "./useSelectDeliveryDateFromQueryParams";

const useStyles = makeStyles({
    page: {
        paddingBottom: 80,
    },
    printIcon: {
        width: 40,
        height: 40,
    },
    printButton: {
        marginLeft: 16,
    },
});

export const OrderPage: FC = () => {
    const { t } = useTranslation("general");
    const dispatch = useAppDispatch();
    const [activeTabIndex, setTabIndex] = useState<number>(0);
    const deliveryDate = useSelector(selectDeliveryDate);
    const productsPdfLoading = useSelector(selectProductsPdfLoading);
    const productsLoading = useSelector(selectProductsLoading);
    const productsError = useSelector(selectProductsError);
    const placeOrderSuccess = useSelector(selectPlaceOrderSuccess);
    const productTypes = useSelector(selectProductTypes);
    useSelectDeliveryDateFromQueryParams();
    const classes = useStyles();

    const tabs = useMemo(
        () =>
            productTypes.map((productType, index) => ({
                label: t(`productTypes.${productType}` as const),
                tabIndex: index,
                productType,
                TabComponent: resolveTabComponent(productType),
            })) ?? [],
        [productTypes, t],
    );

    useEffect(() => {
        if (!deliveryDate && placeOrderSuccess === undefined) {
            dispatch(openDeliveryInfoDialog({ canClose: false }));
        }
    }, [dispatch, deliveryDate, placeOrderSuccess]);

    if (placeOrderSuccess) {
        return (
            <PlaceOrderSuccessPage
                orderNo={placeOrderSuccess.orderNo}
                onContinueShopping={() => dispatch(clearPlaceOrderSuccess())}
            />
        );
    }

    return (
        <Page className={classes.page}>
            <Cart />

            <TabContext value={activeTabIndex.toString()}>
                <Box display="flex" alignItems="center" mt={5} mb={5}>
                    {tabs.length === 1 ? (
                        <H1 lunchBox primary>
                            {t("articles")}
                        </H1>
                    ) : (
                        <TabsContainer
                            data-testid="product-types-tab-group"
                            value={activeTabIndex}
                            onChange={(event, v) => setTabIndex(v)}
                        >
                            {tabs.map(tab => (
                                <Tab
                                    key={`tab-${tab.label}`}
                                    label={tab.label}
                                    data-testid={`tab-${tab.productType}`}
                                />
                            ))}
                        </TabsContainer>
                    )}
                    {tabs.length > 0 && (
                        <>
                            <LoadingIconButton
                                loading={productsPdfLoading}
                                onClick={() => {
                                    const productType = tabs.find(
                                        tab => tab.tabIndex === activeTabIndex,
                                    )?.productType;
                                    if (deliveryDate && productType) {
                                        void dispatch(productsPdf({ deliveryDate, productType }));
                                    }
                                }}
                            >
                                <img className={classes.printIcon} src={printIcon} alt="print" />
                            </LoadingIconButton>
                            <ProductSearch
                                onChange={productName => dispatch(searchProduct(productName))}
                            />
                        </>
                    )}
                </Box>
                {tabs.map(({ label, TabComponent, tabIndex }) => (
                    <TabComponent key={`tab-content-${label}`} tabIndex={tabIndex} />
                ))}
                <Loader display="flex" justifyContent="center" m={4} show={productsLoading} />
                {productsError && <ErrorMessage error={{ body: productsError }} />}
            </TabContext>

            <OrderPageFooter />
        </Page>
    );
};

function resolveTabComponent(productType: ProductType) {
    switch (productType) {
        case ProductType.Deli:
            return DeliTab;
        case ProductType.FoodToGo:
            return FoodToGoTab;
        case ProductType.SaladBar:
            return SaladBarTab;
        default:
            throw new Error(`Unsupported tab: ${productType}`);
    }
}
