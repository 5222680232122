export const reports = {
    pageTitle: "Reports",
    links: {
        productTemperature: "Generate Product Temperatures Report",
        inventory: "Generate Inventory Report",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Reset",
                submit: "Filter",
            },
            label: {
                productName: "Product name",
                fromDate: "From date",
                toDate: "To date",
            },
        },
        table: {
            header: {
                productName: "Product name",
                productNo: "Product number",
                batch: "Batch",
                time: "Time",
                module: "Module",
                checkedBy: "Checked by",
                temperature: "Temperature",
            },
        },
        title: "Product temperatures",
        spreadsheet: "product-temperatures.xlsx",
    },
};
