import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import {
    FilterType,
    Product,
    ProductMenuItem,
    ProductType,
    ReplaceOrderProductPolicy,
} from "../types";
import { fetchCustomer } from "./customerSlice";

export type FetchProductsResponse = {
    products: Product[];
};

export type ClosedInfo = {
    productId: string;
    closedInfo: string;
};

export const fetchProducts = createAsyncThunk(
    "fetch-products",
    async ({
        deliveryDate,
        bypassDeadline,
        bypassProductAvailability,
    }: {
        deliveryDate: string;
        bypassDeadline: boolean;
        bypassProductAvailability: boolean;
    }) =>
        get<{ products: Product[]; productMenu: ProductMenuItem[] }>(Endpoint.products, {
            params: {
                "delivery-date": deliveryDate,
                "bypass-deadline": bypassDeadline ? "true" : undefined,
                "bypass-product-availability": bypassProductAvailability ? "true" : undefined,
            },
        }),
);

const productsAdapter = createEntityAdapter<Product>({
    selectId: product => product.productId,
});

export type ProductState = {
    loading: boolean;
    error?: string;
    search: string;
    filter: Record<ProductType, FilterType>;
    selectedRotationProducts: Record<string, string | undefined>;
};

export const initialState: ProductState = {
    loading: false,
    search: "",
    filter: {
        [ProductType.SaladBar]: FilterType.all,
        [ProductType.Deli]: FilterType.all,
        [ProductType.FoodToGo]: FilterType.all,
    },
    selectedRotationProducts: {},
};

const productsSlice = createSlice({
    name: "products",
    initialState: productsAdapter.getInitialState<ProductState>(initialState),
    reducers: {
        searchProduct: (state, { payload }: PayloadAction<string>) => {
            state.search = payload;
        },
        filterByType: (
            state,
            { payload }: PayloadAction<{ productType: ProductType; filterType: FilterType }>,
        ) => {
            state.filter[payload.productType] = payload.filterType;
        },
        setSelectedRotationProducts: (
            state,
            { payload }: PayloadAction<{ overridden: string; override?: string }>,
        ) => {
            state.selectedRotationProducts[payload.overridden] = payload.override;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchProducts.pending, state => {
            state.loading = true;
            state.error = undefined;
        });
        addCase(fetchProducts.fulfilled, (state, action) => {
            state.loading = false;
            productsAdapter.setAll(state, action.payload.products);
        });
        addCase(fetchProducts.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error.message;
            productsAdapter.removeAll(state);
        });
        addCase(fetchCustomer.fulfilled, (state, action) => {
            state.filter[ProductType.SaladBar] =
                action.payload.replaceOrderProductPolicy !== ReplaceOrderProductPolicy.ReplaceFreely
                    ? FilterType.planogram
                    : action.payload.showPlanogramTabAsDefault
                    ? FilterType.planogram
                    : FilterType.all;
        });
    },
});

export const { filterByType, searchProduct, setSelectedRotationProducts } = productsSlice.actions;

export const {
    selectById: selectProductById,
    selectAll: selectAllProducts,
    selectEntities: selectProductEntities,
} = productsAdapter.getSelectors<RootState>(state => state.products);

export const productsReducer = productsSlice.reducer;
