import { FC } from "react";
import { useAppSelector } from "../../state/configureStore";
import { selectCustomer } from "../../state/slices/customerSlice";
import { OrderMatPage } from "./OrderMatPage";
import { OrderPage } from "./OrderPage";

export const Orders: FC = () => {
    const customer = useAppSelector(selectCustomer);

    return customer?.canPlaceOrders ? (
        <OrderPage />
    ) : customer?.canPlaceOrderMats ? (
        <OrderMatPage />
    ) : null;
};
