import { OrderHistoryResource } from "../locales";

export const orderHistory: OrderHistoryResource = {
    claim: {
        orderRowTitle: "{{productName}} reklamieren",
        orderTitle: "Bestellung reklamieren",
        info: "Im Falle einer fehlenden Lieferung oder anderer schwerwiegender Beschwerden rufen Sie bitte den Kundendienst an unter {{phoneNumber}}",
        placeholderReason: "Wählen Sie einen Grund für die Reklamation aus.",
        bestBefore: "Mindesthaltbarkeitsdatum:",
        batchCode: "Batch-Code:",
        outerBoxBroken: "Außenkarton beschädigt:",
        additionalInfo: "Zusätzliche Informationen:",
        contactForTheClaim: "Kontakt für Reklamationen:",
        contactEmail: "E-Mail-Adresse:",
        picture: "Foto:",
        success: {
            title: "Vielen Dank für Ihre Kontaktaufnahme.",
            content:
                "Wir werden Ihre Reklamation innerhalb von 48 Stunden bearbeiten und uns bei Fragen bei Ihnen melden.",
            bestRegards: "Mit freundlichen Grüßen",
            picadeliCustomerService: "Picadeli-Kundenservice",
        },
        fullOrderText: "You are about to claim all the products in this order.",
    },
    claimStatus: {
        "not-available": "Nicht verfügbar",
        "call-customer-services": "Kundenservice anrufen",
        "claimed": "Reklamation angefordert",
    },
    table: {
        details: {
            deliveryAddress: "Lieferadresse",
            invoiceAddress: "Rechnungsadresse",
            orderedProducts: "Bestellte Produkte",
            table: {
                claimOrder: "Vollständige Bestellung reklamieren",
                cost: "Kosten",
                deliveredQuantity: "Geliefert",
                invoicedAt: "In Rechnung gestellt",
                weight: "Reingewicht",
                orderNumber: "Bestellnr.",
                orderedQuantity: "Bestellt",
                picked: "Bearbeitet",
                productId: "Artikelnr.",
                productName: "Artikelname",
                reclamation: "Reklamieren",
                subtotal: "Zwischensumme",
            },
            copyToCart: "Bestellung in den Warenkorb kopieren",
        },
        header: {
            orderRef: "Ref.",
            createdBy: "Erstellt von",
            deliversTo: "Lieferung an",
            deliveryDate: "Liefertermin",
            status: "Status",
            timeCreated: "Erstellt",
            totalCostExclVat: "Gesamtkosten, ohne MwSt.",
            totalWeight: "Gesamtgewicht",
        },
        review: "Prüfen",
    },
    thereIsNoOrderHistory: "Es gibt keinen Bestellverlauf.",
};
