import { Box, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DialogHeader } from "../../components/DialogHeader";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Form } from "../../components/form/InputForm";
import { InputResolver } from "../../components/form/InputResolver";
import { LoadingButton } from "../../components/LoadingButton";
import { Body1 } from "../../components/Typography";
import { useAppDispatch } from "../../state/configureStore";
import {
    claim,
    ClaimOrderBody,
    ClaimOrderRowBody,
    clearClaimError,
    selectClaimError,
    selectClaimLoading,
} from "../../state/slices/claimCodesSlice";
import {
    fetchCustomerContacts,
    selectSaladBarContact,
} from "../../state/slices/customerContactsSlice";
import { fetchOrderHistory } from "../../state/slices/ordersSlice";
import { ClaimCode, Visibility } from "../../state/types";
import { isSuccess } from "../../utils/redux";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    submit: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        alignSelf: "center",
    },
    imagePreview: {
        width: "50%",
    },
}));

type Props = {
    open: boolean;
    close: () => void;
    claimCodes: ClaimCode[];
    title: string;
    maxQuantity?: number;
    toClaimBody: (claimCode: ClaimCode, e: SyntheticEvent) => ClaimOrderRowBody | ClaimOrderBody;
    informativeText?: string;
};

export const formId = {
    reason: "reason",
    quantity: "quantity",
    batch: "batch",
    bestBefore: "bestBefore",
    boxBroken: "boxBroken",
    info: "info",
    contactEmail: "contactEmail",
    contactName: "contactName",
    image: "image",
};

const MIN_CLAIM_DATE = DateTime.now().minus({ days: 3 }).toISODate();
const MAX_CLAIM_DATE = DateTime.now().plus({ years: 6 }).toISODate();

export const ClaimDialog: FC<Props> = ({
    open,
    close,
    claimCodes,
    title,
    maxQuantity,
    toClaimBody,
    informativeText,
}) => {
    const [selectedClaimCode, setSelectedClaim] = useState<ClaimCode>();
    const { t } = useTranslation(["general", "orderHistory"]);
    const claimError = useSelector(selectClaimError);
    const claimLoading = useSelector(selectClaimLoading);
    const saladBarContact = useSelector(selectSaladBarContact);
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const handleSubmit = async (e: SyntheticEvent) => {
        if (!selectedClaimCode) {
            return;
        }
        e.preventDefault();
        const claimBody = toClaimBody(selectedClaimCode, e);
        const thunkResponse = await dispatch(claim(claimBody));
        if (isSuccess(thunkResponse, claim)) {
            close();
            await dispatch(fetchOrderHistory({ page: 0 }));
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            onBackdropClick={close}
            open={open}
            onEnter={() => {
                void dispatch(fetchCustomerContacts());
                setSelectedClaim(undefined);
            }}
            onExited={() => dispatch(clearClaimError())}
        >
            <DialogHeader title={title} onClose={close} />
            <DialogContent>
                {informativeText && (
                    <Box mb={2}>
                        <Body1>{informativeText}</Body1>
                    </Box>
                )}
                <Form onSubmit={handleSubmit} className={classes.form}>
                    <InputResolver
                        input={{
                            type: "select",
                            id: formId.reason,
                            required: true,
                            menuItems: claimCodes.map(c => ({
                                id: c.claimCodeId.toString(),
                                description: c.description,
                            })),
                            onChange: e =>
                                setSelectedClaim(
                                    claimCodes.find(
                                        c => c.claimCodeId === parseInt(`${e.target.value}`, 10),
                                    ),
                                ),
                            label: t("orderHistory:claim.placeholderReason"),
                            defaultValue: t("orderHistory:claim.placeholderReason"),
                        }}
                    />

                    {selectedClaimCode && (
                        <>
                            <InputResolver
                                input={{
                                    hidden: !selectedClaimCode?.quantityHeader,
                                    type: "number",
                                    id: formId.quantity,
                                    min: 1,
                                    max: maxQuantity,
                                    required: true,
                                    label: selectedClaimCode?.quantityHeader ?? "",
                                }}
                            />

                            <InputResolver
                                input={{
                                    id: formId.bestBefore,
                                    type: "date",
                                    min: MIN_CLAIM_DATE,
                                    max: MAX_CLAIM_DATE,
                                    hidden: selectedClaimCode.bestBefore === Visibility.Hidden,
                                    required:
                                        selectedClaimCode.bestBefore ===
                                        Visibility.VisibleMandatory,
                                    label: t("orderHistory:claim.bestBefore"),
                                }}
                            />

                            <InputResolver
                                input={{
                                    id: formId.batch,
                                    type: "text",
                                    hidden: selectedClaimCode.batch === Visibility.Hidden,
                                    required:
                                        selectedClaimCode.batch === Visibility.VisibleMandatory,
                                    maxLength: 60,
                                    label: t("orderHistory:claim.batchCode"),
                                }}
                            />

                            <InputResolver
                                input={{
                                    id: formId.boxBroken,
                                    type: "checkbox",
                                    hidden: selectedClaimCode.boxBroken === Visibility.Hidden,
                                    required:
                                        selectedClaimCode.boxBroken === Visibility.VisibleMandatory,
                                    label: t("orderHistory:claim.outerBoxBroken"),
                                }}
                            />

                            <InputResolver
                                input={{
                                    id: formId.info,
                                    type: "text-area",
                                    hidden: selectedClaimCode.info === Visibility.Hidden,
                                    rows: 5,
                                    maxLength: 255,
                                    required:
                                        selectedClaimCode.info === Visibility.VisibleMandatory,
                                    label: t("orderHistory:claim.additionalInfo"),
                                }}
                            />

                            <InputResolver
                                input={{
                                    id: formId.contactName,
                                    type: "text",
                                    required: true,
                                    label: t("orderHistory:claim.contactForTheClaim"),
                                    maxLength: 200,
                                    defaultValue: saladBarContact?.name,
                                }}
                            />

                            <InputResolver
                                input={{
                                    id: formId.contactEmail,
                                    type: "email",
                                    required: true,
                                    label: t("orderHistory:claim.contactEmail"),
                                    maxLength: 200,
                                    defaultValue: saladBarContact?.email,
                                }}
                            />

                            <InputResolver
                                input={{
                                    hidden: selectedClaimCode.image === Visibility.Hidden,
                                    id: formId.image,
                                    type: "file",
                                    label: t("orderHistory:claim.picture"),
                                }}
                            />

                            {claimError && <ErrorMessage error={{ body: claimError }} />}
                            <LoadingButton
                                color="primary"
                                className={classes.submit}
                                type="submit"
                                variant="contained"
                                loading={claimLoading}
                            >
                                {t("general:actions.submit")}
                            </LoadingButton>
                        </>
                    )}
                </Form>
            </DialogContent>
        </Dialog>
    );
};
