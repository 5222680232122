import { Button, ButtonProps, createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { colors } from "../../../../constants/colors";

type DateButtonProps = { highlight: boolean };
export const DateButton = withStyles(theme =>
    createStyles({
        root: {
            minWidth: 34,
            width: 34,
            borderRadius: 6,
            height: 34,
            fontSize: theme.typography.pxToRem(18),
            backgroundColor: (props: DateButtonProps) =>
                props.highlight ? colors.heroGreen : colors.white,
            color: (props: DateButtonProps) => (props.highlight ? colors.white : colors.black),
            borderColor: "rgb(245, 245, 245)",
        },
        label: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        outlined: {
            "&:hover": {
                backgroundColor: (props: DateButtonProps) =>
                    props.highlight ? colors.heroGreen : colors.white,
                color: (props: DateButtonProps) => (props.highlight ? colors.white : colors.black),
            },
        },
    }),
)(({ highlight, ...props }: ButtonProps & DateButtonProps) => (
    <Button {...props} variant="outlined" />
));
