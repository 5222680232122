import { Button, makeStyles } from "@material-ui/core";
import cn from "classnames";
import React, { FC, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormField } from "../../hooks/form/useCustomerIdField";
import { useAppDispatch } from "../../state/configureStore";
import {
    clearResetPasswordError,
    resetPassword,
    selectUserState,
} from "../../state/slices/userSlice";
import { AuthInput } from "./AuthInput";
import { AuthPage } from "./AuthPage";
import { AuthSubmitButton } from "./AuthSubmitButton";

const useStyles = makeStyles(theme => ({
    btn: {
        minWidth: 260,
        alignSelf: "center",
    },
    sendBtn: {
        marginTop: theme.spacing(3),
    },
    backToLoginBtn: {
        marginTop: "1em",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
}));

export const ForgotPasswordPage: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("auth");
    const { resetPasswordError, resetPasswordLoading } = useSelector(selectUserState);
    const customerIdField = useFormField();

    const handleSendNewPassword = (ev: FormEvent) => {
        ev.preventDefault();
        void dispatch(resetPassword(customerIdField.asInt()));
    };

    const handleBackToLogin = () => history.replace("/login");

    useEffect(() => () => void dispatch(clearResetPasswordError()), [dispatch]);

    return (
        <AuthPage
            errorMessage={resetPasswordError}
            description={t("forgotPassword.description")}
            title={t("forgotPassword.title")}
        >
            <form className={classes.form} onSubmit={handleSendNewPassword}>
                <AuthInput
                    autoFocus
                    label={t("forgotPassword.customerIdLbl")}
                    value={customerIdField.value}
                    onChange={customerIdField.onChange}
                    autoComplete="username"
                    name="username"
                />
                <AuthSubmitButton
                    className={cn(classes.btn, classes.sendBtn)}
                    loading={resetPasswordLoading}
                >
                    {t("forgotPassword.submitBtn")}
                </AuthSubmitButton>
                <Button
                    className={cn(classes.btn, classes.backToLoginBtn)}
                    onClick={handleBackToLogin}
                >
                    {t("common.backToLoginBtn")}
                </Button>
            </form>
        </AuthPage>
    );
};
