import { CustomerSupportResource } from "../locales";

export const customerSupport: CustomerSupportResource = {
    title: "Welkom bij de klantenservice van Picadeli",
    contactUs: "Contact opnemen",
    openingHours: "Openingstijden",
    email: "E-mailadres: {{value}}",
    phone: "Telefoonnummer:  {{value}}",
    openingHoursStart: "Opent om: {{value}}",
    openingHoursEnd: "Sluit om: {{value}}",
};
