import { GeneralResource } from "../locales";

export const general: GeneralResource = {
    actions: {
        review: "Controleren",
        close: "Sluiten",
        back: "Terug",
        ok: "OK",
        print: "Afdrukken",
        printArticleNumberScheme: "Afdrukken - overzicht met artikelnummers",
        submit: "Verzenden",
        save: "Opslaan",
        edit: "Bewerken",
        cancel: "Annuleren",
        loadMore: "Meer laden",
    },
    articles: "Artikelen",
    edit: "Bewerken",
    navigationLinks: {
        createUser: "Gebruiker maken",
        changePassword: "Wachtwoord wijzigen",
        communication: "Communicatie",
        guidance: "Hulp",
        support: "Klantenservice",
        logout: "Afmelden",
        account: "Account",
        myAccount: "Mijn account",
        order: "Bestellen",
        orderHistory: "Bestelgeschiedenis",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "Geen tijd meer",
        short: {
            days: "{{days}}d",
            hours: "{{hours}}h",
            minutes: "{{minutes}}m",
            seconds: "{{seconds}}s",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Bestelling ontvangen",
        "created": "Bevestigd",
        "dispatched": "Verzamelen",
        "canceled": "Geannuleerd",
        "invoiced": "Gefactureerd",
    },
    price: {
        total: "Totaal",
        totalExclVat: "Totaal (excl. btw)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Meenemen",
        "salad-bar": "Saladebar",
    },
    salesUnit: {
        kg: "Kg",
        krt: "Pakket",
        pcs: "Per stuk",
    },
    address: {
        invoice: "Factuuradres",
        delivery: "Bezorgingsadres",
    },
    weight: "Gewicht",
    loggedInAsCustomer: "Aangemeld als klant",
};
