import { AuthResource } from "../locales";

export const auth: AuthResource = {
    createUser: {
        title: "Créer un utilisateur",
        submit: "Créer un utilisateur",
        newPasswordIs: "Le mot de passe est:",
        emailLabel: "E-mail pour réinitialiser le mot de passe",
    },
    common: {
        backToLoginBtn: "Retour connexion",
    },
    login: {
        title: "PORTAIL CLIENT",
        description: "Connectez-vous pour commander vos produits depuis la boutique en ligne.",
        forgotPasswordBtn: "Mot de passe oublié ?",
        loginBtn: "Connexion",
        passwordLbl: "Mot de passe",
        usernameLbl: "Identifiant client",
    },
    forgotPassword: {
        resetPasswordSuccessNoContent:
            "Un lien a été envoyé à votre e-mail où vous pouvez changer le mot de passe. Si vous n'avez pas reçu d'e-mail, appelez le service client.",
        resetPasswordSuccessInfo:
            "Un lien a été envoyé à votre e-mail où vous pouvez changer le mot de passe.",
        customerIdLbl: "Identifiant client",
        submitBtn: "Réinitialiser le mot de passe",
        description:
            "Veuillez entrer votre numéro de client et nous vous enverrons une réinitialisation du mot de passe à l'e-mail enregistré.",
    },
    password: {
        managePassword: "Gérer mot de passe",
        passwordEmpty: "Le mot de passe est vide.",
        createUserSuccess: "L'utilisateur a été créé.",
        passwordMismatch: "Les mots de passe ne correspondent pas.",
        changePasswordSuccess: "Le mot de passe a été changé.",
        changePassword: "Changer mot de passe",
        setPassword: "Définir mot de passe",
        oldPassword: "Ancien mot de passe",
        newPassword: "Nouveau mot de passe",
        confirmNewPassword: "Confirmer le nouveau mot de passe",
        submit: "Changer mot de passe",
    },
};
