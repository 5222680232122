import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { CSSProperties, FC, SyntheticEvent } from "react";
import { colors } from "../constants/colors";

const useStyles = makeStyles({
    container: {
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: 20,
        boxShadow: "0px 4px 25px rgba(24, 55, 42, 0.05)",
    },
});

export const Card: FC<{
    className?: string;
    onClick?: (ev: SyntheticEvent) => void;
    style?: CSSProperties;
}> = ({ className, onClick, style, children }) => {
    const classes = useStyles();
    return (
        <div className={cn(classes.container, className)} onClick={onClick}>
            {children}
        </div>
    );
};
