import { Box, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Body2 } from "../../../../components/Typography";
import { selectCartDates } from "../../../../state/slices/cartSlice";
import { openDeliveryInfoDialog } from "../../../../state/slices/dialogsSlice";
import { CartSmallText } from "../CartSmallText";
import { HeaderColumn } from "../HeaderColumn";
import { DateButton } from "./DateButton";

const useStyles = makeStyles({
    dayOfWeek: {
        textTransform: "capitalize",
    },
});

export const DeliveryDateColumn: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation("order");
    const dates = useSelector(selectCartDates);

    return (
        <HeaderColumn flexBasis={242}>
            <Body2>{t("cart.selectedDeliveryDate")}</Body2>
            <Box display="flex" flexWrap="wrap" mt={1}>
                {dates.map(d => (
                    <Box
                        key={`delivery-info-${d.dayOfWeek}-${d.dayOfMonth}`}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mr={1}
                    >
                        <CartSmallText className={classes.dayOfWeek}>{d.dayOfWeek}</CartSmallText>
                        <DateButton
                            highlight={d.selected}
                            onClick={() => dispatch(openDeliveryInfoDialog({ canClose: true }))}
                        >
                            {d.dayOfMonth}
                        </DateButton>
                    </Box>
                ))}
            </Box>
        </HeaderColumn>
    );
};
