import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getPersistedLocale, locales } from "./assets/locales/locales";
import { config } from "./config";

i18n.use(initReactI18next)
    .init({
        compatibilityJSON: "v3",
        resources: locales,
        debug: !config.isProd && process.env.NODE_ENV !== "test",
        pluralSeparator: "_",
        lng: getPersistedLocale(),
        fallbackLng: "en-US",
        load: "currentOnly",
    })
    .catch(error => {
        console.error(`error configuring i18n: ${error}`);
    });

setHTMLLang(i18n.language);

i18n.on("languageChanged", lng => setHTMLLang(lng));

function setHTMLLang(i18nLang: string) {
    const docLang = i18nLang.slice(0, 2);
    console.log(`Setting lang="${docLang}" on <html> element`);
    document.documentElement.setAttribute("lang", docLang);
}
