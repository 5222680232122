import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2 } from "../../components/Typography";
import { selectCustomer } from "../../state/slices/customerSlice";

const useStyles = makeStyles(theme => ({
    addressInfo: {
        display: "flex",
    },
    address: {
        "marginLeft": 100,
        "display": "flex",
        "flexDirection": "column",
        "& > *": {
            margin: 0,
        },
        "& > p": {
            lineHeight: "25px",
        },
    },
}));

export const AddressInformation: FC = () => {
    const customer = useSelector(selectCustomer);
    const classes = useStyles();
    const { t } = useTranslation(["myAccount", "auth", "general"]);

    return (
        <div className={classes.addressInfo}>
            <div className={classes.address}>
                <Body2 bold>{t("general:address.invoice")}:</Body2>
                <Body2>{customer?.invoiceAddress.name}</Body2>
                <Body2>{customer?.invoiceAddress.coAddress}</Body2>
                <Body2>{customer?.invoiceAddress.street}</Body2>
                <Body2>
                    {customer?.invoiceAddress.city}, {customer?.invoiceAddress.postalNo}
                </Body2>
                <Body2>{customer?.invoiceAddress.countryCode}</Body2>
            </div>

            <div className={classes.address}>
                <Body2 bold>{t("general:address.delivery")}:</Body2>
                <Body2>{customer?.deliveryAddress.name}</Body2>
                <Body2>{customer?.deliveryAddress.coAddress}</Body2>
                <Body2>{customer?.deliveryAddress.street}</Body2>
                <Body2>
                    {customer?.deliveryAddress.city}, {customer?.deliveryAddress.postalNo}
                </Body2>
                <Body2>{customer?.deliveryAddress.countryCode}</Body2>
            </div>
        </div>
    );
};
