import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2 } from "../../../../components/Typography";
import { selectShippingInfo } from "../../../../state/slices/cartSlice";
import { selectCurrency } from "../../../../state/slices/customerSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatPrice } from "../../../../utils/formatters";
import { Banner } from "./Banner";

export const ShippingBanners: FC = () => {
    const { t } = useTranslation("order");
    const locale = useSelector(selectLocale);
    const currency = useSelector(selectCurrency);
    const shippingInfo = useSelector(selectShippingInfo);

    return (
        <>
            {shippingInfo.map(info => {
                if (!info.hasProducts) {
                    return null;
                }

                let body;
                const Title = () => (
                    <Body2 bold>{t(`cart.shipping.banner.${info.type}` as const)}</Body2>
                );

                // A red banner is displayed when an assortment order value is below the minimum order value limit
                // In the red banner the user can see the minimum order limit and how much more they need to purchase to get over the limit.
                if (info.isBelowMinThreshold) {
                    body = (
                        <Banner severity="error">
                            <Title />
                            <Body2>
                                {t("cart.shipping.ranges.belowMin", {
                                    thresholdMin: formatPrice(info.thresholdMin, currency, locale),
                                    remainingCost: formatPrice(
                                        info.thresholdMin - info.orderValue,
                                        currency,
                                        locale,
                                    ),
                                })}
                            </Body2>
                        </Banner>
                    );
                }

                // A green banner is displayed when an assortment order value is above the freight limit
                else if (info.isFreeShipping) {
                    body = (
                        <Banner>
                            <Title />
                            <Body2>{t("cart.shipping.free.title")}</Body2>
                        </Banner>
                    );
                }

                // A yellow banner is displayed when an assortment order value is between the minimum order and freight limit.
                // In the yellow banner the user can see the freight limit and how much freight cost will be applied and how much
                // more they need to purchase to get over the free freight limit.
                else {
                    body = (
                        <Banner severity="warning">
                            <Title />
                            <Body2>
                                {t("cart.shipping.ranges.betweenMinAndFree", {
                                    thresholdFree: formatPrice(
                                        info.thresholdFree,
                                        currency,
                                        locale,
                                    ),
                                    remainingCost: formatPrice(
                                        info.thresholdFree - info.orderValue,
                                        currency,
                                        locale,
                                    ),
                                    charge: formatPrice(info.price, currency, locale),
                                })}
                            </Body2>
                        </Banner>
                    );
                }

                return <React.Fragment key={`banner-${info.type}`}>{body}</React.Fragment>;
            })}
        </>
    );
};
