import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Body2, H3 } from "../../../../components/Typography";
import { selectProductTypesWeight } from "../../../../state/slices/cartSlice";
import { selectProductTypes } from "../../../../state/slices/productSelectors";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatWeight } from "../../../../utils/formatters";
import { WeightDivider } from "./WeightDivider";

export const ProductTypesWeight: FC = () => {
    const { t } = useTranslation(["order", "general"]);
    const locale = useSelector(selectLocale);
    const productTypes = useSelector(selectProductTypes);
    const weightsByProductType = useSelector(selectProductTypesWeight);

    const weights = useMemo(
        () =>
            productTypes.map(type => ({
                title: t(`general:productTypes.${type}` as const),
                weight: weightsByProductType[type],
            })) ?? [],
        [productTypes, t, weightsByProductType],
    );

    return (
        <>
            <Body2 noWrap>
                {t("general:weight")}{" "}
                {weights.map(({ title }, index) => {
                    const isLast = index === weights.length - 1;
                    return (
                        <React.Fragment key={`weight-title-${title}`}>
                            {title}
                            {!isLast && " / "}
                        </React.Fragment>
                    );
                })}
            </Body2>

            <H3 noWrap>
                {weights.map(({ weight, title }, index) => {
                    const isLast = index === weights.length - 1;
                    return (
                        <React.Fragment key={`weight-kg-${title}`}>
                            {formatWeight(weight, locale)}
                            {!isLast && <WeightDivider />}
                        </React.Fragment>
                    );
                })}
            </H3>
        </>
    );
};
