import { FC, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";

type Props = {
    quantity?: number;
    onQuantityChange: (quantity: number) => void;
    debounce?: number;
    className?: string;
    placeholderValue?: string;
    onFocus?: () => void;
    disabled?: boolean;
    min?: number;
    max?: number;
};

export const DebouncedTextField: FC<Props> = ({
    quantity,
    onQuantityChange,
    debounce = 300,
    className,
    placeholderValue,
    onFocus,
    disabled,
    min,
    max,
}) => {
    const [isFocused, setFocused] = useState(false);
    const [value, setValue] = useState(quantity);
    const { debouncedValue, setDebouncedValue } = useDebounce(value, debounce);

    useEffect(() => setValue(quantity), [quantity]);

    useEffect(() => {
        if (value !== undefined && debouncedValue !== quantity) {
            onQuantityChange(value);
        }
    }, [debouncedValue]); /* eslint-disable-line */ // We only want to trigger effect when debounce value changes

    return (
        <input
            data-testid="qty"
            className={className}
            placeholder={isFocused ? undefined : placeholderValue ?? "0"}
            onFocus={event => {
                event.target.select();
                setFocused(true);
                onFocus?.();
            }}
            onBlur={() => {
                if (value === undefined) {
                    setValue(quantity);
                } else {
                    setDebouncedValue(value);
                }
                setFocused(false);
            }}
            value={value ?? ""}
            onChange={event => {
                event.preventDefault();
                const parsedValue = parseInt(event.target.value, 10);
                setValue(isNaN(parsedValue) ? undefined : clamp(parsedValue, min, max));
            }}
            disabled={disabled}
        />
    );
};

function clamp(value: number, min?: number, max?: number) {
    if (min !== undefined && value < min) return min;
    if (max !== undefined && value > max) return max;
    return value;
}
