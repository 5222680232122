import { ReportsResource } from "../locales";

export const reports: ReportsResource = {
    pageTitle: "Rapporten",
    links: {
        productTemperature: "Producttemperatuurrapport genereren",
        inventory: "Voorraadrapportage maken",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Resetten",
                submit: "Filter",
            },
            label: {
                productName: "Productnaam",
                fromDate: "Vanaf-datum",
                toDate: "Tot-datum",
            },
        },
        table: {
            header: {
                productName: "Productnaam",
                productNo: "Productnummer",
                batch: "Partij",
                time: "Tijd",
                module: "Module",
                checkedBy: "Gecontroleerd door",
                temperature: "Temperatuur",
            },
        },
        title: "Producttemperaturen",
        spreadsheet: "producttemperaturen.xlsx",
    },
};
