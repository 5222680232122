import { PageNotFoundResource } from "../locales";

export const pageNotFound: PageNotFoundResource = {
    title: "Oeps!",
    subTitle: "We kunnen de pagina die je zoekt niet vinden.",
    errorCode: "Foutmelding: 404",
    usefulLinks: "In plaats daarvan zijn hier wat handige links:",
    links: {
        home: "Startpagina",
        order: "Bestellen",
        myAccount: "Mijn account",
    },
};
