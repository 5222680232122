import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../components/Card";
import { H2 } from "../../components/Typography";
import { Message } from "../../state/types";
import { Breadcrumbs } from "./Breadcrumbs";
import { ShortMessage } from "./ShortMessage";

const useStyles = makeStyles({
    container: {},
    messages: {
        "display": "flex",
        "flexDirection": "column",
        "& > div": {
            borderRadius: 10,
            margin: "10px 0",
        },
    },
});

type Props = {
    messages: Message[];
    onBack: () => void;
    onMessageClick: (message: Message) => void;
};

export const MessagesView: FC<Props> = ({ messages, onMessageClick, onBack }) => {
    const classes = useStyles();
    const { t } = useTranslation("landing");
    return (
        <div className={classes.container}>
            <Breadcrumbs links={[{ name: t("home"), onClick: onBack }]} />
            <H2>{t("messages")}</H2>
            <div className={classes.messages}>
                {messages.map(message => (
                    <Card key={message.messageId}>
                        <ShortMessage onClick={() => onMessageClick(message)} message={message} />
                    </Card>
                ))}
            </div>
        </div>
    );
};
