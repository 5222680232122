import {
    Box,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableRowProps,
} from "@material-ui/core";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../../../components/Image";
import { Body1, H2 } from "../../../../components/Typography";
import { colors } from "../../../../constants/colors";
import { useAppSelector } from "../../../../state/configureStore";
import { selectClosedInfoEntities } from "../../../../state/slices/closedProductsSlice";
import { selectMenuEntities } from "../../../../state/slices/productMenuSlice";
import { selectProductEntities } from "../../../../state/slices/productsSlice";
import { selectLocale } from "../../../../state/slices/userSlice";
import { formatWeight } from "../../../../utils/formatters";

const StyledProductImage = styled(Image)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));
const StyledCollapsedRoot = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: theme.spacing(2),
}));
type StyledTableRowProps = TableRowProps & { attention: boolean };
const StyledTableRow = styled(({ attention, ...props }) => <TableRow {...props} />)({
    "color": (props: StyledTableRowProps) => (props.attention ? colors.attentionRed : undefined),
    "& > .MuiTableCell-body ": {
        color: "inherit",
    },
});
type Props = {
    productId: string;
};

export const CollapsedMenu: FC<Props> = memo(({ productId }) => {
    const { t } = useTranslation(["order"]);
    const productMenuMap = useAppSelector(selectMenuEntities);
    const productMap = useAppSelector(selectProductEntities);
    const locale = useAppSelector(selectLocale);
    const closedInfos = useAppSelector(selectClosedInfoEntities);
    const menuProduct = productMap[productId];
    const menuItem = productMenuMap[productId];
    if (!menuItem || !menuProduct) {
        return null;
    }
    return (
        <StyledCollapsedRoot>
            <Box>
                <H2>{menuProduct.name}</H2>
                <Body1 bold>{t("order:productMenu.perPortion")}</Body1>
                <Table>
                    <TableBody>
                        {menuItem.products.map(p => {
                            const product = productMap[p.productId];
                            if (!product) {
                                return null;
                            }
                            const closedInfo = closedInfos[p.productId];
                            return (
                                <StyledTableRow
                                    attention={closedInfo !== undefined}
                                    key={product.productId}
                                >
                                    <TableCell>{product.productId}</TableCell>
                                    <TableCell>{product.legalName ?? "-"}</TableCell>
                                    <TableCell>{closedInfo?.closedInfo}</TableCell>
                                    <TableCell>
                                        {formatWeight(p.quantity * product.drainedWeight, locale, {
                                            maximumFractionDigits: 2,
                                            showGrams: true,
                                        })}
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <StyledProductImage
                center
                width={200}
                height={200}
                src={menuProduct.imageSrc["60"]}
                srcSet={`
                    ${menuProduct.imageSrc["60"]} 1x,
                    ${menuProduct.imageSrc["120"]} 2x
                `}
                fullscreen={{
                    src: menuProduct.imageSrc["640"],
                    imageWidth: 640,
                    imageHeight: 640,
                }}
                alt={menuProduct.name}
            />
        </StyledCollapsedRoot>
    );
});
