import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import cn from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import { useAppSelector } from "../../../state/configureStore";
import { selectAssortmentById } from "../../../state/slices/assortmentsSlice";
import { selectCustomer } from "../../../state/slices/customerSlice";
import { selectLocale } from "../../../state/slices/userSlice";
import { formatDate, formatDateTime } from "../../../utils/formatters";

const useStyles = makeStyles(theme => ({
    tr: {
        cursor: "pointer",
    },
    firstAssortment: {
        "& > td": {
            paddingTop: theme.spacing(1),
        },
    },
    lastAssortment: {
        "& > td": {
            paddingBottom: theme.spacing(1),
        },
    },
    showBorder: {
        "& > td": {
            borderBottom: `1px solid #dfdbdb`,
        },
    },
    passedDeadline: {
        textDecoration: "line-through",
        color: colors.attentionRed,
    },
    td: {
        borderBottom: "none",
        height: "100%",
    },
    deliveryDate: {
        whiteSpace: "nowrap",
        textTransform: "capitalize",
        fontWeight: "bold",
    },
    orderDeadline: {
        whiteSpace: "nowrap",
        textTransform: "capitalize",
    },
    orderSuggestionAvailable: {
        color: colors.heroGreen,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    orderSuggestionAvailableIcon: {
        height: 20,
    },
    orderSuggestionAvailableText: {
        fontSize: 14,
        marginLeft: 5,
    },
}));

type Props = {
    assortmentId: number;
    deadline: string;
    deliveryDate: string;
    isFirstAssortment: boolean;
    isLastAssortment: boolean;
    isLastDeliveryDate: boolean;
    onSelectDate: (deliveryDate: string) => void;
    hasPassedDeadline: boolean;
    isOrderSuggestionAvailable: boolean;
};

export const DeliveryDateRow: FC<Props> = ({
    assortmentId,
    deadline,
    deliveryDate,
    isFirstAssortment,
    isLastAssortment,
    isLastDeliveryDate,
    onSelectDate,
    hasPassedDeadline,
    isOrderSuggestionAvailable,
}) => {
    const { t } = useTranslation("order");
    const classes = useStyles();
    const assortment = useAppSelector(state => selectAssortmentById(state, assortmentId));
    const locale = useSelector(selectLocale);
    const customer = useSelector(selectCustomer);

    return (
        <TableRow
            data-testid="delivery-date-row"
            onClick={() => onSelectDate(deliveryDate)}
            className={cn(classes.tr, {
                [classes.firstAssortment]: isFirstAssortment,
                [classes.lastAssortment]: isLastAssortment,
                [classes.showBorder]: isLastAssortment && !isLastDeliveryDate,
            })}
        >
            <TableCell className={cn(classes.deliveryDate, classes.td)} scope="row">
                {isFirstAssortment &&
                    formatDate(deliveryDate, locale, {
                        day: "2-digit",
                        month: "2-digit",
                        weekday: "short",
                        year: "numeric",
                    })}
            </TableCell>
            <TableCell className={cn(classes.td, classes.orderDeadline)} scope="row">
                <div
                    className={cn({
                        [classes.passedDeadline]: hasPassedDeadline,
                    })}
                >
                    {formatDateTime(deadline, locale, customer?.timeZone, {
                        weekday: "short",
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        year: "numeric",
                    })}
                </div>
                {isOrderSuggestionAvailable && isLastAssortment && (
                    <div className={classes.orderSuggestionAvailable}>
                        <Info className={classes.orderSuggestionAvailableIcon} />
                        <span className={classes.orderSuggestionAvailableText}>
                            {t("deliveryInfo.table.orderSuggestionAvailable")}
                        </span>
                    </div>
                )}
            </TableCell>
            <TableCell className={classes.td} scope="row">
                {assortment?.name ?? ""}
            </TableCell>
            <TableCell className={classes.td} scope="row" />
        </TableRow>
    );
};
