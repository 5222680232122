import { Dialog, DialogContent, IconButton, styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";

const StyledDialog = styled(Dialog)({
    cursor: "pointer",
});
const StyledClosedBtn = styled(IconButton)({
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 1,
    color: "white",
});
const StyledDialogContent = styled(DialogContent)({
    backgroundColor: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

type Props = {
    isOpen: boolean;
    close: () => void;
    img?: {
        src: string;
        srcSet?: string;
        imageWidth: number;
        imageHeight: number;
        alt: string;
    };
};

export const FullscreenImage: React.FC<Props> = ({ img, isOpen, close }) => {
    return (
        <StyledDialog
            fullScreen
            open={isOpen}
            onClick={e => {
                e.stopPropagation();
                close();
            }}
        >
            <StyledClosedBtn
                aria-label="close"
                onClick={e => {
                    e.stopPropagation();
                    close();
                }}
            >
                <CloseIcon />
            </StyledClosedBtn>
            <StyledDialogContent>
                {img && (
                    <img
                        src={img.src}
                        width={img.imageWidth}
                        height={img.imageHeight}
                        srcSet={img.srcSet}
                        alt={img.alt}
                    />
                )}
            </StyledDialogContent>
        </StyledDialog>
    );
};
