import { AuthResource } from "../locales";

export const auth: AuthResource = {
    createUser: {
        title: "Opprett bruker",
        submit: "Opprett bruker",
        newPasswordIs: "Passordet er:",
        emailLabel: "E-post for å tilbakestille passord",
    },
    common: {
        backToLoginBtn: "Tilbake til innlogging",
    },
    login: {
        title: "Kundeportal",
        description: "Logg inn nedenfor for å bestille varene dine direkte i nettbutikken",
        forgotPasswordBtn: "Glemt passord?",
        loginBtn: "Logg inn",
        passwordLbl: "Passord",
        usernameLbl: "Kundenummer",
    },
    forgotPassword: {
        title: "Glemt passord",
        resetPasswordSuccessNoContent:
            "En lenke ble sendt til e-posten din hvor du kan endre passordet. Hvis du ikke mottok en e-post, kontakt kundeservice.",
        resetPasswordSuccessInfo:
            "En lenke for tilbakestilling av passord ble sendt til {{email}}. Hvis du ikke mottok en e-post, ring kundeservice på {{customerSupportPhone}}",
        customerIdLbl: "Kundenummer",
        submitBtn: "Send meg en ny!",
        description:
            "Vennligst skriv inn ditt kundenummer, så sender vi en tilbakestilling av passord til den registrerte e-posten.",
    },
    changePassword: {
        title: "Endre passord",
        description: "Skriv inn et nytt passord for å oppdatere kontoen din.",
        generatePasswordDescription:
            "Trykk på knappen for å oppdatere brukeren med et nytt generert passord.",
        passwordHasBeenUpdated: "Passordet har blitt oppdatert",
        generateNewPassword: "Generer nytt passord",
    },
    password: {
        managePassword: "Administrer passord",
        passwordEmpty: "Passordet er tomt.",
        createUserSuccess: "Bruker har blitt opprettet.",
        passwordMismatch: "Passordene samsvarer ikke.",
        changePasswordSuccess: "Passordet har blitt endret.",
        changePassword: "Endre passord",
        setPassword: "Angi passord",
        oldPassword: "Gammelt passord",
        newPassword: "Nytt passord",
        confirmNewPassword: "Bekreft nytt passord",
        submit: "Endre passord",
    },
    register: {
        title: "Velg ditt passord",
        welcome: "Velkommen {{customerName}}.",
        yourStoreNumberIs: "Ditt butikknummer og brukernavn er:",
        emailLabel: "E-post for å tilbakestille passord",
        submit: "Lagre",
        snackbarSuccessMessage: "Ditt nye passord ble lagret.",
    },
};
