import { Fade, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { AlertTitle } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import cn from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Body2 } from "./Typography";

const useStyles = makeStyles(theme => ({
    alert: {
        margin: theme.spacing(2, 0),
    },
    title: {
        textAlign: "left",
    },
    msg: {
        whiteSpace: "pre-wrap",
        textAlign: "left",
    },
    lead: {
        whiteSpace: "pre-wrap",
        textAlign: "left",
    },
}));

type Props = {
    showSomethingWentWrong?: boolean;
    clearError?: () => void;
    className?: string;
    error: {
        title?: string;
        body?: string;
        lead?: string;
    };
};

export const ErrorMessage: FC<Props> = ({
    error,
    clearError,
    showSomethingWentWrong = true,
    children,
    className,
    ...props
}) => {
    const { t } = useTranslation("error");
    const classes = useStyles();
    return (
        <Fade timeout={300} in mountOnEnter unmountOnExit>
            <Alert
                className={cn(className, classes.alert)}
                severity="error"
                action={
                    clearError ? (
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={clearError}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    ) : undefined
                }
                {...props}
            >
                {(error.title || showSomethingWentWrong) && (
                    <AlertTitle className={classes.title}>
                        {error.title ?? t("ohNoSomethingWentWrong")}
                    </AlertTitle>
                )}
                {error.lead && <Body2 className={classes.lead}>{error.lead}</Body2>}
                {error.body && <Body2 className={classes.msg}>{error.body}</Body2>}
                {children}
            </Alert>
        </Fade>
    );
};
