import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useInterval } from "./hooks/useInterval";
import { useIsLoggedInAsCustomer } from "./hooks/useIsLoggedInAsCustomer.ts";
import { useAppDispatch } from "./state/configureStore";
import { fetchAssortments, selectAssortmentsError } from "./state/slices/assortmentsSlice";
import { fetchCategories, selectCategoriesError } from "./state/slices/categoriesSlice";
import { fetchClosedInfos } from "./state/slices/closedProductsSlice";
import { fetchCustomer, selectCustomerError } from "./state/slices/customerSlice";
import { fetchDeliveryTerms, selectDeliveryTermsError } from "./state/slices/deliveryTermsSlice";
import { fetchFavorites } from "./state/slices/favoritesSlice";
import {
    selectBypassDeadline,
    selectDeliveryDate,
    selectIsBypassProductAvailability,
} from "./state/slices/orderDetailsSlice";
import { fetchOrderHistory } from "./state/slices/ordersSlice";
import { fetchProducts } from "./state/slices/productsSlice";
import { fetchSupportDetails } from "./state/slices/supportSlice";
import {
    fetchUser,
    selectIsLoggedIn,
    selectLocale,
    selectUserError,
} from "./state/slices/userSlice";
import { fetchWeatherForecast } from "./state/slices/weatherSlice";

const POLL_CLOSED_INFO_INTERVAL = 1000 * 60;

export const AppDataFetcher = () => {
    const dispatch = useAppDispatch();
    const deliveryDate = useSelector(selectDeliveryDate);
    const locale = useSelector(selectLocale);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const customerError = useSelector(selectCustomerError);
    const categoriesError = useSelector(selectCategoriesError);
    const assortmentsError = useSelector(selectAssortmentsError);
    const userError = useSelector(selectUserError);
    const deliveryTermsError = useSelector(selectDeliveryTermsError);
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const bypassDeadline = useSelector(selectBypassDeadline);
    const bypassProductAvailability = useSelector(selectIsBypassProductAvailability);

    const loadError =
        customerError || userError || categoriesError || assortmentsError || deliveryTermsError;
    if (loadError) {
        // Error boundry will catch this
        throw new Error(loadError);
    }

    useInterval(
        () => {
            if (deliveryDate) {
                void dispatch(fetchClosedInfos(deliveryDate));
            }
        },
        POLL_CLOSED_INFO_INTERVAL,
        deliveryDate !== undefined,
    );

    useEffect(() => {
        if (isLoggedIn && !isLoggedInAsCustomer) {
            void dispatch(fetchUser());
        }
    }, [dispatch, isLoggedIn, isLoggedInAsCustomer]);

    useEffect(() => {
        if (isLoggedIn) {
            void dispatch(fetchCustomer());
            void dispatch(fetchFavorites());
            void dispatch(fetchOrderHistory({ page: 0 }));
            void dispatch(fetchWeatherForecast());
        }
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            void dispatch(fetchCategories());
            void dispatch(fetchAssortments());
            void dispatch(fetchSupportDetails());
        }
    }, [dispatch, isLoggedIn, locale]);

    useEffect(() => {
        if (isLoggedIn && deliveryDate) {
            void dispatch(fetchDeliveryTerms(deliveryDate));
        }
    }, [dispatch, isLoggedIn, locale, deliveryDate]);

    useEffect(() => {
        if (isLoggedIn && deliveryDate) {
            void dispatch(
                fetchProducts({ deliveryDate, bypassDeadline, bypassProductAvailability }),
            );
        }
    }, [dispatch, isLoggedIn, locale, deliveryDate, bypassDeadline, bypassProductAvailability]);

    return null;
};
