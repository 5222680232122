import { Button, DialogActions, DialogContent, styled } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DialogHeader } from "../../../../components/DialogHeader";
import { useAppDispatch } from "../../../../state/configureStore";
import { clearOrderSuggestion, OrderSuggestion } from "../../../../state/slices/productMenuSlice";
import { ProductType } from "../../../../state/types";
import { AddOrderSuggestionToCartButton } from "./AddOrderSuggestionToCartButton";
import { OrderSuggestionTable } from "./OrderSuggestionTable";

const StyledDialogAction = styled(DialogActions)({
    justifyContent: "center",
});

type Props = {
    productType: ProductType;
    onClose: () => void;
    onAddToCart: (orderSuggestions: OrderSuggestion[]) => void;
};
export const OrderSuggestionDialogView: FC<Props> = ({ productType, onAddToCart, onClose }) => {
    const { t } = useTranslation(["order", "general"]);
    const dispatch = useAppDispatch();

    return (
        <>
            <DialogHeader
                title={t("order:productMenu.orderSuggestionsMeals")}
                subTitle={t("order:productMenu.orderSuggestionsMealsDescription")}
                onClose={onClose}
            />
            <DialogContent>
                <OrderSuggestionTable productType={productType} />
            </DialogContent>
            <StyledDialogAction>
                <Button onClick={() => dispatch(clearOrderSuggestion())}>
                    {t("general:actions.back")}
                </Button>
                <AddOrderSuggestionToCartButton onAddToCart={onAddToCart} />
            </StyledDialogAction>
        </>
    );
};
