import { GuidanceResource } from "../locales";

export const guidance: GuidanceResource = {
    campaignMaterial: "Campagnemateriaal",
    guidance: "Hulp",
    planogram: "Planogram",
    currentPlanogram: "Huidig Planogram",
    futurePlanogram: "Toekomstig planogram {{start}}",
    labels: "Labels",
    deviationAndClaims: "Afwijking en claims",
    risks: "Risico's",
    certificates: "Certificaten",
};
