import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { TextField, TextFieldProps } from "../../components/TextField";

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
}));

type Props = {
    label: string;
    value: string;
    onChange: (newValue: string) => void;
} & TextFieldProps;

export const AuthInput: FC<Props> = ({ ...props }) => {
    const classes = useStyles();
    return <TextField {...props} className={classes.root} />;
};
