import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import editSvg from "../../assets/images/icons/edit.svg";
import { Body2, H4 } from "../../components/Typography";
import { colors } from "../../constants/colors";
import { CustomerContact } from "../../state/types";
import { MissingInfo } from "./MissingInfo";

const useStyles = makeStyles({
    container: {
        "marginTop": 20,
        "& + $container": {
            borderTop: `1px solid ${colors.darkWhite}`,
            paddingTop: 20,
        },
    },
    header: {
        position: "relative",
        display: "flex",
        alignItems: "center",
    },
    editBtn: {
        marginLeft: "auto",
        top: 0,
        right: 0,
    },
    text: {
        "& p": {
            lineHeight: "25px",
        },
    },
});

type Props = {
    contact?: CustomerContact;
    title?: string;
    onEdit: (contact?: CustomerContact) => void;
};

export const Contact: FC<Props> = ({ contact, title, onEdit }) => {
    const classes = useStyles();
    const { t } = useTranslation(["myAccount"]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                {title && <H4 size={18}>{title}</H4>}
                <IconButton
                    className={classes.editBtn}
                    style={{ position: !title ? "absolute" : undefined }}
                    onClick={() => onEdit(contact)}
                >
                    <img src={editSvg} alt="edit" />
                </IconButton>
            </div>
            {!contact ? (
                <MissingInfo>{t("myAccount:missingContactPerson")}</MissingInfo>
            ) : (
                <div className={classes.text}>
                    {contact.name.length > 0 && <Body2>{contact.name}</Body2>}
                    {contact.email.length > 0 && <Body2>{contact.email}</Body2>}
                    {contact.phone.length > 0 && <Body2>{contact.phone}</Body2>}
                </div>
            )}
        </div>
    );
};
