import {
    IconButton,
    InputAdornment,
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import cn from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../constants/colors";
import { useEmailValidator } from "../hooks/form/useEmailValidator";
import { Body2 } from "./Typography";

const useStyles = makeStyles({
    textField: {
        "marginTop": 30,
        "& .MuiOutlinedInput-root": {
            borderRadius: 20,
        },
        "& .MuiInputLabel-shrink": {
            transform: "translate(0px, -20px)",
            transformOrigin: "unset",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            width: "100%",
            padding: "0 10px",
        },
        "& .MuiFormLabel-asterisk": {
            display: "none",
        },
        "& legend": {
            display: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
        },
        "& .MuiOutlinedInput-input": {
            padding: "14px 14px",
            fontSize: 14,
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: 0,
        },
    },
    notchedOutline: {
        borderColor: `${colors.white} !important`,
        borderWidth: 2,
        top: 0,
    },
    labelInfoText: {
        marginLeft: 5,
    },
    border: {
        padding: "10px 3px",
        border: "1px solid black",
        borderRadius: 10,
    },
    iconBtn: {
        padding: "5px",
    },
    adornment: {
        position: "absolute",
        right: 25,
    },
});

export type TextFieldProps = {
    errorMessage?: string;
    label?: string;
    value: string;
    onChange: (value: string) => void;
} & Omit<MuiTextFieldProps, "onChange">;

export const TextField: FC<TextFieldProps> = ({
    error,
    required,
    label,
    errorMessage,
    InputLabelProps,
    value,
    onChange,
    className,
    type,
    ...props
}) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const { t } = useTranslation("form");
    const classes = useStyles();
    const labelInfoText =
        errorMessage || (value.length === 0 && required ? t("required") : undefined);
    return (
        <MuiTextField
            label={
                label ? (
                    <>
                        <Body2 size={13} overflowEllipsis>
                            {label}
                        </Body2>
                        {labelInfoText && (
                            <Body2
                                className={classes.labelInfoText}
                                italic
                                size={11}
                                overflowEllipsis
                            >
                                {labelInfoText}
                            </Body2>
                        )}
                    </>
                ) : undefined
            }
            variant="outlined"
            className={cn(className, classes.textField)}
            value={value}
            InputProps={{
                endAdornment:
                    onChange !== undefined ? (
                        <InputAdornment position="end" className={classes.adornment}>
                            {value.length > 0 && type === "password" && (
                                <IconButton
                                    tabIndex={-1}
                                    className={classes.iconBtn}
                                    aria-label="visibility"
                                    onClick={() => setPasswordVisibility(visible => !visible)}
                                >
                                    {passwordVisibility ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                            )}
                            {value.length > 0 && (
                                <IconButton
                                    tabIndex={-1}
                                    className={classes.iconBtn}
                                    aria-label="clear"
                                    onClick={() => onChange("")}
                                >
                                    <Clear />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ) : undefined,
            }}
            InputLabelProps={{
                shrink: true,
                ...InputLabelProps,
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={isFocused ? label : ""}
            error={errorMessage !== undefined}
            type={type === "password" && passwordVisibility ? "text" : type}
            required={required}
            onChange={e => onChange(e.target.value)}
            {...props}
        />
    );
};

export const EmailTextField: FC<TextFieldProps> = ({
    onChange,
    error,
    label,
    helperText,
    ...props
}) => {
    const emailValidator = useEmailValidator();
    return (
        <TextField
            label={label}
            autoComplete="email"
            type="email"
            name="email"
            onChange={value => {
                const trimmedValue = value.trim();
                onChange(trimmedValue);
                emailValidator.validate(trimmedValue);
            }}
            errorMessage={emailValidator.error}
            {...props}
        />
    );
};
