import { shouldPolyfill } from "@formatjs/intl-datetimeformat/should-polyfill";
import { Locale } from "../assets/locales/locales";

export async function polyfill(locale: Locale) {
    if (!shouldPolyfill(locale.slice(0, 2))) {
        return;
    }
    // Load the polyfill 1st BEFORE loading data
    await import("@formatjs/intl-datetimeformat/polyfill-force");
    // Parallelize CLDR data loading
    await Promise.all([import("@formatjs/intl-datetimeformat/add-all-tz"), resolve(locale)]);
}

async function resolve(locale: Locale) {
    switch (locale) {
        case "en-US":
            return import(`@formatjs/intl-datetimeformat/locale-data/en`);
        case "sv-SE":
            return import(`@formatjs/intl-datetimeformat/locale-data/sv`);
        case "fr-FR":
            return import(`@formatjs/intl-datetimeformat/locale-data/fr`);
        case "fi-FI":
            return import(`@formatjs/intl-datetimeformat/locale-data/fi`);
        case "nl-BE":
            return import(`@formatjs/intl-datetimeformat/locale-data/nl-BE`);
        case "de-DE":
            return import(`@formatjs/intl-datetimeformat/locale-data/de`);
        case "da-DK":
            return import(`@formatjs/intl-datetimeformat/locale-data/da`);
        case "nb-NO":
            return import(`@formatjs/intl-datetimeformat/locale-data/nb`);
        default:
            console.error(`Missing dateTime polyfill for ${locale}`);
            return import(`@formatjs/intl-datetimeformat/locale-data/en`);
    }
}
