import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../components/Page";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { fetchAssets, selectAssets, selectRequestStatus } from "../../state/slices/assetsSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { AssetCategory, RequestStatus } from "../../state/types";
import { CommunicationAssets } from "./CommunicationAssets";
import { Posters } from "./Posters";

const useStyles = makeStyles(theme => ({
    container: {
        "display": "flex",
        "& .file": {
            margin: "5px 0",
        },
    },
    videos: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "67%",
    },
    posters: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(4),
        flexBasis: "33%",
    },
}));

export const CommunicationPage = () => {
    const locale = useAppSelector(selectLocale);
    const assets = useAppSelector(selectAssets);
    const request = useAppSelector(selectRequestStatus);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("communication");
    const classes = useStyles();

    useEffect(() => {
        void dispatch(fetchAssets());
    }, [dispatch, locale]);

    return (
        <Page loading={request === RequestStatus.Loading && assets.length === 0}>
            <div className={classes.container}>
                <div className={classes.videos}>
                    <CommunicationAssets
                        title={t("digitalMaterial")}
                        assetCategory={AssetCategory.marcomDigitalMaterial}
                    />
                    <CommunicationAssets
                        title={t("tastings")}
                        assetCategory={AssetCategory.marcomTastings}
                    />
                    <CommunicationAssets
                        title={t("news")}
                        assetCategory={AssetCategory.marcomNews}
                    />
                </div>

                <div className={classes.posters}>
                    <Posters
                        mainCategory={AssetCategory.marcomStoreMaterial}
                        title={t("storeMaterial")}
                        tabs={[
                            {
                                assetCategory: AssetCategory.marcomStorePosters50x70,
                                label: "50x70",
                            },
                            {
                                assetCategory: AssetCategory.marcomStorePosters70x100,
                                label: "70x100",
                            },
                            { assetCategory: AssetCategory.marcomStorePostersA1, label: "A1" },
                            { assetCategory: AssetCategory.marcomStorePostersA4, label: "A4" },
                        ]}
                    />

                    <Posters
                        mainCategory={AssetCategory.marcomCampaignMaterial}
                        title={t("campaignMaterial")}
                        tabs={[
                            {
                                assetCategory: AssetCategory.marcomCampaignPosters50x70,
                                label: "50x70",
                            },
                            {
                                assetCategory: AssetCategory.marcomCampaignPosters70x100,
                                label: "70x100",
                            },
                            { assetCategory: AssetCategory.marcomCampaignPostersA1, label: "A1" },
                            { assetCategory: AssetCategory.marcomCampaignPostersA4, label: "A4" },
                        ]}
                    />
                </div>
            </div>
        </Page>
    );
};
