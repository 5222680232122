import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Endpoint } from "../../network/endpoints";
import { del, get, post } from "../../network/restClient";
import { RootState } from "../configureStore";
import { ProductId } from "../types";

export const fetchFavorites = createAsyncThunk("fetch-favorites", async () => {
    return get<ProductId[]>(Endpoint.favorites);
});

export const addFavorite = createAsyncThunk("favorites/add", async (productId: string) => {
    await post(`${Endpoint.favorites}/${productId}`, {}, { snackbar: { showServerError: true } });
    return productId;
});

export const removeFavorite = createAsyncThunk("favorites/remove", async (productId: string) => {
    await del(`${Endpoint.favorites}/${productId}`, {}, { snackbar: { showServerError: true } });
    return productId;
});

type State = {
    loading: boolean;
    error?: string;
};

const favoritesEntityAdapter = createEntityAdapter<ProductId>({
    selectId: productId => productId,
});

const favoritesSlice = createSlice({
    name: "favorites",
    initialState: favoritesEntityAdapter.getInitialState<State>({
        loading: false,
        error: undefined,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchFavorites.fulfilled, (state, action) => {
            favoritesEntityAdapter.setAll(state, action.payload);
        });

        addCase(addFavorite.fulfilled, (state, action) => {
            const productId = action.payload;
            favoritesEntityAdapter.upsertOne(state, productId);
        });

        addCase(removeFavorite.fulfilled, (state, action) => {
            const productId = action.payload;
            favoritesEntityAdapter.removeOne(state, productId);
        });
    },
});

export const {
    selectById: selectFavoriteById,
    selectEntities: selectFavoriteEntities,
    selectAll: selectAllFavorites,
} = favoritesEntityAdapter.getSelectors<RootState>(state => state.favorites);

export const favoritesReducer = favoritesSlice.reducer;
