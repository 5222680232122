import { ReportsResource } from "../locales";

export const reports: ReportsResource = {
    pageTitle: "Rapporter",
    links: {
        productTemperature: "Generera rapport för produkttemperaturer",
        inventory: "Generera inventeringsrapport",
    },
    temperatureMeasurements: {
        filter: {
            btn: {
                reset: "Återställ",
                submit: "Filtrera",
            },
            label: {
                productName: "Produktnamn",
                fromDate: "Från datum",
                toDate: "Till datum",
            },
        },
        table: {
            header: {
                productName: "Produktnamn",
                productNo: "Produktnummer",
                batch: "Batch",
                time: "Tid",
                module: "Modul",
                checkedBy: "Kontrollerad av",
                temperature: "Temperatur",
            },
        },
        title: "Produkttemperaturer",
        spreadsheet: "produkttemperaturer.xlsx",
    },
};
