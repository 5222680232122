import { OrderResource } from "../locales";

export const order: OrderResource = {
    addProductsToCart: "Producten toevoegen aan winkelwagen",
    availableRotationProducts: "Beschikbare vervanging",
    banner: "U hebt een ongebruikelijke grote hoeveelheid in uw winkelwagen. Controleer de hoeveelheid voor de gemarkeerde items voordat u uw bestelling plaatst.",
    cart: {
        excludingVat: "exclusief btw",
        chilled: "Gekoeld",
        couldNotDeliverChosenToDate:
            "Gemarkeerd(e) product(en) kunnen niet worden geleverd op de gekozen bezorgdatum. Kies een van de volgende opties:",
        changeDeliveryDate: "Bezorgdatum wijzigen",
        containCountProducts: "Uw winkelwagen bevat {{count}} product",
        containCountProducts_plural: "Uw winkelwagen bevat  {{count}} producten",
        shipping: {
            banner: {
                total: "Verzending",
                frozen: "Bevroren",
                chilled: "Gekoeld",
            },
            free: {
                title: "Gratis verzending",
                values: {
                    total: "Voor bestellingen boven de {{thresholdFree}}",
                    frozen: "Bevroren: bestel > {{thresholdFree}}",
                    chilled: "Gekoeld: bestel > {{thresholdFree}}",
                },
            },
            ranges: {
                betweenMinAndFree:
                    "Uw bestelling kan niet kosteloos worden bezorgd omdat de limiet van {{thresholdFree}} niet is bereikt. Er worden {{charge}} bezorgkosten in rekening gebracht. Voeg voor gratis bezorging {{remainingCost}} toe!",
                belowMin:
                    "Uw bestelling heeft het minimale bestelbedrag van {{thresholdMin}} niet bereikt. Voeg artikelen met een waarde van minimaal {{remainingCost}} toe om de gratis bezorging te ontgrendelen!",
            },
            total: {
                total: "Totale levering",
                frozen: "Bevroren",
                chilled: "Gekoeld",
            },
        },
        hideCartProducts: "Producten in winkelwagen verbergen",
        frozen: "Bevroren",
        placeOrder: "Nu bestellen",
        removeProductsThatCannotBeDelivered:
            "Verwijder alle producten die niet kunnen worden geleverd",
        selectedDeliveryDate: "Geselecteerde leveringsdatum",
        showCart: "Winkelwagen tonen",
        showCartProducts: "Producten in winkelwagen tonen",
        totalShipping: "Totale levering:",
        title: "Winkelwagen",
        vat: "Btw ({{percentage}})",
        weight: {
            "product-types": "Gewicht Saladebar / Deli / Meenemen",
            "frozen": "Gewicht Gekoeld / Bevroren",
            "no-split": "Gewicht",
        },
    },
    creditFreeze: {
        title: "Belangrijke informatie",
        body:
            "Belangrijke informatie\n" +
            "\n" +
            "Het bedrag van uw onbetaalde facturen is te hoog. Pas nadat uw facturen betaald zijn, kunt u opnieuw een bestelling doen.\n" +
            "\n" +
            "Neem contact op met: {{phone}}",
    },
    confirmMinThresholdOrderDialog: {
        title: "Bevestig",
        body: "Weet u zeker dat u een bestelling wilt plaatsen onder het minimale bestelbedrag?",
        checkboxLabel: "Ik bevestig dat deze bestelling in orde is",
        yes: "Ja, ga verder en plaats deze bestelling",
        no: "Nee, ik wil deze bestelling niet plaatsen",
    },
    deliveryInfo: {
        missingDeliveryCalendar: "Geen leveringskalender",
        title: "Leveringsinformatie",
        myDeliveryCalendar: "Mijn leveringskalender",
        orderRef: "Referentie: {{orderRef}}",
        orderRefLabel: "Referentie invoeren (optioneel)",
        selectedDeliveryDate: "Geselecteerde leveringsdatum",
        pickDeliveryDate: "Leveringsdatum kiezen",
        timeLeftToOrder: "Resterende tijd tot bestellen",
        hideAdvancedOptions: "Geavanceerde opties verbergen",
        showAdvancedOptions: "Geavanceerde opties tonen",
        zeroPriceOrder: "Kosteloze bestelling",
        adminCalendar: "Beheeragenda",
        adminCalendarAnyDeliveryDate: "Bezorgkalender omzeilen (FLS mode)",
        adminCalendarWarning: "Doe voorzichtig. Gebruik deze optie alleen als je weet wat je doet!",
        adminCalendarDeliveryDateWarning:
            "OK, tijd voor nog een waarschuwing: If you select a date in this mode, you will NOT be able to see any closed products AND the order will likely cause an error message in EDI Incoming Orders in Jeeves.",
        table: {
            header: {
                deliveryDate: "Leveringsdatum",
                assortment: "Assortiment",
                lastOrderDeadline: "Deadline voor bestelling",
                onTheWay: "Onderweg",
            },
            btn: {
                select: "Selecteren",
                selectAndCreateOrderProposal: "Selecteer en maak een bestelsuggestie",
            },
            orderSuggestionAvailable: "Bestelsuggestie beschikbaar",
        },
    },
    filter: {
        showAllProducts: "All producten",
        showPlanogramProducts: "Planogramproducten",
        showFavorites: "Favorieten tonen",
        showAccessories: "Accessoires",
    },
    orderSuggestion: {
        fetchSuggestionButton: "Bestelsuggestie toevoegen aan winkelwagen",
        replaceCartDialog: {
            content:
                "Als u de producten van de bestelsuggestie toevoegt aan de winkelwagen, worden de bestaande producten verwijderd.",
            title: "Winkelwagen vervangen",
        },
    },
    placeOrderSuccess: {
        snackbarMessage: "We hebben uw bestelling ontvangen.",
        thankYouForYourPurchase: "Bedankt voor uw aankoop.",
        yourOrderHasNowBeenReceived: "We hebben uw bestelling ontvangen.",
        continueShopping: "Doorgaan met winkelen",
    },
    placeOrderError: {
        couldNotPlaceOrder: "Bestelling kon niet worden geplaatst.",
        removeInvalidProductsBtn: "Ongeldige producten verwijderen",
    },
    planogramPdfBtn: "Planogram-PDF",
    productAlreadyInCart: "Zit al in de winkelwagen",
    productAlreadyInList: "Bestel in productlijst",
    productHasBeenReplaced: "Je hebt {{productName}} vervangen",
    productTable: {
        articleNo: "Artikelnr.",
        comparisonPrice: "Prijs/kg",
        comparisonPriceNonFood: "Prijs/kg",
        dataSheet: "Gegevensblad versie {{version}}",
        icon: {
            frozen: "Bevroren",
            inPlanogram: "In planogram",
            orderAssortment: "Bestelassortiment",
            news: "Nieuws",
        },
        name: "naam",
        onTheWay: "onderweg",
        pricePerUnit: "prijs/eenheid",
        selectRotationProduct: "Vervangen",
        selectPriceListProduct: "Selecteer",
        unit: "eenheid",
    },
    productMenu: {
        orderHelpMeals: "Order help meals",
        orderSuggestionsMeals: "Order suggestion meals",
        orderSuggestionsMealsDescription:
            "Here you can see the amount of each product needed for the number of meals you have chosen. The surplus / deficit column shows how much is left over or missing. NOTE Do not forget items for the bulk sale.",
        pricePerPortion: "Price / Portion",
        numPackages: "Number of packages",
        numPortions: "Number of portions",
        perPortion: "Per portion",
        excessWeightTitle: "Surplus / deficit",
        meals: "Meals",
        mealsDescription:
            "Order the desired number of portions per dish. If you order several meals containing, for example, mashed potatoes, the quantity is added together to minimize possible wastage.",
        createOrderSuggestion: "Create order suggestion",
        someProductsCannotBeDelivered:
            "One or more products in this meal can not be delivered. Click for more information.",
    },
    searchProduct: "Zoeken op artikelnummer of naam",
    selectAReplacement: "Kies vervangend product",
    thresholdWarningDialog: {
        pleaseCheck:
            "Controleer dat de hoeveelheid de gewenste hoeveelheid is voordat u de bestelling plaatst.",
        title: "Een ongebruikelijk grote hoeveelheid",
        weHaveNoticed:
            "We hebben gemerkt dat de hoeveelheid van onderstaand(e) product(en) ongebruikelijk hoog is:",
    },
    unavailableRotationProducts: "Gesloten artikel",
};
