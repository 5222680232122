import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { FC } from "react";
import { Body2, Props as TypographyProps } from "../../../components/Typography";

const useStyles = makeStyles(theme => ({
    container: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(19),
    },
}));

export const CartSmallText: FC<TypographyProps> = ({ className, ...props }) => {
    const classes = useStyles();
    return <Body2 className={cn(className, classes.container)} {...props} />;
};
