import * as Sentry from "@sentry/react";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { Locale } from "../assets/locales/locales";
import { roundOneDecimal, roundTwoDecimals } from "./math";

export function formatPercent(percent: number, locale?: string) {
    const fallback = `${roundTwoDecimals(percent * 100)}%`;
    if (!locale) {
        return fallback;
    }

    try {
        return percent.toLocaleString(locale, {
            style: "percent",
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
        });
    } catch (e) {
        Sentry.captureMessage("Could not format percent", {
            level: "warning",
            extra: {
                error: e,
                errorInfo: {
                    percent,
                    locale,
                },
            },
        });
        return `${fallback}`;
    }
}

export function formatPrice(price: number, currency?: string, locale?: string) {
    if (!currency || !locale) {
        return "";
    }

    try {
        return price.toLocaleString(locale, {
            style: "currency",
            currency,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    } catch (e) {
        Sentry.captureMessage("Could not format price", {
            level: "error",
            extra: {
                error: e,
                errorInfo: {
                    price,
                    currency,
                    locale,
                },
            },
        });
        return `${currency} ${roundTwoDecimals(price)}`;
    }
}

type FormatOpts = DateTimeFormatOptions;

export function formatDate(dateStr: string, locale: string, formatOpts?: FormatOpts) {
    // Use UTC instead of Local time to avoid this formatjs bug:
    // https://github.com/formatjs/formatjs/issues/3046
    // We need the type assertion due to incompatible properties in the two union types.
    try {
        return DateTime.fromISO(dateStr, { zone: "UTC" })
            .setLocale(locale)
            .toLocaleString({ ...formatOpts, timeZone: "UTC" } as FormatOpts);
    } catch (e) {
        Sentry.captureMessage("Could not format date", {
            level: "error",
            extra: {
                error: e,
                errorInfo: {
                    locale,
                    dateStr,
                    formatOpts,
                },
            },
        });
        return dateStr;
    }
}

export function formatDateTime(
    dateStr: string,
    locale: string,
    timeZone?: string,
    formatOpts?: FormatOpts,
) {
    if (!timeZone) return "";
    try {
        return DateTime.fromISO(dateStr)
            .setZone(timeZone)
            .setLocale(locale)
            .toLocaleString(formatOpts);
    } catch (e) {
        Sentry.captureMessage("Could not format datetime", {
            level: "error",
            extra: {
                error: e,
                errorInfo: {
                    locale,
                    dateStr,
                    timeZone,
                    formatOpts,
                },
            },
        });
        return dateStr;
    }
}

export function formatTemperature(
    celsius: number,
    locale: Locale,
    options: { maximumFractionDigits?: number; minimumFractionDigits?: number } = {},
) {
    const unit = locale === "en-US" ? "fahrenheit" : "celsius";
    const temperature: number = unit === "fahrenheit" ? toFahrenheit(celsius) : celsius;
    try {
        return temperature.toLocaleString(locale, {
            style: "unit",
            unit,
            minimumFractionDigits: options.minimumFractionDigits ?? 1,
            maximumFractionDigits: options.maximumFractionDigits ?? 1,
        });
    } catch (e: unknown) {
        Sentry.captureMessage("Could not format temperature", {
            level: "error",
            extra: {
                error: e,
                errorInfo: {
                    celsius,
                    locale,
                    options,
                },
            },
        });
        return `${roundOneDecimal(temperature)} ${unit === "fahrenheit" ? "°F" : "°C"}`;
    }
}

function toFahrenheit(celsius: number) {
    return celsius * 1.8 + 32;
}

export function formatWeight(
    kg: number,
    locale: Locale,
    options: { maximumFractionDigits?: number; showGrams?: boolean } = { showGrams: false },
) {
    let weight: { fallback: string; unit: string; value: number };
    switch (locale) {
        default:
            weight = options.showGrams
                ? {
                      fallback: "g",
                      unit: "gram",
                      value: kg * 1000,
                  }
                : {
                      fallback: "kg",
                      unit: "kilogram",
                      value: kg,
                  };
            break;
        case "en-US":
            weight = {
                fallback: "lb",
                unit: "pound",
                value: kg * 2.20462262,
            };
    }

    try {
        return weight.value.toLocaleString(locale, {
            style: "unit",
            unit: weight.unit,
            maximumFractionDigits: options?.maximumFractionDigits ?? 1,
        });
    } catch (e) {
        Sentry.captureMessage("Could not format weight", {
            level: "error",
            extra: {
                error: e,
                errorInfo: {
                    kg,
                    locale,
                    options,
                },
            },
        });
        return `${roundOneDecimal(weight.value)} ${weight.fallback}`;
    }
}

export function getWeightUnit(locale: Locale) {
    switch (locale) {
        case "en-US":
            return "lbs";
        default:
            return "kg";
    }
}
