import { LandingResource } from "../locales";

export const landing: LandingResource = {
    news: "Nouveautés",
    messages: "Messages",
    home: "Écran d'accueil",
    showAllNews: "Afficher toutes les nouveautés ({{count}})",
    showAllMessages: "Afficher tout",
    myMessages: "Mes messages",
    noMessages: "Vous n'avez pas de messages",
    contactUs: "Contactez-nous",
};
