import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
    divider: {
        background: theme.palette.primary.main,
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

export const CartHeaderDivider = () => {
    const classes = useStyles();
    return <Divider className={classes.divider} orientation="vertical" color="primary" flexItem />;
};
