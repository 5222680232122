import { GeneralResource } from "../locales";

export const general: GeneralResource = {
    actions: {
        review: "Granska",
        close: "Stäng",
        ok: "Ok",
        back: "Tillbaka",
        print: "Skriv ut",
        printArticleNumberScheme: "Skriv ut - artikelnummerordning",
        submit: "Skicka",
        save: "Spara",
        edit: "Redigera",
        cancel: "Avbryt",
        loadMore: "Ladda fler",
    },
    articles: "Artiklar",
    edit: "Ändra",
    navigationLinks: {
        createUser: "Skapa en användare",
        changePassword: "Ändra lösenord",
        communication: "Kommunikation",
        guidance: "Vägledning",
        support: "Kundsupport",
        logout: "Logga ut",
        account: "Konto",
        myAccount: "Mitt konto",
        order: "Beställning",
        orderHistory: "Orderhistorik",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "Ingen tid kvar",
        short: {
            days: "{{days}}d",
            hours: "{{hours}}t",
            minutes: "{{minutes}}m",
            seconds: "{{seconds}}s",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Beställning mottagen",
        "created": "Bekräftad",
        "dispatched": "Plock pågår",
        "canceled": "Avbruten",
        "invoiced": "Fakturerad",
    },
    price: {
        total: "Totalt",
        totalExclVat: "Total (ex moms)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Food to go",
        "salad-bar": "Salladsbar",
    },
    salesUnit: {
        kg: "Kg",
        krt: "Kartong",
        pcs: "Styck",
    },
    address: {
        invoice: "Fakturaaddress",
        delivery: "Leveransaddress",
    },
    weight: "Vikt",
    loggedInAsCustomer: "Inloggad som kund",
};
