import {
    makeStyles,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import cn from "classnames";
import { DateTime } from "luxon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { H4 } from "../../../components/Typography";
import { colors } from "../../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../../state/configureStore";
import { changeProductInCart, selectCartItemById } from "../../../state/slices/cartSlice";
import { selectAllCategories } from "../../../state/slices/categoriesSlice";
import { selectCustomer } from "../../../state/slices/customerSlice";
import { changeSalesUnitQuantity } from "../../../state/slices/productSelectionSlice";
import {
    selectAllAvailableReplacementProducts,
    selectAvailableRotationProducts,
    selectChangeProductMenuProducts,
    selectOriginalProduct,
    selectSaladBarFilter,
    selectSelectedRotationProducts,
    selectUpcomingDeliveries,
} from "../../../state/slices/productSelectors";
import { setSelectedRotationProducts } from "../../../state/slices/productsSlice";
import { selectLocale } from "../../../state/slices/userSlice";
import { Product, ProductType, ReplaceOrderProductPolicy } from "../../../state/types";
import { formatDate } from "../../../utils/formatters";
import { ProductTableRow } from "./ProductTableRow";
import { ProductTables } from "./ProductTables";

const useStyles = makeStyles({
    root: {
        "& .MuiPaper-root": { borderRadius: 30, padding: 16 },
    },
    topMargin: {
        marginTop: 30,
    },
    selectAReplacement: {
        marginBottom: 16,
    },
});

type Props = {
    allowReplacingCartItem?: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onReplace: () => void;
    product: Product;
};

export const ChangeProductMenu: FC<Props> = ({
    allowReplacingCartItem,
    anchorEl,
    onClose,
    onReplace,
    product,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("order");
    const classes = useStyles();

    const categories = useSelector(selectAllCategories);
    const productCategories = useSelector(selectAllAvailableReplacementProducts);
    const replaceOrderProductPolicy = useSelector(selectCustomer)?.replaceOrderProductPolicy;
    const selectedRotationProducts = useSelector(selectSelectedRotationProducts);
    const saladBarFilter = useSelector(selectSaladBarFilter);
    const selectedRotationProductId = selectedRotationProducts[product.productId];
    const upcomingDeliveries = useSelector(selectUpcomingDeliveries);
    const cartItem = useAppSelector(state =>
        selectCartItemById(state, `${product.productId}${ProductType.SaladBar}`),
    );
    const originalProduct = useAppSelector(state =>
        selectOriginalProduct(product.productId)(state),
    );
    const productsList = useAppSelector(state => selectChangeProductMenuProducts(state, product));
    const availableRotationProducts = useAppSelector(state =>
        selectAvailableRotationProducts(state, product),
    );

    const showFullTable =
        product.closedInfo !== undefined &&
        availableRotationProducts.length === 0 &&
        !cartItem &&
        replaceOrderProductPolicy === ReplaceOrderProductPolicy.ReplaceClosedWithAny;

    const replaceCartItem = async ({
        overridden,
        productId,
    }: {
        overridden: string;
        productId: string;
    }) => {
        if (!cartItem) return;

        const productType = ProductType.SaladBar;
        const salesUnitQuantity = cartItem.salesUnitQuantity ?? 0;
        await dispatch(
            changeProductInCart({
                replacedProduct: cartItem,
                replacingProduct: { productId, productType, salesUnitQuantity },
            }),
        );
        if (productId === originalProduct?.productId) {
            dispatch(setSelectedRotationProducts({ overridden: productId, override: undefined }));
            dispatch(setSelectedRotationProducts({ overridden, override: undefined }));
        }
    };

    const handleChangeProduct = async (productId: string) => {
        const overridden = product.productId;
        const override = overridden === productId ? undefined : productId;
        onClose();
        onReplace();
        dispatch(
            changeSalesUnitQuantity({
                productId: selectedRotationProductId ?? overridden,
                productType: ProductType.SaladBar,
                salesUnitQuantity: 0,
            }),
        );
        dispatch(setSelectedRotationProducts({ overridden, override }));
        if (cartItem) {
            await replaceCartItem({ overridden, productId });
        }
    };

    return (
        <Popover
            anchorEl={showFullTable ? document.getElementById("root") : anchorEl}
            anchorOrigin={
                showFullTable
                    ? { horizontal: "center", vertical: "center" }
                    : {
                          horizontal: "left",
                          vertical: "bottom",
                      }
            }
            transformOrigin={
                showFullTable ? { horizontal: "center", vertical: "center" } : undefined
            }
            BackdropProps={{ invisible: false }}
            className={classes.root}
            getContentAnchorEl={null}
            onClose={onClose}
            open={Boolean(anchorEl)}
        >
            <>
                {showFullTable && (
                    <>
                        <H4>{t("unavailableRotationProducts")}</H4>
                        <ProductMenuTable
                            upcomingDeliveryDates={upcomingDeliveries.getTableColumns([product])}
                            className={classes.topMargin}
                        >
                            <ProductTableRow
                                allowReplacingCartItem={allowReplacingCartItem}
                                disabled
                                key={product.productId}
                                onChangeProduct={handleChangeProduct}
                                product={product}
                                productType={ProductType.SaladBar}
                                rowId={product.productId}
                                selectProductButton={t("productTable.selectRotationProduct")}
                                upcomingDeliveryDates={upcomingDeliveries.getTableColumns([
                                    product,
                                ])}
                            />
                        </ProductMenuTable>
                    </>
                )}
                {productsList.length > 0 && (
                    <>
                        <H4 className={cn({ [classes.topMargin]: showFullTable })}>
                            {t("availableRotationProducts")}
                        </H4>
                        <ProductMenuTable
                            upcomingDeliveryDates={upcomingDeliveries.getTableColumns(productsList)}
                            className={classes.topMargin}
                        >
                            {productsList.map((arp, index) => (
                                <ProductTableRow
                                    allowReplacingCartItem={allowReplacingCartItem}
                                    disabled={arp.closedInfo !== undefined}
                                    key={arp.productId}
                                    onChangeProduct={handleChangeProduct}
                                    product={arp}
                                    productType={ProductType.SaladBar}
                                    rowId={`table-row-${arp.productId}`}
                                    showBackgroundTint={index % 2 === 1}
                                    selectProductButton={t("productTable.selectRotationProduct")}
                                    upcomingDeliveryDates={upcomingDeliveries.getTableColumns(
                                        productsList,
                                    )}
                                />
                            ))}
                        </ProductMenuTable>
                    </>
                )}
                {showFullTable && (
                    <>
                        <H4 className={cn(classes.topMargin, classes.selectAReplacement)}>
                            {t("selectAReplacement")}
                        </H4>
                        <ProductTables
                            filter={saladBarFilter}
                            onChangeProduct={handleChangeProduct}
                            products={productCategories.sort((a, b) => {
                                const categoryNames = product.categoryIds.map(
                                    categoryId =>
                                        categories.find(c => c.categoryId === categoryId)?.name,
                                );
                                return categoryNames.includes(a.category)
                                    ? -1
                                    : categoryNames.includes(b.category)
                                    ? 1
                                    : 0;
                            })}
                            productType={ProductType.SaladBar}
                            showFavorite={false}
                            selectProductButton={t("productTable.selectPriceListProduct")}
                            showSelectProductButtonIcon={false}
                            disabledImageDialog
                        />
                    </>
                )}
            </>
        </Popover>
    );
};

const useTableStyles = makeStyles({
    th: {
        textTransform: "uppercase",
        fontSize: 14,
        lineHeight: "1rem",
        backgroundColor: colors.darkWhite,
    },
    transitHeader: {
        whiteSpace: "nowrap",
    },
    transitDate: {
        whiteSpace: "nowrap",
        fontSize: 12,
    },
});

const ProductMenuTable: FC<{ className?: string; upcomingDeliveryDates: string[] }> = ({
    children,
    upcomingDeliveryDates,
    className,
}) => {
    const { t } = useTranslation("order");
    const locale = useSelector(selectLocale);
    const customer = useSelector(selectCustomer);
    const classes = useTableStyles();

    return (
        <Table className={cn(className)}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.th}>{/* Favorite column */}</TableCell>
                    <TableCell align="center" className={classes.th}>
                        {t("productTable.articleNo")}
                    </TableCell>
                    <TableCell className={classes.th}>{/* Image column */}</TableCell>
                    <TableCell className={classes.th}>{t("productTable.name")}</TableCell>
                    <TableCell className={classes.th}>{/* Icons column */}</TableCell>
                    <TableCell className={classes.th}>{t("productTable.unit")}</TableCell>
                    {customer?.showPrices && (
                        <TableCell className={classes.th}>
                            {t("productTable.pricePerUnit")}
                        </TableCell>
                    )}
                    {customer?.showPrices && (
                        <TableCell className={classes.th}>
                            {t("productTable.comparisonPrice")}
                        </TableCell>
                    )}
                    {upcomingDeliveryDates.map(deliveryDate => (
                        <TableCell align="center" className={classes.th} key={deliveryDate}>
                            <span className={classes.transitHeader}>
                                {t("productTable.onTheWay")}
                            </span>{" "}
                            <br />
                            <span className={classes.transitDate}>
                                {formatDate(deliveryDate, locale, DateTime.DATE_SHORT)}
                            </span>
                        </TableCell>
                    ))}
                    <TableCell className={classes.th}>{/* Select/closed info column */}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
        </Table>
    );
};
