import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { fetchNewsAndMessages } from "../sharedActions";
import { News, RequestStatus } from "../types";

const adapter = createEntityAdapter<News>({
    selectId: entity => entity.newsId,
});

const slice = createSlice({
    name: "news",
    initialState: adapter.getInitialState<{ error?: string; requestStatus: RequestStatus }>({
        requestStatus: RequestStatus.Idle,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchNewsAndMessages.pending, (state, action) => {
            state.requestStatus = RequestStatus.Loading;
        });
        addCase(fetchNewsAndMessages.fulfilled, (state, action) => {
            state.requestStatus = RequestStatus.Fulfilled;
            adapter.setAll(state, action.payload.news);
        });
        addCase(fetchNewsAndMessages.rejected, (state, { error }) => {
            state.requestStatus = RequestStatus.Rejected;
        });
    },
});

export const { selectAll: selectNews } = adapter.getSelectors<RootState>(state => state.news);

export const selectRequestStatus = (state: RootState) => state.news.requestStatus;

export const newsReducer = slice.reducer;
