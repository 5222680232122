import { AccessToken, Permission } from "../state/types";
import { history } from "../utils/history";
import { Endpoint } from "./endpoints";
import { CustomerIsMissingError } from "./networkError";
import { get } from "./restClient";

let token: (AccessToken & { expiresAt: Date }) | undefined;
let customerId: number | undefined;

export function setAccessToken(accessToken: AccessToken) {
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + (accessToken.maxAge - 10));
    token = { ...accessToken, expiresAt };
}

export function getAccessToken() {
    return token;
}

export function hasAccessToken() {
    return token !== undefined;
}

export function isTokenExpired() {
    return token ? token.expiresAt < new Date() : true;
}

export function setCustomerFromQueryParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const customerIdParam = searchParams.get("customer-id");
    if (customerIdParam) {
        const parsedCustomerId = parseInt(customerIdParam, 10);
        if (!isNaN(parsedCustomerId)) {
            searchParams.delete("customer-id");
            history.replace({
                search: searchParams.toString(),
            });
            setCustomerId(parsedCustomerId);
        }
    }
}

function setCustomerId(id: number) {
    customerId = id;
}

export function clearAuth() {
    removeAccessToken();
    clearCustomerId();
}

export function clearCustomerId() {
    customerId = undefined;
}

function removeAccessToken() {
    token = undefined;
}

export function isLoggedInAsCustomer() {
    const permissions = token?.permissions ?? [];
    return permissions.includes(Permission.LogInAsCustomer);
}

export async function fetchAndSetToken() {
    const response = await get<AccessToken>(Endpoint.accessToken, {
        requireAuth: false,
        headers: {
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
            "Expires": "0",
        },
        params: {
            "customer-id": customerId,
        },
    });
    if (response.customerId === undefined) {
        throw new CustomerIsMissingError();
    }
    setAccessToken(response);
}
