import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    selectFoodToGoFilter,
    selectFoodToGoProducts,
} from "../../../state/slices/productSelectors";
import { filterByType } from "../../../state/slices/productsSlice";
import { FilterType, ProductType } from "../../../state/types";
import { ProductTables } from "../product-table/ProductTables";
import { FilterButton } from "./FilterButton";
import { TabContent } from "./TabContent";
import { TabHeader } from "./TabHeader";

export const FoodToGoTab: FC<{ tabIndex: number }> = ({ tabIndex }) => {
    const { t } = useTranslation("order");
    const dispatch = useDispatch();
    const filter = useSelector(selectFoodToGoFilter);
    const products = useSelector(selectFoodToGoProducts);

    return (
        <TabContent tabIndex={tabIndex}>
            <TabHeader>
                <FilterButton
                    onClick={() =>
                        dispatch(
                            filterByType({
                                productType: ProductType.FoodToGo,
                                filterType: FilterType.all,
                            }),
                        )
                    }
                    highlight={filter === FilterType.all}
                >
                    {t("filter.showAllProducts")}
                </FilterButton>
                <FilterButton
                    onClick={() =>
                        dispatch(
                            filterByType({
                                productType: ProductType.FoodToGo,
                                filterType: FilterType.favorites,
                            }),
                        )
                    }
                    highlight={filter === FilterType.favorites}
                >
                    {t("filter.showFavorites")}
                </FilterButton>
            </TabHeader>

            <ProductTables
                showFavorite
                productType={ProductType.FoodToGo}
                products={products}
                filter={filter}
            />
        </TabContent>
    );
};
