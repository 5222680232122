import { Product } from "../state/types";

export const productSortComparer = (locale: string) => (a: Product, b: Product) => {
    if (a.isInPlanogram && !b.isInPlanogram) {
        return -1;
    } else if (!a.isInPlanogram && b.isInPlanogram) {
        return 1;
    }
    return a.name.localeCompare(b.name, locale);
};
