import { Button } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../state/configureStore";
import { selectClosedInfoEntities } from "../../../../state/slices/closedProductsSlice";
import {
    onCreateOrderSuggestion,
    selectMenuEntities,
    selectMenuQuantity,
} from "../../../../state/slices/productMenuSlice";
import { selectProductEntities } from "../../../../state/slices/productsSlice";
import { GtmEvents, pushDataLayer } from "../../../../utils/pushDataLayer";

export const CreateOrderSuggestionButton: FC = () => {
    const { t } = useTranslation(["order"]);
    const dispatch = useAppDispatch();
    const menuQuantityMap = useSelector(selectMenuQuantity);
    const productMenuMap = useSelector(selectMenuEntities);
    const productMap = useSelector(selectProductEntities);
    const closedInfos = useSelector(selectClosedInfoEntities);

    const handleCreateOrderSuggestion = () => {
        pushDataLayer(GtmEvents.OrderAssistanceMeal);
        dispatch(onCreateOrderSuggestion({ productMap, productMenuMap, closedInfos }));
    };

    return (
        <Button
            color="primary"
            onClick={handleCreateOrderSuggestion}
            disabled={Object.keys(menuQuantityMap).length === 0}
            variant="contained"
        >
            {t("order:productMenu.createOrderSuggestion")}
        </Button>
    );
};
