import { GeneralResource } from "../locales";

export const general: GeneralResource = {
    actions: {
        review: "Voir",
        close: "Fermer",
        back: "Retour",
        ok: "OK",
        print: "Imprimer",
        printArticleNumberScheme: "Imprimer la commande, commandé par numéro d'article",
        submit: "Envoyer",
        save: "Enregistrer",
        edit: "Modifier",
        cancel: "Annuler",
        loadMore: "Charger plus",
    },
    articles: "Articles",
    edit: "Modifier",
    navigationLinks: {
        createUser: "Créer un utilisateur",
        changePassword: "Changer le mot de passe",
        communication: "Communication",
        guidance: "Support",
        support: "Service client",
        logout: "Déconnexion",
        account: "Compte",
        myAccount: "Mon compte",
        order: "Commander",
        orderHistory: "Historique",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "Ne reste plus de temps",
        short: {
            days: "{{days}}d",
            hours: "{{hours}}h",
            minutes: "{{minutes}}m",
            seconds: "{{seconds}}s",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Commande reçue",
        "created": "Confirmé",
        "dispatched": "Préparé",
        "canceled": "Annulée",
        "invoiced": "Facturé",
    },
    price: {
        total: "Total",
        totalExclVat: "Total (HT)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Food To Go",
        "salad-bar": "Bar à salades",
    },
    salesUnit: {
        kg: "Kg",
        krt: "Colis",
        pcs: "Pi",
    },
    address: {
        invoice: "Adresse de facturation",
        delivery: "Adresse de livraison",
    },
    weight: "Poids",
    loggedInAsCustomer: "Connecté en tant que client",
};
