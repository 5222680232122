import { makeStyles, MenuItem } from "@material-ui/core";
import { Language as LanguageIcon } from "@material-ui/icons";
import cn from "classnames";
import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Locale } from "../../assets/locales/locales";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import { selectLocale, setLocale } from "../../state/slices/userSlice";
import { IconSelect } from "../IconSelect";

const useStyles = makeStyles({
    container: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    icon: {
        marginRight: 5,
    },
});

type Props = {
    className?: string;
    locales: Locale[];
};

export const LocaleSelect: FC<Props> = ({ className, locales }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);
    const locale = useAppSelector(selectLocale);
    const { t } = useTranslation("general");
    const classes = useStyles();
    const dispatch = useAppDispatch();

    return (
        <div
            ref={containerRef}
            className={cn(className, classes.container)}
            onClick={() => setOpen(cur => !cur)}
        >
            <LanguageIcon className={classes.icon} fontSize="small" />
            <IconSelect
                value={locale}
                open={isOpen}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    anchorEl: containerRef?.current,
                    getContentAnchorEl: undefined,
                }}
            >
                {locales.map(item => (
                    <MenuItem
                        key={item}
                        value={item}
                        onClick={() => void dispatch(setLocale(item))}
                    >
                        {t(`general:locales:${item}` as const)}
                    </MenuItem>
                ))}
            </IconSelect>
        </div>
    );
};
