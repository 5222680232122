import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingButton } from "../../../../components/LoadingButton";
import { Body1 } from "../../../../components/Typography";
import { useDialog } from "../../../../hooks/useDialog";
import { useIsLoggedInAsCustomer } from "../../../../hooks/useIsLoggedInAsCustomer.ts";
import { useAppDispatch } from "../../../../state/configureStore";
import {
    selectCartProducts,
    selectIsOrderBelowMinThreshold,
    selectPlaceOrderDisabled,
    selectUpdateCartItemStatus,
} from "../../../../state/slices/cartSlice";
import {
    selectDeliveryDate,
    selectIsBypassProductAvailability,
    selectIsZeroPriceOrder,
    selectOrderRef,
} from "../../../../state/slices/orderDetailsSlice";
import { placeOrder, selectPlaceOrderLoading } from "../../../../state/slices/ordersSlice";
import { RequestStatus } from "../../../../state/types";
import { groupBy } from "../../../../utils/array";
import { HeaderColumn } from "../HeaderColumn";
import { ConfirmMinThresholdOrderDialog } from "./ConfirmMinThresholdOrderDialog";
import { FreeShippingInfo } from "./FreeShippingInfo";

type Props = {
    showPlaceOrderBtn?: boolean;
};

const useStyles = makeStyles({
    orderMatText: {
        maxWidth: "10rem",
    },
});

export const OrderColumn: FC<Props> = ({ showPlaceOrderBtn }) => {
    const { t } = useTranslation("order");
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const isOrderBelowMinThreshold = useSelector(selectIsOrderBelowMinThreshold);
    const confirmMinThresholdOrderDialog = useDialog();
    const placeOrderDisabled = useSelector(selectPlaceOrderDisabled);
    const placeOrderLoading = useSelector(selectPlaceOrderLoading);
    const updateCartItemStatus = useSelector(selectUpdateCartItemStatus);
    const cartProducts = useSelector(selectCartProducts);
    const deliveryDate = useSelector(selectDeliveryDate);
    const zeroPriceOrder = useSelector(selectIsZeroPriceOrder);
    const reference = useSelector(selectOrderRef);
    const isLoggedInAsCustomer = useIsLoggedInAsCustomer();
    const bypassProductAvailability = useSelector(selectIsBypassProductAvailability);

    const handlePlaceOrder = async () => {
        if (updateCartItemStatus === RequestStatus.Loading) {
            // If we are updating a cart item, we do not allow the user to place an order.
            // We check this here instead of disabling the button since we do not want the
            // "place order" button to flash every time we change the quantity.
            // This will practically never happen.
            return;
        }
        if (deliveryDate) {
            const items = Object.entries(groupBy(cartProducts, p => p.productId)).map(
                ([productId, productsById]) => ({
                    productId,
                    quantity: productsById.reduce(
                        (sum, p) => sum + p.salesUnitQuantity * p.orderMultiple,
                        0,
                    ),
                }),
            );
            await dispatch(
                placeOrder({
                    order: {
                        reference,
                        deliveryDate,
                        items,
                        zeroPriceOrder,
                        bypassProductAvailability,
                    },
                }),
            );
        }
    };

    return (
        <HeaderColumn>
            {showPlaceOrderBtn ? (
                <LoadingButton
                    disabled={placeOrderDisabled}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (isLoggedInAsCustomer && isOrderBelowMinThreshold) {
                            confirmMinThresholdOrderDialog.open();
                        } else {
                            void handlePlaceOrder();
                        }
                    }}
                    loading={placeOrderLoading}
                >
                    {t("cart.placeOrder")}
                </LoadingButton>
            ) : (
                <Body1 className={classes.orderMatText}>{t("cart.orderMatText")}</Body1>
            )}
            <FreeShippingInfo />

            <ConfirmMinThresholdOrderDialog
                open={confirmMinThresholdOrderDialog.isOpen}
                onClose={confirmMinThresholdOrderDialog.close}
                onConfirm={handlePlaceOrder}
            />
        </HeaderColumn>
    );
};
