import { GeneralResource } from "../locales";

export const general: GeneralResource = {
    actions: {
        review: "Tarkista",
        close: "Sulje",
        back: "Takaisin",
        ok: "OK",
        print: "Tulosta",
        printArticleNumberScheme: "Tulosta - lajittelu tuotenumeron mukaan",
        submit: "Lähetä",
        save: "Tallenna",
        edit: "Muokkaa",
        cancel: "Peruuta",
        loadMore: "Lataa lisää",
    },
    articles: "Artikkelit",
    edit: "Muokkaa",
    navigationLinks: {
        createUser: "Luo käyttäjä",
        changePassword: "Vaihda salasana",
        communication: "Asiakaspalvelu",
        guidance: "Info ja ohjeet",
        support: "Asiakastuki",
        logout: "Kirjaudu ulos",
        account: "Asiakastili",
        myAccount: "Oma asiakastili",
        order: "Verkkokauppa",
        orderHistory: "Tilaushistoria",
    },
    metrics: {
        kg: "kg",
    },
    time: {
        noTimeLeft: "Aika loppui",
        short: {
            days: "{{days}}d",
            hours: "{{hours}}h",
            minutes: "{{minutes}}m",
            seconds: "{{seconds}}s",
        },
    },
    orderStatus: {
        "not-in-jeeves": "Tilaus vastaanotettu",
        "created": "Vahvistettu",
        "dispatched": "Noudettavana",
        "canceled": "Peruutettu",
        "invoiced": "Laskutettu",
    },
    price: {
        total: "Yhteensä",
        totalExclVat: "Yhteensä (ei sis. alv)",
    },
    productTypes: {
        "deli": "Deli",
        "food-to-go": "Ruokaa mukaan",
        "salad-bar": "Salaattibaari",
    },
    salesUnit: {
        kg: "kg",
        krt: "Paketti",
        pcs: "Kappale",
    },
    address: {
        invoice: "Laskutusosoite",
        delivery: "Toimitusosoite",
    },
    weight: "Paino",
    loggedInAsCustomer: "Kirjauduttu sisään asiakkaana",
};
